import React from 'react';
import Avatar from './index';

export default function itemCardUserAvatar(props) {
    const { sharedItemUsers } = props;
    const enableClickType = 'itemUserShare';
    return (
        <div className="flex flex-wrap justify-items-auto">
            {sharedItemUsers && Array.isArray(sharedItemUsers) ? (
                sharedItemUsers.map((sharedItemUser, itrCnt) => {
                    return (
                        <div className="pr-2" key={sharedItemUser.user.id + '||' + itrCnt.toString()}>
                            <Avatar
                                key={sharedItemUser.user.id + '|' + itrCnt.toString()}
                                userId={sharedItemUser.user.id}
                                firstName={sharedItemUser.user.id ? sharedItemUser.user.firstName: null}
                                lastName={sharedItemUser.user.id  ? sharedItemUser.user.lastName : null}
                                emailAddress={sharedItemUser.user.email ? sharedItemUser.user.email : null}
                                avatarUrl={sharedItemUser.user.avatarUrl}
                                showName={false}
                                enableClick={true}
                                sourceId={`${sharedItemUser.sourceListId}|${sharedItemUser.sourceItemId}|${sharedItemUser.user.id}`}
                                enableClickType={enableClickType}
                            />
                        </div>
                        // <div/>
                    );
                })
            ) : null}
        </div>
    );

};
