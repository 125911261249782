import React, { Fragment } from 'react';
import { Subscribe } from 'unstated';
import PropTypes from 'prop-types';
import Button from '../button';
import DrawerContainer from '../../../containers/drawer';

export default function AddButton(props) {
  const { length, list } = props;
  const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;
  let buttonText = null;
  switch (length) {
    case 'short':
      buttonText = 'add';
      break;
    case 'medium':
      buttonText = 'add item';
      break;
    case 'long':
      buttonText = 'add your first item';
      break;
    default:
      break;
  }

  if (displayConsole) {
    console.log(`common > buttons > add - list: `, list);
  }

  return (
    <Subscribe to={[DrawerContainer]}>
      {appState => (
        <Fragment>
          <Button
            buttonType="pill-add"
            weight="thin"
            xtra="hover:shadow"
            onClick={() => {
              appState.selectList(list.id);
              appState.transferListToDrawer(list);
              appState.showHide('item', 'create', list.id);
            }}
          >
            <span className="text-center">{buttonText}</span>
          </Button>
        </Fragment>
      )}
    </Subscribe>
  );
}

AddButton.propTypes = {
  length: PropTypes.string,
  list: PropTypes.object,
};
AddButton.defaultProps = {
  length: 'short',
  list: null,
};
