import React, {useState} from 'react';
import SVGEdit from '../common/icons/edit-svg';

export default function ItemEdit() {
  const [mouseOn, setMouseOn] = useState(false);
  let baseClass = 'h-10 w-10 rounded-full mx-auto ';
  let subTextClass = 'text-xxs text-grey-dark uppercase mt-2 ';

  return (
    <div className="cursor-pointer">
      <div className={mouseOn ? baseClass+= ' bg-gyfto-card-left-panel-bg ' : baseClass } onMouseEnter={() => setMouseOn(true)} onMouseLeave={() => setMouseOn(false)}>
        <div className="pt-2 pl-3">
        <SVGEdit primaryColor="#b8c2cc" hoverColor={mouseOn ? '#e3e3e3' : '#f4f4f4'} secondaryColor="#6cb2eb" width="20px" height="20px" altText="Edit this item" />
        </div>
      </div>
      <div className={mouseOn ? subTextClass += ' visible' : subTextClass += ' invisible'} style={{textAlign:'center'}}>edit</div>
    </div>
  );
};
