import gql from 'graphql-tag';
  
const fragmentList = gql`
  fragment FragmentList on List {
        id
        listId
        createdAt
        updatedAt
        pinned
        deletedState
        listDetails: ListDetails {
        ...FragmentListDetails
        }
        listUsers : ListUsers{
          ...FragmentListUser
        }
        listItems: ListItems(where: {Item: {deletedState: {_eq: false}}}, order_by: {Item: {ordinal: asc}}) {
          id
          item: Item {
            id
            ordinal
            name
            imageUrl
            obtained
            deletedState
          }
        }
        amountMin: ListItems(where: {Item: {deletedState: {_eq: false}}}, order_by: {Item: {amount: asc}}, limit: 1) {
          Item {
            amount
          }
        }
        amountMax: ListItems(where: {Item: {deletedState: {_eq: false}}}, order_by: {Item: {amount: desc}}, limit: 1) {
          Item {
            amount
          }
        }
        itemsTotal: ListItems_aggregate(where: {Item: {deletedState: {_eq: false}}}) {
          total:aggregate {
            count
          }
        }
        itemsObtained: ListItems_aggregate(where: {Item: {deletedState: {_eq: false}, obtained: {_eq: true}}}) {
          total: aggregate {
            count
          }
        }
        itemsNotObtained: ListItems_aggregate(where: {Item: {deletedState: {_eq: false}, obtained: {_eq: false}}}) {
          total: aggregate {
            count
          }
        }
        sharedListsCount: SharedLists_aggregate(where:{deletedState:{_eq:false}}){
          total:aggregate{count}
        }
        sharedLists: SharedLists(where:{deletedState:{_eq:false}}) {
          ...FragmentSharedList
        }
        sourceList: SourceList {
          id
          createdAt
          updatedAt
          listDetails: ListDetails {
            ...FragmentListDetails
          }
          listUsers: ListUsers {
            ...FragmentListUser
          }
          sharedLists: SharedLists {
            ...FragmentSharedList
          }
        }
      }
`;

export default fragmentList;
