import React from 'react';
import SVGEmptyShare from '../common/icons/empty-share-svg';


export default function EmptyShare() {
  return (
    <div className="">
      <div>
        <SVGEmptyShare  />
      </div>
    </div>
  );
};
