import React, { Fragment, useState } from 'react';
import ItemEdit from '../item/itemEdit';
import ListNameEdit from './list.name.inplace.edit';
import HideShowLongText from '../common/hideShowLongText';


export default function ListDetails(props) {
    const COMPONENT_NAME = 'list.listDetails';
    const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;
    const { listDetails, currentUserIsOwnerOrEditor, userIsAuthenticated } = props;
    const [showInPlaceListNameEdit, setShowInPlaceListNameEdit] = useState(false);

    if (displayConsole) {
        console.log(`${COMPONENT_NAME} > onMount > props:`, props);
    }

    /**
   * FIRES WHEN A LIST OWNER/EDITOR WANTS TO IN PLACE
   * EDIT THE LIST NAME AND/OR DESCRIPTION
   * @param {bool} showInPlaceListEditVal 
   */
    const toggleInPlaceListEdit = (showInPlaceListEditVal) => {
        if (displayConsole) {
            console.log(`${COMPONENT_NAME} > toggleInPlaceListEdit > showInPlaceListEditVal: ${showInPlaceListEditVal}`);
        }
        if (showInPlaceListEditVal !== null) {
            // SHOW THE COMPONENT TO EDIT THE LIST NAME AND DESCRIPTION
            setShowInPlaceListNameEdit(showInPlaceListEditVal);
        }
    }
    return (
        <div className="w-full" >
            <div className="flex align-middle">
                {userIsAuthenticated && currentUserIsOwnerOrEditor ? (
                    <div className="xs:-ml-4 md:-ml-12 pr-2" onClick={() => {
                        toggleInPlaceListEdit(!showInPlaceListNameEdit);
                    }}><ItemEdit /></div>
                ) : (
                    <Fragment />
                )
                }
                <div className="flex flex-col w-full">
                    <Fragment>
                        {showInPlaceListNameEdit ? (
                            <ListNameEdit listDetails={listDetails} showHideFunc={toggleInPlaceListEdit} />
                        ) : (
                            <Fragment>
                                <div><h1 className="list-details-name">{listDetails && listDetails.name ? listDetails.name : ''}</h1></div>
                                <div className="xs:hidden md:inline-block pr-2">
                                    <HideShowLongText displayText={listDetails && listDetails.description ? listDetails.description : ''} displayTextCssOverride="list-details-description" lengthToHide={80} />
                                </div>
                                <div className="md:hidden pr-2">
                                    <HideShowLongText displayText={listDetails && listDetails.description ? listDetails.description : ''} displayTextCssOverride="list-details-description" lengthToHide={30} />
                                </div>
                            </Fragment>
                        )}
                    </Fragment>
                </div>
            </div>

        </div>
    );
};
