import gql from 'graphql-tag';

const fragmentItemWatch = gql`
  fragment FragmentItemWatch on ItemWatch {
    id
    watchUsers: User {
      ...FragmentUser
    }
  }
`;

export default fragmentItemWatch;
