import React, { Fragment, useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import Button from '../common/button';
import UserAvatar from '../avatar/userAvatar';
import {fnGetItemUsersWatchFromItem } from '../../functions/index'

import { mutationCreateItemWatch, mutationDeleteItemWatch } from '../../graphql/graphql';

/* eslint-disable  */
export default function ItemWatch({ itemWatchId, currentUserId, itemWatchesType, listItem, itemWatches, displayType }) {
  const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false; //listItem.id === '218281ea-c7be-42dc-967e-27ee67779c00' && currentUserId === '39';
  const [addWatch, { dataAdd }] = useMutation(mutationCreateItemWatch);
  const [deleteWatch, { dataDelete }] = useMutation(mutationDeleteItemWatch);
  const [stateItemWatchesType, setItemWatchesType] = useState(itemWatchesType);
  const [stateItemWatchId, setItemWatchId] = useState(itemWatchId);
  const [itemWatchUsers, setItemWatchUsers] = useState([]);
  let itemWatchImgClass = 'cursor-pointer';
  const [mouseOn, setMouseOn] = useState(false);
  const funcName = 'itemWatch';
  let baseClass = 'h-10 w-10 rounded-full mx-auto ';
  let subTextClass = 'text-xxs text-grey-dark uppercase mt-2 ';



  // RUN ON COMPONENT LOAD REGARDLESS OF data STATE
  useEffect(() => {
    async function doUseEffectStuff() {
      if (listItem) {
        if (displayConsole) {
          console.log(`${funcName} > itemWatchId : ${itemWatchId}`);
          console.log(`${funcName} > currentUser : ${currentUserId}`);
          console.log(`${funcName} > listItem: `, listItem);
          console.log(`${funcName} > itemWatches: `, itemWatches);
          console.log(`${funcName} > itemWatchesType: `, itemWatchesType);
          console.log(`${funcName} > displayType: `, displayType);
        }
      }

      // IF THERE ARE itemWatches - LET'S GO
      if (listItem && listItem.itemWatches && Array.isArray(listItem.itemWatches) && listItem.itemWatches.length > 0) {

        // GET AN ARRAY OF ALL THE itemWatch USERS AND PUSH THE LOCAL itmWatchUsrs TO THE STATE
        const itmWatchUsrs = await fnGetItemUsersWatchFromItem(listItem, true);
        setItemWatchUsers(itmWatchUsrs);
        if (displayConsole) {
          console.log(`${funcName} > useEffect > itemWatchUsers from fnGetItemUsersWatchFromItem:`, itmWatchUsrs);
        }

      }

    }
    doUseEffectStuff();

  }, [listItem]);


  const toggleItemWatch = () => {
    // 0 = CANNOT CREATE, 1 = CAN CREATE, 2 = CAN DELETE/CREATE
    if (stateItemWatchesType > 0) {
      // DON'T ALLOW THE SETTING OF AN Item Watch IF THERE ARE NO SPACES LEFT (i.e. qty)
      switch (stateItemWatchesType) {
        case 2:
          // THIS USER HAS PREVIOUSLY CREATED AN ITEM WATCH - SO DELETE IT
          if (stateItemWatchId) {
            if (displayConsole) {
              console.log(`itemWatch > toggleItemWatch - DELETING stateItemWatchId: ${stateItemWatchId}`);
            }
            deleteWatch({ variables: { itemWatchId: stateItemWatchId } })
              .then(({ data }) => {
                if (displayConsole) {
                  console.log(`itemWatch > toggleItemWatch - DELETING DONE - data: `, data);
                }
                setItemWatchesType(1);
                setItemWatchId(null);
              });
          }
          break;
        default:
          // THIS USER IS CREATING AN ITEM WATCH
          addWatch({ variables: { itemId: listItem.itemId, userId: currentUserId } })
            .then(({ data }) => {
              if (data && data.insert_ItemWatch_one) {
                // UPDATE THE STATE OF THE ITEM WATCH
                if (displayConsole) {
                  console.log(`itemWatch > addWatch > data from mutation: `, data);
                }
                setItemWatchesType(2);
                setItemWatchId(data.insert_ItemWatch_one.id);
              } else {
                console.error('itemWatch > toggleItemWatch > There was an error with the result from the query: ', data);
              }
            })
            .catch(error => {
              console.error('itemWatch > toggleItemWatch > There was an error sending the query: ', error);
            });
      }
    }
  };

  // GREY
  const watchTaken =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAKqSURBVEhL7ZXNaxNBGMbjt4iIBwW9qPiBHj0JKngR9SCIJ0E8+x/oTRoPil8HTbubul6C3XlnzWKrFC0KYtFT1IOIiFBBD960Wj+gXtT4vLNPmrSbpjEJ6CE/GLJ55n3fZ2Z2ZjbTpcs/IVcsrvatPehZe8SPol1BMLyMXSm0T2NcLHI0l13N0y+yxRcZ8oz89MWWa9qEb+zFQqGwlKHO0BO5hNgvtbGa64kdzItsZGhjPGP2eMZ+ZoH3eA5Q4LxvpIjfb9Sf64y0wfQlta94vuFiRa5prupaq9eY3Sxfn6thuBYGH5hwJo7jxexyBCKrMOO7iZE8gMnDJFbuaB/DHJqL2LMuFjW9OF7DrjQw82l6hVIKLYj+J4m5K1qaOcBaMMCcqyniUZoOkhdg5J9QaDIXhiso16VP5EDFuM/afZTrcrlQWImV+YHYcfWgXAXvdj2LPaI0K0EQLELcL23Z0dGFlGcFs32stYPr8TpKVfJhuNUZG3uPUkMw0O3a+LchML6vtdWDUhVdXs7iLaWOgVf4zh2vOF5OaTqVTYNzvINS22A/7ExWUkqU0sD4uAbp8aDUNpXjp7UppdEbSZdaA/OhPUS5ZXCUDtN0LDcysoRyffww2u/eB24c3emU/5r+KNoA0wmtNdeRmwIj7XEjFfuqlcvefVyMfc3ZnqI8N+VyeR4SbiWJ8mLmddgIjdUcDnxQa7GrOXg1DnPUY97AwGZ2zUryVbNvNAftdqOrtCGaiGUfovlHL4z2siuF7g3EjTtTY2+2bFohm83Ox81zITHX77P01F6T+gztdLIh5TdMz2kOu9sH5sdwCXxPZiSlXpFt2vTZafo9tvYowzuLe4dGntF80jVdCZGnzeyBttClhdHJKWMTnWjmC9UxclG0SRv/dvlfyWT+AN6HHupb3yFyAAAAAElFTkSuQmCC';

  // GREEN OUTLINE
  const watchOpen =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAALTSURBVEhL7Zbbq0xRHMe3S/GAFHLNs5BCES8iL/LqPGBm75kRbxMSmZm1zpLLA3mQv0BSJ5IUzczaMzWSpOTZpVyeeNNB57jP8f2t/e3MHHMxwyYP51Or1nx/t3Xf403yXxDUzEy/pFYlK3qTX80v9ca8KTS1AtveolnmfBEjsbT0jgT6oR7yQzUShHpsvFn1NKgUsgPXBqbR1ZO+aL7Vz5p9JRbtSlA2K+jancDqAyj6yQVbPRyEqgjtKoreR/vKpHeTlePzdtfMfPTv0fdL5ANfxESxoquPQTi4n+nbk7SDAQLrkbM+lC1mZ9DkkKWE7TKLP5ImfRS65LaiCYmF72GXCzlTodpD00QkECP+ILP1y3or5bag0KmooAxAn6TclpQtbIP/Z/i9/3lwDhjOuURWn6bUEWPMVAzyjjTpU+4I/M5wlc5SaoDCT2D4ni7mFlDqSsKa1dL4syv7qrmFkltqUIoYqJlZbm9D9ZhS7EhumXXm5tHZlHBoSvnF0Z6pB5RiR3K7GjWziJLnbamZ6bIUMLymFDs43W+khtSiFAHRXY101aykFBt+1axxs7X6IaUGEE+IEaf6IqXYkJwud6gMpQaZ0CyR1wfGkWTFLKf8x0guTGpU7rKcJcoTgcMFtyShvtX1g9AryIG9vc2VPE+1FZy4uXB64YpbfYzyb4Oiuaioeo7ndg7l9qRKZl20NPqbPHeU+yZZKWyXHNHWqbWUu5Mqq4ybdajeyfeVcs+kyoXN8u672YaDacq9gUAdLZMe9sv5DZR/ScLqjRLDgRco98d4cfmyWL2Dckfgu7Mx0zZXpx8w6iNIIq+a7NdByi2ITXxwPupxHEwHTuUuHjiZ/VDzQy992PCvw92EUfGlKR6QdD2Su6uG2b2UEy8NW/DKFRUbfOgeL9E9Vzc4O1nSuhuIVdfFRre/B/68JVHwrTQUTVD+N8i72/HtnaQFz/sBJaXrkP4diK8AAAAASUVORK5CYII=';

  // GREEN FILL
  const watchFilled =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGZSURBVEhL7ZaxSsNQFIY7iKg4KQ66iCBYbqwWg3pblTyBb+FD+BY6iJPQxVXEUcTeRKQgVFy6dehQQZBOorP+Jz0U2pyW9CaKQz74aUnO+U6bNLc3l5Hx5xhPzZqSOjYl5xmvX37JefO1c13V6x6X9MDxQ6PVLfLerVX1sBcOLomHrwsFDGoh33LUeUOpyZrW0/hgl3JNmBa5WDua4CC/CPGrIOkLvtEVht5I5/qj2uRk/XAgvJAF9iEn62W6ly68n6LAPuqT3DwmiimrotyYPPd7ziaPiVLdLbhSUxoJdH6Lx0QJL7V2PqTGJCGn8ZaneIwMnsVTqTlZ1Anrh1PTag7FnWizdTrkZP1ocGmOBIFVyMXaeKCpMiixSIV18am77gzW4BdBFiu0xpODdePxsF9cgKQ5KI2RJvWyxo5gZ2OF1ltBPiRYm9HD7cnAI7YK6Yh/q15aVMtt6fBYXlvCPW8Iw7rBOarh8nS5287P40fzNDiUjtE5Lvsdwp0Jdhu9obTzGHenYYvxvAksDGdh8J4PZ2T8B3K5H8jqs+A30u8UAAAAAElFTkSuQmCC';


  let img = watchTaken;
  // 0 = CANNOT CREATE, 1 = CAN CREATE, 2 = CAN DELETE/CREATE
  if (stateItemWatchesType > 0) {
    switch (stateItemWatchesType) {
      case 2:
        img = watchFilled;
        itemWatchImgClass = 'cursor-pointer';
        break;
      case 3:
        img = watchOpen;
        itemWatchImgClass = 'cursor-pointer';
        break;
      default:
        img = watchOpen;
        itemWatchImgClass = 'cursor-pointer';
    }
  } else {
    itemWatchImgClass = '';
  }

  if (displayConsole) {
    console.log(`${funcName} > stateItemWatchesType: ${stateItemWatchesType}`);
  }

  return (
    <Fragment>
      <div>
        {displayType === 'button' ? (
          <div className='w-full'>
            <Button
              buttonType="pill-void-standard-light"
              xtra="text-xs flex items-center justify-center"
              weight="thin"
              responsive={false}
              onClick={() => { stateItemWatchesType > 0 ? toggleItemWatch() : null }}
            >
              {stateItemWatchesType > 0 ? (
                //THIS WILL SHOW THE ACTION TO WATCH/BUY THIS ITEM
                <div className='flex items-center justify-center'>
                  <img className={itemWatchImgClass} src={img} alt="Tell others you are buying this item!" />
                  <span className=" pl-2">I'm buying this</span>
                </div>
              ) : (
                //THIS WILL PREVENT THE USER FROM WATCHING/BUYING THIS ITEM
                <div className='flex items-center justify-center'>
                  <div className='pr-4'>Watched by </div>
                  <UserAvatar users={itemWatchUsers} size="xsm" />
                </div>
              )}

            </Button>
          </div>
        ) : (
          <div style={{ "paddingTop": "0.37rem" }} onClick={() => toggleItemWatch()}>
            <img className={itemWatchImgClass} src={img} alt="Tell others you are buying this item!" />
          </div>
        )}
      </div>
    </Fragment>
  );
}
/* eslint-enable */

ItemWatch.propTypes = {
  itemWatchId: PropTypes.string,
  currentUserId: PropTypes.string,
  itemWatchesType: PropTypes.number,
  listItem: PropTypes.object,
  itemWatches: PropTypes.array,
  displayType: PropTypes.string // TYPES: 'button' FOUND ON ITEM CARDS, 'compact' FOUND ON MIN CARDS
};

ItemWatch.defaultProps = {
  itemWatchId: null,
  currentUserId: null,
  itemWatchesType: 0,
  listItem: null,
  itemWatches: null,
  displayType: 'button'
};
