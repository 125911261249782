import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Avatar from './index';

export default function HeaderAvatar({ handleClickEvent, userId, firstName, lastName, avatarUrl }) {
  // eslint-disable-next-line
  const handleClick = () => {
    if (handleClickEvent) {
      // THIS IS ACTUALLY CALLING BACK UP TO THE
      // PARENT COMPONENT WHERE THIS CLICK EVENT LIVES
      handleClickEvent();
    }
  }


  return (
    // eslint-disable-next-line
    <Fragment>
      <div className="z-10" onClick={handleClick} >
        <Avatar userId={userId} firstName={firstName} lastName={lastName} avatarUrl={avatarUrl} />
      </div>
    </Fragment>

  );
}

HeaderAvatar.propTypes = {
  userId: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  avatarUrl: PropTypes.string,
  handleClickEvent: PropTypes.func,
};

HeaderAvatar.defaultProps = {
  userId: null,
  firstName: null,
  lastName: null,
  avatarUrl: null,
  handleClickEvent: null,
};
