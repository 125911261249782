import React, { Fragment, useState } from 'react';
import PrivateList from '../common/privateList';
import Button from '../common/button';
import AllLists from '../home/allLists'

/* eslint-disable  */
export default function ListPrivateReveal({ appState, userId, data }) {
  const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;
  const COMPONENT_NAME = 'list.private.reveal';
  const [showPrivateLists, setShowPrivateLists] = useState(false);
  const listUserType = ["Owner", "Editor"];


  return (
    <Fragment>
      {!showPrivateLists ? (
        <Fragment>
          {/* WIDE SCREENS */}
          <div className="xs:hidden md:flex w-full justify-center" >
            <div className='flex pt-3'>
              <div className=''>
                <PrivateList />
              </div>
              <div className='pl-3 pt-2'>
                <div className='font-bold text-2xl'>Click here to show private lists</div>
                <div className='flex justify-center'>
                  <div className='pt-6 w-48'>
                    <Button
                      onClick={() => setShowPrivateLists(true)}
                      buttonType="pill-standard"
                      weight="thin"
                      xtra="hover:shadow"
                    >
                      <div className="flex justify-center">
                        <span>show private lists</span>
                      </div>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* SMALL SCREENS */}
          <div className="xs:flex md:hidden w-full justify-center" >
            <div className='w-full flex flex-col pt-3 justify-center'>
              <div className='flex justify-center'>
                <PrivateList />
              </div>
              <div className='pl-3 pt-6'>
                <div className='font-bold text-2xl'>Click here to show private lists</div>
                <div className='flex justify-center'>
                  <div className='pt-6 w-52'>
                    <Button
                      onClick={() => setShowPrivateLists(true)}
                      buttonType="pill-standard"
                      weight="thin"
                      xtra="hover:shadow"
                    >
                      <div className="flex justify-center">
                        <span>show private lists</span>
                      </div>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      ) : (
        <AllLists userId={userId} listUserType={listUserType} containerData={data} showNewCard={false} />
      )}

    </Fragment>
  );
}