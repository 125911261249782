import React, { Component } from 'react';

export default class Footer extends Component {
  render() {
    return (
      <div>
        {/* THIS IS THE BOTTOM AREA THAT SPANS THE WHOLE PAGE */}
        <div className="flex h-32 justify-center">
          {/* THIS IS THE INNER CONTAINER THAT CENTERS THE CONTENT AND FORCES A MAX WIDTH */}
          <div className="pt-2 container max-w-gyfto-max items-center h-full flex justify-center">
            <span className="text-white text-sm font-bold">
              Made in Dallas, TX with{' '}
              <span aria-label="Love" role="img">
                ❤️
              </span>
            </span>
          </div>
        </div>
      </div>
    );
  }
}
