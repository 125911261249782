import gql from 'graphql-tag';

const fragmentSharedList = gql`
  fragment FragmentSharedList on List {
    id
    deletedState
    listUsers: ListUsers {
      ...FragmentListUser
    }
  }
`;

export default fragmentSharedList;
 