import React, { useState } from 'react';
import PropTypes from 'prop-types';

export default function SVGDelete(props) {
    const { primaryColor, secondaryColor, width, height, altText } = props;
    const [isShown, setIsShown] = useState(false);
    return (
        <div style={{height: height, width: width}} onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 24">
                {altText ? (<title>{altText}</title>) : null}
                <g fill="none" fillRule="evenodd" stroke={isShown ? `${secondaryColor}` : `${primaryColor}`} strokeWidth="2">
                    <path d="M.283 4.001h19.644M2.003 3.962L3.45 21.944c.225.4.437.663.635.788.198.126.526.204.984.236h10.006c.457-.027.806-.106 1.046-.236s.523-.392.85-.788l1.58-17.982" />
                    <path d="M5.471 4.032L7.49 1.288h4.946l2.141 2.744" />
                </g>
            </svg>
        </div>

    );
}

SVGDelete.propTypes = {
    primaryColor: PropTypes.string,
    secondaryColor: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    altText: PropTypes.string
};

SVGDelete.defaultProps = {
    primaryColor: '#5FBA7D',
    secondaryColor: '#000000',
    width: "24px",
    height: "24px",
    altText: null
};
