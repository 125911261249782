import React from 'react';

export default function DrawerMessageInformation(props) {

    return (
        <div className="w-full rounded-full bg-blue-lightest flex shadow-md">
            <div className="flex justify-start items-center p-3">
                <div className="rounded-full bg-blue-dark px-4 py-2 shadow-inner">
                    <span className="text-white font-bold">i</span>
                </div>
                <div className="pl-3">
                    <span className=" text-grey-dark">{props.children}</span>
                </div>
            </div>
        </div>
    );
}
