import React, { Fragment, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types'
import Button from '../common/button';

export default function HideShowLongText(props) {
    const { displayText, lengthToHide, displayTextCssOverride } = props;
    const [panelVisibleState, setPanelVisibleState] = useState('show');
    const [panelWasShown, setPanelWasShown] = useState(true);
    const showHidePanelRef = useRef();

    useEffect(() => {
        //TRYING THE useRef TO 'SEE' HOW MUCH TEXT IS IN THERE
        if (lengthToHide && displayText) {
            if (showHidePanelRef && showHidePanelRef.current) {

                const lineHeight = 2;
                var divHeight = showHidePanelRef.current.offsetHeight
                var lines = divHeight / lineHeight;

                if (lines > lengthToHide) {
                    setPanelVisibleState('hide');
                    setPanelWasShown(true);
                } else {
                    setPanelWasShown(false);
                }
            }
        } else {
            // DON'T SHOW THE show less OPTION IF THE PARENT COMPONENT DOESN'T
            // CARE
            setPanelWasShown(false);
        }
    }, []);


    return (
        <Fragment>
            {panelVisibleState === 'show' ? (
                <Fragment>
                    <div className={displayTextCssOverride && displayTextCssOverride !== '' ? displayTextCssOverride : null} style={{ lineHeight: '1.5rem' }} ref={showHidePanelRef}>
                        {displayText}
                    </div>
                    {panelWasShown ? (
                        <div className="pt-2 max-w-show-more-panel">
                            <Button
                                buttonType="pill-void-standard-light"
                                xtra=" text-xs "
                                weight="thin"
                                responsive={false}
                                onClick={async () => {
                                    setPanelVisibleState('hide');
                                }}
                            >
                                <span className="text-center">show less</span>
                            </Button>
                        </div>
                    ) : null}
                </Fragment>

            ) : (
                    <Fragment>
                        <div className="max-h-show-more-panel overflow-hidden" style={{ lineHeight: '1.5rem' }} ref={showHidePanelRef}>
                            {displayText}
                        </div>
                        <div className="pt-2  max-w-show-more-panel">
                            <Button
                                buttonType="pill-void-standard-light"
                                xtra="text-xs "
                                weight="thin"
                                responsive={false}
                                onClick={async () => {
                                    setPanelVisibleState('show');
                                }}
                            >
                                <span className="text-center">show more</span>
                            </Button>
                        </div>
                    </Fragment>
                )}

        </Fragment>
    );
}

HideShowLongText.propTypes = {
    displayText: PropTypes.string,
    lengthToHide: PropTypes.number
};

HideShowLongText.defaultProps = {
    displayText: null,
    lengthToHide: null
};
