import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _size from 'lodash.size';
import _forEach from 'lodash.foreach';
import CardSpace from '../home/card.css';
import ItemName from '../item/itemName';
import UserAvatar from '../avatar/userAvatar';

export default class ListCardMin extends Component {
  render() {
    const { list } = this.props;
    const userId = localStorage.getItem(`user_id`);
    const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;

    let wantType = 'want';
    let haveType = 'have';
    let sharedWithType = 'shared with';
    let listUserType = null;
    let sourceListOwner = null;
    let listDescription = '';
    let listName = '';
    let listMissingPropertiesMessage = '';
    let listMissingPropertiesShowBoth = false;
    let listItemsTotalCount = 0;
    let listItemsObtainedCount = 0;

    if (!list) {
      console.error(`Missing List.`);
      return <React.Fragment />;
    }

    if (displayConsole) {
      console.log(`list.card.min > List (prop) :`, list);
    }

    // FIRST, WHAT KIND OF LIST IS THIS - Owner, Editor OR SharedWith
    // FROM THE listUser ELEMENT
    if (list.listUsers && _size(list.listUsers) > 0) {
      _forEach(list.listUsers, listUser => {
        if (listUser && listUser.user && listUser.user.id && listUser.user.id === userId) {
          // THIS IS OUR listUser TYPE FOR THE CURRENT USER
          // eslint-disable-next-line
          listUserType = listUser.listUserType;
          if (listUserType !== 'Owner') {
            wantType = 'wants';
            haveType = 'has';
            sharedWithType = 'also shared with';
          }
        }
      });
    }

    // NEXT, DETERMINE THE SHARED USERS
    const sharedListUsers = [];
    if (listUserType !== 'SharedWith') {
      // Owner OR Editor
      if (list.sharedLists && _size(list.sharedLists) > 0) {
        _forEach(list.sharedLists, sharedList => {
          if (sharedList && sharedList.deletedState === false && _size(sharedList.listUsers) > 0) {
            _forEach(sharedList.listUsers, sharedListUser => {
              if (sharedListUser && sharedListUser.user) {
                sharedListUsers.push(sharedListUser.user);
              }
            });
          }
        });
      }
    } else {
      // PART 1 - DETERMINED THE SharedWith
      /* eslint-disable no-lonely-if */
      if (list.sourceList && list.sourceList.sharedLists && _size(list.sourceList.sharedLists) > 0) {
        _forEach(list.sourceList.sharedLists, sharedList => {
          if (sharedList.listUsers && sharedList.deletedState === false && _size(sharedList.listUsers) > 0) {
            _forEach(sharedList.listUsers, sharedListUser => {
              // THIS IS ONLY GIVING US THE SharedWith USERS THAT
              // AREN'T THE CURRENT USER
              if (sharedListUser && sharedListUser.user && sharedListUser.user.id !== userId) {
                sharedListUsers.push(sharedListUser.user);
              }
            });
          }
        });
      }

      // PART 2 - FIGURE OUT THE SOURCE LIST OWNER
      if (list.sourceList && list.sourceList.listUsers && _size(list.sourceList.listUsers) > 0) {
        _forEach(list.sourceList.listUsers, listUser => {
          if (listUser && listUser.listUserType && listUser.listUserType === 'Owner' && listUser.user) {
            sourceListOwner = listUser.user;
          }
        });
      }
      /* eslint-enable no-lonely-if */
    }

    // LAST, MAP SOME COMMON list PROPERTIES TO VARS

    // listName
    if (list && list.listDetails && list.listDetails.name) {
      listName = list.listDetails.name;
    }

    // listDetails
    if (list && list.listDetails && list.listDetails.description) {
      // TRIM THE listDetails FOR THE SharedWith LIST IF IT'S TOO LONG
      if (list.listDetails.description.length > 62) {
        // listDescription = list.listDetails.description.substring(0, 62);
        listDescription = listDescription.concat(list.listDetails.description.substring(0, 62), ' ...');
      } else {
        listDescription = list.listDetails.description;
      }
    }

    // listItemsTotalCount
    if (list && list.itemsTotal && list.itemsTotal.total.count && list.itemsTotal.total.count) {
      listItemsTotalCount = list.itemsTotal.total.count;
    }

    // listItemsObtainedCount
    if (list && list.itemsObtained && list.itemsObtained.total && list.itemsObtained.total.count) {
      listItemsObtainedCount = list.itemsObtained.total.count;
    }

    // listMissingPropertiesMessage - THIS IS SHOWN WHEN EITHER THE listItemsTotalCount OR sharedListUsers < 1
    if (listItemsTotalCount < 1 && _size(sharedListUsers) < 1) {
      listMissingPropertiesMessage = "You haven't created any items or any shares for this list yet.";
      // ONLY SHOW ONE OF THESE MESSAGES IN THE list CARD
      listMissingPropertiesShowBoth = true;
    } else if (listItemsTotalCount < 1) {
      listMissingPropertiesMessage = "You haven't created any items for this list yet.";
    } else if (_size(sharedListUsers) < 1) {
      listMissingPropertiesMessage = "You haven't created any shares for this list yet.";
    }

    return (
      <React.Fragment>
        <CardSpace isMin>
          <div>
            {/* THIS IS THE UserAvatar THAT SHOWS AT THE TOP OF
                CARDS WHEN IT'S A SharedWith */}
            {listUserType === 'SharedWith' ? (
              <div className="flex justify-center">
                <div className="absolute -mt-10">
                  <UserAvatar users={[sourceListOwner]} avatarShadow avatarShadowType="drop" maxDisplay={3} />
                </div>
              </div>
            ) : (
                <div />
              )}
          </div>
          {/* IF THE LIST IS A SharedWith THEN WE WANT TO SHOW
              THE UserAvatar OF THE LIST OWNER */}
          {listUserType === 'SharedWith' ? (
            <div className="px-4 pt-8 card_header_bacground_pattern">
              <div className="font-bold text-xl mb-2">
                <ItemName name={listName} />
              </div>
              <div className="text-grey-darker text-base min-h-gyfto-card-item-description">{listDescription}</div>
            </div>
          ) : (
              <div className="px-4 pt-4">
                <div className="font-bold text-xl mb-2">
                  <ItemName name={listName} />
                </div>
                <div className="text-grey-darker text-base min-h-gyfto-card-item-description">{listDescription}</div>
              </div>
            )}

          <div className="px-4 pt-4">
            {listItemsTotalCount > 0 ? (
              <div className="flex-wrap justify-between pb-4">
                <div className="item-prop-display">
                  <div className="item-prop-pill">
                    <span className="item-prop uppercase">{wantType}</span>
                    <span className="item-prop-value">{listItemsTotalCount}</span>
                  </div>
                </div>
                <div className="item-prop-display">
                  <div className="item-prop-pill">
                    <span className="item-prop uppercase">{haveType}</span>
                    <span className="item-prop-value">{listItemsObtainedCount}</span>
                  </div>
                </div>
                {/* TODO: ADD A SECTION HERE FOR 'Watched' ITEMS FOR LISTS THAT ARE SHARED */}
              </div>
            ) : (
                // IF THERE AREN'T ANY ITEMS AND THE USER IS Owner/Editor THEN SHOW THE BUTTON
                <div>
                  <span className="italic">{listMissingPropertiesShowBoth ? '' : listMissingPropertiesMessage}</span>
                </div>
              )}
            <div>
              {_size(sharedListUsers) > 0 ? (
                <div>
                  <div className="section-header">{sharedWithType}</div>
                  <div>
                    <UserAvatar users={sharedListUsers} size="sm" maxDisplay={3} />
                  </div>
                </div>
              ) : (
                  // IF THERE AREN'T ANY SHARED USERS AND THE USER IS Owner/Editor THEN SHOW THE BUTTON
                  <div>
                    <span className="italic">{listMissingPropertiesMessage}</span>
                  </div>
                )}
            </div>
          </div>
        </CardSpace>
      </React.Fragment>
    );
  }
}

ListCardMin.propTypes = {
  list: PropTypes.object,
  showActions: PropTypes.bool,
};

ListCardMin.defaultProps = {
  list: null,
  showActions: false,
};
