import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class CardSpace extends Component {
  render() {
    /* eslint-disable-next-line  */
    const { children,isMin,type } = this.props;

    let cssClassName = 'list-card hover:shadow-lg ';
    if(type === 'new'){
      cssClassName += ' bg-gyfto-green ';
    }

    if(type === 'more'){
      cssClassName += ' bg-gyfto-blue ';
    }

    // isMin === MORE COMPACT CARD
    if (isMin) {
      cssClassName += ' min-h-gyfto-card-min min-w-gyfto-min max-w-gyfto-max-drawer rounded-lg';

    } else {
      cssClassName += ' min-h-gyfto-card w-gyfto-list-card min-w-gyfto-card rounded-lg'
    }

    if(type === 'combo'){
      // RESET THE CSS ONLY FOR COMBO AS IT WILL BE HANDLED IN THE CARD DEFINITION
      cssClassName = 'min-h-gyfto-card w-gyfto-list-card min-w-gyfto-card rounded-lg';
      // cssClassName = ' ';
    }
    return (
      <div className="flex-no-grow flex-no-shrink">
        <div className={cssClassName}>{children}</div>
      </div>
    );
  }
}

CardSpace.propTypes = {
  isMin: PropTypes.bool,
  type: PropTypes.string,
};

CardSpace.defaultProps = {
  isMin: false,
  type: 'list',
};
