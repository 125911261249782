import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Subscribe } from 'unstated';
import DrawerContainer from '../../containers/drawer'
import AllListsContainer from './allLists.container';
import PageSpace from '../common/pageSpace';
import NavBar from '../navbar/index';
import NavBottom from '../navbar/nav.bottom';
import { fnGetUserIdFromStorage } from '../../functions/index';

export default function Home({ auth }) {
  const isAuthenticated = localStorage.getItem('isLoggedIn');
  const [userId, setUserId] = useState('');

  // THIS WILL ASYNC GET THE BACKGROUND COLORS FOR ANY OF THE AVATARS THAT ARE JUST TEXT
  useEffect(() => {
    async function getUserDeets() {

      setUserId(await fnGetUserIdFromStorage());
    }
    getUserDeets();
  }, [])

  return (
    <Subscribe to={[DrawerContainer]}>
      {appState => (
        <div>
          <div className="md:hidden sticky bg-white" style={{ top: '0px', zIndex: 900 }}>
            <NavBar navType="H" auth={auth} />
          </div>
          <div className="flex md:justify-center">
            {/* THIS IS THE WHOLE CONTENT AREA CENTERED - MAX OF 800PX */}
            <div className="w-full md:max-w-gyfto-max items-center md:pl-4 md:pr-4">
              <Helmet>
                <title>Gyfto - Home</title>
                <meta property="og:title" content={`Gyfto - Home`} />
                <meta name="description" content={`Gyfto - Home`} />
              </Helmet>
              <PageSpace>
                <div className="w-full" visible={isAuthenticated}>
                  <div className="xs:pt-6">
                    <div>
                      <div className="flex justify-start items-center">
                        <h1 className="page-section-header pb-4 xs:pl-4">your lists</h1>
                        {/* 02/17/22 - REMOVING THIS (FOR NOW) AS A TEST TO FIGURE OUT IF WE REALLY NEED THIS HERE AT ALL */}
                        {/* <div className="pl-4">
                          <div className=" rounded-full bg-grey-lighter flex shadow-md -mt-4">
                            <div className="w-full flex justify-center items-center p-3">
                              <div className=" flex cursor-pointer" onClick={() => appState.showHide('list', 'create')}>
                                <div><SVGNew primaryColor="#5FBA7D" secondaryColor="#ffffff" width="18px" height="18px" /></div>
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </div>
                      <AllListsContainer userId={userId} listUserType={["Owner", "Editor"]} showNewCard={true} showMoreCard={true} maxDisplayNumber={5} appState={appState} />
                      <br />
                    </div>
                    <div>
                      <h1 className="page-section-header pb-8 xs:pl-4">your shares</h1>
                      <AllListsContainer userId={userId} listUserType={["SharedWith"]} showNewCard={false} showMoreCard={true} maxDisplayNumber={6} />
                    </div>
                  </div>
                </div>
              </PageSpace>
            </div>
          </div>
          <NavBottom navType="H" auth={auth} />
        </div>
      )}
    </Subscribe>
  );
}
