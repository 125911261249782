import React from 'react';
import PropTypes from 'prop-types';
import _forEach from 'lodash.foreach';
import _some from 'lodash.some';
import Avatar from './index';
import More from './more';

class UserAvatar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { users, size, direction, subtext, highlightUsers, maxDisplay , forAssetId, enableClick, enableClickType, sourceId, trimTextLength, traceControl } = this.props;
    const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;// forAssetId === 'Jason';
    let { avatarShadow, avatarShadowType, alttext } = this.props;
    const avatars = [];
    let divFlexType = 'flex flex-wrap flex-shrink-0';
    const currentUserId = localStorage.getItem('user_id');

    if (displayConsole) {
      console.log(`userAvatar > render - props`);
      console.dir(this.props);
    }

    // BUILD THE AVATAR(S) - ASSUMES THIS IS AN ARRAY OF  user OBJECTs:
    if (users) {
      // ITERATE ACROSS USERS IF IT'S AN ARRAY - TYPICAL USE CASE
      if (Array.isArray(users)) {
        // THIS IS AN ARRAY WITH MULTIPLE ITEMS - ITERATE ACROSS TO BUILD OUT THE UI

        // IF maxDisplay IS PROVIDED THEN WE NEED TO ONLY DISPLAY THE
        // APPROPRIATE NUMBER OF AVATARS
        let itrCnt = 0;
        // THIS DOES A SORT ON THE users AND ORDERS BY THE listUserType IN REVERSE ORDER TO HANDLE THE 'CREATED BY' 
        // ORDER ON THE list.render COMPONENT
        _forEach(users.sort((a, b) => (a.listUserType < b.listUserType ? 1 : b.listUserType < a.listUserType ? -1 : 0)), user => {
          if (user && user.id) {

            // highlistUsers ARE A COLLECTION OF USER IDS
            // THAT - IF PRESENT - WE CAN USE TO MATCHUP AND HIGHLIGHT
            // WHEN WE PASS TO THE UserAvatar COMPONENT
            if (highlightUsers && Array.isArray(highlightUsers) && highlightUsers.length > 0) {
              // OVERRIDE WHATEVER MAY BE PASSED IN ON avatarShadow
              // IF WE ALSO GOT highlitUsers PASSED IN AND THERE
              // WAS A MATCH
              avatarShadow = _some(highlightUsers, ['watchUserId', user.id]);
              if (avatarShadow) {
                // IF WE FIND A MATCH - OVERRIDE THE OTHER PROPS AS WELL
                avatarShadowType = 'highlight';
                alttext = `Watched by ${user.firstName ? user.firstName : user.email}`;
                if (displayConsole) {
                  console.log(`highlight text for ${user.firstName}: ${alttext}`);
                }
              }
            }

            // BREAK IF WE HAVE HIT THE maxDisplay SIZE (IF PASSED)
            if (maxDisplay && maxDisplay !== 0 && maxDisplay < itrCnt + 1) {
              avatars.push(<More moreCount={users.length - maxDisplay} key={2179} />);
              return false;
            }

            // DO SOME DIFF CSS STYLING BASED UPON PROPS
            let cssClassName = "pr-4";
            if(enableClick){
              if(direction === 'vertical'){
                cssClassName = "px-1 w-full hover:bg-grey-lightest";
              }
            }

            // ADD TO TEMP ARRAY
            avatars.push(
              <div className={cssClassName} key={user.id + '||' + itrCnt.toString()} >
                <Avatar
                  key={user.id + '|' + itrCnt.toString()}
                  size={size}
                  userId={user.id}
                  // IF THE CURRENT USER AND THE ACCOUNT THAT CREATED THIS LIST IS THE SAME
                  // THEN REPLACE THE firstName lastName TEXT
                  firstName={user.id === currentUserId ? 'You' : user.firstName}
                  lastName={user.id === currentUserId ? '' : user.lastName}
                  emailAddress={user.email ? user.email : null}
                  avatarUrl={user.avatarUrl}
                  // SHOW NAMES IF THE DISPLAY IS VERITCAL
                  showName={direction === 'vertical'}
                  // subtext={user.firstName ? null : user.email}
                  subtext={subtext}
                  userRole={user.listUserType}
                  alttext={alttext}
                  direction={direction}
                  avatarShadow={avatarShadow}
                  avatarShadowType={avatarShadowType}
                  forAssetId={forAssetId}
                  enableClick={enableClick}
                  sourceId={sourceId}
                  enableClickType={enableClickType}
                  totalAvatarInArr={avatars.length + 1}
                  trimTextLength={trimTextLength}
                  traceControl
                /> 
              </div>
            );

            if(displayConsole){
              console.log(`userAvatar > pushed this item into the render array: `, user);
            }
            // INCREMENT
            itrCnt += 1;

            // console.log(`maxDisplay: ${maxDisplay} itrCnt: ${itrCnt}`);
          } else {
            if(displayConsole){
              console.log(`userAvatar > didn't get the pattern for the render prop 'users' object that we expected: `, users);
            }
          }
        });
      }
    }

    if(displayConsole ){
      console.log(`userAvatar > avatars length: ${avatars.length} `, avatars);
    }

    return <div className={divFlexType}>{avatars}</div>;
  }
}

UserAvatar.propTypes = {
  direction: PropTypes.string,
  size: PropTypes.string,
  users: PropTypes.array,
  subtext: PropTypes.string,
  alttext: PropTypes.string,
  avatarShadow: PropTypes.bool,
  avatarShadowType: PropTypes.string,
  highlightUsers: PropTypes.array,
  maxDisplay: PropTypes.number,
  forAssetId: PropTypes.string,
  trimTextLength: PropTypes.number,
  traceControl: PropTypes.bool
};

UserAvatar.defaultProps = {
  direction: null,
  size: 'md',
  users: [],
  subtext: null,
  alttext: null,
  avatarShadow: false,
  avatarShadowType: null,
  highlightUsers: [],
  maxDisplay: 0,
  forAssetId: null,
  trimTextLength: 0,
  traceControl: false
};

export default UserAvatar;
