import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Subscribe } from 'unstated';
import DrawerContainer from '../../containers/drawer';
import { fnGetColorValue, fnSetDefaultImgSrcOnError } from '../../functions/index';
import Button from '../common/button';

// class UserAvatar extends React.Component {
export default function UserAvatar(props) {
  const {
    avatarUrl,
    size,
    showName,
    direction,
    firstName,
    lastName,
    emailAddress,
    userId,
    subtext,
    alttext,
    avatarShadow,
    avatarShadowType,
    forAssetId,
    enableClick,
    enableClickType,
    sourceId,
    userRole,
    totalAvatarInArr,
    trimTextLength,
    traceControl
  } = props;

  const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false; //forAssetId === "06ddde6a-eb27-46b0-bfb3-4b6d535d3647";
  const COMPONENT_NAME = 'UserAvatar';
  const [displayTextBgColor, setDisplayTextBgColor] = useState('');
  const [renderUserName, setRenderUserName] = useState('');
  let userDisplayName = !firstName || firstName === '' ? emailAddress ? emailAddress.split("@")[0] : '' : firstName + ' ' + lastName;

  // THIS WILL ASYNC GET THE BACKGROUND COLORS FOR ANY OF THE AVATARS THAT ARE JUST TEXT
  useEffect(() => {
    async function runAsyncFunc() {
      
      // SORT OUT ALL THE userDisplayName STUFF ONE TIME
      const localUserDisplayName = !firstName || firstName === '' ? emailAddress ? emailAddress.split("@")[0] : '' : firstName + ' ' + lastName;

      // IF trimTextLength IS PASSED IN - TRIM THE LENGTH FOR THE userDisplayName
      if (trimTextLength && trimTextLength > 0 && localUserDisplayName.length > trimTextLength) {
        setRenderUserName(`${localUserDisplayName.substring(0, trimTextLength-3)}...`); 
      } else {
        setRenderUserName(localUserDisplayName);
      }

      // DO THE COLORING STUFF FOR THE AVATAR CIRCLE
      setDisplayTextBgColor(await fnGetColorValue(`${localUserDisplayName}`));
    }
    runAsyncFunc();

    if (displayConsole) {
      console.log(`avatar > default props: `, props);
      console.log(`avatar > userDisplayName === ${userDisplayName} `);
    }
  }, [])


  let displayTextCss = "pl-2";
  if (subtext) {
    displayTextCss += ' pt-1'
  } else {
    displayTextCss += ' pt-3'
  }

  if (displayConsole && avatarShadow) {
    console.log(`avatar > avatarShadow is ${avatarShadow} for ${userDisplayName}: `, props);
  }

  if (displayConsole && !avatarShadow) {
    console.log(`avatar > props: `, props);
  }

  /**
   * BUILDS OUT THE ICON FOR THE AVATAR
   * @param {*} propSize 
   * @param {*} avatarUrl 
   * @param {*} firstName 
   * @param {*} lastName 
   * @param {*} userDisplayName 
   * @param {*} avatarShadow 
   * @param {*} avatarShadowType 
   * @param {*} alttext 
   * @param {*} userId 
   */
  const buildAvatarElement = (propSize, avatarUrl, firstName, lastName, userDisplayName, avatarShadow, avatarShadowType, alttext, userId) => {
    let classStyleImage = "";
    let classStyleText = "avatar-circle ";
    let returnElement = null;

    switch (propSize) {
      case 'xs':
        classStyleImage += 'md:h-6 xs:h-4 rounded-full mx-auto';
        classStyleText += ' md:h-6 md:w-6 xs:h-4 xs:w-4 mb-2';
        break;
      case 'xsm':
        classStyleImage += 'h-8 w-8 rounded-full my-auto mx-auto';
        classStyleText += ' w-8 h-8 xs:mr-2';
        break;
      case 'sm':
        classStyleImage += 'h-10 w-10 rounded-full my-auto mx-auto';
        classStyleText += ' w-10 h-10 xs:mr-2';
        break;
      case 'lg':
        classStyleImage += 'md:h-16 xs:h-16 rounded-full mx-auto';
        classStyleText += ' md:h-8 md:w-8 xs:h-6 xs:w-6 mb-2';
        break;
      default:
        classStyleImage += ' md:h-10 xs:h-10 rounded-full mx-auto';
        classStyleText += ' w-11 h-11';
        break;
    }

    let shortName, initials = null;
    if (!firstName || firstName === '') {
      // THIS WILL ONLY HAPPEN FOR USERS THAT DON'T HAVE A FIRST/LAST NAME (i.e. lists were created but not signed up yet)
      // SO USE EVERYTHING BEFORE THE @ SIGN
      shortName = `${userDisplayName}`;
      initials = `${userDisplayName.substring(0, 1)}`;
    } else {
      shortName = `${firstName || ''} ${lastName ? lastName.substring(0, 1) : ''}`;
      initials = `${firstName ? firstName.substring(0, 1) : ''}${lastName ? lastName.substring(0, 1) : ''}`;
    }

    // SET THE SPECIFIC STYLE ON THE ELEMENT
    const avatarStyle = {
      backgroundColor: displayTextBgColor,
    };

    // ADD A DROPSHADOW FOR THE AVATAR
    if (avatarShadow) {
      let shadowCss = ' shadow-md';
      if (avatarShadowType === 'highlight') {
        // ASSUMING A shadow-md FOR ALL REQUESTS IF avatarShadow === true
        // OTHERWISE, DO AN OUTLINE
        shadowCss = ' shadow-highlighted-user';
      }

      classStyleImage = classStyleImage.concat(shadowCss);
      classStyleText = classStyleText.concat(shadowCss);
    }

    if (avatarUrl) {
      returnElement = (
        <div key={userId + '|' + Date.now.toString()}>
          <img
            className={classStyleImage}
            alt={alttext || shortName}
            title={alttext || shortName}
            src={avatarUrl}
            onError={fnSetDefaultImgSrcOnError}
            loading="lazy"
          />
        </div>
      );
    } else {
      // return initials;
      returnElement = (
        <div className="">
          <div style={avatarStyle} className={classStyleText} alt={alttext || shortName} title={alttext || shortName}>
            <div className="xs:pt-3">{initials}</div>
          </div>
        </div>
      );
    }

    if (displayConsole) {
      console.log(`avatar > buildAvatarElement > returnElement: `, returnElement);
      console.log(`avatar > buildAvatarElement > classStyleImage: '${classStyleImage}'`)
    }

    return returnElement;
  }

  const handleClick = async (appState) => {
    if (appState && enableClick && enableClickType) {
      const user = {
        id: userId
        , firstName
        , lastName
        , avatarUrl
        , email: emailAddress
      }
      switch (enableClickType) {
        case 'listUserShare':
          appState.captureUserAvatarClick(user, sourceId, enableClickType);
          appState.showHide('share', 'delete', null, 'Manage List Shares');
          break;
        case 'itemUserShare':
          appState.captureUserAvatarClick(user, sourceId, enableClickType);
          appState.showHide('share', 'delete', null, 'Manage Shared Item');
          break;
        case 'listUserShare_Editor':
          appState.captureUserAvatarClick(user, sourceId, enableClickType);
          appState.showHide('share', 'delete', null, 'Manage List Editors');
          break;
        default:
          console.log(`avatar > unknown 'enableClickType' in props: `, enableClickType);
          break;
      }

    }
  }
  return (
    <Subscribe to={[DrawerContainer]}>
      {appState => (
        <div className={enableClick ? 'cursor-pointer' : ''} onClick={() => { handleClick(appState) }}>
          {/* eslint-disable-next-line */}
          <div className="mt-1 flex flex-no-shrink align-middle" key={userId + '2179'}>
            <div >
              {buildAvatarElement(size, avatarUrl, firstName, lastName, userDisplayName, avatarShadow, avatarShadowType, alttext, userId)}
            </div>

            <div className={displayTextCss} hidden={!(direction === 'vertical')}>
              {showName ? <div className="text-sm">{`${renderUserName}`}</div> : null}


              {subtext ? <div className="text-xs text-gyfto-font-grey">{subtext}</div> : null}
              {totalAvatarInArr >= 1 && userRole && userRole !== '' && (userRole.toUpperCase() === 'OWNER' || userRole.toUpperCase() === 'EDITOR') ? (
                <div>
                  <Button
                    buttonType="pill-void-tiny"
                    xtra={`py-1 cursor-default ${userRole.toUpperCase() === 'OWNER' ? "bg-gyfto-blue-dark border-gyfto-blue-dark" : "bg-gyfto-gold border-gyfto-gold text-black"}`}
                    weight="thin"
                    responsive={false}
                  >
                    <span className="text-center">{userRole}</span>
                  </Button>
                </div>
              ) : null}
            </div>
          </div>
        </div>)}
    </Subscribe>
  );
}

UserAvatar.propTypes = {
  size: PropTypes.string,
  showName: PropTypes.bool,
  direction: PropTypes.string,
  subtext: PropTypes.string,
  alttext: PropTypes.string,
  avatarUrl: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  userId: PropTypes.string,
  avatarShadow: PropTypes.bool,
  avatarShadowType: PropTypes.string,
  forAssetId: PropTypes.string,
  userRole: PropTypes.string,
  totalAvatarInArr: PropTypes.number,
  trimTextLength: PropTypes.number,
  traceControl: PropTypes.bool
};

UserAvatar.defaultProps = {
  size: null,
  showName: true,
  direction: null,
  subtext: null,
  alttext: null,
  avatarUrl: null,
  firstName: null,
  lastName: null,
  emailAddress: null,
  userId: null,
  avatarShadow: false,
  avatarShadowType: 'drop',
  forAssetId: null,
  userRole: null,
  totalAvatarInArr: 0,
  trimTextLength: 0,
  traceControl: false
};

// export default UserAvatar;
