import React, { Fragment, useState } from 'react';
import { Subscribe } from 'unstated';
import { useQuery, useMutation } from '@apollo/react-hooks';
import history from '../history/index';
import _forEach from 'lodash.foreach';
import ItemCardMin from '../item/itemCard.min';
import Button from '../common/button';
import Checkbox from '../common/checkbox';
import DrawerSpace from '../common/drawerSpace';
import DrawerContainer from '../../containers/drawer';
import { qryGetListsByUserTypeShallow_Full, mutationAddItemToList } from '../../graphql/graphql';
import ListCardMin from '../list/list.card.min';
import { fnGyftoEventPipeline } from '../../functions/index';
import ErrorUI from '../common/error';

export default function CopyItem(props) {
  const { itemId, listId } = props;
  const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;
  // CALLING OUT TO GRAPHQL
  const { loading, error, data } = useQuery(qryGetListsByUserTypeShallow_Full, {
    variables: { userId: localStorage.getItem(`user_id`), listUserType: ["Owner","Editor"] },
    suspend: false,
  });


  if (displayConsole) {
    console.log(`draweritem.item.copy > loaded component: itemId - ${itemId} || listId - ${listId}`);
  }

  const [addItemToList] = useMutation(mutationAddItemToList);
  const [errorMsg, setErrorMsg] = useState(null);

  // STATE TO TRACK THE SELECTED ITEM
  const [selectedListId, setSelectedListId] = useState(0);

  if (loading) return <Fragment>Loading...</Fragment>;

  if (error || errorMsg) {
    return (
      <DrawerSpace>
        <ErrorUI errorMsg={error ? error.message : errorMsg} />
      </DrawerSpace>
    )
  }

  // POPULATE ARRAY
  const returnItems = [];
  const listsWhereThisItemAlreadExists = [];
  if (data) {
    if (displayConsole) {
      console.log(`draweritem.item.copy > data: `, data);
    }

    // THIS IS GNARLY BUT BASICALLY:
    // 1) GET ALL THE ACTIVE LISTS FOR A USER
    // 2) ITREATE THROUGH THOSE LISTS AND THOSE LISITEMS
    // 3) IF THE ITEM TO COPY IS FOUND IN THAT LIST - SKIP IT
    // 4) OTHERWISE - SHOW THE LIST AS A LOCATION TO COPY THE ITEM TO
    if (data.User && Array.isArray(data.User)) {
      let dataUser = data.User[0];
      if (dataUser && dataUser.listUsers && Array.isArray(dataUser.listUsers)) {
        _forEach(dataUser.listUsers, listUser => {
          if (listUser && listUser.list && listUser.list.id && listUser.list.id !== listId) {
            let currentList = listUser.list;
            let pushList = true;
            if (currentList && currentList.listItems && Array.isArray(currentList.listItems)) {
              _forEach(currentList.listItems, listItem => {
                if (listItem && listItem.item && listItem.item.id === itemId) {
                  // WE FOUND THIS ITEM ON ANOTHER LIST THAT THE USER OWNS
                  if (displayConsole) {
                    console.log(`draweritem.item.copy > found item ${itemId} on list ${currentList.listDetails.name}: `);
                  }

                  pushList = false;
                }
              })
              // if(displayConsole){
              //   console.log(`draweritem.item.copy > list items for list ${currentList.id}: `, currentList.listItems);
              //   console.log(`draweritem.item.copy > filter count for itemId ${itemId}: `, currentList.listItems.filter(itm => itm.id === itemId).length);
              // }
            }

            if (pushList) {
              // SHOW THIS LIST IN THE UI AS A COPY DESTINATION
              returnItems.push(currentList);
            } else {
              // listsWhereThisItemAlreadExists.push({ "id": currentList.id, "name": `${currentList.listDetails.name}` });
              listsWhereThisItemAlreadExists.push({ "list": currentList });
            }
          }
        })
      }
    }
  }

  // FOR THE CHECKBOX
  const handleChange = (e, inputListId) => {
    if (displayConsole) {
      console.log(`draweritem.item.copy > handleChange > listId: ${inputListId} || e: `, e.target);
    }
    if (inputListId && inputListId !== '') {
      // THIS COMES WHEN PASSING FROM A DIV
      setSelectedListId(inputListId);
    } else {
      // THIS COMES WHEN PASSING FROM A UL/LI
      // UNCHECK THE ITEM IF IT'S ALREADY SELECTED
      if (e.target.name === selectedListId) {
        setSelectedListId(null);
      } else {
        setSelectedListId(e.target.dataset.id);
      }
    }
  };

  return (
    <Subscribe to={[DrawerContainer]}>
      {appState => (
        <Fragment>
          <DrawerSpace>
            {loading ? (
              <div>
                <span className="drawer-header-description-text">Loading...</span>
              </div>
            ) : (
                <Fragment />
              )}
            {appState && appState.state && appState.state.selectedItemObj ? (
              <div className="pt-2">
                <div className="drawer-header-description-text pb-3">Copy this item to another list</div>
                <div className="">
                  <ItemCardMin item={appState.state.selectedItemObj} />
                </div>
                {returnItems && returnItems.length > 1 ? (
                  <div className="drawer-header-description-text pt-8 pb-2">Select your list</div>
                ) : <Fragment />}
                <div>
                  {/* ITERATE THROUGH LISTS THAT ARE AVAILABLE TO COPY TO AND DISPLAY THOSE TO SCREEN */}
                  {returnItems && returnItems.length > 1 ? (
                    returnItems
                      .sort((a, b) =>
                        a.updatedAt < b.updatedAt ? 1 : b.updatedAt < a.updatedAt ? -1 : 0
                      )
                      .map(list => (
                        // eslint-disable-next-line
                        <div key={list.id} className="py-4 flex items-start" >
                          <div>
                            <Checkbox name={list.id} id={list.listId} checked={selectedListId ? list.listId.toString() === selectedListId.toString() : false} onChange={handleChange} />
                          </div>
                          <div id={list.id} className="ml-4 cursor-pointer" onClick={(e) => {
                            handleChange(e, list.listId)
                          }} name={list.id}>
                            <ListCardMin key={list.id} list={list} showActions={false} />
                          </div>
                        </div>
                      ))
                  ) : (
                      <div className="pt-6">
                        <span className="font-bold">We couldn't find any other lists to copy this item to</span>
                        <br />
                        {listsWhereThisItemAlreadExists.length > 0 ? (
                          <div className="pt-4">
                            This item already exists in the following {listsWhereThisItemAlreadExists.length > 1 ? "lists" : "list"}:<br />
                            <div>
                              {listsWhereThisItemAlreadExists.map(listEntry => (
                                <div key={listEntry.list.id} className="pt-4 cursor-pointer" onClick={() => {
                                  appState.selectList(listEntry.list.id);
                                  appState.transferListToDrawer(listEntry.list);
                                  history.push(`/l/${listEntry.list.id}`)
                                }}>
                                  <ListCardMin key={listEntry.list.id} list={listEntry.list} showActions={false} />
                                </div>
                              ))}
                            </div>
                          </div>
                        ) : (
                            <div className="pt-4">
                              You don't have any other lists to copy this item to
                            </div>
                          )}
                      </div>
                    )}
                </div>
              </div>
            ) : (
                <div>
                  <span className="drawer-header-description-text">Unable to find an item to copy...</span>
                </div>
              )}
            {/* <div className="flex content-center"> */}
              <div className={selectedListId ? ['btn-drawer', 'show'].join(' ') : 'btn-drawer'}>
                <Button
                  buttonType="pill-standard"
                  weight="thin"
                  xtra="hover:shadow"
                  onClick={async e => {
                    if (displayConsole) {
                      console.log(`draweritem.item.copy > Button onClick > selectedItemObj.listId: ${appState.state.selectedItemObj.itemId} || listId: ${selectedListId}`, appState.state.selectedItemObj);
                    }
                    await addItemToList({
                      variables: {
                        itemId: parseInt(appState.state.selectedItemObj.itemId),
                        listId: parseInt(selectedListId),
                      },
                    })
                      .then(({ returnData }) => {
                        if (displayConsole) {
                          console.log(`draweritem.item.copy > addItemToList > item added: `, returnData);
                        }

                        fnGyftoEventPipeline('Item', 'Copied', selectedListId);
                        appState.closeDrawer();
                        appState.showMessageCard('msg', `${appState.state.selectedItemObj.name} copied to the list`);
                      })
                      .catch(err => {
                        if (displayConsole) {
                          console.log(`draweritem.item.copy > addItemToList > ERROR copying item: `, err);
                        }
                        setErrorMsg(err.toString());
                      });
                  }}
                >
                  <span className="text-center">copy to list</span>
                </Button>
              </div>
            {/* </div> */}
          </DrawerSpace>
        </Fragment>
      )}
    </Subscribe>
  );
}
