import { Container } from 'unstated';
const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;

class DrawerContainer extends Container {
  state = {
    show: false,
    messageCardShow: false,
    messageCardMsg: null,
    messageCardType: null,
    drawerType: null,
    drawerAction: null,
    drawerTitle: null,
    itemId: null,
    itemIds: null,
    newItem: null, 
    newItemUrl: null,
    selectedListId: null,
    selectedListListId: null,
    selectedListObj: null,
    selectedItemId: null,
    selectedItemIds: null,
    selectedItemObj: null,
    selectedItemObjs: null,
    showModal: false,
    auth: null,
    userAvatarClickObj: null,
    listHistory: [
      {type:"list",name:"Jason's Birthday List"}
      ,{type:"list",name:"Office"}
      ,{type:"list",name:"Stuff for the house/family"}
      ,{type:"list",name:"Ideas for Kelli's"}
      ,{type:"share",name:"Gyfto's Christmas"}
      ,{type:"share",name:"Amanda's Birthday List"}
  ]
  };

  // THIS EVENT FIRES WHEN THE DRAWER IS SHOWN/HIDDEN
  drawerChange = drawerState => {
    this.setState({ show: drawerState });
  };

  // THIS EVENT FIRES WHEN THE USER WANTS TO SHOW OR HIDE THE DRAWER - IT WILL FLIP
  // THE CURRENT STATE OF THE DRAWER
  showHide = (type, action, id, title) => {
    this.setState({ show: true, drawerType: type, drawerAction: action, drawerTitle: title, itemId: id });
  };

  /**
   * SHOW THE DRAWER FOR MULTI-SELECTED items
   * @param {*} type 
   * @param {*} action 
   * @param {*} ids 
   * @param {*} title 
   */
  showHideMulti = (type, action, ids, title) => {
    this.setState({ show: true, drawerType: type, drawerAction: action, drawerTitle: title, itemIds: ids });
  };

  closeDrawer = () => {
    this.setState({
      show: false,
      drawerType: null,
      drawerAction: null,
      itemId: null,
      newItem: null,
      newItemUrl: null,
      selectedListObj: null,
      selectedItemObj: null,
      // selectedListId: null,
      // selectedItemId: null,
    });
  };

  captureItemUrl = itemUrl => {
    if (itemUrl) {
      this.setState({ newItemUrl: itemUrl });
    }
  };

  // FOR THE MESSAGE CARD
  showMessageCard = (msgType, msgMsg) => {
    this.setState({ messageCardShow: true, messageCardMsg: msgMsg, messageCardType: msgType });
    setTimeout(() => {
      this.hideMessageCard();
    }, 3000);
  };

  hideMessageCard = () => {
    this.setState({ messageCardShow: false, messageCardMsg: null, messageCardType: null });
  };

  // FOR THE MODAL
  showModal = (modalState) => {
    this.setState({ showModal: modalState });
  }

  // THIS WILL UPDATE THE LIST TO MAKE SURE IT'S AT THE TOP fnUpdateListLastTouch

  /*
  THIS IS DRIVEN BY THE SCRAPE API - THE SHAPE OF THE OBJ IS:
  {  
    "currency":null,
    "description":"Presented at Baselworld 2018, TUDORâs famous Black Bay diversâ watch is now available with a new GMT function that establishes local time without losing sight of the time in two other time zones. Fitted with the Manufacture Calibre MT5652, the Black Bay GMT sports a distinctive burgundy and blue bezel.",
    "amount":0,
    "store":"tudorwatch",
    "name":"tudor black bay gmt",
    "imageUrl":"https://www.tudorwatch.com/statics/images/watches/grid/grid-m79830rb-0001.png",
    "url":"https://www.tudorwatch.com/watches/black-bay-gmt/m79830rb-0001"
  }

  */
  captureItemDetails = inputObject => {
    if (inputObject) {
      if (displayConsole) {
        console.log(`drawerState > captureItemDetails - This was passed into state:`);
        console.dir(inputObject);
      }
      this.setState({ newItem: inputObject });
    }
  };

  /*
    THIS IS DRIVEN FROM SELECTING AN ACTION ON THE ITEM (EDIT, DELETE, COPY, MOVE) - THE SHAPE OF THE OBJ IS:
    {
      "id":"cjtd1ovb30rh70105c6m8fhtg",
      "name":"\nPatagonia Men's Classic Synchilla® Fleece Vest\n",
      "description":"\nThis looks very, very warm 🔥",
      "imageUrl":null,
      "amount":0,
      "code":null,
      "color":null,
      "obtained":false,
      "ordinal":101,
      "processedImage":false,
      "qty":1,
      "size":null,
      "store":null,
      "url":null,
      "updatedAt":"2019-03-17T14:58:25.000Z",
      "itemWatches":[],
      "sharedLists":[
          {
            "id":"cjqbarv095miv0116g2q9ynp7",
            "listUsers":[
                {
                  "listUserType":"Owner",
                  "user":{
                      "id":"cjsb56ywc0jnf0191j80qtf25",
                      "firstName":"Gyfto",
                      "lastName":"App",
                      "avatarUrl":"https://lh5.googleusercontent.com/-mBXq_2FxHQ8/AAAAAAAAAAI/AAAAAAAAABo/C7-mZJQHdOM/photo.jpg",
                      "__typename":"User"
                  },
                  "__typename":"ListUser"
                }
            ],
            "__typename":"List"
          }
      ],
      "__typename":"Item"
    }

  */
  transferItemToDrawer = inputObject => {
    if (inputObject) {
      this.setState({ selectedItemObj: inputObject });
      if (displayConsole) {
        console.log(`drawerState > transferItemToDrawer - This was passed into state:`);
        console.dir(inputObject);
      }
    }
  };

  /**
   * THIS WILL TRANSFER AN ARRAY OF ITEMS TO THE DRAWER
   * @param {array of objects} inputObjects 
   */
  transferItemsToDrawer = inputObjects => {
    if (inputObjects && Array.isArray(inputObjects) && inputObjects.length > 0) {
      this.setState({ selectedItemObjs: inputObjects });
      if (displayConsole) {
        console.log(`drawerState > transferItemToDrawer - This was passed into state:`);
        console.dir(inputObjects);
      }
    }
  };

  /*
  THIS IS DRIVEN FROM SELECTING AN ACTION ON THE list (EDIT, DELETE, COPY, MOVE) - THE SHAPE OF THE OBJ IS:
  {
    "id":"cju7evh9t04cj0111s55nf4ep",
    "createdAt":"2019-04-07T21:00:34.000Z",
    "deletedState":false,
    "listDetails":{
       "id":"cju7evh9t04ck0111dps3ev3x",
       "name":"A list to edit on",
       "description":"This is a list that I need to test all the editing of the details.  \n\nInitial state is 'others'",
       "buyType":"SharedBuy",
       "__typename":"ListDetails"
    },
    "listUsers":[
       {
          "listUserType":"Owner",
          "user":{
             "id":"cjsb56ywc0jnf0191j80qtf25",
             "firstName":"Gyfto",
             "lastName":"App",
             "avatarUrl":"https://lh5.googleusercontent.com/-mBXq_2FxHQ8/AAAAAAAAAAI/AAAAAAAAABo/C7-mZJQHdOM/photo.jpg",
             "__typename":"User"
          },
          "__typename":"ListUser"
       }
    ],
    "items":[
       {
          "id":"cju7evpb3015h0108rx8uu5dr",
          "name":"Item 1",
          "description":"",
          "imageUrl":"",
          "amount":0,
          "code":"",
          "color":"",
          "obtained":false,
          "ordinal":101,
          "processedImage":false,
          "qty":1,
          "size":"",
          "store":"",
          "url":"",
          "updatedAt":"2019-04-07T21:00:44.000Z",
          "itemWatches":[
 
          ],
          "sharedLists":[
             {
                "id":"cju7evh9t04cj0111s55nf4ep",
                "listUsers":[
                   {
                      "listUserType":"Owner",
                      "user":{
                         "id":"cjsb56ywc0jnf0191j80qtf25",
                         "firstName":"Gyfto",
                         "lastName":"App",
                         "avatarUrl":"https://lh5.googleusercontent.com/-mBXq_2FxHQ8/AAAAAAAAAAI/AAAAAAAAABo/C7-mZJQHdOM/photo.jpg",
                         "__typename":"User"
                      },
                      "__typename":"ListUser"
                   }
                ],
                "__typename":"List"
             }
          ],
          "__typename":"Item"
       },
    ],
    "sharedLists":[
 
    ],
    "sourceList":null,
    "__typename":"List"
  }
  */

  transferListToDrawer = inputObject => {
    if (inputObject) {
      const { id, listId } = inputObject;
      if (id && listId) {
        this.setState({ selectedListObj: inputObject, selectedListId: id, selectedListListId: listId });
      } else {
        this.setState({ selectedListObj: inputObject });
      }
      if (displayConsole) {
        console.log(`drawerState > transferListToDrawer - This was passed into state:`);
        console.dir(inputObject);
      }

    }
  };

  selectList = (id, listId) => {
    if (id) {
      if (displayConsole) {
        console.log(`drawerState > list id: ${id}`);
      }
      this.setState({ selectedListId: id });
    }

    if (listId) {
      if (displayConsole) {
        console.log(`drawerState > listId: ${listId}`);
      }
      this.setState({ selectedListListId: listId });
    }
  };

  selectItem = itemId => {
    if (itemId) {
      if (displayConsole) {
        console.log(`drawerState > item: ${itemId}`);
      }
      this.setState({ selectedItemId: itemId });
    }
  };

  /**
   * SELECTS MULTIPLE item
   * @param {array of items} itemId
   */
  selectItems = itemIds => {
    if (itemIds && Array.isArray(itemIds) && itemIds.length > 0) {
      if (displayConsole) {
        console.log(`drawerState > selected item ids: ${itemIds}`);
      }
      this.setState({ selectedItemIds: itemIds });
    }
  };

  /**
   * COLLECTS DETAILS OF WHAT TYPE OF SHARE WE ARE DELETING
   * @param {string} userId - userId OF THE SHARED USER
   * @param {string} objectId - listId OF THE SOURCE LIST
   */
  captureUserAvatarClick = (user, objectId, shareType) =>{
    if(user && objectId){
      if (displayConsole) {
        console.log(`drawerState > captureUserAvatarClick > userId: ${user.id? user.id : ''} | objectId: ${objectId} | shareType: ${shareType}`);
      }

      this.setState({userAvatarClickObj: {user, objectId:objectId, shareType: shareType}});
    } else {
      if (displayConsole) {
        console.log(`drawerState > captureUserAvatarClick > couldn't setState on userAvatarCLickObj:  userId: ${user.id? user.id : ''} | objectId: ${objectId} | shareType: ${shareType}`);
      }
    }
  }

  /* eslint-disable  */
  deleteItem(cacheObj, itemDeleted) {
    if (displayConsole) {
      console.log(`in appSate > deleteItem`);
      console.dir(cacheObj);
      console.dir(itemDeleted);
    }
    /* eslint-enable  */
  }

  setAuth = (auth) => {
    if (displayConsole) {
      console.log(`drawerState > auth: `,auth);
    }
    this.setState({ auth: auth });
  };
}

export default DrawerContainer;
