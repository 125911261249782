import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';

// Apollo Client STUFF
import { ApolloProvider } from '@apollo/react-hooks';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { ApolloLink, split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';
import { RestLink } from 'apollo-link-rest';
import './index.css';
import App from './App';
import history from './components/history/index';
import registerServiceWorker from './registerServiceWorker';
import {fnGetUserToken} from './functions/index'
// Auth0 AUTH STUFF
import Auth from './auth/auth'; 
const auth = new Auth();

// LOGGING
const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;

// APOLLO LINKS
const httpLink = createHttpLink({ uri: `${process.env.REACT_APP_GRAPHQL_ENDPOINT}` });

// WEB SOCKET
// const wsLink = new WebSocketLink({
//   uri: `${process.env.REACT_APP_GRAPHQL_WS_ENDPOINT}`,
//   options: {
//     lazy: true,
//     reconnect: true,
//     connectionParams: async () => {
//       const token = await fnGetUserToken();
//       return {
//         headers: {
//           Authorization: token ? `Bearer ${token}` : "",
//         },
//       }
//     },
//   },
// })

// RESOLVED ISSUES WITH JWT AND SUBSCRIPTIONS VIA THIS GIST https://github.com/apollographql/apollo-server/issues/1505
const wsLink = new WebSocketLink({
  uri: `${process.env.REACT_APP_GRAPHQL_WS_ENDPOINT}`,
  options: {
    lazy: true,
    reconnect: true,
  },
})

const subscriptionMiddleware = {
  applyMiddleware: async (options, next) => {
    options.authToken = await fnGetUserToken()
    next()
  },
}

// add the middleware to the web socket link via the Subscription Transport client
wsLink.subscriptionClient.use([subscriptionMiddleware])

// REST 
// EXAMPLE: https://medium.com/november-five/using-apollo-client-with-a-rest-api-ad0203a807cd
const restLink = new RestLink({
  endpoints: {
    createList: { uri: `${process.env.REACT_APP_WEB_FUNCTION_BASE_URL}` }
  }
});

// AUTH
const authLink = setContext ((_, { headers }) => {
  // return the headers to the context so httpLink can read them
  const localToken = localStorage.getItem('id_token');
  // const localToken = token; //localStorage.getItem('id_token');
  if(displayConsole){
    console.log(`index.js > authLink: localToken: '${localToken}'`);
  }
  return {
    headers:  {
      ...headers,
      authorization: localToken ? `Bearer ${localToken}` : "",
    }
  }
});

// BRING ALL THE Apollo Links together
const link = ApolloLink.split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) => {
          if (displayConsole) {
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
          }
        }
        );
      }
      if (networkError) {
        if (displayConsole) {
          console.error(`[Network error]: ${networkError}`, networkError.stack)
        }
      };
    }),
    authLink,
    restLink,
    httpLink
  ]),
);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache()
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <Router history={history}>
      <App auth={auth} />
    </Router>
  </ApolloProvider>,
  document.getElementById('root')
);
registerServiceWorker();
