import React, { useEffect, useState } from 'react';
import { Subscribe } from 'unstated';
import DrawerContainer from '../../containers/drawer';
import ModalListNoAuth from './modal.list.noauth';
import ModalUserExpired from './modal.user.expired';
 
export default function Modal({ showModal, modalType, dataObject, auth }) {
  const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;
  const currentUserId = localStorage.getItem(`user_id`);
  if (displayConsole) {
    console.log(`modal > showModal '${showModal}': `, dataObject);
  }
  
  const [showIt, setShowIt] = useState(showModal);
  const [modalDataObject, setModalDataObject] = useState(dataObject);


  // THIS GETS PASSED DOWN TO PROVIDE AN ABILITY TO CLOSE THE MODAL TO SUB COMPONENTS
  const setModalVis = async (modalVisible) => {
    setShowIt(modalVisible);
  }

  // DO SOMETHING WITH THE dataObject - IF PROVIDED
  useEffect(() => {
    if (dataObject) {
      if (displayConsole) {
        console.log(`modal > useEffect > dataObject for modalType '${modalType}': `, dataObject);
      }
      if (modalType === 'sharedListCTA') {
        let firstNameOwner, firstNameShared, listName, listId = '';
        if (dataObject.List && Array.isArray(dataObject.List) && dataObject.List.length > 0) {
          const sharedList = dataObject.List[0];

          if (sharedList) {
            // LIST NAME
            listName = sharedList.listDetails && sharedList.listDetails.name ? sharedList.listDetails.name : null;
            
            // LIST ID
            listId = sharedList.id ? sharedList.id : null;

            // LIST OWNER
            if (sharedList.sourceList) {
              const sourceList = sharedList.sourceList;
              if (sourceList.listUsers && Array.isArray(sourceList.listUsers)
                && sourceList.listUsers.length > 0 && sourceList.listUsers[0].listUserType
                && sourceList.listUsers[0].listUserType === 'Owner'
                && sourceList.listUsers[0].user
                && sourceList.listUsers[0].user.firstName
                ) {
                firstNameOwner = sourceList.listUsers[0].user.firstName ? sourceList.listUsers[0].user.firstName : null
              }
            }

            // LIST SHARED WITH
            if (sharedList.listUsers && Array.isArray(sharedList.listUsers)
              && sharedList.listUsers.length > 0 && sharedList.listUsers[0].listUserType
              && sharedList.listUsers[0].listUserType === 'SharedWith'
              && sharedList.listUsers[0].user
              && sharedList.listUsers[0].user.firstName) {
              firstNameShared = sharedList.listUsers[0].user.firstName ? sharedList.listUsers[0].user.firstName : null
            }

          }
        }
        setModalDataObject({
          "listOwnerFirstName": `${firstNameOwner}`
          , "listSharedFirstName": `${firstNameShared}`
          , "listName": `${listName}`
          , "listId": `${listId}`
        })
      }

      if(modalType === 'userExpired'){
        if(dataObject && dataObject.listId){
          setModalDataObject({
            "listId": `${dataObject.listId}`
            ,"locationType": `${dataObject.locationType ? dataObject.locationType : ''}`
          })
        }
      }
    }
  }, []);


  return (
    <Subscribe to={[DrawerContainer]}>
      {appState => (
        <div className={showIt ? 'flex items-stretch ' : 'hidden'}>
          <div
            className="w-full fixed inset-0 z-1200  h-full"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }}
          >
            {modalType && modalType === 'sharedListCTA' && currentUserId === null ? (
            <ModalListNoAuth handleModalState={setModalVis} modalDataObject={modalDataObject} auth={auth} />
            ) : <ModalUserExpired handleModalState={setModalVis} modalDataObject={modalDataObject} auth={auth} />}
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </div>
      )}
    </Subscribe> 
  );
}
// }
