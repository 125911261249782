import gql from 'graphql-tag';

import FragmentListDetails from './fragments/fragmentListDetails';
import FragmentSharedList from './fragments/fragmentSharedList';
import FragmentListUser from './fragments/fragmentListUser';
import FragmentUser from './fragments/fragmentUser';
import FragmentItem from './fragments/fragmentItem';
import FragmentItemWatch from './fragments/fragmentItemWatch';
import FragmentList from './fragments/fragmentList';
import fragmentListDetails from './fragments/fragmentListDetails';
/* eslint-disable import/prefer-default-export */

// QUERIES
export const qryGetItemDetails = gql`
  query qryGetItemDetails($itemId: ID!) {
    Item(id: $itemId) {
      ...FragmentItem
    }
  }
  ${FragmentItem}
  ${FragmentItemWatch}
  ${FragmentUser}
  ${FragmentListUser}
`;

export const qryGetItems = gql`
  query qryGetItems($itemIds: [uuid!]) {
  Item( where: {id: {_in: $itemIds}}) {
    ...FragmentItem
  }
}
  ${FragmentItem}
  ${FragmentItemWatch}
  ${FragmentUser}
  ${FragmentListUser}
`;

/**
 * USED IN items COMPONENT
 */
export const qryGetallItemsForUser = gql`
  query qryGetallItemsForUser($userId: uuid!, $offsetStart: Int!, $qryLimit: Int!,$orderBy: Item_order_by!, $whereBy:Item_bool_exp!) {
    allUsers: User(where: {id: {_eq: $userId}}) { 
      id
      firstName
      lastName
      userId
      Items(limit:$qryLimit,offset:$offsetStart, where:$whereBy,order_by:[$orderBy]){
        ...FragmentItem
      }
      totalDataset: Items_aggregate (where:{_and:{deletedState:{_eq:false},obtained:{_eq:false}} }) {
        aggregate {
          count(columns: amount)
          max {
            amount
          }
        }
      }
			pagingDataset: Items_aggregate(where:$whereBy) {
        aggregate {
          count(columns: amount)
          max {
            amount
          }
        }
      }
    }
  }
  ${FragmentItem}
  ${FragmentItemWatch}
  ${FragmentUser}
  ${FragmentListDetails}
  ${FragmentListUser}
`;



// USED IN draweritem.item.delete
export const qryGetListsAssocToItem = gql`
  query getListsAssocToItem($itemId: uuid!) {
    Item(where:{id:{_eq:$itemId}}) {
      id
      listItems: ListItems {
        id
        list: List{
          ...FragmentList
        }
      }
    }
  }
  ${FragmentList}
  ${FragmentListDetails}
  ${FragmentSharedList}
  ${FragmentListUser}
  ${FragmentUser}
`;


export const getAllInvitedUsersBySender = gql`
  query getInvitedUsersBySender($senderId: Int) {
  Invitation (
    distinct_on: [receiverEmail]
    where: {_and: {senderId: {_eq: $senderId}, receiverEmail: {_is_null: false}}}
  ) 
  {
    id
    receiverEmail
    receiver: UserReceiver {
      ...FragmentUser
    }
  }
}
${FragmentUser}
`;

export const qryGetListDetailsShallow = gql`
  query getGetListDetails_Shallow($listId: ID!) {
    List(id: $listId) {
      ...FragmentList
    }
  }
  ${FragmentList}
  ${FragmentListDetails}
  ${FragmentSharedList}
  ${FragmentListUser}
  ${FragmentUser}
`;


/**
 * USE ON THE /l & /sh COMPONENTS (within allLists)
 */
export const qryGetListsByUserTypeShallow_Full = gql`
  query getListsByUserType_Shallow($userId: uuid!, $listUserType: [String!]) {
  User(where: {id: {_eq: $userId}}) {
    listUsers: ListUsers(where: {_and:{listUserType: {_in: $listUserType},List:{deletedState:{_eq:false}}} }, order_by:{List:{pinned:desc, updatedAt:desc}}) {
      list: List {
      ...FragmentList
      }
    }
  }
}
${FragmentList}
${FragmentListDetails}
${FragmentSharedList}
${FragmentListUser}
${FragmentUser}
`;

/**
 * USED TO SHOW ANY visibilityType === private LISTS
 */
export const qryGetPrivateListsByUserTypeShallow_Full = gql`
  query getPrivateListsByUserType_Shallow($userId: uuid!, $listUserType: [String!],$listVisibility:[String!]) {
  User(where: {id: {_eq: $userId}}) {
    listUsers: ListUsers(where: {_and:{listUserType: {_in: $listUserType},List:{deletedState:{_eq:false},ListDetails:{visibilityType:{_in:$listVisibility}}}} }, order_by:{List:{pinned:desc, updatedAt:desc}}) {
      list: List {
	      ...FragmentList
				
      }
    }
  }
}
${FragmentList}
${FragmentListDetails}
${FragmentSharedList}
${FragmentListUser}
${FragmentUser}
`;

/**
 * USE ON THE SEARCH COMPONENT
 */
// export const qrySearchListsByUserTypeShallow_Full = gql`
//   query searchListsByUserType_Shallow($userId: uuid!, $listUserType: [String!], $searchTerm: String!) {
//   User(where: {id: {_eq: $userId}}) {
//     listUsers: ListUsers(where: {_and: {listUserType: {_in: $listUserType}, List: {deletedState: {_eq: false}, ListDetails: {name: {_ilike: $searchTerm}}}}}, order_by: {List: {pinned: desc, updatedAt: desc}}) {
//       list: List {
//         id
//         listDetails: ListDetails {
//           name
//           description
//         }
//       }
//       listUserType
//     }
//   }
// }
// ${FragmentList}
// ${FragmentListDetails}
// ${FragmentSharedList}
// ${FragmentListUser}
// ${FragmentUser}
// `;

export const qrySearchListsByUserTypeShallow_Full = gql`
  query searchListsByUserType_Shallow($userId: uuid!, $listUserType: [String!]) {
  User(where: {id: {_eq: $userId}}) {
    listUsers: ListUsers(where: {_and: {listUserType: {_in: $listUserType}, List: {deletedState: {_eq: false}}}}, order_by: {List: {pinned: desc, updatedAt: desc}}) {
      list: List {
        id
        listId
        listDetails: ListDetails {
          id
          name
          description
        }
        listItems:ListItems(where:{Item:{imageUrl:{_is_null: false}, obtained: {_eq: false}}},order_by:{Item:{ordinal:asc}},limit:1){
          item: Item{
            id
            ordinal
            imageUrl
            obtained
            name
          }
        }
        sourceList: SourceList {
          id
          listUsers: ListUsers {
            listUserType
            user: User {
              ...FragmentUser
            }
          }
        }
      }
      listUserType
    }
  }
}
${FragmentUser}
`;


// MAIN QUERY ON list COMPONENT
export const queryGetListDetailAndItems = gql`
  query getListDetailsAndItems($listId: uuid!) {
  List(where: {id: {_eq: $listId}}) {
    id
    listId
    createdAt
    updatedAt
    deletedState
    listDetails: ListDetails {
      ...FragmentListDetails
    }
    listUsers: ListUsers {
      listUserType
      user: User {
        id 
        firstName
        lastName
        avatarUrl
      }
    }
      listItems: ListItems( where:{Item:{deletedState:{_eq:false}}} ,order_by:{Item:{ordinal:asc}}) {
      item: Item {
        id
        itemId
        name
        description
        imageUrl
        amount
        code
        color
        obtained
        ordinal
        processedImage
        qty
        size
        deletedState
        store
        url
        updatedAt
        succsessfulScrape
        user: User{
          id
          firstName
          lastName
          avatarUrl
        }
        itemWatches: ItemWatches(where:{Item:{deletedState:{_eq:false}}}){
          id
          watchUsers: User {
            ...FragmentUser
          }
        }
        sharedLists: ListItems{
          id
          list: List{
            # CAN'T USE FragmentList HERE B/C IT'S ON A List
            id
            listId
            listDetails: ListDetails {
              ...FragmentListDetails
            }
            listUsers: ListUsers {
              listUserType
              user: User {
                ...FragmentUser
              }
            }
          }
        }
      }
    }
    sharedLists: SharedLists(where: {deletedState: {_eq: false}}) {
      id
      updatedAt
      listUsers: ListUsers {
        listUserType
        user: User {
          ...FragmentUser
        }
      }
    }
    sourceList: SourceList {
      id
      updatedAt
      listDetails: ListDetails {
        ...FragmentListDetails
      }
      listUsers: ListUsers {
        listUserType
        user: User {
          ...FragmentUser
        }
      }
      sharedLists: SharedLists(where: {deletedState: {_eq: false}}) {
        id
        updatedAt
        listUsers: ListUsers {
          listUserType
          user: User {
            ...FragmentUser
          }
        }
      }
    }
  }
}
  ${FragmentItemWatch}
  ${FragmentUser}
  ${FragmentListUser}
  ${fragmentListDetails}
  ${FragmentList}
  ${FragmentSharedList}
`;

/**
 * USED IN THE draweritem.share.list.delete COMPONENT
 */
export const qryGetSharedList = gql`
  query qryGetSharedList($userId: uuid!, $listId: uuid!) {
  ListUser(where: {User: {id: {_eq: $userId}}, List: {SourceList: {id: {_eq: $listId}}}}) {
    id
    user: User {
      ...FragmentUser
    }
    list: List {
      ...FragmentList
    }
  }
}
  ${FragmentItemWatch}
  ${FragmentUser}
  ${FragmentListUser}
  ${fragmentListDetails}
  ${FragmentList}
  ${FragmentSharedList}
`;

/**
 * USED IN THE draweritem.share.list.delete.editor COMPONENT
 */
export const qryGetEditorList = gql`
  query qryGetEditorList($userId: uuid!, $listId: uuid!) {
  ListUser(where: {User: {id: {_eq: $userId}}, List: {id: {_eq: $listId}}}) {
    id
    user: User {
      ...FragmentUser
    }
    list: List {
      ...FragmentList
    }
  }
}
  ${FragmentItemWatch}
  ${FragmentUser}
  ${FragmentListUser}
  ${fragmentListDetails}
  ${FragmentList}
  ${FragmentSharedList}
`;

export const qryGetSharedListItems = gql`
  query qryGetSharedListItems($sourceListId: uuid!, $sharedUserId: uuid!, $sourceItemId: uuid!) {
    User:User(where: {id: {_eq: $sharedUserId}}) {
      listUsers: ListUsers(where: {List: {SourceList: {id: {_eq: $sourceListId}}}}) {
        id
        user: User {
          ...FragmentUser
        }
        list:List {
          id
          sourceListId
          listItems: ListItems(where: {Item: {id: {_eq: $sourceItemId}}}) {
            id
            item:Item {
              ...FragmentItem
            }
          }
        }
      }
    }
  }
  ${FragmentUser}
  ${FragmentItem}
`;


// MUTATIONS

export const mutationCreateItemWatch = gql`
  mutation createItemWatch($itemId:Int!, $userId:Int!) {
  insert_ItemWatch_one(object: {itemId: $itemId, userId: $userId}){
    id
    item:Item{
      ...FragmentItem
    }
    user: User{
      ...FragmentUser
    }
  }
}
${FragmentUser}
${FragmentItem}
${FragmentItemWatch}
`;

export const mutationDeleteItemWatch = gql`
  mutation deleteItemWatch($itemWatchId: uuid!) {
  delete_ItemWatch(where: {id: {_eq: $itemWatchId}}){
    affected_rows
    returning{
      id
      item:Item{
      ...FragmentItem
    }
    }
  }
}
${FragmentItem}
${FragmentItemWatch}
${FragmentUser}
`;

export const mutationTogglePin = gql`
  mutation toggleListPin ($listId:uuid!, $pinned: Boolean){
  update_List(where: {id: {_eq: $listId}}, _set: {pinned: $pinned}){
    returning{
      id
      pinned
    }
  }
}
`;


// APOLLO REST LINK
export const mutationCreateList = gql`
  mutation createList { 
    List(input: $input) @rest(type: "list", endpoint: "createList", path:"/gyfto_list_create?returnContents=true", method: "POST") { 
      ...FragmentList
     } 
  }
  ${FragmentList}
  ${FragmentListDetails}
  ${FragmentSharedList}
  ${FragmentListUser}
  ${FragmentUser}
`;

export const mutationUpdateList = gql`
  mutation updateList($listId: uuid!, $listDetailsId: Int!, $buyType: String, $visibilityType: String, $listName: String, $listDescription: String, $updateDateTime: timestamptz) {
  update_ListDetails(_set: {name: $listName, description: $listDescription, buyType: $buyType, visibilityType: $visibilityType}, where: {listDetailsId: {_eq: $listDetailsId}}) {
    affected_rows
  }
  update_List(_set: {updatedAt: $updateDateTime}, where: {id: {_eq: $listId}}) {
    returning {
      ...FragmentList
    }
  }
}
  ${FragmentList}
  ${FragmentListDetails}
  ${FragmentSharedList}
  ${FragmentListUser}
  ${FragmentUser}
`;

export const mutationDeleteList = gql`
  mutation deleteList($listId: uuid!, $deletedState: Boolean!) {
      update_List(where: {id: {_eq: $listId}}, _set: {deletedState: $deletedState}) {
      returning {
        ...FragmentList
      }
    }
  }
  ${FragmentList}
  ${FragmentListDetails}
  ${FragmentSharedList}
  ${FragmentListUser}
  ${FragmentUser}
`;

export const mutationCreateItem = gql`
  mutation createItem($name: String!, $description: String, $amount: money, $qty: smallint, $color: String, $size: String, $store: String, $url: String, $imageUrl: String, $ordinal: smallint, $processedImage: Boolean, $code: String, $listId:Int, $userId: Int, $succsessfulScrape: Boolean) {
  insert_Item(objects: {amount: $amount, code: $code, color: $color, description: $description, imageUrl: $imageUrl, name: $name, ordinal: $ordinal, processedImage: $processedImage, qty: $qty, size: $size, store: $store, url: $url, succsessfulScrape:$succsessfulScrape, ListItems:{data:{listId: $listId}},userId:$userId}) {
    returning {
      ...FragmentItem
    }
  }
}
  ${FragmentItem}
  ${FragmentItemWatch}
  ${FragmentListUser}
  ${FragmentUser}
`;

export const mutationUpdateItem = gql`
  mutation updateItem(
    $itemId: uuid!
    $name: String!
    $description: String
    $amount: money
    $qty: smallint
    $color: String
    $size: String
    $store: String
    $url: String
    $imageUrl: String
    $ordinal: smallint
    $processedImage: Boolean
    $code: String
  ) {
     update_Item(where:{id:{_eq:$itemId}},_set:{
      name: $name
      description: $description
      amount: $amount
      ordinal: $ordinal
      qty: $qty
      color: $color
      size: $size
      store: $store
      url: $url
      imageUrl: $imageUrl
      processedImage: $processedImage
      code: $code})
     {
      returning{
        ...FragmentItem
      }
    }
  }
  ${FragmentItem}
  ${FragmentItemWatch}
  ${FragmentListUser}
  ${FragmentUser}
`;

export const mutationDeleteItem = gql`
  mutation deleteItem($itemId: uuid!) {
    update_Item(where:{id:{_eq:$itemId}},_set:{deletedState: true}) {
      returning{
        ...FragmentItem
      }
    }
  }
  ${FragmentItem}
  ${FragmentItemWatch}
  ${FragmentListUser}
  ${FragmentUser}
`;

// MARKS AN ITEM AS OBTAINED BY AN OWNER/EDITOR
export const mutationSetItemObtained = gql`
   mutation setItemObtained($itemId: uuid!, $obtained: Boolean, $obtainedBy: Int) {
    update_Item(where:{id:{_eq:$itemId}},_set:{obtained: $obtained, obtainedBy: $obtainedBy}) {
      returning{
        ...FragmentItem
      }
    }
  }
  ${FragmentItem}
  ${FragmentItemWatch}
  ${FragmentListUser}
  ${FragmentUser}
`;

// MARKS AN ITEM AS OBTAINED BY A SHARED USER
export const mutationSetItemObtainedBy = gql`
   mutation setItemObtainedBy($itemId: uuid!, $obtainedBy: Int) {
    update_Item(where:{id:{_eq:$itemId}},_set:{obtainedBy: $obtainedBy}) {
      returning{
        ...FragmentItem
      }
    }
}
  ${FragmentItem}
  ${FragmentItemWatch}
  ${FragmentListUser}
  ${FragmentUser}
`;

export const mutationAddItemToList = gql`
  mutation addItemToList ($itemId: Int!, $listId: Int!){
  insert_ListItem(objects: {itemId: $itemId, listId: $listId}){
    returning{
      itemId
      listId
    }
  }
}
`;

export const mutationDeleteItemAndListItem = gql`
  mutation deleteItemAndListItem($listItemIds:[uuid!], $itemId: uuid!){
  delete_ListItem(where:{id:{_in:$listItemIds}}){
    affected_rows
    returning{
      item: Item{
        ...FragmentItem
      }
      list: List{
        ...FragmentList
      }
    }
  }
  
  delete_Item(where: {id:{_eq: $itemId}}){
    affected_rows
    returning{
      ...FragmentItem
    }
  }
}
  ${FragmentItem}
  ${FragmentItemWatch}
  ${FragmentListUser}
  ${FragmentUser}
  ${FragmentList}
  ${FragmentListDetails}
  ${FragmentSharedList}
`;

export const mutationDeleteListItems = gql`
  mutation deleteListItems($listItemIds:[uuid!]){
  delete_ListItem(where:{id:{_in:$listItemIds}}){
    affected_rows
    returning{
      item: Item{
        ...FragmentItem
      }
      list: List{
        ...FragmentList
      }
    }
  }
}
  ${FragmentItem}
  ${FragmentItemWatch}
  ${FragmentListUser}
  ${FragmentUser}
  ${FragmentList}
  ${FragmentListDetails}
  ${FragmentSharedList}
`;

/**
 * THIS IS USED IN THE draweritem.share.delete COMPONENT TO REMOVE
 * A SHARED LIST FROM A USER, WILL ALSO DELETE THE SHARED USERS LIST (listUser + listItems)
 */
export const mutationDeleteShareForUser = gql`
  mutation mutationDeleteShareForUser($listUserId: uuid!, $listId: Int!) {
  # listUser FIRST
  delete_ListUser(where: {id: {_eq: $listUserId}}) {
    affected_rows
  }
  
  # listItems NEXT
  delete_ListItem(where: {listId: {_eq: $listId}}) {
    affected_rows
  }
  
  #list AT THE END
  delete_List(where: {listId: {_eq: $listId}}){
    affected_rows
  }
}
`;

/**
 * THIS IS USED TO UPDATE THE ListDetails WITHIN THE list.listDetails COMPONENT
 */
export const mutationUpdateListDetails = gql`
  mutation updateListDetails($listDetailsId: uuid!, $listName: String!, $listDescription: String!) {
  update_ListDetails(where: {id: {_eq: $listDetailsId}}, _set: {description: $listDescription, name: $listName}){
    returning{
      ...FragmentListDetails
    }
  }
}
${FragmentListDetails}
`;

/**
 * THIS IS USED INT HE draweritem.share.list.delete.editor COMPONENT TO REMOVE
 * AN EDITOR FROM A LIST
 */
export const mutationDeleteShareForEditor = gql`
  mutation mutationDeleteShareForEditor($listUserId: uuid!) {
  # listUser FIRST
  delete_ListUser(where: {id: {_eq: $listUserId}}) {
    affected_rows
  }
}
`;

/**
 * THIS IS USED TO MOVE AN ITEM FROM ONE LIST TO ANOTHER - IT WILL:
 * - DELETE ALL ITEM WATCHES
 * - DELETE ANY LISTITEMS THAT AREN'T THE PRIMARY LISTITEM
 * - UPDATE LISTITEM TO POINT TO THE NEW LIST
 */
export const mutationMoveItem = gql`
  mutation mutationMoveItem($itemWatchIds: [uuid!], $listItemIds: [uuid!], $listItemId: uuid!, $listId:Int!) {
    # DELETE ANY itemWatches ON THIS ITEM
    delete_ItemWatch(where: {id: {_in: $itemWatchIds}}) {
      affected_rows
      returning {
        id
      }
    }
    
    # DELETE ANY listItems ON THIS ITEM
    delete_ListItem(where: {id: {_in: $listItemIds}}) {
      affected_rows
    }
    
    # MOVE THE ITEM TO A DIFF LIST
    update_ListItem(where: {id: {_eq: $listItemId}}, _set: {listId: $listId}){
      affected_rows
      returning{
        id
        listId
        itemId
      }
    }
  }
`;

// SUBSCRIPTIONS
export const subGetListsByUserTypeShallow = gql`
  subscription getListsByUserType_Shallow($userId: uuid!, $listUserType: [String!]) {
  User(where: {id: {_eq: $userId}}) {
      listUsers: ListUsers(where: {_and: {listUserType:{_in:$listUserType}, List: {deletedState: {_eq: false}, ListDetails:{visibilityType:{_eq:"visible"}}}}}, limit: 6, order_by: {List: {pinned: desc, updatedAt: desc}}) {
      list: List {
      ...FragmentList
      }
    }
  }
}
${FragmentList}
${FragmentListDetails}
${FragmentSharedList}
${FragmentListUser}
${FragmentUser}
`;

export const subGetListDetailAndItems = gql`
  subscription getListDetailsAndItems($listId: uuid!) {
  List(where: {id: {_eq: $listId}}) {
    id
    listId
    createdAt
    updatedAt
    deletedState
    pinned
    listDetails: ListDetails {
      ...FragmentListDetails
    }
    listUsers: ListUsers {
      listUserType
      id
      user: User {
        id 
        firstName
        lastName
        avatarUrl
        email
      }
    }
    itemsTotal: ListItems_aggregate(where: {Item: {deletedState: {_eq: false}}}) {
      total: aggregate {
        count
      }
    }
    listItems: ListItems( where:{Item:{deletedState:{_eq:false}}} ,order_by:{Item:{ordinal:asc}}) {
      id
      item: Item {
        id
        itemId
        name
        description
        imageUrl
        amount
        code
        color
        obtained
        ordinal
        processedImage
        qty
        size
        deletedState
        store
        url
        updatedAt
        succsessfulScrape
        user: User{
          id
          firstName
          lastName
          avatarUrl
        }
        obtainedBy: ObtainedBy{
          id
          firstName
          lastName
          avatarUrl
        }
        itemWatches: ItemWatches(where:{Item:{deletedState:{_eq:false}}}){
          id
          watchUsers: User {
            ...FragmentUser
          }
        }
        sharedLists: ListItems{
          id
          list: List{
            # CAN'T USE FragmentList HERE B/C IT'S ON A List
            id
            listId
            deletedState
            listDetails: ListDetails {
              ...FragmentListDetails
            }
            listUsers: ListUsers {
              listUserType
              id
              user: User {
                ...FragmentUser
              }
            }
          }
        }
      }
    }
    sharedLists: SharedLists(where: {deletedState: {_eq: false}}) {
      id
      updatedAt
      listUsers: ListUsers {
        listUserType
        id
        user: User {
          ...FragmentUser
        }
      }
    }
    sourceList: SourceList {
      id
      updatedAt
      listDetails: ListDetails {
        ...FragmentListDetails
      }
      listUsers: ListUsers {
        listUserType
        user: User {
          ...FragmentUser
        }
      }
      sharedLists: SharedLists(where: {deletedState: {_eq: false}}) {
        id
        updatedAt
        listUsers: ListUsers {
          listUserType
          user: User {
            ...FragmentUser
          }
        }
      }
    }
  }
}
  ${FragmentItemWatch}
  ${FragmentUser}
  ${FragmentListUser}
  ${fragmentListDetails}
  ${FragmentList}
  ${FragmentSharedList}
`;

/* eslint-enable import/prefer-default-export */
