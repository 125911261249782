import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Subscribe } from 'unstated';
import { Editor, EditorState } from 'draft-js';
// import 'draft-js/dist/Draft.css';
// import '../../../node_modules/draft-js/dist/Draft.css';

import Button from '../common/button';
import { fnGetItemDetailsFromUrl } from '../../functions/index';
import DrawerContainer from '../../containers/drawer';
import DrawerSpace from '../common/drawerSpace';
import Loading from '../common/loading';
import Or from '../common/or';

export default function ItemCreateEnterItemUrl(props) {
  const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;
  const COMPONENT_NAME = 'itemCreate.enterItemUrl';
  const [itemUrl, setItemUrl] = useState('');
  const [bulkItems, setBulkItems] = useState('');
  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty(),
  );

  const [disableBtn, setBtnDisable] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const { forward, saveScrapedItemFn } = props;
  // eslint-disable-next-line

  // THIS WILL CALL A FUNCTION TO SCRAPE THE URL
  const handleClick_itemUrl = async () => {

    if (displayConsole) {
      console.log(`itemCreate.enterItemUrl > about to call out to the FN to scrape this page: ${itemUrl}`);
    }
    let responseObj = null;
    responseObj = await fnGetItemDetailsFromUrl(itemUrl).catch((e) => {
      if (displayConsole) {
        console.log(`itemCreate.enterItemUrl > error calling out to get the details of this URL: ${itemUrl} | Error message: ${e.message}`);
      }
    });
    return responseObj;
  };

  const handleChange = event => {
    setItemUrl(event.target.value);
  };

  return (
    <Subscribe to={[DrawerContainer]}>
      {appState => (
        <DrawerSpace>
          <div className="pt-2">
            {showLoading ? (
              <div className="flex content-center">
                <Loading loadingText="Getting item details from your link..." />
              </div>

            ) : (
              <form>
                <div className="drawer-header-description-text">
                  Enter your item's URL
                </div>
                <div className='text-grey-dark text-base pt-1' style={{ lineHeight: '1.35rem' }}>
                  If you don't have one, click 'Next' below.
                </div>
                <div className='pt-4'>
                  <input
                    autoFocus
                    id="inputUrl"
                    type="text"
                    className="w-full form-input form-input-link focus:outline-none focus:shadow-outline"
                    // eslint-disable-next-line
                    value={itemUrl}
                    onChange={handleChange}
                    placeholder="Your item's url"
                  />
                </div>
                <br />
                {process.env.REACT_APP_DISPLAY_CONSOLE === 'local' ? (
                  <div className="flex justify-end content-end mt-2">
                    <Button
                      buttonType="pill-void-standard"
                      xtra="text-xs py-1 px-2"
                      weight="thin"
                      responsive={false}
                      onClick={async () => {
                        // RESET THE VALUE OF THE values.description FIELD
                        setItemUrl('https://www.bobswatches.com/rolex-pepsi-gmt-master-16750.html');
                      }}
                    >
                      <span className="text-center">test value</span>
                    </Button>
                  </div>
                ) : <Fragment />}
                <div className="flex justify-between mt-8">
                  <Button
                    buttonType="pill-cancel"
                    weight="thin"
                    xtra="hover:shadow"
                    onClick={() => {
                      appState.closeDrawer();
                    }}
                  >
                    <span className="text-center">Cancel</span>
                  </Button>
                  <Button
                    buttonType="pill-add"
                    weight="thin"
                    xtra="hover:shadow"
                    disabled={disableBtn}
                    onClick={async e => {
                      try {
                        if (itemUrl) {
                          // https://codesandbox.io/s/v817wz0257
                          // CALL API TO SCRAPE PAGE
                          setBtnDisable(true);
                          setShowLoading(true);
                          await handleClick_itemUrl().then(res => {
                            if (res && res.returnData) {
                              appState.captureItemUrl(itemUrl);
                              appState.transferItemToDrawer(res.returnData);
                              setShowLoading(false);
                            }
                          });
                        }
                      } catch (error) {
                        appState.showMessageCard('error', "Issue getting details about that item!");
                      } finally {
                        forward();
                      }
                    }}
                  >
                    <span className="text-center">Next</span>
                  </Button>
                </div>
                {/* 12/31/22 - COMMENTING OUT UNTIL I CAN FIGURE OUT HOW TO HANDLE THIS TYPE OF MULTI INPUT */}
                <div hidden>
                  <div className='pt-8 w-full'>
                    <Or />
                  </div>
                  <div className="drawer-header-description-text pt-8">
                    Create several items at once
                  </div>
                  <div className="text-grey-dark text-base pt-1" style={{ lineHeight: '1.35rem' }}>
                    Type in your item names below and they will each become an item on the list.
                  </div>
                  <div className='pt-6  h-full'>
                    {/* <textarea
                    id="bulkItems"
                    rows="6"
                    className="w-full form-input focus:outline-none focus:shadow-outline"
                    value={bulkItems}
                    onChange={e => setBulkItems(e.target.value)}
                  /> */}
                    <Editor editorState={editorState} onChange={setEditorState} />
                  </div>
                  <div className="flex justify-between mt-8">
                    <Button
                      buttonType="pill-cancel"
                      weight="thin"
                      xtra="hover:shadow"
                      onClick={() => {
                        appState.closeDrawer();
                      }}
                    >
                      <span className="text-center">Cancel</span>
                    </Button>
                    <Button
                      buttonType="pill-add"
                      weight="thin"
                      xtra="hover:shadow"
                      disabled={disableBtn}
                      onClick={async e => {
                        try {
                          if (bulkItems) {
                            // setBtnDisable(true);
                            // setShowLoading(true);
                            // let foo = convertFromRaw(JSON.parse(bulkItems)); 
                            // const blocksFromHTML = convertFromHTML(bulkItems);
                            // const state = ContentState.createFromBlockArray(
                            //   blocksFromHTML.contentBlocks,
                            //   blocksFromHTML.entityMap,
                            // );
                            // console.log(`state:`, state);
                          }
                        } catch (error) {
                          let errText = `Issue creating items!`;
                          appState.showMessageCard('error', errText);
                          console.error(`${COMPONENT_NAME} > ${errText}`, error);
                        } finally {
                          // forward();
                        }
                      }}
                    >
                      <span className="text-center">Create Items</span>
                    </Button>
                  </div>
                </div>
              </form>
            )}
          </div>
        </DrawerSpace>
      )}
    </Subscribe>
  );
}

ItemCreateEnterItemUrl.propTypes = {
  forward: PropTypes.func,
};

ItemCreateEnterItemUrl.defaultProps = {
  forward: null,
};
