import React from 'react';
import { Helmet } from 'react-helmet';
import PageSpace from '../common/pageSpace';

const NotTheListYouAreLookingFor = () => (
  <div>
    <PageSpace>
      <div className="w-full bg-red">
        <Helmet>
          <title>Gyfto - Your Social Giftlist</title>
          <meta property="og:title" content={`Gyfto - Your Social Giftlist`} />
          <meta name="description" content={`Gyfto - Your Social Giftlist`} />
        </Helmet>
        <div className="flex ">
          <div className="flex-1"></div>
          <div className="flex-none md:max-w-gyfto-max">
            NOT THE LIST
          </div>
          <div className="flex-1"></div>

        </div>
        {/* THIS IS THE WHOLE CONTENT AREA CENTERED - MAX OF 800PX */}
        {/* <div className="w-full md:max-w-gyfto-max items-center md:pl-4 md:pr-4">
          <div>THIS IS NOT THE LIST YOU ARE LOOKING FOR </div>
        </div> */}
      </div>
    </PageSpace>
  </div>
);

export default NotTheListYouAreLookingFor;
