import React, { useState, Fragment } from 'react';
import { Subscribe } from 'unstated';
import DrawerContainer from '../../containers/drawer';
import DrawerSpace from '../common/drawerSpace';
import Loading from '../common/loading';
import ItemCreateShareItem from './itemCreate.enterItemShare';

export default function ItemCreateShareItemContainer(props) {
  const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;
  const COMPONENT_NAME = 'itemCreate.enterItemShare.container';
  // eslint-disable-next-line


  return (
    <Subscribe to={[DrawerContainer]}>
      {appState => (
        <Fragment>
          <ItemCreateShareItem inboundList={appState.state.selectedListObj} />
        </Fragment>
      )}
    </Subscribe>
  );
}
