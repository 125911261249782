/*
INSPIRATION: https://medium.com/@farid12ansari7/make-a-snackbar-or-pop-up-notification-for-a-react-web-app-fd246dd1b9f0
*/

import React from 'react';
import { Subscribe } from 'unstated';
import DrawerContainer from '../../containers/drawer';
import NotificationCardControl from './notificationCardControl';

export default function NotificationCard() {
  return (
    <Subscribe to={[DrawerContainer]}>
      {drawerContainer => (
        <div className="z-50">
          <NotificationCardControl
            showCard={drawerContainer.state.messageCardShow}
            type={drawerContainer.state.messageCardType}
            msg={drawerContainer.state.messageCardMsg}
          />
        </div>
      )}
    </Subscribe>
  );
}
