import React from 'react';

const svgTextStyle = {
  fontFamily: 'HammersmithOne-Regular, HammersmithOne, Arial',
  fontSize: 64,
};

const LogoLg = props => (
<svg xmlns="http://www.w3.org/2000/svg" width={290} height={61}>
  <defs/>
  <g fill="#5FBA7D" fillRule="evenodd">
    <text fillOpacity=".975" 
    // font-family="HammersmithOne-Regular, HammersmithOne" font-size="64" 
    style={svgTextStyle}
    transform="translate(.675 -6.042)">
      <tspan x="66.809" y="58.367">GYFTO</tspan>
    </text>
    <path d="M57.887 20.41h-.007V10.286c0-5.44-4.74-9.89-10.531-9.89H11.44C5.648.396.91 4.846.91 10.286v10.125H.9v7.432h.008v16.17c0 5.44 4.74 9.89 10.531 9.89H47.35c5.792 0 10.53-4.45 10.53-9.89V27.848h.005l.003-7.44zM45.61 48.845H33.133v-1.441-18.883c1.46 1.632 2.84 3.249 4.306 4.79 1.377 1.446 2.883 2.78 4.269 4.219 1.403 1.46 2.73 2.99 4.09 4.488l.287.318c.035-.186.069-.3.077-.415.268-3.324.527-6.647.807-9.969.028-.344-.04-.627-.288-.858a802.579 802.579 0 00-3.152-2.915c-.106-.097-.221-.185-.398-.33h9.259v14.493c0 3.586-3.041 6.503-6.78 6.503zM5.925 42.335V27.838h9.057c-.308.317-.55.581-.81.829-.943.897-1.888 1.793-2.844 2.677-.332.308-.353.662-.293 1.064.426 2.803.77 5.613.74 8.453-.006.51-.045 1.024-.073 1.63.152-.084.271-.114.337-.19.558-.648 1.048-1.357 1.667-1.945 2.118-2.018 4.308-3.97 6.42-5.994 1.52-1.457 2.963-2.99 4.439-4.489.07-.07.14-.14.266-.27V48.835H12.727c-3.75 0-6.802-2.916-6.802-6.5zM45.61 5.072c3.737 0 6.778 2.874 6.778 6.407v8.943l-1.268-.001h-.338c-.844 0-1.66.002-2.055.008-.258.004-.4-.075-.52-.292a17.436 17.436 0 01-1.753-4.639c-.12-.548-.353-.85-.937-.842-.84.01-1.703.06-2.514.254-2.437.581-4.56 1.784-6.56 3.18-.957.669-1.85 1.42-2.78 2.125-.137.103-.306.17-.532.293v-9.975-5.46h12.48v-.001zm-39.685 6.4c0-3.534 3.109-6.41 6.93-6.41H25.18v15.436c-.323-.103-.618-.149-.86-.28-.484-.268-.942-.575-1.402-.877-2.686-1.765-5.76-2.647-8.91-3.35a4.377 4.377 0 00-.724-.087c-1.326-.066-1.758.152-2.21 1.295-.374.95-.634 1.94-.963 2.905-.043.124-.205.303-.315.304-.842.01-1.685.013-2.535.014H5.924v-8.95z"/>
  </g>
</svg>
);

export default LogoLg;
