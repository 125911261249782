import React, { Fragment } from 'react';
import PropTypes from 'prop-types'
import Lottie from 'lottie-react-web';
import animationData from './lotties/gift_jumping.json';

export default function Loading(props) {
  const { loadingText } = props;
  return (
    <Fragment>
      <div className="flex flex-col justify-center mx-auto my-auto pt-2">
        {loadingText ? (
          <div><span className="drawer-header-description-text">{loadingText}</span></div>
        )
        : <Fragment/>}
        <div>
          <Lottie
            options={{
              animationData,
              loop: true,
            }}
            height={200}
            width={200}
          />
        </div>
      </div>

    </Fragment>
  );
}

Loading.propTypes = {
  loadingText: PropTypes.string,
};

Loading.defaultProps = {
  loadingText: null,
};
