import React from 'react';
import SVGEmptyList from '../common/icons/empty-list-svg';


export default function EmptyList() {
  return (
    <div className="">
      <div>
        <SVGEmptyList  />
      </div>
    </div>
  );
};
