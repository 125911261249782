
import React, { Component, Fragment } from 'react';
// import PropTypes from 'prop-types';
import { Subscribe } from 'unstated';
import CardSpace from '../home/card.css';
import history from '../history/index';
// THIS IS FOR THE unstated CONTAINER
import DrawerContainer from '../../containers/drawer';

export default function ListCardCombo(props) {

  return (
    <React.Fragment>
      <Subscribe to={[DrawerContainer]}>
        {appState => (
          <CardSpace type='combo'>
            <div className="flex flex-col justify-between">
              <div className="flex-1 min-h-gyfto-card-combo bg-gyfto-green text-center pt-3 cursor-pointer hover:shadow-lg rounded-lg" onClick={() => appState ? appState.showHide('list', 'create') : null}>
                <div className="text-center text-white text-2xl flex-1 mt-12">New List</div>
              </div>
              <div className="flex-auto p-2"><br /></div>
              <div className="flex-1 min-h-gyfto-card-combo text-center bg-gyfto-blue cursor-pointer hover:shadow-lg rounded-lg" onClick={() => history.push(`/ls`)}>
                <div className="text-center text-white text-2xl flex-1 mt-12">See More</div>
              </div>
            </div>
          </CardSpace>
        )}
      </Subscribe>
    </React.Fragment>
  );
}
