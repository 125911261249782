import React from 'react';

export default function SVGEmptyShare(props) {
    const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;

    if (displayConsole) {
        console.log(`empty-share-svg > props: `, props);
    }
    return (
        <div >
            <svg xmlns="http://www.w3.org/2000/svg" width="226.963" height="201.634" viewBox="0 0 226.963 201.634">
                <defs>
                    <clipPath id="Empty_Shares_clip-path">
                        <path id="Path_17" d="M363.079,344.125q-23.038,37.628-60.666,34.813t-82.552,7.3q-44.924,10.111-33.789-34.813t17.406-72.441q6.271-27.517,33.789-34.813a130.937,130.937,0,0,1,57.083-2.048q29.565,5.248,60.666,34.813T363.079,344.125Z" />
                    </clipPath>
                </defs>
                <g id="Empty_Shares" transform="translate(-854.674 13756.817)">
                    <g id="Blob_shape" transform="translate(602.72 -13862.271) rotate(-19)" clipPath="url(#Empty_Shares_clip-path)">
                        <path id="Path_16" d="M363.079,344.125q-23.038,37.628-60.666,34.813t-82.552,7.3q-44.924,10.111-33.789-34.813t17.406-72.441q6.271-27.517,33.789-34.813a130.937,130.937,0,0,1,57.083-2.048q29.565,5.248,60.666,34.813T363.079,344.125Z" fill="rgba(166,182,191,0.1)" />
                    </g>
                    <path id="Path_7" d="M114.078,141.714V4a4,4,0,0,0-4-4h-98.2a4,4,0,0,0-4,4V141.714a4,4,0,0,0,4,4h98.2A4,4,0,0,0,114.078,141.714Z" transform="translate(907 -13736)" fill="#fff" />
                    <path id="Path_8" d="M107.2,37.516V10.644a4,4,0,0,0-4-4H48.309M107.2,88.71v7.307m0,16.114v36.228a4,4,0,0,1-4,4H87.31M42.31,6.644H5a4,4,0,0,0-4,4V148.359a4,4,0,0,0,4,4H72.31" transform="translate(907 -13736)" fill="none" stroke="#6dc088" strokeLinecap="round" strokeWidth="2" />
                    <path id="Path_9" d="M107.2,37.516V10.644a4,4,0,0,0-4-4H48.309M107.2,88.71v7.307m0,16.114v36.228a4,4,0,0,1-4,4H87.31M42.31,6.644H5a4,4,0,0,0-4,4V148.359a4,4,0,0,0,4,4H55.81" transform="translate(907 -13736)" fill="none" stroke="#6dc088" strokeLinecap="round" strokeWidth="2" />
                    <path id="Path_10"  d="M107.2,10.644a4,4,0,0,0-4-4H5a4,4,0,0,0-4,4V148.359a4,4,0,0,0,4,4H57.81M107.2,43.69V76.213m0,9.262V99.017m0,10.5v38.846a4,4,0,0,1-4,4H93.5M28.642,66.014H38.663m25.147,0h0M28.642,92.906H70.77M28.642,119.8H70.77" transform="translate(907 -13736)" fill="none" stroke="#adbcc3" strokeLinecap="round" strokeWidth="2" />
                    <rect id="Rectangle_3" width="91" height="79" transform="translate(917.137 -13675)" fill="#fff" />
                    <path id="Path_11"  d="M28.642,39.8H95.524" transform="translate(901.227 -13698.8)" fill="none" stroke="#adbcc3" strokeLinecap="round" strokeWidth="2" />
                    <circle id="Ellipse_6" cx="20" cy="20" r="20" transform="translate(917.31 -13720)" fill="#5fba7d" opacity="0.909" />
                    <path id="Path_18"  d="M28.642,39.8H95.524" transform="translate(901.227 -13682.8)" fill="none" stroke="#adbcc3" strokeLinecap="round" strokeWidth="2" />
                    <path id="Path_19"  d="M28.642,39.8H95.524" transform="translate(901.227 -13666.8)" fill="none" stroke="#adbcc3" strokeLinecap="round" strokeWidth="2" />
                    <path id="Path_20"  d="M28.642,39.8H95.524" transform="translate(901.227 -13650.8)" fill="none" stroke="#adbcc3" strokeLinecap="round" strokeWidth="2" />
                    <circle id="Ellipse_7" cx="20" cy="20" r="20" transform="translate(943.31 -13720)" fill="rgba(95,186,125,0.49)" opacity="0.909" />
                    <circle id="Ellipse_8" cx="20" cy="20" r="20" transform="translate(968.155 -13720)" fill="rgba(95,186,125,0.2)" opacity="0.909" />
                </g>
            </svg>


        </div>
    );
}
