import React from 'react';

export default function Or() {
  return (
    <div className='flex justify-between'>
      <div className='flex-auto bg-gyfto-green w-full mr-2' style={{maxHeight:'1px',marginTop:'15px'}}></div>
      
      <div className='rounded-full bg-grey-light'>
        <div className='p-2 z-50 text-sm font-bold text-gyfto-font-grey align-middle text-center'>or</div>
      </div>
      <div className='flex-auto bg-gyfto-green w-full ml-2' style={{maxHeight:'1px',marginTop:'15px'}}></div>
    </div>
  );
}
