import React from 'react';
import PropTypes from 'prop-types';

class ItemProps extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { listItem, isShare } = this.props;

    let sizeDisplay;
    let amountDisplay;
    let colorDisplay;
    let qtyDisplay;
    let codeDisplay;
    // eslint-disable-next-line

    /* eslint-disable no-unused-expressions */
    listItem.color ? (colorDisplay = 'item-prop-display') : (colorDisplay = 'hidden');

    listItem.qty > 1 ? (qtyDisplay = 'item-prop-display') : (qtyDisplay = 'hidden');

    listItem.size ? (sizeDisplay = 'item-prop-display') : (sizeDisplay = 'hidden');

    listItem.amount && parseFloat(listItem.amount.substring(1).replace(',', '')) > 0.0 ? (amountDisplay = 'item-prop-display') : (amountDisplay = 'hidden');

    listItem.code && !isShare ? (codeDisplay = 'item-prop-display') : (codeDisplay = 'hidden');
    /* eslint-enable no-unused-expressions */

    return (
      <div>
        {/* THE COST/QTY/COLOR/PRICE SECTION */}
        {/* flex-wrap justify-between p-4 max-w-xs */}
        <div className="flex flex-wrap p-4 max-w-xs">
          <div className={amountDisplay}>
            <div className="item-prop-pill">
              <span className="item-prop">AMOUNT</span>
              <span className="item-prop-value">{listItem.amount}</span>
            </div>
          </div>
          <div className={qtyDisplay}>
            <div className="item-prop-pill">
              <span className="item-prop">QTY</span>
              <span className="item-prop-value">{listItem.qty}</span>
            </div>
          </div>
          <div className={sizeDisplay}>
            <div className="item-prop-pill">
              <span className="item-prop">SIZE</span>
              <span className="item-prop-value">{listItem.size}</span>
            </div>
          </div>
          <div className={colorDisplay}>
            <div className="item-prop-pill">
              <span className="item-prop">COLOR</span>
              <span className="item-prop-value">{listItem.color}</span>
            </div>
          </div>
          <div className={codeDisplay}>
            <div className="item-prop-pill">
              <span className="item-prop">CODE</span>
              <span className="item-prop-value">{listItem.code}</span>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default ItemProps;

ItemProps.propTypes = {
  listItem: PropTypes.object,
  isShare: PropTypes.bool
};

ItemProps.defaultProps = {
  listItem: null,
  isShare: false
};