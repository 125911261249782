import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Subscribe } from 'unstated';
import { useQuery, useMutation } from '@apollo/react-hooks';
import DrawerContainer from '../../containers/drawer';
import DrawerSpace from '../common/drawerSpace';
import Loading from '../common/loading';
import Button from '../common/button';
import DrawerMessageWarning from '../common/drawerMessageWarning';
import UserAvatar from '../avatar/userAvatar';
import ItemCardMin from '../item/itemCard.min';
import { qryGetSharedListItems, mutationDeleteListItems } from '../../graphql/graphql';

/* eslint-disable  */


export default function DrawerItemShareItemDelete(props) {

  const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;
  const COMPONENT_NAME = 'draweritem.share.item.delete';
  const [userArr, setUserArr] = useState([props.user]);
  const [itemId, setItemId] = useState(props.objectId);
  const [sourceListId, setSourceListId] = useState('');
  let listItemId = '';
  let item = null
  const [deleteListItem] = useMutation(mutationDeleteListItems);

  useEffect(() => {
    if (props && props.objectId && props.objectId.includes('|')) {
      const splitArr = props.objectId.split('|');
      if(splitArr && Array.isArray(splitArr) && splitArr.length > 0){
        if (displayConsole) {
          console.log(`${COMPONENT_NAME} > useEffect > splitArr': `, splitArr);
        }
        setSourceListId(splitArr[0]);
        setItemId(splitArr[1]);
      }
    } else {
      if (displayConsole) {
        console.log(`${COMPONENT_NAME} > missing objectId from props': `, props);
      }
    }

  }, [props])

  // GET DATA
  const { loading, error, data } = useQuery(qryGetSharedListItems, {
    variables: { sourceItemId: itemId, sourceListId: sourceListId, sharedUserId: userArr[0].id },
    suspend: false
  });

  if (error) {
    console.error(`Error calling query:`, error);
  }

  if (data) {
    if (displayConsole) {
      console.log(`${COMPONENT_NAME} > data from 'qryGetSharedList': `, data);
    }
    if (data.User && Array.isArray(data.User) && data.User.length > 0) {
      const usr = data.User[0];
      if (usr && Array.isArray(usr.listUsers) && usr.listUsers.length > 0) {
        const lstUsrs = usr.listUsers[0];
        if(lstUsrs && lstUsrs.list && Array.isArray(lstUsrs.list.listItems) && lstUsrs.list.listItems.length > 0){
          const lstItms = lstUsrs.list.listItems;
          if (displayConsole) {
            console.log(`${COMPONENT_NAME} > lstItms found in data from 'qryGetSharedList': `, lstItms);
          }
          if(lstItms && Array.isArray(lstItms) && lstItms.length > 0){
            const lstItm = lstItms[0].item ? lstItms[0].item : null;
            if(lstItm && lstItm.id){
              // SET item TO USE TO SHOW THE ITEM CARD
              item = lstItm;

              // SET listItemId TO USE IN THE MUTATION
              listItemId = lstItms[0].id;
            }
          }
        } else {
          if (displayConsole) {
            console.log(`${COMPONENT_NAME} > lstUsrs object shape from data from 'qryGetSharedList' is bad: `, usr);
          }
        }
      }
    }
  }
  return (
    <Subscribe to={[DrawerContainer]}>
      {appState => (
        <Fragment>
          {loading ? (
            <DrawerSpace>
              <div className="flex content-center">
                <Loading loadingText="Wait for it..." />
              </div>
            </DrawerSpace>
          ) : null}
          <DrawerSpace>
            <div className="py-4">
              <DrawerMessageWarning>
                <span>You can't take this back!</span>
              </DrawerMessageWarning>
            </div>
            <div className="drawer-header-description-text pb-4 pt-3">
              If you want to remove this item for this user's list, hit the <i>delete item share</i> button below
            </div>
            <div>
              <UserAvatar users={userArr} direction="vertical" subtext={userArr && Array.isArray(userArr) && userArr.length === 1 ? userArr[0].email : null} />
            </div>
            <div className="leading-normal text-grey-dark pt-6">This item will no longer be shared with {userArr[0] && userArr[0].firstName ? userArr[0].firstName : 'this user'}:</div>
            {item ? (
              <Fragment>
                <div className="py-3">
                  <ItemCardMin item={item} />
                </div>
                <div className="fixed pin-b pb-12">
                  <div hidden={listItemId === ''} className="mx-auto " style={{ minWidth: '310px' }}>
                    <Button
                      buttonType="pill-delete"
                      xtra="hover:shadow"
                      showSpinner={true}
                      onClick={async () => {
                        if(listItemId){
                          const listItemIds = [listItemId];
                          await deleteListItem({
                            variables: {listItemIds},
                          })
                            .then(() => {
                              appState.closeDrawer();
                              appState.showMessageCard('msg', `Took that shared item back!`);
                            })
                            .catch(e => {
                              if (dispalyConsole) {
                                console.error(`${COMPONENT_NAME} > calling deleteListItem > Error attempting to call 'mutationDeleteListItems': `, e);
                              }
                            });
                        }
                      }}
                    >
                      <span className="text-center">Delete item share</span>
                    </Button>
                  </div>
                </div>
              </Fragment>
            ) : null}
          </DrawerSpace>
        </Fragment>
      )
      }
    </Subscribe >
  );
}

/* eslint-enable  */
DrawerItemShareItemDelete.propTypes = {
  user: PropTypes.object
};

DrawerItemShareItemDelete.defaultProps = {
  user: null
};