import React, { useState } from 'react';
import PropTypes from 'prop-types';

export default function SVGShare(props) {
    const { primaryColor, secondaryColor, width, height } = props;
    const [isShown, setIsShown] = useState(false);
    return (
        <div style={{ height: height, width: width }} onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 27">
                <defs />
                <g fill="none" fillRule="evenodd" transform="translate(1.73 -3.25)">
                    <rect width="23.186" height="23.174" x=".147" y="5.824" stroke={isShown ? `${secondaryColor}` : `${primaryColor}`} strokeWidth="2" rx="4" />
                    <path fill="#FFF" d="M14.094 4.007h10.37v10.37h-10.37z" />
                    <path fill="#5FBA7D" d="M23.89 4.693l-1.832 9.166-7.333-7.333z" />
                    <path stroke={isShown ? `${secondaryColor}` : `${primaryColor}`} strokeLinecap="square" strokeWidth="3" d="M18.407 10.375l-6.793 6.984" />
                </g>
            </svg>
        </div>

    );
}

SVGShare.propTypes = {
    primaryColor: PropTypes.string,
    secondaryColor: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string
};

SVGShare.defaultProps = {
    primaryColor: '#5FBA7D',
    secondaryColor: '#000000',
    width: "24px",
    height: "24px"
};
