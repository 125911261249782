import React, { Fragment, useState, useEffect } from 'react';
import ItemSpace from './itemMin.css';
import ItemObtained from './itemObtained';
import ItemName from './itemName';
import ItemImage from './itemImage';
import HideShowLongText from '../common/hideShowLongText';

// eslint-disable-next-line
export default function ItemCardMinWide({ item, type, isShare, obtainedBy, showListName, list }) {

  const COMPONENT_NAME = 'ItemCardMinWide';
  const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;
  const [listName, setListName] = useState(null);
  const [listUrl, setListUrl] = useState(null);
  let cardLeftCss = 'item-card-left';
  // CHANGE THE WIDTH OF THE LEFT SIDE OF THE CARD IF ASKED TO
  if (item && item.obtained === false) {
    // THIS SHOULD ONLY HAPPEN WHEN THIS COMPONENT IS BEING USED ON THE 'items' PAGE
    cardLeftCss += ' min-w-item-card-min-wide-left-not-obtained';
  }

  useEffect(() => {
    async function doUseEffectStuff() {
      if (displayConsole) {
        console.log(`${COMPONENT_NAME} > Here is the list':`, list);
        console.log(`${COMPONENT_NAME} > Here is the item':`, item);
      }

      //SET THE listName & listUrl FROM THE PASSED IN list OBJECT
      if (list) {
        setListName(list && list.listDetails && list.listDetails.name ? list.listDetails.name : 'FOO');
        setListUrl(list && list.id ? list.id : null);
      }
    }
    doUseEffectStuff();
  }, [list]);
  return (
    <Fragment>
      <ItemSpace type={type}>
        <div className="flex">
          <div className={cardLeftCss}>
            <div className="pt-4 flex justify-center">
              <ItemObtained item={item} isObtained={item.obtained} itemName={item.name} itemId={item.id} isShare={isShare} obtainedBy={item.obtainedBy} screenSize="md" />
            </div>
          </div>
          <div className="flex-no-shrink w-2/3">
            <div className="p-4">
              <ItemName url={item.url} name={item.name} localRoute={false} />
              {showListName ? (
                <div className='flex'>
                  <div className='pt-2 pb-0 text-sm text-grey'>From the list </div>
                  <div className='pt-2 pl-1 pb-0 text-sm italic'>
                    <a href={`/l/${listUrl}`} className="item-name underline text-gyfto-green" alt={listName}>
                      {listName}
                    </a>
                  </div>
                </div>
              ) : null}
            </div>

            <div className="px-4 pb-2 item-description ">
              <HideShowLongText displayText={item && item.description ? item.description : ''} lengthToHide={30} />
            </div>

            {showListName ? (
              <div className="flex flex-wrap p-4 max-w-xs">
                <div className="item-prop-pill">
                  <span className="item-prop">AMOUNT</span>
                  <span className="item-prop-value">{item.amount}</span>
                </div>
              </div>
            ) : null}
          </div>
          <div className="flex align-middle items-center">
            {item.imageUrl ? (
              <ItemImage
                imageUrl={item.imageUrl}
                processedImage={item.processedImage}
                size="medium"
              />
            ) : (
              <div />
            )}
          </div>
        </div>
      </ItemSpace>
    </Fragment >
  );
}
