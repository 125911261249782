import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

export default function Checkbox({ name, checked, onChange, id }) {
  const COMPONENT_NAME = 'checkbox'
  const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;

  // THIS WILL FIRE ONCE WE GET THE checked STATE FROM PROPS
  useEffect(() => {
    async function doUseEffectStuff() {
      if (displayConsole) {
        console.log(`${COMPONENT_NAME} > name: ${name} | checked: ${checked} | id: ${id}`);
      }
    }
    doUseEffectStuff();
  }, [checked]);

  return (
    <div className="circular-container">
      {/* eslint-disable-next-line */}
            <label className="checkbox-label">
        <input type="checkbox" name={name} data-id={id} checked={checked} onChange={onChange} />
        <span className="checkbox-custom circular" />
      </label>
    </div>
  );
}

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.number,
};
