import React from 'react';

export default function NotificationCardControl(props) {
  // eslint-disable-next-line react/prop-types
  const { showCard, type, msg } = props;

  let classStyle = 'bg-gyfto-green';
  switch (type) {
    case 'warning':
      classStyle = 'bg-gyfto-mustard';
      break;
    case 'error':
      classStyle = 'bg-red';
      break;
    default:
      classStyle = 'bg-gyfto-green';
      break;
  }
  return (
    <div className="z-50">
      <div className={showCard ? ['snackbar', 'show', classStyle, 'flex'].join(' ') : 'snackbar'}>
        <div className="">{msg}</div>
      </div>
    </div>
  );
}
