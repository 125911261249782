import React from 'react';
import PropTypes from 'prop-types';
import { Subscribe } from 'unstated';
import DrawerContainer from '../../containers/drawer';
import SVGNew from '../common/icons/new-svg';

export default function ListActionBottom({ navType, list, auth }) {
    return (
        <Subscribe to={[DrawerContainer]}>
            {appState => (
                <div className="flex md:hidden z-50 absolute sticky pb-8" style={{ bottom: '0px' }}>
                    <div className="flex-none w-4"></div>
                    <div className="flex-1">
                        <div className="w-full rounded-full bg-gyfto-green flex shadow-md" style={{ maxHeight: '60px', overflow: 'hidden' }}>
                            <div className="w-12" />
                            <div className="flex items-center justify-center w-full" style={{minWidth: '60px'}} onClick={() => {
                                appState.selectList(list.id);
                                appState.transferListToDrawer(list);
                                appState.showHide('list', 'edit', list.id);
                            }}>
                                <div><span className="text-white text-l text-bold uppercase">Edit</span></div>
                            </div>
                            <div className="w-full flex justify-center items-center p-3">
                                <div className=" flex" onClick={() => {
                                    appState.selectList(list.id);
                                    appState.transferListToDrawer(list);
                                    appState.showHide('item', 'create', list.id);
                                }}>
                                    <div className="rounded-full bg-white">
                                        <div className="flex-col justify-center center p-8">
                                            <SVGNew primaryColor="#5FBA7D" secondaryColor="#979dac" width="28px" height="28px" />
                                            {/* <div className=""><span className="text-gyfto-green text-xs text-bold uppercase">add</span></div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center justify-center w-full" style={{minWidth: '60px'}} onClick={() => {
                                appState.selectList(list.id);
                                appState.transferListToDrawer(list);
                                appState.showHide('share', 'create', list.id);
                            }}>
                                <div><span className="text-white text-l text-bold uppercase">Share</span></div>
                            </div>
                            <div className="w-12" />
                        </div>
                        <div></div>
                    </div>
                    <div className="flex-none w-4"></div>
                </div>
            )}
        </Subscribe>
    );
}

ListActionBottom.propTypes = {
    navType: PropTypes.string,
    list: PropTypes.object,
};

ListActionBottom.defaultProps = {
    navType: 'H',
    list: null,
};
