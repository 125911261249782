import React, { useState } from 'react';
import PropTypes from 'prop-types';

export default function SVGSearch(props) {
    const { primaryColor, secondaryColor, width, height } = props;
    const [isShown, setIsShown] = useState(false);
    return (
        <div style={{ height: height, width: width }} onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)}>
            <svg viewBox="0 0 22 22" id="img__search-24" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fillRule="evenodd" stroke={isShown ? `${secondaryColor}` : `${primaryColor}`} strokeWidth="2">
                    <circle cx="10" cy="10" r="7"></circle>
                    <path strokeLinecap="round" d="M15 15l5 5"></path>
                    </g>
                </svg>
        </div>

    );
}

SVGSearch.propTypes = {
    primaryColor: PropTypes.string,
    secondaryColor: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string
};

SVGSearch.defaultProps = {
    primaryColor: '#5FBA7D',
    secondaryColor: '#000000',
    width: "24px",
    height: "24px"
};
