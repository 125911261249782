import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Subscribe } from 'unstated';
import _forEach from 'lodash.foreach';
import DrawerSpace from '../common/drawerSpace';
import DrawerContainer from '../../containers/drawer';
import DrawerItemsDisplayItems from '../drawer/draweritem.items.display.items';
import { fnGyftoEventPipeline } from '../../functions/index';
import ErrorUI from '../common/error';
import DrawerMessageError from '../common/drawerMessageError';


/* eslint-disable  */

export default function DrawerItemsAction(props) {
  const { action, itemIds } = props;
  const dispalyConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;
  const COMPONENT_NAME = 'draweritem.items.action';

  // const [deleteItemAndListItem] = useMutation(mutationDeleteItemAndListItem);
  // const [deleteListItem] = useMutation(mutationDeleteListItems);
  // const [assocLists, setAssocLists] = useState([]);
  const [errorMsg, setErrorMsg] = useState(null);
  // const [currentListItemId, setCurrentListItemId] = useState(null);
  // const [componentLoaded, setComponentLoaded] = useState(false);



  // ONLY RAN ON INITIAL MOUNT OF data
  useEffect(() => {
    async function doUseEffectStuff() {
      // if(itemIds && Array.isArray(itemIds) && itemIds.length > 0){
      // if (data) {
      //   console.log(`${COMPONENT_NAME} > data: `, data);
      // }
    }
    doUseEffectStuff();
  });




  let tmpAssocLists = [];
  // THIS WILL GET THE MOST RECENT COPY OF LISTS ASSOCIATED TO THIS ITEM (AS OPPOSED TO TAKING IN FROM THE item IN PROPS)
  // if (data) {
  //   if (dispalyConsole) {
  //     console.log(`draweritem.item.delete.assocLists > data > lists associated to item '${item.id}': `, data);
  //   }
  //   if (Array.isArray(data.Item) && data.Item[0] && data.Item[0].listItems && Array.isArray(data.Item[0].listItems)) {
  //     // GET ALL THE LISTS FROM QUERY AND BIND TO A LOCAL ARRAY
  //     const sourceListItems = data.Item[0].listItems;
  //     let ordinalVal = 1;

  //     _forEach(sourceListItems, listItem => {
  //       if (listItem && listItem.list && listItem.list.id && listItem.list.id !== listId) {
  //         // BASICALLY - WE'RE PUSHING LISTS INTO THIS ARRAY WHERE WE PRE-CHECK A LIST IF 
  //         // IT IS THE LIST WE'RE IN BUT WE DON'T DISPLAY IT TO THE SCREEN

  //         // BUT - DON'T SHOW LISTS THAT ARE SHARES OF THE PARENT LIST ASSOCIATED TO THIS ITEM
  //         // console.log(` ### SEE THIS listItem:`, listItem);
  //         if (listItem.list.sourceList && listItem.list.sourceList.id) {
  //           // LET PURPOSELY BLANK - IF THIS lisItem HAS A sourceList WITH AND id THEN IT'S A SHARED LIST
  //           // AND WE DON'T WANT TO ADD IT
  //           // console.log(`listItem sourceList id:`, listItem.list.sourceList.id);
  //         } else {
  //           tmpAssocLists.push({ "id": `${listItem.list.id}`, "listItemId": `${listItem.id}`, "checked": listItem.list.id === listId ? true : false, "list": listItem.list, ordinal: ordinalVal, "displayToScreen": listItem.list.id !== listId ? true : false, "listName": `${listItem.list.listDetails.name}` });
  //         }
  //         ordinalVal += 1;
  //       } else {
  //         if (true) {
  //           console.log(`draweritem.item.delete.assocLists > missing listId: `, listItem);
  //         }
  //       }
  //     });

  //     if (tmpAssocLists.length > 0) {
  //       if (dispalyConsole) {
  //         console.log(`draweritem.item.delete.assocLists > tmpAssocLists: `, tmpAssocLists);
  //       }
  //     }
  //   } else {
  //     if (dispalyConsole) {
  //       console.log(`draweritem.item.delete.assocLists > issue(s) with lists associated to item': `, data);
  //     }
  //   }
  // }



  // FIRES WHEN A CHECKBOX IS CLICKED - HANDLES STATE MGMT FOR THE LISTS CHECKED, ETC.
  const handleChange = e => {
    if (dispalyConsole && false) {
      console.log(`${e.target.name} is ${e.target.checked}`, e);
    }

    // A BIT OF A HACK BUT THIS UPDATES THE useState ARRAY WITH A NEW ARRAY WITH AN UPDATED
    // LIST OF CHECKED ITEMS
    let tmpArr = assocLists.filter(l => l.id !== e.target.name);
    let tmpI = assocLists.filter(l => l.id === e.target.name);
    if (tmpI && Array.isArray(tmpI) && tmpI.length > 0) {
      tmpI.map(i => {
        tmpArr.push({ "listItemId": `${i.listItemId}`, "id": `${i.id}`, "checked": e.target.checked, "list": i.list, ordinal: i.ordinal, "displayToScreen": i.displayToScreen, "listName": i.listName });
      })

    }
    setAssocLists(tmpArr);
  };

  // RETURNS COUNT OF NUMBER OF CHECKED LISTS
  const checkForCheck = (arrLists) => {
    let foundCheck = 0;

    if (arrLists && Array.isArray(arrLists)) {
      _forEach(arrLists, list => {
        if (list && list.checked && list.checked === true) {
          foundCheck += 1;
        }
      })
    }

    return foundCheck;
  };

  // HANDLE THE DELETE OF THE ITEM -- BASED UPON WHAT IS PASSED IN
  // WE WILL EITHER DELETE A listItem OR A listItem AND AN item
  const doTheDelete = async (appState) => {
    let allUpdated = false;
    if (appState && item.id && currentListItemId) {
      if (dispalyConsole) {
        console.log(`draweritem.item.delete > doTheDelete > assocLists: `, assocLists);
        console.log(`draweritem.item.delete > doTheDelete > currentListItemId: `, currentListItemId);
        console.log(`draweritem.item.delete > doTheDelete > item.id: `, item.id);
      }
      const listItemIds = []

      // FIRST FIGURE OUT IF THE ITEM EXISTS ON OTHER LISTS AND IF WE CHECKED THOSE LISTS BY
      // CHECKING THE assocLists ARRAY

      if (assocLists && assocLists.length > 0) {
        listItemIds.push(`${currentListItemId}`);
        // THIS MEANS THAT THERE ARE OTHER LISTS WHERE THIS ITEM EXISTS
        console.log(`deleting itemId: ${item.id} exists in multiple diff lists: `, assocLists);
        // START BY ITERATING THROUGH THE assocLIsts ARR AND FIND EVERYTHING THAT THE
        // USER CHECKED
        assocLists.filter(l => l.checked === true).map((chkd) => {
          console.log(`In the map func of assocLists - pushing this into listItemIds: `, chkd);
          listItemIds.push(`${chkd.listItemId}`);
        });

        // NOW, IF item.sharedLists SIZE === THE SIZE OF liteItemIds (THE LIST ITEMS THAT WERE CHECKED + THE ONE FOR THE SOURCE LIST) 
        // WE CAN DO THE FULL DELETE OF THE ListItems AND THE Item
        if (item.sharedLists.length === listItemIds.length) {
          await deleteItemAndListItem({
            variables: { listItemIds, itemId: item.id },
          })
            .then(() => {
              allUpdated = true;
            })
            .catch(e => {
              if (dispalyConsole) {
                console.error(`draweritem.item.delete > doTheDelete > Error attempting to call 'deleteItemAndListItem': `, e);
              }
            });

        } else {
          // HOWEVER, IF THOSE NUMBERS AREN'T THE SAME THAT MEANS THAT THE USER WANTS TO LEAVE AN Item / ListItem 
          // SO JUST DELETE THE PICKED ListItem(s)
          await deleteListItem({
            variables: { listItemIds },
          })
            .then(() => {
              allUpdated = true;
            })
            .catch(e => {
              if (dispalyConsole) {
                console.error(`draweritem.item.delete > doTheDelete > Error attempting to call 'deleteListItem': `, e);
              }
            });

        }
      } else {
        // THIS ITEM ONLY EXISTS IN THE CURRENT LIST - BECAUSE OF THE WAY THAT THE CHECKBOX UI WORKS
        // TO FIND THE SAME ITEM ACROSS MULTIPLE DIFFERENT LISTS, WE WILL POPULATE THE listItemIds ARR HERE MANUALLY
        if (item && item.sharedLists && Array.isArray(item.sharedLists)) {
          if (dispalyConsole) {
            console.log(`draweritem.item.delete.assocLists > doTheDelete > lists associated to item '${item.id}': `, item);
          }

          _forEach(item.sharedLists, listItem => {
            if (listItem && listItem.id) {
              // THIS IS THE CURRENT ListItem THAT WE PULLED OUT OF THE item PASSED IN (FROM sharedLists)
              listItemIds.push(`${listItem.id}`);
            }
          });
        }
        const graphqlVars = { listItemIds, itemId: item.id };
        if (dispalyConsole) {
          console.log(`draweritem.item.delete > doTheDelete > This item only exists in the current list. About to call 'deleteItemAndListItem' mutation with these vars: `, graphqlVars);
        }
        await deleteItemAndListItem({
          variables: graphqlVars,
        })
          .then(() => {
            allUpdated = true;
          })
          .catch(e => {
            if (dispalyConsole) {
              console.error(`draweritem.item.delete > doTheDelete > Error attempting to call 'deleteItemAndListItem': `, e);
            }
          });
      }

    } else {
      if (dispalyConsole) {
        console.log(`draweritem.item.delete > doTheDelete > Error attempting the delete - missing one of the core properties: itemId: ${item.id} | currentListItemId: ${currentListItemId} | assocLists: `, assocLists);
      }
    }

    // LASTLY, SHOW MESSAGE TO THE USER
    if (allUpdated) {
      fnGyftoEventPipeline("Item", "Deleted", item.id);
      appState.closeDrawer();
      appState.showMessageCard('msg', `Item removed from the list`);
    } else {
      if (dispalyConsole) {
        console.error(`draweritem.item.delete > doTheDelete > Item wasn't removed from all lists: `);
      }
      appState.closeDrawer();
      appState.showMessageCard('error', "Issue attempting to delete the item from all the lists");
    }
  }

  // IF EVERYTHING GOES WRONG - SHOW A FRIENDLY ERROR UI
  if (errorMsg) {
    return (
      <DrawerContainer>
        <ErrorUI errorMsg={errorMsg ? errorMsg : ""} />
      </DrawerContainer>
    );
  }

  return (
    <Subscribe to={[DrawerContainer]}>
      {appState => (
        <Fragment>
          <div>
            <DrawerSpace>
              {action === 'delete' ? (
                <div className="py-4">
                  <DrawerMessageError>
                    <span>You can't take this back!</span>
                  </DrawerMessageError>
                </div>
              ) : null}
              <div>
                <DrawerItemsDisplayItems itemIds={itemIds} />
              </div>
            </DrawerSpace>
          </div>
        </Fragment>
      )}
    </Subscribe>
  );
}
/* eslint-enable  */

DrawerItemsAction.propTypes = {
  action: PropTypes.string,
  itemIds: PropTypes.array
};


DrawerItemsAction.defaultProps = {
  action: null,
  itemIds: null,
};
