import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';

import _forEach from 'lodash.foreach';
import _size from 'lodash.size';
import { format } from 'date-fns';
import history from '../history/index';
import PageSpace from '../common/pageSpace';
import UserAvatar from '../avatar/userAvatar';
import NotTheList from './notthelist';
import DraggableItemList from '../item/itemListDraggable';
import ItemCardMinWide from '../item/itemCard.min.wide';
import ItemWatchedCardMinWide from '../item/itemWatchedCard.min.wide';
import Button from '../common/button';
import Slider from '../common/slider';
import Pill from '../common/pill';
import Checkbox from '../common/checkbox';
import Filter from '../common/icons/filter-svg';
import { SkeletonItemCard } from '../common/skeletons';
import ShareButton from '../common/buttons/shareButton';
import AddButton from '../common/buttons/addButton';
import EditButton from '../common/buttons/editButton';
import DeleteButton from '../common/buttons/deleteButton';
import LeaveButton from '../common/buttons/leaveButton';
import SVGDelete from '../common/icons/delete-svg';
import SVGMove from '../common/icons/move-svg';
import SVGCopy from '../common/icons/copy-svg';
import SVGShare from '../common/icons/share-svg';
import Search from '../common/search';
import NavBar from '../navbar/index';
import Header from '../../components/header';
import HideShowLongText from '../common/hideShowLongText';
import ErrorUI from '../common/error';
import ListDetails from './list.listDetails';
import ListBookmark from './listBookmark';
import ListActionBottom from './list.action.bottom';
import { fnSaveBreadCrumb, fnGetListHeaderImg, fnGetItemWatchFromItemList, fnGetItemWatchFromItem } from '../../functions/index'

/* eslint-disable  */
export default function ListRender(props) {
  const COMPONENT_NAME = 'list.render';
  const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;
  const { listId, ogListId, appState, auth, fromShareLink, loading, error, data } = props;
  const constTrimTextLength = 12;
  const [initialized, setInitialized] = useState(false);
  const [listBuyType, setListBuyType] = useState('SharedBuy');
  const [listFilterAmnt, setListFilterAmnt] = useState(null);
  const [storeArr, setStoreArr] = useState(null);
  const [filterVisible, setFilterVisible] = useState('');
  const [listFilterObject, setListFilterObject] = useState(null);
  const [listFilterObjectStoreValue, setListFilterObjectStoreValue] = useState(null);
  const [listFilterObjectWatchesValue, setListFilterObjectWatchesValue] = useState(false);
  const [listFilterWatches, setListFilterWatches] = useState(null);
  const [listWatches, setListWatchesValue] = useState(null);
  const [listHeaderImg, setListHeaderImg] = useState('https://gyfto.dev/shareimage-512.png');
  const [selectedListItems, setSelectedListItems] = useState([]);
  const [selectedListItemsAmnt, setSelectedListItemsAmnt] = useState(0);
  const [selectAllItems, setSelectAllItems] = useState(false);
  const [listFilterListName, setListFilterListName] = useState(null);

  // THIS WILL FIRE ONCE WE GET THE data OBJECT FROM PROPS
  useEffect(() => {
    async function doUseEffectStuff() {

      if (data && data.List && Array.isArray(data.List) && data.List[0]) {

        let useEffectList = data.List[0];
        if (useEffectList) {
          // GET THE HEADER IMAGE FOR THIS LIST
          let fooImg = await fnGetListHeaderImg(useEffectList);
          setListHeaderImg(fooImg);

          if (displayConsole) {
            console.log(`${COMPONENT_NAME} > doUseEffectStuff > Header Img for listId: ${listId} is ${fooImg}`, useEffectList);
          }

          // BREADCRUMBS
          fnSaveBreadCrumb(list);

          // DRAWER STATE
          appState.selectList(listId);

          // BUILD THE FILTER FOR items THIS CURRENT USER IS WATCHING
          const fooTmpFilterWatches = await fnGetItemWatchFromItemList(useEffectList.listItems, currentUserId, true);
          if (fooTmpFilterWatches) {
            setListFilterWatches(fooTmpFilterWatches);
          }
          if (displayConsole) {
            console.log(`${COMPONENT_NAME} > doUseEffectStuff > Found ${fooTmpFilterWatches.length} watched items for this list`, fooTmpFilterWatches);
          }

          // FIGURE OUT IF THIS list HAS ANY WATCHED ITEMS
          const fooTmpWatches = await fnGetItemWatchFromItemList(useEffectList.listItems, null, true);
          if (fooTmpWatches) {
            setListWatchesValue(fooTmpWatches);
          }
          if (displayConsole) {
            console.log(`${COMPONENT_NAME} > doUseEffectStuff > Found ${fooTmpWatches.length} watched items for this list`, fooTmpWatches);
          }
        }
      }
    }
    doUseEffectStuff();
  }, [data]);

  // RUN ON COMPONENT LOAD REGARDLESS OF data STATE
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      appState.selectList(listId);
    }

  }, []);

  if (displayConsole) {
    console.log(`${COMPONENT_NAME} > Props: listId: ${listId}`, props);
  }

  // DETERMINE IF THE USER IS AUTHENTICATED
  let isAuthenticated = false;
  let authenticatedStyle = {
    display: 'inherit',
  };

  // HIDE ELEMENTS OF THE PAGE IF THIS USER IS EITHER NOT AUTHENTICATED
  // AND/OR IS COMING FROM A SHARED LINK (/sl)
  if (fromShareLink) {
    authenticatedStyle = { display: 'none' };

    // THE ONLY REASON WE DO THIS IS TO SUPPORT SCENARIOS WHERE AN /og/:listId IS PASSED IN
    if (ogListId && auth) {
      isAuthenticated = auth.isAuthenticated();
    }
  } else {
    if (auth) {
      isAuthenticated = auth.isAuthenticated();
    }

  }

  // // HIDE ELEMENTS OF THE PAGE IF THIS USER IS EITHER NOT AUTHENTICATED
  // // AND/OR IS COMING FROM A SHARED LINK (/sl)
  // if (auth && !fromShareLink) {
  //   isAuthenticated = auth.isAuthenticated();

  // } else {
  //   authenticatedStyle = { display: 'none' };

  // }

  // DOUBLE CHECK FOR FULLY NON-AUTHED USERS
  if (!isAuthenticated) {
    authenticatedStyle = { display: 'none' };
  }

  if (displayConsole) {
    console.log(`${COMPONENT_NAME} > user isAuthenticated: ${isAuthenticated}`, auth);
  }


  if (loading) {
    return (
      <PageSpace>
        <div className="flex w-full justify-center content-center">
          <div>
            <SkeletonItemCard />
            <br />
            <br />
            <SkeletonItemCard />
            <br />
            <br />
            <SkeletonItemCard />
            <br />
            <br />
            <SkeletonItemCard />
            <br />
            <br />
            <SkeletonItemCard />
            <br />
            <br />
            <SkeletonItemCard />
          </div>
        </div>
      </PageSpace>
    );
  }

  if (error) {
    if (displayConsole) {
      console.log(`${COMPONENT_NAME} > Error on query for listId: ${listId}`, error.message);
    }
    return (
      <Fragment>
        <div className="flex ">
          <div className="flex-1"></div>
          <div className="flex-none md:max-w-gyfto-max">
            <div className="py-8">
              <ErrorUI displayMessage="Click 'Sign In' above to see this list." errorMsg={error && error.message ? error.message : null} />
            </div>
          </div>
          <div className="flex-1"></div>

        </div>
      </Fragment>
    );
  }

  // VARIABLES
  let list = null;
  if (data && data.List && Array.isArray(data.List) && data.List[0]) {
    list = data.List[0];
  } else {
    if (displayConsole) {
      console.log(`list > Shape of query data is weird: `, data);
    }
  }

  let sharedText;
  let listOwnerText;
  let currentUserId = localStorage.getItem(`user_id`);
  const listOwners = [];
  let listOwnerName = null;
  const listEditors = [];
  const sharedListUsersRender = [];
  const sharedListIds = [];
  const openItems = [];
  const obtainedItems = [];
  const itemStores = [];
  let valueMax = 0;
  let valueOpenItemsTotal = 0;
  let isShare = false;
  let sourceListOwner = [];
  let sourceListOwnerName = null;
  let currentUserCanSee = false;
  let currentUserIsOwnerOrEditor = false;
  let currentUserListUserType = null;
  let currentUserCanTakeActionOnItemCard = false;//true;
  let ogListMode = false;


  // ONLY SHOW IF LIST IS FOUND
  if (list) {
    // ANY DEBUG LOGGING HERE
    if (displayConsole) {
      console.log(`Object for listId: ${listId}`);
      console.dir(list);
    }

    // ***** 1) FIRST DETERMINE IF THIS IS AN 'OWNED' OR 'SHARED' LIST
    // IF THE listOwner NODE HAS A 'SharedWith' IN IT - IT'S A SHARE;
    // OTHERWISE, IT'S AN 'OWNED' LIST
    if (list.listUsers && _size(list.listUsers) > 0) {
      _forEach(list.listUsers, listUser => {
        // IF THE listUserType == OWNER || EDITOR
        if (listUser.listUserType === 'Owner' || listUser.listUserType === 'Editor') {
          if (listUser.user.id === currentUserId) {
            currentUserListUserType = listUser.listUserType;

            // THIS IS WHAT LIGHTS UP EDITING ON ITEM CARDS & LISTS
            currentUserCanTakeActionOnItemCard = true;
            currentUserIsOwnerOrEditor = true;
            if (displayConsole) {
              console.log(`${COMPONENT_NAME} > listUserType of '${currentUserListUserType}' for userId '${currentUserId}'`)
            }
          }
          sharedText = 'SHARED WITH';
          listOwnerText = 'CREATED BY';
          currentUserCanSee = true;


          if (listUser.listUserType === 'Owner') {
            listOwners.push({
              avatarUrl: listUser.user.avatarUrl
              , firstName: listUser.user.firstName
              , id: listUser.user.id
              , lastName: listUser.user.lastName
              , listUserType: listUser.listUserType
              , email: listUser.user.email
            });
          } else {
            listEditors.push({
              avatarUrl: listUser.user.avatarUrl
              , firstName: listUser.user.firstName
              , id: listUser.user.id
              , lastName: listUser.user.lastName
              , listUserType: listUser.listUserType
              , email: listUser.user.email
            });
          }
        } else if (listUser.listUserType === 'SharedWith') {
          isShare = true;
          sharedText = 'ALSO SHARED WITH';
          listOwnerText = 'SHARED WITH';
          currentUserCanSee = true;

          // 'TECHNICALLY' THIS LIST IS 'OWNED' BY THE 'SharedWith' USER
          listOwners.push({
            avatarUrl: listUser.user.avatarUrl
            , firstName: listUser.user.firstName
            , id: listUser.user.id
            , lastName: listUser.user.lastName
            , listUserType: listUser.listUserType
            , email: listUser.user.email
          });

          // ADD THE shareWith LIST OWNER FOR THE /og BANNER
          listOwnerName = listUser.user.firstName ? listUser.user.firstName : listUser.user.email;
        }
      });
    }

    // ***** END 1)

    // ***** 2) NEXT, FIGURE OUT ALL THE PEOPLE THAT THIS LIST IS ALSO SHARED WITH
    // THIS SECTION CAN BE USED IN TWO WAYS:
    // 1) THIS WILL BE WHEN THE CURRENT LIST IS A SHARED LIST (HAS A SOURCE LIST)
    if (list.sourceList && list.sourceList.sharedLists && _size(list.sourceList.sharedLists) > 0) {
      _forEach(list.sourceList.sharedLists, sharedList => {
        if (sharedList && sharedList.listUsers && _size(sharedList.listUsers) > 0) {
          _forEach(sharedList.listUsers, sharedListUser => {
            // ONLY BUILD THIS FOR OTHER SHARED USERS
            if (sharedListUser.user.id !== currentUserId) {
              sharedListUsersRender.push(sharedListUser.user);
            }
          });
        }
      });
    }

    // O2 2) WHEN THE CURRENT LIST IS THE ORIGINAL LIST (!SOURCE LIST) BUT HAS sharedLists
    if (!list.sourceList && list.sharedLists && _size(list.sharedLists) > 0) {
      _forEach(list.sharedLists, sharedList => {
        _forEach(sharedList.listUsers, sharedListUser => {
          // ONLY BUILD THIS FOR OTHER SHARED USERS
          sharedListUsersRender.push(sharedListUser.user);

          // THIS IS TO MAKE SURE THAT THE PROPER SHARED WITH ENDS UP ON THE CARD IN THE LIST
          sharedListIds.push(sharedList.id);
        });
      })
    }

    if (displayConsole) {
      console.log(`${COMPONENT_NAME} > sharedListUsersRender:`, sharedListUsersRender);
      console.log(`${COMPONENT_NAME} > sharedListIds:`, sharedListIds);
    }

    // ***** END 2)

    // ***** 3) BUILD OUT THE ITEMS

    // ITERATE THROUGH ALL THE ListItems AND DO A COUPLE OF THINGS:
    // - BUILD MAX/MIN OF ITEM VALUES
    // - BUILD OUT THE ARRAYS FOR ITEMS AND OBTAINED ITEMS
    // - TAKE NOTE OF ALL THE DIFF STORES
    // - GET THE IMAGE OF THE FIRST ITEM TO SET AS THE LIST HEADER IMG
    const tmpArrStore = [];
    if (list.listItems && Array.isArray(list.listItems) && _size(list.listItems) > 0) {
      list.listItems.filter(item => !item.deletedState).map((listItem, index) => {
        if (listItem && listItem.item) {

          if (listItem.item.amount) {
            // BUILD THE MAX AMOUNT OF THE LIST
            let convertedItemAmount = 0.00;
            try {
              convertedItemAmount = parseFloat(listItem.item.amount.substring(1).replace(',', ''));
            } catch (error) {
              console.error(`list.render > error converting !`, error);
            }
            if (convertedItemAmount > valueMax) {
              valueMax = convertedItemAmount;
            }
          }

          // BUILD THE OPEN AND OBTAINED LIST ITEM ARRAYS
          // FOR 'SHARED' LISTS, LOOK AT THE obtainedBy FIELD && obtained === FALSE TO CONSIDER THAT OBTAINED
          if (isShare) {
            // IF isShare === TRUE AND THE ITEM HASN'T BEEN OBTAINED - SHOW IT IN-LINE AND ALLOW
            // SHAREDWITH USERS TO WATCH IT

            if (listItem.item.obtained === false) {
              openItems.push(listItem);
              valueOpenItemsTotal += parseFloat(listItem.item.amount.substring(1).replace(',', ''));
            } else {
              obtainedItems.push(listItem.item);
            }


          } else {
            // FOR OWNER/EDITOR LISTS, ONLY LOOK AT obtained
            if (listItem.item.obtained) {
              obtainedItems.push(listItem.item);
            } else {
              openItems.push(listItem);
              valueOpenItemsTotal += parseFloat(listItem.item.amount.substring(1).replace(',', ''));
            }
          }

          // STORES 
          if (!listItem.item.obtained && listItem.item.store) {
            if (tmpArrStore.indexOf(listItem.item.store) === -1) {
              tmpArrStore.push(listItem.item.store);
            }
          }
        }
      });

      // PERSIST THE UNIQUE stores AND ORDER
      if (tmpArrStore.length > 0) {
        tmpArrStore.sort((a, b) => (a > b) ? 1 : ((b > a) ? -1 : 0)).map((itm, idx) => {
          if (itm) {
            itemStores.push({ storeName: itm, checked: false, id: idx + 1 })
          }
        })
      }
    }
    // ***** END 3)

    // ***** 4) IF THIS IS A SharedWith - FIGURE OUT WHO THE ULTIMATE OWNER IS
    // console.log(`${COMPONENT_NAME} > Object for listId: ${listId}`, data);
    if (isShare && list && list.sourceList && list.sourceList.listUsers && Array.isArray(list.sourceList.listUsers) && list.sourceList.listUsers.length > 0) {
      list.sourceList.listUsers.filter(lu => lu.listUserType === 'Owner').map((ownerUser, index) => {
        if (ownerUser && ownerUser.user) {
          sourceListOwner.push(ownerUser.user);
          sourceListOwnerName = ownerUser.user.firstName + ' ' + ownerUser.user.lastName;

          // SINCE WE'RE HERE - LETS CHECK AND SEE IF THE CURRENT USER IS VISITING THE LIST IN AN 'og' MODE
          if (ogListId) {
            currentUserCanTakeActionOnItemCard = false;
            ogListMode = true;
          }
        }
      });

    } else {
      // THIS MAY BE A LIST RETURNED FOR AN UNAUTH USER SO THE SHAPE IS DIFFERENT THAN
      // THE ONE ABOVE
      if (list && list.listUsers && Array.isArray(list.listUsers) && list.listUsers.length > 0) {
        list.listUsers.filter(lu => lu.listUserType === 'Owner').map((ownerUser, index) => {
          if (ownerUser && ownerUser.user) {
            sourceListOwner.push(ownerUser.user);
            sourceListOwnerName = ownerUser.user.firstName + ' ' + ownerUser.user.lastName;
          }
        });

      }
    }
    // ***** END 4)

  } else {
    if (displayConsole) {
      console.log(`${COMPONENT_NAME} > Object for listId: ${listId}`, data);
    }
    return <NotTheList />;
  }

  /**
   * FIRED WHEN THE USER MOVES THE SLIDER
   * @param {int} filterAmount 
   */
  const sliderChange = (filterAmount) => {
    if (filterAmount) {
      if (openItems && openItems.length > 1) {
        handleFilterSelection('price', filterAmount);
      }
    }
  }

  /**
   * FILTERS THE ITEMS IN A LIST BASED UPON filterType AND filterValue
   * @param {string} filterType 
   * @param {any} filterValue 
   */
  const handleFilterSelection = (filterType, filterValue) => {
    // console.log(`handleFilterSelection > ${filterType} : ${filterValue}`);
    if (filterType) {
      // CLEAR OUT ANY OF THE SELECTED ITEMS SO THAT WE DON'T ACCIDENTALLY 
      // APPLY ON THE WHOLE SET - NOT JUST THE FILTERED ONE
      setSelectedListItems([]);
      setSelectAllItems(false);

      // FILTERS BY STORES
      setListFilterObject({ filterType, filterValue });
      if (filterType === 'store') {
        setListFilterObjectStoreValue(filterValue);
      }

      // FILTERS BY CURRENT USERS WATCHES
      if (filterType === 'watches') {
        setListFilterObjectWatchesValue(filterValue);
      }

      if (filterType === 'search') {
        setListFilterListName(filterValue);
      }
    }
  }

  /**
   * THIS WILL CLEAR ANY FILTERS APPLIED TO THE LIST
   */
  const clearFilterSelection = () => {
    setListFilterObject(null);
    setListFilterObjectStoreValue(null);
    setListFilterObjectWatchesValue(false);
    setListFilterListName(null);
  }

  /**
   * FIRES WHEN A FILTER IS CLICKED
   * @param {string} filterName 
   */
  const handleFilterClick = (filterName) => {
    if (filterName) {
      setFilterVisible(filterName);
    }
  }

  /** 
   * HANDLES THE <Toggle /> ACTION FOR FILTERING 'My Watches'
   * */
  const handleMyWatchesToggle = (toggleState) => {
    handleFilterSelection('watches', toggleState);
  };

  /** 
   * HANDLES THE <Toggle /> ACTION FOR FILTERING 'My Watches'
   * */
  const handleFilterListName = (searchValue) => {
    handleFilterSelection('search', searchValue);
  };

  /**
   * FIRES WHEN THE itemCard IN <DraggableItemList /> IS CHECKED
   */
  const onListItemClick = listItemId => {
    if (displayConsole) {
      console.log(`${COMPONENT_NAME} > onListItemClick > listItemId provided '${listItemId} - look in list.listItems'`, list.listItems);
    }

    if (listItemId) {
      // FIRST, FIND THE ACTUAL listItem SO THAT WE CAN START AGGREGATING SELECTED VALUES
      let selectedListItem = null;
      if (list.listItems && Array.isArray(list.listItems) && _size(list.listItems) > 0) {
        list.listItems.filter(item => !item.deletedState).map((listItem, index) => {
          if (listItem && listItem.item && listItem.item.id === listItemId) {
            selectedListItem = listItem;
            if (displayConsole) {
              console.log(`${COMPONENT_NAME} > onListItemClick > found selectedListItem '${listItemId} in listItem.itm'`, listItem.item);
            }
          }
        })
      }


      // NEXT, FIGURE OUT IF THE listItemId IS ALREADY IN THE selectedListItems ARRAY - IF IT IS, IT'S
      // BEEN THERE BEFORE AND WE SHOULD REMOVE IT; OTHERWISE, ADD IT
      if (_.find(selectedListItems, searchItemId => { return searchItemId === listItemId; }) === listItemId) {
        setSelectedListItems(selectedListItems.filter(item => item !== listItemId));

        // DECREMENT FROM selectedListItemsAmnt
        setSelectedListItemsAmnt(selectedListItemsAmnt - parseFloat(selectedListItem.item.amount.substring(1).replace(',', '')));
      } else {
        setSelectedListItems(prevValues => [...prevValues, listItemId]);

        // INCREMENT selectedListItemsAmnt
        setSelectedListItemsAmnt(selectedListItemsAmnt + parseFloat(selectedListItem.item.amount.substring(1).replace(',', '')));
      }
    } else {
      if (displayConsole) {
        console.error(`${COMPONENT_NAME} > onListItemClick > listItemId expected but provided '${listItemId}'`);
      }
    }
  }

  /**
   * FIRES WHEN THE 'Select All Items' IS CLICKED
   * @param {html element} e 
   */
  const handleToggleSelectAll = e => {
    if (displayConsole) {
      console.log(`${COMPONENT_NAME} > handleToggleSelectAll - e`, e.target);
    }
    // FIRST, ITERATE THROUGH ALL OF THE selectedItems AND THEN DO THE TOGGLE
    const handleToggleArr = [];
    let selectedItemsAmnt = 0;

    if (e && e.target && e.target.checked && e.target.checked === true) {
      // GET ALL THE listItem.item.id AND PUSH THIS ARR DOWN TO THE ITEM CARDS
      if (list.listItems && Array.isArray(list.listItems) && _size(list.listItems) > 0) {
        list.listItems.filter(item => !item.deletedState).map((listItem, index) => {
          if (listItem && listItem.item && listItem.item.obtained == false) {
            handleToggleArr.push(listItem.item.id);
            selectedItemsAmnt += parseFloat(listItem.item.amount.substring(1).replace(',', ''));
          }
        })
      }

      // WE ALREADY KNOW THE TOTAL VALUE OF ALL THE OPEN ITEMS SO USE THAT FOR THE SELECTED VALUE AS WELL
      selectedItemsAmnt = parseFloat(valueOpenItemsTotal.toFixed(0));
    }

    // CHECK ALL THE BOXES
    setSelectAllItems(e.target.checked);

    // SEND THE handleToggleArr DOWN TO THE ITEM CARDS
    setSelectedListItems(handleToggleArr);

    // HANDLE ALL THE SUMMING
    setSelectedListItemsAmnt(selectedItemsAmnt);

  }

  return (
    <Fragment>
      {/* SHOW THE NAVBAR FOR AUTHENTICATED USERS. THE HEADER FOR AUTHENTICATED USERS IS SET IN THE App.js */}
      {isAuthenticated ? (
        <div className="md:hidden sticky bg-white" style={{ top: '0px', zIndex: 900 }}>
          {isShare || !currentUserIsOwnerOrEditor ? (
            <NavBar navType="H" auth={auth} />
          )
            : (
              <NavBar navType="L" list={list} auth={auth} />
            )
          }
        </div>

      ) : (
        <div className="md:hidden block sticky bg-white" style={{ top: '0px', zIndex: 900 }}>
          <Header auth={auth} showModal="true" />
        </div>
      )}
      {/* THIS IS THE BANNER THAT SHOWS WHEN A LIST OWNER IS LOOKING 
      AT A LIST THROUGH THE EYES OF A SHARED USER (i.e. oglist) */}
      {ogListId ? (
        <div className="w-full bg-purple flex justify-center sticky" style={{ top: '60px', zIndex: 900, minHeight: '60px' }}>
          <div className="md:w-gyfto-main-content-xs flex justify-between">
            {/* THIS IS FOR LARGE SCREENS */}
            <div className="xs:hidden md:flex text-white font-bold pl-2 pr-6 my-auto">You are viewing this list as {listOwnerName}</div>

            {/* THIS IS FOR SMALL SCREENS */}
            <div className="xs:flex md:hidden w-full">
              {listOwnerName.length > 9 ? (<div className="xs:max-w-gyfto-landing-value-prop-text-box xs:break-words text-white font-bold pl-2 my-auto">Viewing as shared user</div>) : (
                <div className="text-white font-bold pl-2 pr-6 my-auto">You are viewing this list as {listOwnerName}</div>
              )}
            </div>


            <div className="my-auto flex pr-2">
              <div className="pr-2" style={{ minWidth: '120px' }}>
                <Button
                  onClick={() => {
                    navigator.clipboard.writeText(`${process.env.REACT_APP_PUBLIC_URL}l/${listId}`);
                    appState.showMessageCard('msg', `Link copied!`);
                  }}
                  buttonType="pill-share-outline-none"
                  weight="thin"
                  xtra="xs:py-2 xs:px-4"
                  responsive={false}
                >
                  <div className="">copy link</div>
                </Button>
              </div>
              <div>
                <Button
                  // onClick={e => history.push(`/l/${ogListId}`)}
                  onClick={e => history.goBack()}
                  buttonType="pill-share-outline-white"
                  weight="thin"
                  xtra="hover:shadow xs:py-2 xs:px-4"
                  responsive={false}
                >
                  <div className="">back</div>
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <PageSpace>
        <div className="w-full" >
          <div className="flex md:justify-center">
            {/* THIS IS THE WHOLE CONTENT AREA CENTERED - MAX OF 800PX */}
            <div className="w-full md:max-w-gyfto-max items-center md:pl-4 md:pr-4">
              <Helmet>
                <title>{`Gyfto - ${list.listDetails.name}`}</title>
                <meta property="og:title" content={`Gyfto - ${list.listDetails.name}`} />
                <meta name="description" content={`Gyfto - ${list.listDetails.name}`} />
                <meta property="og:image" content={`${listHeaderImg}`} />
              </Helmet>
              <div className="flex" >
                {/* THIS IS THE LEFT HAND SIDE OF THE CENTERED CONTENT AREA - ALWAYS VISIBLE */}
                <div className="w-gyfto-main-content -mt-2">
                  <div className='w-full flex justify-between'>
                    {/* THIS SHOWS THE AVATAR OF THE LIST OWNER IF WE'RE IN A SharedWith MODE */}
                    {isShare ? (
                      <div className="flex items-start xs:pt-6">
                        <div>
                          <UserAvatar users={sourceListOwner} forAssetId={list.id} />
                        </div>
                        <div>
                          <div className="">
                            <h1 className="list-details-name">{list.listDetails.name}</h1>
                            <p className="text-xs text-gyfto-font-grey pt-1">{sourceListOwnerName ? 'By ' + sourceListOwnerName : ''}</p>
                            <div className="xs:hidden md:inline-block mt-2 pr-2 pb-2">
                              <HideShowLongText displayText={list.listDetails.description} lengthToHide={80} />
                            </div>
                            <div className="md:hidden mt-2 pr-2">
                              <HideShowLongText displayText={list.listDetails.description} lengthToHide={30} />
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="xs:pt-6 xs:px-3 w-full">
                        <ListDetails listDetails={list.listDetails} currentUserIsOwnerOrEditor={currentUserIsOwnerOrEditor} userIsAuthenticated={isAuthenticated} sourceListOwner={sourceListOwner} />
                      </div>
                    )}
                    {/* LIST BOOKMARK */}
                    {isAuthenticated && (list.listDetails && list.listDetails.visibilityType && list.listDetails.visibilityType === 'visible') ? (<div className='xs:pt-4 md:pr-6'><ListBookmark listId={list.id} listPinState={list.pinned} currentUserId={currentUserId} /></div>) : null}

                  </div>


                  {/* LIST DETAILS */}

                  {/* LIST TYPE (OwnerBuy, etc.) FOR MD AND LARGER SCREENS*/}
                  {currentUserCanSee && isAuthenticated && currentUserIsOwnerOrEditor && !isShare ? (
                    <div className="xs:hidden md:flex flex-row-reverse w-full pr-4 pt-4">
                      <div className="item-prop-display">
                        <div className="item-prop-pill">
                          <span className="item-prop text-xs">TYPE</span>
                          <span className="item-prop-value text-xs">{list.listDetails.buyType === 'OwnerBuy' ? "I'm Buying" : "Others are buying"}</span>
                        </div>
                      </div>
                      <div className="item-prop-display">
                        <div className="item-prop-pill">
                          <span className="item-prop text-xs">VISIBILITY</span>
                          <span className="item-prop-value text-xs">{list.listDetails.visibilityType && list.listDetails.visibilityType === 'visible' ? "Visible" : "Private"}</span>
                        </div>
                      </div>
                    </div>
                  ) : <Fragment />}
                  {/* THIS IS THE LIST OWNERS / EDITORS SECTION FOR SMALL SCREENS */}
                  <div className="pl-2 pt-4 pb-4 md:hidden">
                    <span className="section-header">{listOwnerText}</span>
                    <div className="flex items-start">
                      <UserAvatar users={listOwners} direction="vertical" subtext={format(list.createdAt, 'MM/DD/YYYY')} forAssetId={list.id} />
                      <UserAvatar users={listEditors} direction="vertical" subtext={format(list.createdAt, 'MM/DD/YYYY')} enableClick={true} enableClickType="listUserShare_Editor" sourceId={list.id} forAssetId={list.id} />
                    </div>
                    {_size(sharedListUsersRender) > 0 && isAuthenticated && currentUserCanSee ? (
                      <Fragment>
                        <div className="mt-2">
                          <span className="section-header">{sharedText}</span>
                          <div className="flex flex-wrap items-center mt-1">
                            {currentUserIsOwnerOrEditor ? (
                              <UserAvatar users={sharedListUsersRender} trimTextLength={constTrimTextLength} enableClick={true} enableClickType="listUserShare" sourceId={list.id} forAssetId={list.id} traceControl={true} />
                            ) : (
                              <UserAvatar users={sharedListUsersRender} trimTextLength={constTrimTextLength} direction="vertical" forAssetId={list.id} />
                            )}
                          </div>
                        </div>
                      </Fragment>
                    ) : (
                      <div />
                    )}
                    {/* LIST TYPE (OwnerBuy, etc.) FOR SM SCREENS*/}
                    {isAuthenticated && !isShare ? (
                      <div className="flex flex-row-reverse w-full pt-6">
                        <div className="item-prop-display">
                          <div className="item-prop-pill">
                            <span className="item-prop text-xs">TYPE</span>
                            <span className="item-prop-value text-xs">{list.listDetails.buyType === 'OwnerBuy' ? "I'm Buying" : "Others are buying"}</span>
                          </div>
                        </div>
                        <div className="item-prop-display">
                          <div className="item-prop-pill">
                            <span className="item-prop text-xs">VISIBILITY</span>
                            <span className="item-prop-value text-xs">{list.listDetails.visibilityType && list.listDetails.visibilityType === 'visible' ? "Visible" : "Private"}</span>
                          </div>
                        </div>
                      </div>
                    ) : <Fragment />}
                  </div>

                  {/* THIS IS THE MAIN CONTENT AREA - LIST ITEM CARDS */}
                  <div className="py-2 md:gyfto-item-card">
                    {/* FIRST - ARE THERE ANY ITEMS? */}

                    {/* NO ITEMS - FOR THE OWNER */}
                    {_size(openItems) < 1 && !isShare ? (
                      // SHOW A CARD TEMPLATE THAT HAS AN 'add item' CTA
                      <Fragment>
                        {/* THIS IS FOR LARGER SCREENS */}
                        <div className="xs:hidden md:inline-block">
                          {/* THIS LAYER IS FOR THE BUTTON */}
                          <div className="z-20 min-h-gyfto-card-item md:w-gyfto-item-card-skeleton absolute ">
                            <div className="flex justify-center pt-16">
                              <Button
                                buttonType="pill-add"
                                weight="thin"
                                xtra="hover:shadow"
                                onClick={() => {
                                  appState.selectList(list.id, list.listId);
                                  appState.showHide('item', 'create', list.id);
                                }}
                              >
                                <span className="text-center"> add your {_size(obtainedItems) > 0 ? 'next' : 'first'} item </span>
                              </Button>
                            </div>
                          </div>
                          {/* THIS LAYER IS FOR THE TRANSPARENT CARD */}
                          <div className="z-10 pt-20 min-h-gyfto-card-item md:w-gyfto-item-card-skeleton bg-gyfto-main opacity-50 absolute" />
                          {/* THIS LAYER IS FOR THE SKELETON */}
                          <SkeletonItemCard />
                        </div>
                        {/* THIS IS FOR SMALLER SCREENS */}
                        <div className="md:hidden">
                          {/* THIS LAYER IS FOR THE BUTTON */}
                          <div className="w-full flex justify-center pt-12 min-h-screen-1/4">
                            <Button
                              buttonType="pill-add"
                              weight="thin"
                              xtra="hover:shadow"
                              onClick={() => {
                                appState.selectList(list.id, list.listId);
                                appState.showHide('item', 'create', list.id);
                              }}
                            >
                              <span className="text-center"> add your {_size(obtainedItems) > 0 ? 'next' : 'first'} item </span>
                            </Button>
                          </div>
                        </div>
                      </Fragment>
                    ) : null}

                    {/* THIS SHOWS THE ITEMS THAT ARE OPEN - NOT OBTAINED */}
                    {/* IF THIS IS A SHARE THEN DON'T SHOW THE SortableList */}

                    {_size(openItems) > 1 && valueMax > 0 ? (
                      <Fragment>
                        {/* THIS IS THE STICKY PANEL THAT HOUSES FILTERING, ACTION BUTTONS AND SELECTED ITEMS - BROKEN  UP INTO 3 PARTS */}
                        <div className="flex justify-between items-center bg-white w-full min-h-gyfto-header-nav-mobile max-w-gyfto-max-card-item rounded-default z-50 absolute sticky filter-bar border-grey-lighter border-b-2" >
                          {/* FIRST PART IS THE FILTERING */}
                          <div className='flex-none w-full'>
                            <div className="flex items-start min-h-gyfto-card-item-description w-full">
                              <div className="pt-3 px-2"><Filter secondaryColor="#7e7e7e" primaryColor="#7e7e7e" /></div>
                              {/* HIDING THE PRICE FILTER ON SMALL SCREENS FOR NOW */}
                              <div onClick={() => handleFilterClick('price')} className="text-sm px-2 py-3 hover:bg-grey-lighter cursor-pointer">Price</div>
                              <div onClick={() => handleFilterClick('store')} className="text-sm px-2 py-3 hover:bg-grey-lighter cursor-pointer">Stores</div>
                              {/* 12/21/22 - NEED TO FIGURE OUT WHAT TO DO WITH MY WATCHES */}
                              {/* HIDE THE 'My Watches' FILTER IF 1) THERE AREN'T ANY WATCHES OR 2) THIS IS A SHARE B/C WE SHOW THE WATCHED ITEMS AT THE BOTTOM */}
                              {/* <div hidden={!isAuthenticated || isShare || listFilterWatches === null || _size(listFilterWatches) === 0} onClick={() => handleFilterClick('watches')} className="text-sm px-2 py-3 hover:bg-grey-lighter cursor-pointer">My Watches ({_size(listFilterWatches)})</div> */}
                              <div className='flex w-full justify-end pr-2'>
                                <div className='xs:w-48 md:w-64'>
                                  <Search parentCompChangeEventReceiver={handleFilterListName} inputBoxPlaceholderText="Search this list..." showClearSearch={true} />
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* THIS BECOMES VISIBLE WHEN AN itemCard IS SELECTED */}
                          {false && !filterVisible && selectedListItems.length > 0 && currentUserCanSee && isAuthenticated && currentUserIsOwnerOrEditor && !isShare ? (
                            <div className='flex-none border-r-2 border-l-2 border-grey-light xs:min-w-selected-items-buttons-panel-xs md:min-w-selected-items-buttons-panel-md' >
                              {/* THIS IS THE SET OF ACTIONS THAT APPEAR AFTER A SELECT */}
                              <div className='flex items-center justify-between w-full' style={{ minWidth: '120px' }}>
                                <div className='w-2' />
                                {/* COPY */}
                                <div className='flex-col content-center h-7'>
                                  <div className="icon-svg-col cursor-pointer" onClick={() => {
                                    // appState.transferItemToDrawer(listItem);
                                    // appState.selectItem(listItem.id);
                                    // appState.showHide('item', 'delete', listItem.id);
                                    // appState.transferItemToDrawer(null);
                                    appState.selectItem(selectedListItems);
                                    // appState.showHide('item', 'delete', selectedListItems);
                                  }}>
                                    <SVGCopy primaryColor="#5FBA7D" secondaryColor="#979dac" width="22px" height="22px" />
                                  </div>
                                  <div className='text-xxs text-grey-dark uppercase  mt-2'>copy</div>
                                </div>
                                {/* SHARE */}
                                <div className='flex-col content-center h-7'>
                                  <div className="icon-svg-col cursor-pointer" onClick={() => {
                                    // appState.transferItemToDrawer(listItem);
                                    // appState.selectItem(listItem.id);
                                    // appState.showHide('item', 'delete', listItem.id);
                                    // appState.transferItemToDrawer(null);
                                    appState.selectItem(selectedListItems);
                                    // appState.showHide('item', 'delete', selectedListItems);
                                  }}>
                                    <SVGShare primaryColor="#5FBA7D" secondaryColor="#979dac" width="22px" height="22px" />
                                  </div>
                                  <div className='text-xxs text-grey-dark uppercase  mt-2'>share</div>
                                </div>
                                {/* MOVE */}
                                <div className='flex-col content-center h-7'>
                                  <div className="icon-svg-col cursor-pointer pt-1" onClick={() => {
                                    // appState.transferItemToDrawer(listItem);
                                    // appState.selectItem(listItem.id);
                                    // appState.showHide('item', 'delete', listItem.id);
                                    // appState.transferItemToDrawer(null);
                                    appState.selectItem(selectedListItems);
                                    // appState.showHide('item', 'delete', selectedListItems);
                                  }}>
                                    <SVGMove primaryColor="#5FBA7D" secondaryColor="#979dac" width="22px" height="20px" />
                                  </div>
                                  <div className='text-xxs text-grey-dark uppercase  mt-2'>move</div>
                                </div>
                                <div className='flex-col content-center h-7'>
                                  <div className="icon-svg-col cursor-pointer" onClick={() => {
                                    appState.transferItemsToDrawer(selectedListItems);
                                    appState.selectItems(selectedListItems);
                                    appState.showHideMulti('items', 'delete', selectedListItems);
                                  }}>
                                    <SVGDelete primaryColor="#5FBA7D" secondaryColor="#979dac" width="18px" height="20px" />
                                  </div>
                                  <div className='text-xxs text-grey-dark uppercase  mt-3'>delete</div>
                                </div>
                                <div className='w-2' />
                              </div>

                            </div>
                          ) : null}
                        </div>
                        {/* THIRD PART IS THE SELECTED  ITEMS */}
                        {filterVisible && filterVisible !== '' ? (
                          <div className="py-3 bg-grey-lightest" style={{ minHeight: '60px', maxWidth: '560px' }}>
                            {filterVisible === 'price' ? (
                              <div className="w-full px-4">
                                <Slider maxValue={valueMax} minValue={0} step={10} handleChangeEvent={sliderChange} enableSpecific={true} />
                              </div>

                            ) : null}
                            {filterVisible === 'watches' ? (
                              <div className="w-full pb-6">
                                <div className="drawer-header-description-text pt-3 pb-1">Filter by the items you have setup watches for?</div>
                                <input checked={listFilterObjectWatchesValue} className="hidden" type="checkbox" id="filterMyWatchesToggleSwitch" onChange={(e) => { handleMyWatchesToggle(e.target.checked) }} />
                                <div className="switchToggleContent">
                                  <label htmlFor="filterMyWatchesToggleSwitch" className="switchToggleLabel">
                                    <div className="switchToggleToggle" />
                                    <div className="switchToggleItems">
                                      <div className="switchToggleLeft pt-3" style={{ marginLeft: '-10px' }}>No</div>
                                      <div className="switchToggleRight pt-3" style={{ marginLeft: '-80px' }} >Yes</div>
                                    </div>
                                  </label>
                                </div>
                              </div>
                            ) : null}
                            <div />
                            {filterVisible === 'store' ? (
                              <div className="w-full flex flex-wrap pb-3" hidden={filterVisible !== 'store'}>
                                {/* THIS WILL FILTER OUT ANY DUPES AND THEN ORDER BY storeName */}
                                {itemStores.map((store, index, arr) => (
                                  <div onClick={() => handleFilterSelection('store', store.storeName)} className={listFilterObjectStoreValue === store.storeName ? `filter-item-pill cursor-pointer m-1 hover:bg-green-lighter bg-green-lighter` : `filter-item-pill cursor-pointer m-1 hover:bg-green-lighter `} key={store.id}>{store.storeName}</div>
                                ))}
                              </div>
                            ) : null}
                            {/* THIS IS THE BOTTOM OF THE PANEL THAT POPS OUT WHEN A FILTER IS APPLIED */}
                            <div className="flex-1">
                              <div className="flex justify-end my-auto pt-2 pr-2">
                                <div hidden={!filterVisible || filterVisible === ''}>
                                  <Button
                                    buttonType="pill-void-standard"
                                    xtra="text-xs py-1 px-2"
                                    weight="thin"
                                    responsive={false}
                                    onClick={async () => {
                                      // RESET THE VALUE OF THE FILTER(S)
                                      clearFilterSelection();
                                      setFilterVisible('');
                                    }}
                                  >
                                    <span className="text-center">clear filter</span>
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}

                      </Fragment>
                    ) : (null)}

                    {/* SHOW SELECT ALL */}
                    <div className="py-4 flex items-center max-w-gyfto-select-all-content-max-w" style={{ paddingLeft: '.75rem', paddingRight: '.75rem' }}>
                      {!filterVisible && _size(openItems) > 1 && valueMax > 0 ? (
                        <Fragment>
                          <div className='flex w-full '>
                            <div><Checkbox name="chkBoxSelectAll" checked={selectAllItems} onChange={handleToggleSelectAll} /></div>
                            <div className='w-full'>
                              {selectedListItems.length > 0 ? (
                                <div className='flex justify-between w-full'>
                                  <div className="pl-4 drawer-header-description-text">Selected {selectedListItems.length} items</div>
                                  <div className=''>SHARE</div>
                                </div>
                              ) : (
                                <div className="pl-4 drawer-header-description-text">Select all items</div>
                              )}
                            </div>
                          </div>
                        </Fragment>
                      ) : null}
                    </div>

                    {/* RENDER THE LIST */}
                    <DraggableItemList itemFilterObject={listFilterObject} filterVisible={filterVisible} itemList={openItems} isShare={isShare} currentUserCanSee={currentUserCanSee} listId={listId} isAuthenticated={isAuthenticated} sharedListIds={sharedListIds} listBuyType={list.listDetails.buyType} listUserType={currentUserListUserType} currentUserCanTakeActionOnItemCard={currentUserCanTakeActionOnItemCard} currentUserIsOwnerOrEditor={currentUserIsOwnerOrEditor} listEditorsLength={listEditors.length} ogListMode={ogListMode} onListItemClick={onListItemClick} selectedListItems={selectedListItems} />
                    <br />

                    {/* SHOW THE LIST DETAILS */}
                    {_size(openItems) > 1 && valueMax > 0 ? (
                      // THIS IS THE LIST DETAILS PART
                      <div className="bg-white w-full max-w-gyfto-max-card-item rounded-default pl-3 mb-3">
                        <div className="flex " style={{ minHeight: '40px' }}>
                          {/* FULL WORDS FOR WIDE SCREENS */}
                          <div className="xs:hidden md:flex w-full my-auto justify-center">
                            <div className="flex pr-6">
                              {/* <div className="text-sm px-1 py-3">Total Items</div>
                              <div className="text-sm px-2 py-3 text-gyfto-green">{openItems.length}</div> */}
                              <Pill label={'Available Items'} value={openItems.length} />
                            </div>
                            <div className="flex pr-6">
                              {/* <div className="text-sm px-1 py-3">Obtained Items</div>
                              <div className="text-sm px-2 py-3 text-gyfto-green">{obtainedItems.length}</div> */}
                              <Pill label={'Obtained Items'} value={obtainedItems.length} />
                            </div>
                            <div className="flex">
                              {/* <div className="text-sm px-1 py-3">Total List Amount</div>
                              <div className="text-sm px-2 py-3 text-gyfto-green">${valueOpenItemsTotal.toFixed(0)}</div> */}
                              <Pill label={'List Amount'} value={'$' + valueOpenItemsTotal.toFixed(0)} />
                            </div>
                          </div>
                          {/* SHORT WORDS FOR SMALL SCREENS */}
                          <div className="xs:flex md:hidden w-full my-auto justify-center">
                            <div className="flex pr-6">
                              <div className="text-sm px-1 py-3">Total</div>
                              <div className="text-sm px-2 py-3 text-gyfto-green">{openItems.length}</div>
                            </div>
                            {/* ONLY SHOW OBTAINED ITEMS FOR authenticated USERS */}
                            {currentUserCanSee && currentUserIsOwnerOrEditor && isAuthenticated && !isShare ? (
                              <div className="flex pr-6">
                                <div className="text-sm px-1 py-3">Obtained</div>
                                <div className="text-sm px-2 py-3 text-gyfto-green">{obtainedItems.length}</div>
                              </div>
                            ) : null}
                            <div className="flex">
                              <div className="text-sm px-1 py-3">Amount</div>
                              <div className="text-sm px-2 py-3 text-gyfto-green">${valueOpenItemsTotal.toFixed(0)}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* THIS IS THE 'OBATINED' SECTION - ONLY SHOW IF THE USER IS AUTHENTICATED, THE USER IS AN OWNER/EDITOR,  IT'S NOT A SHARE AND THERE ARE OBTAINED ITEMS */}
                  {(_size(obtainedItems) || _size(listWatches)) > 0 && currentUserCanSee && isAuthenticated ? (
                    <div>
                      {/* FOR SMALL SCREENS */}
                      <div className="md:hidden">

                        <div className="pt-4" hidden={isShare}>
                          <h1 className="list-obtained-header-sm mt-8 p-6 text-center">
                            <span role="img" aria-labelledby="fireworks" aria-label="fireworks">
                              🎉
                            </span>{' '}
                            <span>
                              yea! you got {_size(obtainedItems) > 1 ? 'these' : 'this'}!{' '}
                            </span>
                            <span role="img" aria-labelledby="fireworks" aria-label="fireworks">
                              🎉
                            </span>
                          </h1>
                          {obtainedItems
                            .sort((a, b) =>
                              a.updatedAt > b.updatedAt ? 1 : b.updatedAt > a.updatedAt ? -1 : 0
                            )
                            .map((obtainedItem, index) => {
                              return (
                                <div key={obtainedItem.id}>
                                  <div className="pb-6">
                                    <ItemCardMinWide item={obtainedItem} type="min-med" />
                                  </div>
                                </div>)
                            })}
                        </div>
                        {/* SHOW THE LIST WATCHES IF THEY ARE THERE */}
                        {isShare && listWatches && Array.isArray(listWatches) && listWatches.length > 0 ? (
                          <div className="pt-6">
                            {listWatches
                              .sort((a, b) =>
                                a.item.updatedAt > b.item.updatedAt ? 1 : b.item.updatedAt > a.item.updatedAt ? -1 : 0
                              )
                              .map((watchedItem, index) => {
                                return (
                                  <div key={watchedItem.item.id}>
                                    <div className="pb-6">
                                      <ItemWatchedCardMinWide item={watchedItem.item} isShare={isShare} type="min-med" />
                                    </div>
                                  </div>)
                              })}
                          </div>
                        ) : null}
                      </div>
                      {/* FOR LARGE SCREENS */}
                      <div className="hidden md:inline-block pt-6 pl-2">
                        <div className="pt-4">
                          {/* DO THIS FOR OBTAINED ITEMS - LIST OWNER/EDITOR */}
                          <div hidden={isShare}>
                            <h1 className="list-items-obtained-header pt-2 pb-4">
                                <span role="img" aria-labelledby="fireworks" aria-label="fireworks">
                                  🎉
                                </span>{' '}
                                <span>
                                  yea! you got {_size(obtainedItems) > 1 ? 'these' : 'this'}!{' '}
                                </span>
                                <span role="img" aria-labelledby="fireworks" aria-label="fireworks">
                                  🎉
                                </span>{' '}
                            </h1>
                            {obtainedItems
                              .sort((a, b) =>
                                a.updatedAt > b.updatedAt ? 1 : b.updatedAt > a.updatedAt ? -1 : 0
                              )
                              .map((obtainedItem, index) => {
                                return (
                                  <div key={obtainedItem.id}>
                                    <div className="pb-6">
                                      <ItemCardMinWide item={obtainedItem} isShare={isShare} obtainedBy={obtainedItem.obtainedBy} type="min-wide" />
                                    </div>
                                  </div>)
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {/* THIS IS THE STICKY LIST ACTION SECTION AT THE BOTTOM FOR SMALL SCREENS */}
                  {currentUserCanSee && currentUserIsOwnerOrEditor && isAuthenticated && !isShare ? (
                    <ListActionBottom list={list} auth={auth} />
                  ) : null}
                </div>

                {/* THIS IS THE RIGHT HAND SIDE OF THE CENTERED CONTENT AREA - VISIBLE MD: ABOVE */}
                <div className="hidden md:inline-block -mt-8 h-auto" style={authenticatedStyle}>
                  <div className="w-gyfto-side-pillar hidden md:inline-block">
                    <div className="w-full bg-white rounded-t-none rounded-sm b-2 border-b sticky" style={{ top: '0px' }}>
                      {/* SHOWING THE 'OWNERS' SECTION */}
                      <div className="pl-2 pr-2 pt-4">
                        <div className="section-header">{listOwnerText}</div>
                        <div className="mt-2">
                          <UserAvatar
                            users={listOwners}
                            direction="vertical"
                            subtext={format(list.createdAt, 'MM/DD/YYYY')}
                            forAssetId={list.id}
                          />
                        </div>
                        {/* SHOWING THE 'EDITORS' SECTION */}
                        <div className="mt-1">
                          <UserAvatar
                            users={listEditors}
                            direction="vertical"
                            subtext={format(list.createdAt, 'MM/DD/YYYY')}
                            enableClick={true}
                            enableClickType="listUserShare_Editor"
                            sourceId={list.id}
                            forAssetId={list.id}
                          />
                        </div>
                      </div>
                      {/* SHOWING THE 'LIST ACTIONS' SECTION */}
                      {currentUserCanSee && currentUserIsOwnerOrEditor && isAuthenticated && !isShare ? (
                        <Fragment>
                          <div className="list-side-pillar-break" />
                          <div className="pl-2 pr-2 pb-4">
                            <div className="section-header">LIST ACTIONS</div>
                            <div className="mt-2 flex-col">
                              <div>
                                <AddButton length="short" list={list} showIcon={true} />
                              </div>
                              <Fragment>
                                <br />
                                <div>
                                  <ShareButton length="short" list={list} />
                                </div>
                              </Fragment>
                              <br />
                              {currentUserListUserType && currentUserListUserType.toUpperCase() === 'OWNER' ? (
                                <Fragment>
                                  <div>
                                    <EditButton length="short" list={list} />
                                  </div>
                                  <br />
                                  <div>
                                    <DeleteButton length="short" list={list} />
                                  </div>
                                </Fragment>
                              ) : null}
                            </div>
                          </div>
                        </Fragment>
                      ) : (
                        <Fragment />
                      )}
                      {/* THIS IS FOR SHARED LISTS ONLY */}
                      {isShare ? (
                        <Fragment>
                          <div className="list-side-pillar-break" />
                          <div className="pl-2 pr-2 pb-4">
                            <div className="section-header">LIST ACTIONS</div>
                            <div className="mt-2 flex-col">
                              <LeaveButton length="medium" list={list} />
                            </div>
                          </div>
                        </Fragment>
                      ) : null}
                      {/* SHOWING THE 'SHARED USERS' SECTION */}
                      <div className="list-side-pillar-break" />
                      <div className="pl-2 pr-2 pb-4">
                        {/* ONLY SHOW 'SHARED WITH' IF THERE ARE ADDITIONAL SHARES */}
                        {_size(sharedListUsersRender) > 0 && isAuthenticated && currentUserCanSee ? (
                          <div>
                            <div className="section-header">{sharedText}</div>
                            <div className="mt-2">
                              {currentUserIsOwnerOrEditor ? (
                                <UserAvatar users={sharedListUsersRender} direction="vertical" enableClick={true} enableClickType="listUserShare" sourceId={list.id} forAssetId={list.id} />
                              ) : (
                                <UserAvatar users={sharedListUsersRender} direction="vertical" forAssetId={list.id} trimTextLength={14} />
                              )}
                            </div>
                          </div>
                        ) : (
                          <div />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageSpace >
    </Fragment >
  );
}
/* eslint-enable  */
