import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

export default function More(props) {
  const { moreCount } = props;
  return (
    <Fragment>
      <div className="flex flex-col items-center pt-1 pl-1">
        <span className="text-xl">{moreCount}</span>
        <span className="text-xs">more</span>
      </div>
    </Fragment>
  );
}

More.propTypes = {
  moreCount: PropTypes.number.isRequired,
};
