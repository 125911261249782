import React from 'react';
import Button from '../common/button';

export default function Pill({ label, value }) {

    return (
        <div className='flex items-center'>
            <div hidden={!label || label === ""} className="pr-2 text-xs">{label}</div>
            <div>
                <Button
                    buttonType="pill-void-standard-light-no-border-no-hover"
                    xtra=" text-xs "
                    weight="thin"
                    responsive={false}
                >
                    <span className="text-center px-1">{value}</span>
                </Button>
            </div>
        </div>
    )

}