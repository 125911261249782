import React, { Fragment, useState } from 'react';
import { Subscribe } from 'unstated';
import { useMutation } from '@apollo/react-hooks';
import history from '../history/index';
import Button from '../common/button';
import Loading from '../common/loading';
import ListCard from '../list/list.card';
import DrawerContainer from '../../containers/drawer';
import DrawerSpace from '../common/drawerSpace';
import DrawerMessageError from '../common/drawerMessageError';
import { mutationDeleteList } from '../../graphql/graphql';
/* eslint-disable  */

export default function DrawerItemListDelete() {
  const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;
  const [showLoading, setShowLoading] = useState(false);
  const [deleteList] = useMutation(mutationDeleteList);

  return (
    <Subscribe to={[DrawerContainer]}>
      {appState => (
        <Fragment>
          {showLoading ? (
            <DrawerSpace>
              <div className="flex content-center">
                <Loading loadingText="Deleting your list..." />
              </div>
            </DrawerSpace>
          ) : (
              <Fragment>
                <DrawerSpace>
                  {appState && appState.state && appState.state.selectedListObj ? (
                    <Fragment>
                      <div className="py-4">
                        <DrawerMessageError>
                          <span>You can't take this back!</span>
                        </DrawerMessageError>
                      </div>
                      <div className="pt-4">
                        <ListCard list={appState.state.selectedListObj} showActions={false} />
                      </div>
                      <div className=" pt-8 w-full flex justify-center content-center">
                        <Button
                          showSpinner={true}
                          buttonType="pill-drawer-delete"
                          xtra="hover:shadow w-gyfto-drawer-button"
                          onClick={async e => {
                            setShowLoading(true);
                            await deleteList({
                              variables: { listId: appState.state.selectedListObj.id, deletedState: true },
                            }).then(({ data }) => {
                              setShowLoading(false);
                              appState.closeDrawer();
                              history.push(`/h`);
                            });
                          }}
                        >
                          <span className="text-center">delete this list</span>
                        </Button>
                      </div>
                    </Fragment>
                  ) : (
                      <div />
                    )}
                </DrawerSpace>
              </Fragment>
            )}
        </Fragment>
      )}
    </Subscribe>
  );
}
/* eslint-enable  */
