import React, { useState } from 'react';
import SVGDelete from '../common/icons/delete-svg';


export default function ItemDelete() {
  const [mouseOn, setMouseOn] = useState(false);
  let baseClass = 'h-10 w-10 rounded-full mx-auto ';
  let subTextClass = 'text-xxs text-grey-dark uppercase mt-2 ';

  return (
    <div className="cursor-pointer">
      <div className={mouseOn ? baseClass+= ' bg-gyfto-card-left-panel-bg ' : baseClass } onMouseEnter={() => setMouseOn(true)} onMouseLeave={() => setMouseOn(false)}>
        <div className="pt-2 pl-3">
        <SVGDelete primaryColor="#b8c2cc" secondaryColor="#ef5753" width="18px" height="22px" altText="Delete this item" />
        </div>
      </div>
      <div className={mouseOn ? subTextClass += ' visible' : subTextClass += ' invisible'}>delete</div>
    </div>
  );
};
