import React, { Fragment, useState, useEffect } from 'react';
import { Subscribe } from 'unstated';
import { useQuery, useMutation } from '@apollo/react-hooks';
import history from '../history/index';
import _forEach from 'lodash.foreach';
import ItemCardMin from '../item/itemCard.min';
import Button from '../common/button';
import Checkbox from '../common/checkbox';
import DrawerSpace from '../common/drawerSpace';
import DrawerContainer from '../../containers/drawer';
import { qryGetListsByUserTypeShallow_Full, mutationMoveItem } from '../../graphql/graphql';
import ListCardMin from '../list/list.card.min';
import { fnGyftoEventPipeline } from '../../functions/index';
import ErrorUI from '../common/error';
import DrawerMessageWarning from '../common/drawerMessageWarning';
import UserAvatar from '../avatar/userAvatar';
import Loading from '../common/loading';
import Search from '../common/search';

export default function MoveItem(props) {
  const { itemId, item, listId } = props;
  const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;
  const COMPONENT_NAME = 'draweritem.item.move';
  // CALLING OUT TO GRAPHQL
  const currentUserId = localStorage.getItem(`user_id`);
  const { loading: loading1, error: error1, data: data1 } = useQuery(qryGetListsByUserTypeShallow_Full, {
    variables: { userId: currentUserId, listUserType: ["Owner", "Editor"] },
    suspend: false,
  });

  if (displayConsole) {
    console.log(`${COMPONENT_NAME} > loaded component: itemId - ${itemId} || listId - ${listId} || item: `, item);
  }

  const [moveItemToList] = useMutation(mutationMoveItem);
  const [errorMsg, setErrorMsg] = useState(null);
  const [returnItems, setReturnItems] = useState([]);
  const [listsFromData, setListsFromData] = useState([]);
  const [listsForUI, setListsForUI] = useState([]);
  const [itemSharedWithUsers, setItemSharedWithUsers] = useState(null);
  const [listItemsIdsToDelete, setListItemsIdsToDelete] = useState([]);
  const [itemWatchIdsToDelete, setItemWatchIdsToDelete] = useState([]);
  const [listItemIdToMove, setListItemIdToMove] = useState(null);
  const [listsWhereThisItemAlreadExists, setListsWhereThisItemAlreadExists] = useState([]);


  /** 
   * AFTER THE QUERY RUNS, ITERATE ACROSS THE DATA (ONCE)
   */
  useEffect(() => {
    async function doUseEffectStuff() {
      // POPULATE ARRAY FOR USER'S LISTS
      const tmpReturnItems = [];
      const tmpListsWhereThisItemAlreadExists = [];
      if (data1) {
        if (displayConsole) {
          console.log(`${COMPONENT_NAME} > data: `, data1);
        }

        // THIS IS GNARLY BUT BASICALLY:
        // 1) GET ALL THE ACTIVE LISTS FOR A USER
        // 2) ITREATE THROUGH THOSE LISTS AND THOSE LISITEMS
        // 3) IF THE ITEM TO MOVE IS FOUND IN THAT LIST - SKIP IT
        // 4) OTHERWISE - SHOW THE LIST AS A LOCATION TO MOVE THE ITEM TO
        if (data1.User && Array.isArray(data1.User)) {
          let dataUser = data1.User[0];
          if (dataUser && dataUser.listUsers && Array.isArray(dataUser.listUsers)) {
            _forEach(dataUser.listUsers, listUser => {
              if (listUser && listUser.list && listUser.list.id && listUser.list.id !== listId) {
                let currentList = listUser.list;
                let pushList = true;
                if (currentList && currentList.listItems && Array.isArray(currentList.listItems)) {
                  _forEach(currentList.listItems, listItem => {
                    if (listItem && listItem.item && listItem.item.id === itemId) {
                      // WE FOUND THIS ITEM ON ANOTHER LIST THAT THE USER OWNS
                      if (displayConsole) {
                        console.log(`${COMPONENT_NAME} > found item ${itemId} on list ${currentList.listDetails.name}: `);
                      }

                      pushList = false;
                    }
                  })
                }

                if (pushList) {
                  // SHOW THIS LIST IN THE UI AS A COPY DESTINATION
                  tmpReturnItems.push(currentList);
                } else {
                  tmpListsWhereThisItemAlreadExists.push({ "list": currentList });
                }
              }
            })
          }
        }
      }

      // POPULATE ARRAY FOR ITEM'S SHARES
      const tmpItemSharedWithUsers = [];
      const tmpListItemsIdsToDelete = []
      const tmpItemWatchIdsToDelete = [];
      let tmpListItemIdToMove = null;
      let sysItemId = null;
      if (item) {
        if (displayConsole) {
          console.log(`${COMPONENT_NAME} > item: `, item);
        }

        // GET THE SYSTEM ID FOR THE ITEM
        sysItemId = item.itemId ? item.itemId : null;
        if (displayConsole) {
          console.log(`${COMPONENT_NAME} > sysItemId: `, sysItemId);
        }

        // GET THE WATCHES FOR THIS ITEM
        if (item.itemWatches && Array.isArray(item.itemWatches)) {
          _forEach(item.itemWatches, itemWatch => {
            if (itemWatch && itemWatch.id) {
              tmpItemWatchIdsToDelete.push(itemWatch.id);
            }
          });
        }

        if (displayConsole) {
          console.log(`${COMPONENT_NAME} > tmpItemWatchIdsToDelete: `, tmpItemWatchIdsToDelete);
        }

        // GET THE SHARES FOR THIS ITEM
        if (item.sharedLists && Array.isArray(item.sharedLists)) {
          _forEach(item.sharedLists, sharedList => {
            if (sharedList && sharedList.list && sharedList.list.listUsers && Array.isArray(sharedList.list.listUsers)) {
              _forEach(sharedList.list.listUsers, listUser => {
                if (listUser && listUser.listUserType && listUser.listUserType === 'SharedWith' && listUser.user && listUser.user.id !== currentUserId) {
                  tmpItemSharedWithUsers.push(listUser.user);
                  tmpListItemsIdsToDelete.push(sharedList.id);
                } else {
                  // WE'VE FOUND THE listItemId THAT WE NEED TO USE TO MOVE TO A NEW LIST
                  tmpListItemIdToMove = sharedList.id;
                }
              });
            }
          });
        }

        // FINALLY - PERSIST EVERYTHING TO useState
        setListItemIdToMove(tmpListItemIdToMove);
        setItemSharedWithUsers(tmpItemSharedWithUsers);
        setListItemsIdsToDelete(tmpListItemsIdsToDelete);
        setItemWatchIdsToDelete(tmpItemWatchIdsToDelete);
        // setReturnItems(tmpReturnItems);
        setListsForUI(tmpReturnItems); // THIS IS ULTIMATELY WHAT IS RENDERED TO THE SCREEN
        setListsFromData(tmpReturnItems); // THIS IS AN IMMUTABLE REDUCED SET FROM DATA1
        setListsWhereThisItemAlreadExists(tmpListsWhereThisItemAlreadExists);

        if (displayConsole) {
          console.log(`${COMPONENT_NAME} > tmpItemSharedWithUsers: `, tmpItemSharedWithUsers);
        }
      }
    }
    doUseEffectStuff();
  }, [data1]);

  // STATE TO TRACK THE SELECTED ITEM
  const [selectedListId, setSelectedListId] = useState(0);

  if (loading1) {
    return (
      <DrawerSpace>
        <div className="flex content-center">
          <Loading loadingText="Wait for it..." />
        </div>
      </DrawerSpace>
    );
  }

  if (error1 || errorMsg) {
    return (
      <DrawerSpace>
        <ErrorUI errorMsg={error1 ? error1.message : errorMsg} />
      </DrawerSpace>
    )
  }

  // // POPULATE ARRAY FOR USER'S LISTS
  // const returnItems = [];
  // const listsWhereThisItemAlreadExists = [];
  // if (data1) {
  //   if (displayConsole) {
  //     console.log(`${COMPONENT_NAME} > data: `, data1);
  //   }

  //   // THIS IS GNARLY BUT BASICALLY:
  //   // 1) GET ALL THE ACTIVE LISTS FOR A USER
  //   // 2) ITREATE THROUGH THOSE LISTS AND THOSE LISITEMS
  //   // 3) IF THE ITEM TO MOVE IS FOUND IN THAT LIST - SKIP IT
  //   // 4) OTHERWISE - SHOW THE LIST AS A LOCATION TO MOVE THE ITEM TO
  //   if (data1.User && Array.isArray(data1.User)) {
  //     let dataUser = data1.User[0];
  //     if (dataUser && dataUser.listUsers && Array.isArray(dataUser.listUsers)) {
  //       _forEach(dataUser.listUsers, listUser => {
  //         if (listUser && listUser.list && listUser.list.id && listUser.list.id !== listId) {
  //           let currentList = listUser.list;
  //           let pushList = true;
  //           if (currentList && currentList.listItems && Array.isArray(currentList.listItems)) {
  //             _forEach(currentList.listItems, listItem => {
  //               if (listItem && listItem.item && listItem.item.id === itemId) {
  //                 // WE FOUND THIS ITEM ON ANOTHER LIST THAT THE USER OWNS
  //                 if (displayConsole) {
  //                   console.log(`${COMPONENT_NAME} > found item ${itemId} on list ${currentList.listDetails.name}: `);
  //                 }

  //                 pushList = false;
  //               }
  //             })
  //             // if(displayConsole){
  //             //   console.log(`${COMPONENT_NAME} > list items for list ${currentList.id}: `, currentList.listItems);
  //             //   console.log(`${COMPONENT_NAME} > filter count for itemId ${itemId}: `, currentList.listItems.filter(itm => itm.id === itemId).length);
  //             // }
  //           }

  //           if (pushList) {
  //             // SHOW THIS LIST IN THE UI AS A COPY DESTINATION
  //             returnItems.push(currentList);
  //           } else {
  //             // listsWhereThisItemAlreadExists.push({ "id": currentList.id, "name": `${currentList.listDetails.name}` });
  //             listsWhereThisItemAlreadExists.push({ "list": currentList });
  //           }
  //         }
  //       })
  //     }
  //   }
  // }

  // // POPULATE ARRAY FOR ITEM'S SHARES
  // const itemSharedWithUsers = [];
  // const listItemsIdsToDelete = []
  // const itemWatchIdsToDelete = [];
  // let listItemIdToMove = null;
  // let sysItemId = null;
  // if (item) {
  //   if (displayConsole) {
  //     console.log(`${COMPONENT_NAME} > item: `, item);
  //   }

  //   // GET THE SYSTEM ID FOR THE ITEM
  //   sysItemId = item.itemId ? item.itemId : null;
  //   if (displayConsole) {
  //     console.log(`${COMPONENT_NAME} > sysItemId: `, sysItemId);
  //   }

  //   // GET THE WATCHES FOR THIS ITEM
  //   if (item.itemWatches && Array.isArray(item.itemWatches)) {
  //     _forEach(item.itemWatches, itemWatch => {
  //       if (itemWatch && itemWatch.id) {
  //         itemWatchIdsToDelete.push(itemWatch.id);
  //       }
  //     });
  //   }

  //   if (displayConsole) {
  //     console.log(`${COMPONENT_NAME} > itemWatchIdsToDelete: `, itemWatchIdsToDelete);
  //   }

  //   // GET THE SHARES FOR THIS ITEM
  //   if (item.sharedLists && Array.isArray(item.sharedLists)) {
  //     _forEach(item.sharedLists, sharedList => {
  //       if (sharedList && sharedList.list && sharedList.list.listUsers && Array.isArray(sharedList.list.listUsers)) {
  //         _forEach(sharedList.list.listUsers, listUser => {
  //           if (listUser && listUser.listUserType && listUser.listUserType === 'SharedWith' && listUser.user && listUser.user.id !== currentUserId) {
  //             itemSharedWithUsers.push(listUser.user);
  //             listItemsIdsToDelete.push(sharedList.id);
  //           } else {
  //             // WE'VE FOUND THE listItemId THAT WE NEED TO USE TO MOVE TO A NEW LIST
  //             listItemIdToMove = sharedList.id;
  //           }
  //         });
  //       }
  //     });
  //   }

  //   if (displayConsole) {
  //     console.log(`${COMPONENT_NAME} > itemSharedWithUsers: `, itemSharedWithUsers);
  //   }
  // }


  // FOR THE CHECKBOX
  const handleChange = (e, inputListId) => {
    if (displayConsole) {
      console.log(`${COMPONENT_NAME} > handleChange > listId: ${inputListId} || e: `, e.target);
    }
    if (inputListId && inputListId !== '') {
      // THIS COMES WHEN PASSING FROM A DIV
      setSelectedListId(inputListId);
    } else {
      // THIS COMES WHEN PASSING FROM A UL/LI
      // UNCHECK THE ITEM IF IT'S ALREADY SELECTED
      if (e.target.name === selectedListId) {
        setSelectedListId(null);
      } else {
        setSelectedListId(e.target.dataset.id);
      }
    }
  };

  /**
   * CLEAR ANY SELECTED ITEMS
   */
  const clearSelectedItems = () => {
    setSelectedListId(0);
  }

  /** 
   * HANDLES THE <Toggle /> ACTION FOR FILTERING 'My Watches'
   * {
      "id": "9b4c4778-f884-46de-ab66-26d1cb6f1cc9",
      "listId": 271,
      "createdAt": "2022-09-03T13:05:02.7798+00:00",
      "updatedAt": "2022-12-03T14:36:18.902432+00:00",
      "pinned": false,
      "deletedState": false,
      "listDetails": {
          "id": "30073a9f-0928-4131-8283-f7830e2ce7bb",
          "name": "Bulk items list",
          "description": "A list to test how to bring bulk items in all at once",
          "buyType": "SharedBuy",
          "visibilityType": "private",
          "listDetailsId": 234,
          "__typename": "ListDetails"
      },
      "listUsers": [],
      "listItems": [],
      "amountMin": [],
      "amountMax": [],
      "itemsTotal": {
          "total": {
              "count": 0,
              "__typename": "ListItem_aggregate_fields"
          },
          "__typename": "ListItem_aggregate"
      },
      "itemsObtained": {
          "total": {
              "count": 0,
              "__typename": "ListItem_aggregate_fields"
          },
          "__typename": "ListItem_aggregate"
      },
      "itemsNotObtained": {
          "total": {
              "count": 0,
              "__typename": "ListItem_aggregate_fields"
          },
          "__typename": "ListItem_aggregate"
      },
      "sharedListsCount": {
          "total": {
              "count": 0,
              "__typename": "List_aggregate_fields"
          },
          "__typename": "List_aggregate"
      },
      "sharedLists": [],
      "sourceList": null,
      "__typename": "List"
  }
   * */
  const handleFilterListName = (searchValue) => {

    if (searchValue) {
      const tmpArr = [];
      // GO TO THE IMMUTABLE FILTERED SET OF LISTS FROM DATA1 TO SEE IF THIS SEARCH FILTER MATCHES
      if (listsFromData && Array.isArray(listsFromData) && listsFromData.length > 0) {
        if (displayConsole) {
          console.log(`${COMPONENT_NAME} > handleFilterListName > data: `, data1);
        }

        for (const currentList of listsFromData) {
          // IF LIST NAME OR DESCRIPTION CONTAINS THE SEARCH VALUE 
          if (currentList && (currentList.listDetails.name.toUpperCase().includes(searchValue.toUpperCase()) || currentList.listDetails.description.toUpperCase().includes(searchValue.toUpperCase()))) {
            tmpArr.push(currentList);
          }
        }
      }

      // PUSH ANYTHING WE FOUND TO THE UI
      if (tmpArr && Array.isArray(tmpArr) && tmpArr.length > 0) {
        if(displayConsole){
          console.log(`${COMPONENT_NAME} > handleFilterListName > tmpArr:`, tmpArr);
        }
        setListsForUI(tmpArr);
      }
    } else {
      // RESET THE UI
      setListsForUI(listsFromData);
    }
  };

  return (
    <Subscribe to={[DrawerContainer]}>
      {appState => (
        <Fragment>
          <DrawerSpace>
            {loading1 ? (
              <div>
                <span className="pl-3 drawer-header-description-text">Loading...</span>
              </div>
            ) : (
              <Fragment />
            )}
            {appState && appState.state && appState.state.selectedItemObj ? (
              <div className="pt-2">
                <div className="drawer-header-description-text pb-3">Move this item to another list</div>
                <div className="">
                  <ItemCardMin item={appState.state.selectedItemObj} />
                </div>

                {itemSharedWithUsers && itemSharedWithUsers.length > 0 ? (
                  <div className="pt-6">
                    <DrawerMessageWarning>
                      <span>Once you move this item it will no longer be shared with {itemSharedWithUsers.length == 1 ? (itemSharedWithUsers[0].firstName ? itemSharedWithUsers[0].firstName : 'this user') : 'these users'}</span>
                    </DrawerMessageWarning>
                    <div className="pt-4 pl-2">
                      <UserAvatar users={itemSharedWithUsers} direction="vertical" />
                    </div>
                  </div>
                ) : null}

                <div className='w-full pb-4'>
                  <div className="drawer-header-description-text pt-8 pb-2">Select your list</div>
                  <Search parentCompChangeEventReceiver={handleFilterListName} inputBoxPlaceholderText="Search for lists..." showClearSearch={true} />
                </div>

                <div>
                  {/* ITERATE THROUGH LISTS THAT ARE AVAILABLE TO MOVE TO AND DISPLAY THOSE TO SCREEN */}
                  {listsForUI && listsForUI.length > 0 ? (
                    listsForUI
                      .sort((a, b) =>
                        a.updatedAt < b.updatedAt ? 1 : b.updatedAt < a.updatedAt ? -1 : 0
                      )
                      .map(list => (
                        // eslint-disable-next-line
                        <div key={list.id} className="py-4 flex items-start" >
                          <div>
                            <Checkbox name={list.id} id={list.listId} checked={selectedListId ? list.listId.toString() === selectedListId.toString() : false} onChange={handleChange} />
                          </div>
                          <div id={list.id} className="ml-4 cursor-pointer" onClick={(e) => {
                            handleChange(e, list.listId)
                          }} name={list.id}>
                            <ListCardMin key={list.id} list={list} showActions={false} />
                          </div>
                        </div>
                      ))
                  ) : (
                    <div className="pt-6">
                      <span className="font-bold">We couldn't find any other lists to move this item to</span>
                      <br />
                      {listsWhereThisItemAlreadExists && listsWhereThisItemAlreadExists.length > 0 ? (
                        <div className="pt-4">
                          This item already exists in the following {listsWhereThisItemAlreadExists.length > 1 ? "lists" : "list"}:<br />
                          <div>
                            {listsWhereThisItemAlreadExists.map(listEntry => (
                              <div key={listEntry.list.id} className="pt-4 cursor-pointer" onClick={() => {
                                appState.selectList(listEntry.list.id);
                                appState.transferListToDrawer(listEntry.list);
                                history.push(`/l/${listEntry.list.id}`)
                              }}>
                                <ListCardMin key={listEntry.list.id} list={listEntry.list} showActions={false} />
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <div className="pt-4">
                          You don't have any other lists to move this item to
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div>
                <span className="drawer-header-description-text">Unable to find an item to move...</span>
              </div>
            )}
            <div className={selectedListId ? ['btn-drawer', 'show'].join(' ') : 'btn-drawer'}>
              <div className='flex -mr-12'>
                <div className='mr-4'>
                  <Button
                    buttonType="pill-void"
                    weight="thin"
                    xtra="hover:shadow"
                    onClick={async e => {
                      await clearSelectedItems();
                    }}
                  >
                    <span className="text-center">clear</span>
                  </Button>
                </div>
                <div>
                  <Button
                    buttonType="pill-standard"
                    weight="thin"
                    xtra="hover:shadow"
                    onClick={async e => {
                      if (displayConsole) {
                        console.log(`${COMPONENT_NAME} > Button onClick > selectedItemObj.itemId: ${appState.state.selectedItemObj.itemId} || listId: ${selectedListId}`, appState.state.selectedItemObj);
                      }
                      await moveItemToList({
                        variables: {
                          itemWatchIds: itemWatchIdsToDelete
                          , listItemIds: listItemsIdsToDelete
                          , listItemId: listItemIdToMove
                          , listId: selectedListId
                        },
                      })
                        .then(({ returnData }) => {
                          if (displayConsole) {
                            console.log(`${COMPONENT_NAME} > moveItemToList > item moved: `, returnData);
                          }

                          fnGyftoEventPipeline('Item', 'Moved', selectedListId);
                          appState.closeDrawer();
                          appState.showMessageCard('msg', `${appState.state.selectedItemObj.name} moved to the list`);
                        })
                        .catch(err => {
                          if (displayConsole) {
                            console.log(`${COMPONENT_NAME} > moveItemToList > ERROR moving item: `, err);
                          }
                          setErrorMsg(err.toString());
                        });
                    }}
                  >
                    <span className="text-center">move to list</span>
                  </Button>
                </div>
              </div>
            </div>
          </DrawerSpace>
        </Fragment>
      )}
    </Subscribe>
  );
}
