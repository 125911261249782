import React from 'react';
// import SVGEmptyList from '../common/icons/empty-list-svg';
import SVGPrivateList from '../common/icons/private-list-svg';


export default function PrivateList() {
  return (
    <div className="">
      <div>
        <SVGPrivateList  />
      </div>
    </div>
  );
};
