import React from 'react';

const AppContext = React.createContext({ userProfile: {}, userId: null, isAuthenticated: false });
// const Context = React.createContext();

// // Then create a provider Component
// class AppContext extends Component {
//   state = {
//     userProfile: {},
//     userId: 'default_value',
//     isAuthenticated: false,
//   };

//   // eslint-disable-next-line
//   handleAuthentication = authState => this.setState({ isAuthenticated: authState });

//   render() {
//     // eslint-disable-next-line
//     const { children } = this.props;
//     return (
//       <Context.Provider
//         value={{
//           state: this.state,
//           // handleAuthentication: this.handleAuthentication,
//         }}
//       >
//         {children}
//       </Context.Provider>
//     );
//   }
// }

export default AppContext;
