import auth0 from 'auth0-js';
import history from '../components/history/index';
const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;

export default class Auth {
  accessToken;

  idToken;

  expiresAt;

  userProfile;

  auth0 = new auth0.WebAuth({
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    redirectUri: `${process.env.REACT_APP_PUBLIC_URL}callback`,
    responseType: 'token id_token',
    scope: 'openid profile',
  });

  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
    this.getIdToken = this.getIdToken.bind(this);
    this.getProfile = this.getProfile.bind(this);
    this.renewSession = this.renewSession.bind(this);
  }

  login() {
    this.auth0.authorize();
  }

  handleAuthentication() {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        // console.log(`auth > handleAuthentication - this is authResult`);
        // console.dir(authResult);

        // FINALLY, setSession
        this.setSession(authResult);
      } else if (err) {
        history.replace('/');
        // console.log(err);
        // alert(`Error: ${err.error}. Check the console for further details.`);
      }
    });
  }

  getAccessToken() {
    return this.accessToken;
  }

  getIdToken() {
    return this.idToken;
  }

  // CALLS BACK TO AUTH0 TO GET THE USERS
  // PROFILE INFO BASED UPON THE accessToken
  getProfile(cb) {
    this.auth0.client.userInfo(this.accessToken, (err, profile) => {
      if (profile) {
        this.userProfile = profile;
      }
      cb(err, profile);
    });
  }

  setSession(authResult) {
    if (authResult && authResult.accessToken && authResult.idToken) {
      const userProfileNamespace = 'https://www.gyfto.com';
      let replaceRoute = '/h';
      // Set the time that the access token will expire at
      localStorage.setItem('access_token', authResult.accessToken);
      this.accessToken = authResult.accessToken;

      localStorage.setItem('id_token', authResult.idToken);
      this.idToken = authResult.idToken;

      const expiresAt = JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime());
      localStorage.setItem('expires_at', expiresAt);
      this.expiresAt = expiresAt;

      // Set isLoggedIn flag in localStorage
      localStorage.setItem('isLoggedIn', 'true');

      // NEXT - STORE INFO ABOUT THE USER
      if (authResult.idTokenPayload) {
        const userObj = authResult.idTokenPayload;

        if (displayConsole) {
          console.log(`auth > setSession - here is the userObj:`);
          console.dir(userObj);
        }

        if (userObj) {
          // STORE IT FOR RETRIEVAL LATER
          localStorage.setItem('user_object', JSON.stringify(userObj));

          // THIS IS STORING THE GYFTO USER ID FROM THE AUTH0 PAYLOAD
          const userProfileObj = userObj[userProfileNamespace].User;
          if(displayConsole){
            console.log(`auth > setSession > userProfileObj: `, userProfileObj);
          }
          const {id, userId} = userProfileObj;
          if (id) {
            // THIS id IS COMING FROM GYFTO BUT BEING PULLED THROUGH THE
            // AUTH0 AUTH PIPELINE VIA RULES
            localStorage.setItem('user_id', id);
          } else {
            if (displayConsole) {
              console.log(`auth > setSession - couldn't populate the 'user_id' from the userProfileObj object:`, userObj);
            }
          }

          if (userId) {
            // THIS userId IS COMING FROM GYFTO BUT BEING PULLED THROUGH THE
            // AUTH0 AUTH PIPELINE VIA RULES
            localStorage.setItem('user_id_app', userId);
          } else {
            if (displayConsole) {
              console.log(`auth > setSession - couldn't populate the 'user_id' from the userProfileObj object:`, userObj);
            }
          }

          // LAST, CHECK TO SEE IF WE HAVE COME FROM A PREVIOUS LINK TO TAKE US BACK THERE
          const routeLink = sessionStorage.getItem('deepLinkRoute');
          replaceRoute = routeLink && routeLink !== '' ? routeLink : '/h';
        }
      } else {
        replaceRoute = '/';
      }

      // LAST, GO HOME
      history.replace(replaceRoute);
    }
  }

  renewSession() {
    this.auth0.checkSession({}, (err, authResult) => {
      // console.log(`in auth.js > renewSession:`);
      // console.log(`authResult:`);
      // console.dir(authResult);
      if (authResult && authResult.accessToken && authResult.idToken) {
        // console.log(`authResult`);
        // console.dir(authResult);
        this.setSession(authResult);
      } else if (err) {
        this.logout();
        // console.log(err);
        // alert(`Could not get a new token (${err.error}: ${err.error_description}).`);
      }
    });
  }

  logout() {
    // Remove tokens and expiry time
    this.accessToken = null;
    this.idToken = null;
    this.expiresAt = 0;

    // REMOVE EVERYTHING FROM localStorage & sessionStorage
    localStorage.clear();
    sessionStorage.clear();

    // Remove user profile
    this.userProfile = null;

    // navigate to the home route
    history.replace('/');
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    const expiresAtLocalStorage = JSON.parse(localStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAtLocalStorage;
  }
}
