import React, { Fragment, useEffect, useState, useRef } from 'react';
import ClickOutHandler from 'react-onclickout';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import Button from '../common/button';
import Logo from '../common/logo';
import HeaderAvatar from '../avatar/headerAvatar';
import { Subscribe } from 'unstated';
import DrawerContainer from '../../containers/drawer';
import HoverMenu from '../common/hoverMenu/index';
import Modal from '../../components/modal/index';
// import ListCardMinScroll from '../list/list.card.min.scroll';
import ListCardBreadCrumbs from '../list/list.card.breadcrumbs';
import { fnGetBreadCrumbs } from '../../functions/index';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SVGSearch from '../common/icons/search-svg';
// 032720 - COMMENTING THIS OUT UNTIL WE HAVE A FIX FOR SHOWING 'ITEM' IN THIS LIST
// import Carat from '../common/caratHeader';
// import HoverMenu from '../common/hoverMenu';

function Header({ auth, location }) {
  const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;
  const COMPONENT_NAME = 'header';

  // MANAGING STATE OF THE POP-UNDER
  const [isHiddenAvatarMenu, setIsHiddenAvatarMenu] = useState(false);
  const defaultHoverMenuAvatarClass = ' mt-8 absolute hidden';
  const [hoverMenuAvatarClass, setHoverMenuAvatarClass] = useState(defaultHoverMenuAvatarClass);
  let showModal = false;
  let modalType = "";

  // FIGURE OUT IF USER IS AUTHENTICATED OR NOT
  const isAuthenticated = auth ? auth.isAuthenticated() : null;

  const userObj = JSON.parse(localStorage.getItem('user_object'));
  const userId = localStorage.getItem(`user_id`);
  let modalDataObj = {};
  let breadCrumbs = [];

  useEffect(() => {

    async function doUseEffectStuff() {
      if (displayConsole) {
        console.log(`${COMPONENT_NAME} > location: `, location);
        console.log(`${COMPONENT_NAME} > isAuthenticated: `, isAuthenticated);
        console.log(`${COMPONENT_NAME} > userId: `, userId);
      }

      // SHOW THE MODAL WHEN A USER HAS BEEN LOGGED OUT
      if (isAuthenticated === false && userId && userId !== '') {
        // if (isAuthenticated === false ) {
        // THIS SHOULD ONLY BE TRUE IF THE USER WAS LOGGED IN BUT THEIR TOKEN EXPRIED
        // LET'S SHOW A MODAL AND TELL THEM
        // GET WHERE WE ARE AT
        if (location && location.pathname) {
          // LOOKS LIKE "/l/658937f4-941c-4262-b3b9-49a1336e856d"

          const urlParams = location.pathname.split('/');
          modalDataObj = { "locationType": urlParams[1] ? urlParams[1] : '', "listId": urlParams[2] ? urlParams[2] : '' }
          if (displayConsole) {
            console.log(`${COMPONENT_NAME} > useEffect > modalDataObj > `, modalDataObj);
          }

        }
        modalType = "userExpired";
        showModal = true;
      }
    }

    doUseEffectStuff();

  }, [isAuthenticated])

  // LOCAL FUNCTIONS
  const handleClickShowCreateMenu = (e) => {
    // const { btnNewCarat } = this.state;
    // this.setState({ btnNewCarat: !btnNewCarat });
  }

  const handleShowAvatarMenu = (visible) => {
    // 
    if (displayConsole) {
      console.log(`${COMPONENT_NAME} > handleShowAvatarMenu > visible: `, visible)
    }
    // CHANGE THE CSS TO SHOW/HIDE
    if (visible) {
      // SHOW THE MENU
      setHoverMenuAvatarClass(' -ml-5  mt-8 absolute ');
    } else {
      setHoverMenuAvatarClass(defaultHoverMenuAvatarClass);
    }

    // TRACK THE STATE
    setIsHiddenAvatarMenu(visible);

  };

  const handleClickSignInSignUp = (e) => {
    auth.login();
  }

  const handleClickLogout = () => {
    auth.logout();
  }

  // SHOW THE SCROLL TO TOP BASED UPON SCROLL POSITION
  // https://dev.to/lukekyl/react-hiding-an-element-as-you-scroll-43g6
  //const inputRef = useRef();
  // const [opacityState, setOpacityState] = useState("0");

  // const scrollHandler = () => {
  //   if (typeof window !== "undefined") {
  //     window.onscroll = () => {
  //       let currentScrollPos = window.pageYOffset;
  //       if (currentScrollPos > 0 && currentScrollPos > 200) {
  //         setOpacityState("1");
  //       } else {
  //         setOpacityState("0");
  //       }
  //     }
  //   }
  // };

  // This function handle the scroll performance issue
  // const debounce = (func, wait = 20, immediate = true) => {
  //   let timeOut;
  //   return () => {
  //     let context = this,
  //       args = arguments;
  //     const later = () => {
  //       timeOut = null;
  //       if (!immediate) func.apply(context, args);
  //     };
  //     const callNow = immediate && !timeOut;
  //     clearTimeout(timeOut);
  //     timeOut = setTimeout(later, wait);
  //     if (callNow) func.apply(context, args);
  //   };
  // };

  // USEEFFECT FOR THE SCROLLING STUFF
  // useEffect(() => {
  //   window.addEventListener("scroll", debounce(scrollHandler))
  //   return () => {
  //     window.removeEventListener("scroll", () => scrollHandler);
  //   }
  // }, [debounce, scrollHandler]);

  // SCROLL TO TOP - TEMPORARILY REMOVING THIS FUNCTIONALITY
  // const scrollToTop = () => {
  //   // console.log(`${COMPONENT_NAME} > scrollToTop > clicked! inputRef `, inputRef);
  //   if (inputRef) {
  //     setOpacityState("0"); 
  //     setTimeout(() => {inputRef.current.scrollIntoView({ behavior: 'smooth' })}, 50);
  //   }
  // }

  // FIGURE OUT WHAT NAV LINKS TO SHOW
  let homeCss = 'text-link-inactive';
  let homeBorderBg = 'bg-white';
  let listsCss = 'text-link-inactive';
  let listsBorderBg = 'bg-white';
  let listsButtonType = 'pill-standard';
  let sharesCss = 'text-link-inactive';
  let sharesBorderBg = 'bg-white';
  let sharesButtonType = 'pill-standard';
  let itemsCss = 'text-link-inactive';
  let itemsBorderBg = 'bg-white';
  let itemsButtonType = 'pill-standard';
  // ONLY ADD THE BOTTOM BORDER TO THE HEADER IF THE
  // USER IS AUTHENTICATED
  let pageCss = 'bg-white h-14 b-2 justify-center flex';
  pageCss += isAuthenticated ? ' border-b' : '';

  switch (location.pathname) {
    case '/h':
      homeCss = 'text-link-active';
      homeBorderBg = 'bg-gyfto-green';
      listsBorderBg = 'bg-white';
      sharesBorderBg = 'bg-white';
      break;
    case '/ls':
      listsCss = 'text-link-active';
      listsBorderBg = 'bg-gyfto-green';
      listsButtonType = 'pill-white';
      break;
    case '/sh':
      sharesCss = 'text-link-active';
      sharesBorderBg = 'bg-gyfto-green';
      sharesButtonType = 'pill-white';
      break;
    case '/itms':
      itemsCss = 'text-link-active';
      itemsBorderBg = 'bg-gyfto-green';
      itemsButtonType = 'pill-white';
      break;
    default:
      // IF IT GOT HERE IT *may* BE THE /l ROUTE
      listsCss = 'text-link-active';
      listsBorderBg = 'bg-white';
      sharesBorderBg = 'bg-white';
  }
  return (
    <Subscribe to={[DrawerContainer]}>
      {drawerContainer => (
        // <div ref={inputRef}>
        <div>
          {showModal ? (
            <Modal showModal={true} modalType={modalType} dataObject={modalDataObj} auth={auth} />
          ) : null}
          {/* THIS IS THE TOP WHITE BORDER THAT SPANS THE WHOLE PAGE */}
          <div className={pageCss}>
            {/* THIS IS THE INNER CONTAINER THAT CENTERS THE CONTENT AND FORCES A MAX WIDTH */}
            <div className="container max-w-gyfto-max items-center h-full flex justify-between">
              {/* IMAGE ON THE LEFT */}
              {isAuthenticated ? (
                <div className="xs:pl-2 sm:pl-4">
                  <Link to="/h">
                    <Logo showText={true} />
                  </Link>
                </div>
              ) : (
                <div className="xs:pl-2 sm:pl-4">
                  <Link to="/">
                    <Logo showText={true} />
                  </Link>
                </div>)}

              {/* THESE ARE THE LINKS */}
              {isAuthenticated ? (
                <Fragment>
                  <div className=" bg-white flex justify-around h-full" style={{ zIndex: 900 }}>
                    {/* HIDE THE LINKS ON SMALLER SCREENS */}
                    <div className="xs:hidden md:flex md:w-1/2 justify-end h-full">
                      <div className="h-full flex items-center">
                        <div className="flex flex-col">
                          <div className="pt-5 pb-4">
                            <Link to="/h" className={`pr-4 ${homeCss} hover:text-gyfto-green`}>
                              HOME
                            </Link>
                          </div>
                          <div
                            className={`min-h-gyfto-header-item-active ${homeBorderBg} max-w-gyfto-header-item-active`}
                          />
                        </div>

                        <div className="flex flex-col">
                          <div className="pt-5 pb-4">
                            <Link to="/ls" className={`pr-4 ${listsCss} hover:text-gyfto-green`}>
                              LISTS
                            </Link>
                          </div>
                          <div
                            className={`min-h-gyfto-header-item-active ${listsBorderBg} max-w-gyfto-header-item-active`}
                          />
                        </div>
                        <div className="flex flex-col">
                          <div className="pt-5 pb-4">
                            <Link to="/itms" className={`pr-4 ${itemsCss} hover:text-gyfto-green`}>
                              ITEMS
                            </Link>
                          </div>
                          <div
                            className={`min-h-gyfto-header-item-active ${itemsBorderBg} max-w-gyfto-header-item-active`}
                          />
                        </div>
                        <div className="flex flex-col">
                          <div className="pt-5 pb-4">
                            <Link to="/sh" className={`pr-4 ${sharesCss} hover:text-gyfto-green`}>
                              SHARES
                            </Link>
                          </div>
                          <div
                            className={`min-h-gyfto-header-item-active ${sharesBorderBg} max-w-gyfto-header-item-active`}
                          />
                        </div>
                        <div className="flex flex-col px-2">
                          <div className=" cursor-pointer" onClick={() => drawerContainer.showHide('search', null, null, 'Search')}>
                            <SVGSearch primaryColor="#b8c2cc" secondaryColor="#5FBA7D" width="24px" height="24px" altText="Search" />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* SIGNUP/IN / USER AVATAR ON THE RIGHT */}
                    <div className="justify-end xs:pr-4 items-center flex align-middle">
                      <div className="xs:hidden md:block">
                        <div className="flex items-center">
                          <div className="mr-2">
                            <Button
                              // 032720 - COMMENTING THIS OUT UNTIL WE HAVE A FIX FOR SHOWING 'ITEM' IN THIS LIST
                              // onClick={e => this.handleClickShowCreateMenu(e)}
                              onClick={() => drawerContainer.showHide('list', 'create')}
                              buttonType="pill-standard"
                              weight="thin"
                              xtra="hover:shadow"
                            >
                              <div className="flex">
                                {/* 032720 - COMMENTING THIS OUT UNTIL WE HAVE A FIX FOR SHOWING 'ITEM' IN THIS LIST */}
                                {/* new &nbsp;&nbsp; <Carat hide={btnNewCarat} /> */}
                                new list
                              </div>
                            </Button>
                          </div>
                        </div>
                        {/* 032720 - COMMENTING THIS OUT UNTIL WE HAVE A FIX FOR SHOWING 'ITEM' IN THIS LIST */}
                        {/* {btnNewCarat ? (
                      <ClickOutHandler onClickOut={this.handleClickShowCreateMenu}>
                        <HoverMenu />
                      </ClickOutHandler>
                    ) : (
                        <div />
                      )} */}
                      </div>
                      {/* SHOW THE HEADER AVATAR WITH A CLICKABLE MENU FOR MD AND LARGER SCREENS, HIDDEN FOR SMALLER */}
                      <div className="xs:hidden md:flex flex-row">
                        <ClickOutHandler onClickOut={() => { handleShowAvatarMenu(false) }}>
                          <div className="cursor-pointer" onClick={() => { handleShowAvatarMenu(!isHiddenAvatarMenu) }}>
                            <HeaderAvatar
                              firstName={userObj && userObj.given_name ? userObj.given_name : ''}
                              lastName={userObj && userObj.family_name ? userObj.family_name : ''}
                              userId={userId || ''}
                              avatarUrl={userObj && userObj.picture ? userObj.picture : ''}
                            />
                          </div>
                        </ClickOutHandler>
                        <div className={hoverMenuAvatarClass}>
                          <HoverMenu>
                            <div className="p-6" style={{ width: "180px" }}>
                              <Button
                                onClick={handleClickLogout}
                                buttonType="pill"
                                weight="thin"
                                xtra="hover:shadow"
                              >
                                LOG OUT
                              </Button>
                            </div>
                          </HoverMenu>
                        </div>
                      </div>
                      {/* SHOW THE HEADER AVATAR WITH A CLICKABLE MENU FOR MD AND LARGER SCREENS, HIDDEN FOR SMALLER */}
                      <div className="md:hidden xs:flex flex-row items-center h-full">
                        <div className="flex flex-col pr-4">
                          <div className=" cursor-pointer" onClick={() => drawerContainer.showHide('search', null, null, 'Search')}>
                            <SVGSearch primaryColor="#5FBA7D" secondaryColor="#5FBA7D" width="24px" height="24px" altText="Search" />
                          </div>
                        </div>
                        <div className="cursor-pointer" onClick={() => {
                          drawerContainer.setAuth(auth);
                          drawerContainer.showHide('user', 'settings', null, 'User Details');
                        }}>
                          <HeaderAvatar
                            firstName={userObj && userObj.given_name ? userObj.given_name : ''}
                            lastName={userObj && userObj.family_name ? userObj.family_name : ''}
                            userId={userId || ''}
                            avatarUrl={userObj && userObj.picture ? userObj.picture : ''}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Fragment>
              ) : (
                // THIS IS SHOWN WHEN THE !isAuthenticated - LIKELY WHEN THE USER WAS LOGGED IN BUT THEIR TOKEN EXPIRED
                <div className="flex items-center justify-end w-full">
                  <div className="xs:pr-2 md:pr-12">
                    <span><a href="/#about" rel="noopener noreferrer" className="header-base-text">About</a></span>
                  </div>
                  {/* THESE BUTTONS SHOW ON WIDE SCREENS */}
                  <div className="hidden md:flex">
                    <div className="md:pr-2">
                      <Button
                        onClick={e => handleClickSignInSignUp(e)}
                        buttonType="pill-standard"
                        weight="thin"
                        xtra="hover:shadow"
                        responsive={true}
                      >
                        <div className="flex">sign up</div>
                      </Button>
                    </div>
                    <div>
                      <Button
                        onClick={e => handleClickSignInSignUp(e)}
                        buttonType="pill-no-style-hover"
                        weight="thin"
                      >
                        <div className="flex">sign in</div>
                      </Button>
                    </div>
                  </div>
                  <div className="xs:flex md:hidden">
                    <div className="xs:pr-4">
                      <Button
                        onClick={e => handleClickSignInSignUp(e)}
                        buttonType="pill-standard"
                        weight="thin"
                        xtra="hover:shadow"
                        responsive={false}
                      >
                        <div className="flex">get started</div>
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {isAuthenticated ? (
            <Fragment>
              <div className="md:hidden xs:flex justify-between px-10 h-full bg-grey-lightest b-2 border-b">
                {/* NOTE: THIS minWidth IS TO MAKE THIS BUTTON THE SAME SIZE AS THE ONE BELOW */}
                <div className="flex flex-col" style={{ minWidth: '5.375rem' }}>
                  <div className="py-2">
                    <Link to="/ls" >
                      <Button
                        buttonType={listsButtonType}
                        weight="thin"
                        responsive={false}
                        xtra="hover:shadow">
                        LISTS
                      </Button>
                    </Link>
                  </div>
                  <div
                    className={`min-h-gyfto-header-item-active ${listsBorderBg} w-full`}
                  />
                </div>
                {/* <div className='hidden'>&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;</div> */}
                <div className="flex flex-col" style={{ minWidth: '5.375rem' }}>
                  <div className="py-2">
                    <Link to="/itms">
                      <Button
                        buttonType={itemsButtonType}
                        weight="thin"
                        responsive={false}
                        xtra="hover:shadow">
                        ITEMS
                      </Button>
                    </Link>
                  </div>
                  <div
                    className={`min-h-gyfto-header-item-active ${itemsBorderBg} w-full`}
                  />
                </div>
                <div className="flex flex-col" style={{ minWidth: '5.375rem' }}>
                  <div className="py-2">
                    <Link to="/sh">
                      <Button
                        buttonType={sharesButtonType}
                        weight="thin"
                        responsive={false}
                        xtra="hover:shadow">
                        SHARES
                      </Button>
                    </Link>
                  </div>
                  <div
                    className={`min-h-gyfto-header-item-active ${sharesBorderBg} w-full`}
                  />
                </div>
              </div>
              <div className="md:hidden xs:flex justify-start pl-4 h-full bg-grey-lightest b-2 border-b">
                <div className="w-full flex items-center">
                  <ListCardBreadCrumbs location={location} />
                </div>
              </div>
            </Fragment>
          ) : (null)}
        </div>
      )}
    </Subscribe>
  );
}

Header.propTypes = {
  location: PropTypes.object,
  auth: PropTypes.object,
};

Header.defaultProps = {
  location: null,
  auth: null,
};

export default withRouter(Header);
