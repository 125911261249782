import React, { useState } from 'react';
import SVGMove from '../common/icons/move-svg';


export default function ItemMove() {
  const [mouseOn, setMouseOn] = useState(false);
  let baseClass = 'h-10 w-10 rounded-full mx-auto ';
  let subTextClass = 'text-xxs text-grey-dark uppercase mt-2 ';
  return (
    <div className="cursor-pointer">
      <div className={mouseOn ? baseClass+= ' bg-gyfto-card-left-panel-bg ' : baseClass } onMouseEnter={() => setMouseOn(true)} onMouseLeave={() => setMouseOn(false)}>
        <div className="pt-2 pl-2.5">
        <SVGMove primaryColor="#b8c2cc" secondaryColor="#7B896C" width="22px" height="22px" altText="Move this item" />
        </div>
      </div>
      <div className={mouseOn ? subTextClass += ' visible' : subTextClass += ' invisible'}>move</div>
    </div>
  );
};
