import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import { Subscribe } from 'unstated';
import DrawerContainer from '../../containers/drawer';
import ListRender from './list.render';
import { useSubscription } from '@apollo/react-hooks';
import { subGetListDetailAndItems } from '../../graphql/graphql';

  export default function ListConatinerAuth (props) {

    // eslint-disable-next-line
    const { match, auth, fromShareLink } = props;
    const { listId, ogListId } = match.params;
    const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false; //listId === 'e1514e21-13b3-4ea9-83c3-6b84268af813';//false;
    const COMPONENT_NAME = 'list.container.auth';

    if (displayConsole) {
      console.log(`${COMPONENT_NAME} > Props: listId: ${listId}`);
    }

    // GET DATA
    const { loading, error, data } = useSubscription(subGetListDetailAndItems, {
      variables: { listId },
      suspend: false
    });

    if(data && displayConsole){
      console.log(`listContainer > listId: data: `, data);
    }

    
    return (
      <Subscribe to={[DrawerContainer]}>
        {drawerContainer => (
          <div>
            <ListRender appState={drawerContainer} listId={listId} ogListId={ogListId} auth={auth} fromShareLink={fromShareLink} loading={loading} error={error} data={data} />
          </div>
        )}
      </Subscribe>
    );
  }

ListConatinerAuth.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      listId: PropTypes.string,
    }).isRequired,
  }).isRequired,
  fromShareLink: PropTypes.bool
};
