import React, { Component } from 'react';

export default class DrawerSpace extends Component {
  render() {
    /* eslint-disable-next-line  */
    const { children } = this.props;
    return (
      <div>
        {/* THIS IS THE TOP WHITE BORDER THAT SPANS THE WHOLE PAGE */}
        {/* TRYING TO SORT OUT WEIRD PADDING AT THE TOP OF THE MAIN SECTION */}
        <div className="flex h-screen">
          {/* THIS IS THE INNER CONTAINER THAT CENTERS THE CONTENT AND FORCES A MAX WIDTH */}
          <div className="flex-none min-w-gutter" />
          <div className="flex-1 w-gyfto-drawer-content xs:max-w-gyfto-drawer-content-xs md:max-w-gyfto-drawer-content-md min-w-gyfto-drawer-content" >
            <div className="">{children}</div>
          </div>
          <div className="flex-none min-w-gutter" />
        </div>
      </div>
    );
  }
}
