import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Subscribe } from 'unstated';
import Button from '../common/button';
import DrawerContainer from '../../containers/drawer';
import DrawerSpace from '../common/drawerSpace';
import Loading from '../common/loading';
import Avatar from '../avatar/index';
import Checkbox from '../common/checkbox';
import ItemCardMin from '../item/itemCard.min';
import { fnCreateInvitation } from '../../functions/index';

export default function ItemCreateShareItem(props) {
  const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;
  const [disableBtn, setBtnDisable] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [selectedItems, setSelectedItem] = useState([]);
  const [initialized, setInitialized] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const COMPONENT_NAME = 'itemCreate.enterItemShare';
  const { inboundList } = props;
  const currentUserId = localStorage.getItem('user_id');


  // SAVE THE prop listItems TO LOCAL STATE
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      const useEffectArr = [];

      if (inboundList) {
        if (inboundList && inboundList.sharedLists && Array.isArray(inboundList.sharedLists) && inboundList.sharedLists.length > 0) {
          const sharedLists = inboundList.sharedLists;
          if (sharedLists) {
            for (const sharedList of sharedLists) {
              if (sharedList && Array.isArray(sharedList.listUsers) && sharedList.listUsers.length > 0) {
                for (const sharedListUser of sharedList.listUsers) {
                  if (sharedListUser && sharedListUser.user) {
                    useEffectArr.push({ checked: false, user: sharedListUser.user });
                  }
                }
              }
            }
          }
        } else {
          if (displayConsole) {
            console.log(`${COMPONENT_NAME} > no SharedUsers in this list '${inboundList.id}'`, inboundList);
          }
        }
      }

      if (useEffectArr && Array.isArray(useEffectArr) && useEffectArr.length > 0) {
        setSelectedItem(useEffectArr);
      }
    }
  }, [inboundList]
  );

  // UPDATE THE selectAll STATE AFTER THE selectedItems ARRAY
  // IS UPDATED
  useEffect(() => {
    // UPDATE selectAll TOGGLE BY COUNTING ALL 'checked' ITEMS IN THE
    // selectedItems STATE - IF THE NUMBER EQUALS THE NUMBER OF ITEMS IN THE ARRAY
    // THEN EVERYTHING IS CHECKED
    setSelectAll(selectedItems.filter(l => l.checked === true).length === selectedItems.length ? true : false);
  }, [selectedItems])

  // FIRES WHEN A CHECKBOX IS CLICKED - HANDLES STATE MGMT FOR THE LISTS CHECKED, ETC.
  const handleCheckboxCheck = e => {
    if (displayConsole) {
      console.log(`${e.target.name} is ${e.target.checked}`);
    }

    // A BIT OF A HACK BUT THIS UPDATES THE useState ARRAY WITH A NEW ARRAY WITH AN UPDATED
    // LIST OF CHECKED ITEMS
    let tmpArr = selectedItems.filter(l => l.user.id !== e.target.name);
    let tmpI = selectedItems.filter(l => l.user.id === e.target.name);
    if (tmpI && Array.isArray(tmpI) && tmpI.length > 0) {
      tmpI.map(i => {
        tmpArr.push({ checked: e.target.checked, user: i.user });
      })

    }

    // UDPATE selectedItems
    setSelectedItem(tmpArr);
  };

  // HANDLES THE 'SELECT ALL' CHECKBOX
  const handleToggleSelectAll = e => {

    if (displayConsole) {
      console.log(`drawerItem.share.create > handleToggleSelectAll - e`, e);
    }
    // FIRST, ITERATE THROUGH ALL OF THE selectedItems AND THEN DO THE TOGGLE
    const handleToggleArr = [];
    selectedItems.map(itm => {
      handleToggleArr.push({ checked: e.target.checked, user: itm.user });
    })

    // CHECK ALL THE BOXES
    setSelectedItem(handleToggleArr);
  }

  /**
   * CALLING OUT TO FUNCTION THAT WILL PUT A NEW INVITATION ON THE PIPELINE - PAYLOAD
   * LOOKS LIKE:
   {
   "listId":"afd0f139-b383-47db-9f22-b224d9daae66",
   "senderUserId":"fd8fad3a-e3c9-4065-8f77-b6d41e6e4a6a",
   "selectedUsers":[
      {
         "id":"unkown-1605306435092",
         "firstName":"",
         "lastName":"",
         "avatarUrl":"",
         "email":"kellitrent@gmail.com",
         "userType":"new"
      }
   ],
   "selectedItems":[
      {
         "id":"09674fb2-fc7d-41a1-bafb-31d11cb377f5",
         "itemId":46,
         "name":"ShakeWeight"
      },
      {
         "id":"5a13466e-789a-48a6-8f87-f6834d67e064",
         "itemId":14,
         "name":"Rolex Pepsi GMT-Master 16750"
      }
   ],
   "listEditorRights":false,
   "invitationMessage":"Some message goes here"
}
   */
  const createInvitation = async (listId, userId, appState) => {
    // COMMENTING OUT UNTIL WE'RE READY TO CALL OUT TO THE SERVICE
    if (listId && userId && selectedItems.length > 0 
      && appState && appState.state
      && appState.state.selectedItemObj) {
      
      
      // BUILD THE OBJECT FOR THE NEWLY CREATED ITEM
      const sendTheseItems = [];
      const newlyCreatedItem = appState.state.selectedItemObj;
      //sendTheseItems.push({ "id": selectedItem.item.id, "itemId": selectedItem.item.itemId, "name": selectedItem.item.name })
      sendTheseItems.push({itemId:newlyCreatedItem.itemId, name: `${newlyCreatedItem.name ? newlyCreatedItem.name : null}`, id:`${newlyCreatedItem.id}`});

      // GET ALL THE USERS SELECTED BY THE USER
      const selectedUsers = [];
      const checkedUsers = selectedItems.filter(si => si.checked);
      if (checkedUsers && Array.isArray(checkedUsers) && checkedUsers.length > 0) {
        checkedUsers.filter(si => si.checked).forEach(selectedUser => {
          if (selectedUser && selectedUser.user && selectedUser.user.id) {
            selectedUsers.push({email: selectedUser.user.email, id: selectedUser.user.id});
          }
        });
      }
      const invitationObj = {
        listId,
        senderUserId: userId,
        selectedUsers: selectedUsers,
        selectedItems: sendTheseItems,
        listEditorRights: false,
        invitationMessage: null
      };

      // SEND THIS OFF TO THE EVENTS PIPELINE
      if (displayConsole) {
        console.log(`${COMPONENT_NAME} > createInvitation > sending this Invitation off to be created: `, invitationObj);
      }
      await fnCreateInvitation(invitationObj).then((invitationCreated) => {
        if (displayConsole) {
          console.log(`${COMPONENT_NAME} > createInvitation > This came back from invitation creation: `, invitationCreated);
        }
        if (invitationCreated) {
          appState.closeDrawer();
          appState.showMessageCard('msg', `${selectedUsers.length > 1 ? 'Invitation' : 'Invitations'} created!`);
        } else {
          appState.closeDrawer();
          appState.showMessageCard('error', `Issue creating ${selectedUsers.length > 1 ? 'Invitation' : 'Invitations'}. Please try again.'`);
        }
      })
    } else {
      if (displayConsole) {
        console.log(`${COMPONENT_NAME} > inviteNewUsers - missing either userId ${userId} or listId ${listId}`);
      }
    }
  };

  return (
    <Subscribe to={[DrawerContainer]}>
      {appState => (
        <DrawerSpace>
          <div className="pt-2">
            {showLoading ? (
              <div className="flex content-center">
                <Loading loadingText="Getting the shares for your list..." />
              </div>

            ) : (
                <div>
                  <div className="drawer-header-description-text pb-4">
                    Pick users that you want to share this item with:
                  </div>
                  <div>
                    <ItemCardMin item={appState.state.selectedItemObj} />
                  </div>
                  <div className="py-4 flex items-start align-middle">
                    <Checkbox name="chkBoxSelectAll" checked={selectAll} onChange={handleToggleSelectAll} />
                    <span className="pl-4 drawer-header-description-text">Select shared users</span>
                  </div>
                  <div className="">
                    {
                      selectedItems
                        .sort((a, b) => (a.user.userId > b.user.userId ? 1 : b.user.userId > a.user.userId ? -1 : 0))
                        .map((itrUser, idx) => {
                          return (
                            <div key={itrUser.user.id} className="flex items-start align-middle pb-4">
                              <div className="pt-3">
                                <Checkbox key={'chk' + itrUser.user.id + '|' + idx.toString()} name={itrUser.user.id} checked={itrUser.checked} onChange={handleCheckboxCheck} />
                              </div>
                              <div className="pl-3">
                                <Avatar
                                  key={itrUser.user.id + '|' + idx.toString()}
                                  userId={itrUser.user.id}
                                  firstName={itrUser.user.firstName}
                                  lastName={itrUser.user.lastName}
                                  emailAddress={itrUser.user.email ? itrUser.user.email : null}
                                  avatarUrl={itrUser.user.avatarUrl}
                                  showName={true}
                                  direction={'vertical'}
                                  forAssetId={itrUser.user.id} 
                                />
                              </div>
                            </div>
                          )
                        })
                    }
                  </div>
                  <div className="flex justify-between mt-8">
                    <Button
                      buttonType="pill-cancel"
                      weight="thin"
                      xtra="hover:shadow"
                      onClick={() => {
                        appState.closeDrawer();
                      }}
                    >
                      <span className="text-center">Skip Sharing</span>
                    </Button>
                    <Button
                      buttonType="pill-add"
                      weight="thin"
                      xtra="hover:shadow"
                      disabled={selectedItems.filter(si => si.checked).length < 1}
                      showSpinner={true}
                      onClick={async e => {
                        setShowLoading(true);
                        if (
                          appState &&
                          appState.state &&
                          appState.state.selectedListObj &&
                          appState.state.selectedListObj.id &&
                          currentUserId
                        ) {
                          await createInvitation(appState.state.selectedListObj.id, currentUserId, appState).catch(error => {
                            if (displayConsole) {
                              console.log(`drawerItem.share.create > Add button - error calling inviteNewUsers: `, error);
                            }
                          });
                        } else {
                          appState.closeDrawer();
                          appState.showMessageCard('error', "Hmm...ran into an issue!");
                        }
                      }}
                    >
                      <span className="text-center">Share</span>
                    </Button>
                  </div>
                </div>
              )}
          </div>
        </DrawerSpace>
      )}
    </Subscribe>
  );
}
