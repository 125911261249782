import gql from 'graphql-tag';

const fragmentListDetails = gql`
  fragment FragmentListDetails on ListDetails {
    id
    name
    description
    buyType
    visibilityType
    listDetailsId
  }
`;

export default fragmentListDetails;
