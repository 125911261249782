import React, { useState } from 'react';


export default function Search({parentCompChangeEventReceiver, inputBoxPlaceholderText, showClearSearch}) {
    const [searchVal, setSearchVal] = useState('');

    /**
     * THIS WILL FIRE WHEN THE SEARCH BOX TEXT CHANGES
     * @param {*} event 
     */
    const handleChange = event => {
        if (event && event.target) {
            const { value } = event.target;
            setSearchVal(value);

            // CALL OUT TO THE PARTENT COMPONENT TO PASS THE VALUE
            if(parentCompChangeEventReceiver){
                parentCompChangeEventReceiver(value);
            }
        }
    };

    /**
     * THIS WILL CLEAR THE SEARCH BOX
     */
    const clearSearch = () =>{
        // CLEARS THE SERACH BOX VALUE
        setSearchVal('');

        // PASSES THE CLEARED VALUE BACK UP TO THE PARENT
        if(parentCompChangeEventReceiver){
            parentCompChangeEventReceiver(null);
        }
    };

    return (
        <div className='flex w-full'>
            <input
                autoFocus
                id="inputUrl"
                type="text"
                className="w-full form-input form-input-search focus:outline-none focus:shadow-outline"
                // eslint-disable-next-line
                value={searchVal}
                onChange={handleChange}
                placeholder={inputBoxPlaceholderText ? inputBoxPlaceholderText : 'Search'}
            />
            {showClearSearch ? (
                <div className='flex items-center justify-center z-50 pin-r -ml-10 mt-2 absolute sticky h-8 w-8 rounded-full bg-grey-lightest tex-xxs text-grey-dark cursor-pointer' onClick={clearSearch}>x</div>
            ) : null}
        </div>
    )

}