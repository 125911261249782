import React, { Fragment, useState, useEffect } from 'react';
import HideShowLongText from '../common/hideShowLongText';
import { fnConvertUtcToLocalDate } from '../../functions/index'

// eslint-disable-next-line
export default function ItemCard({ item, type, isShare, obtainedBy, showListName, list, displayType }) {

    const COMPONENT_NAME = 'ItemCard';
    const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;
    const [listName, setListName] = useState(null);
    const [listUrl, setListUrl] = useState(null);
    const [itemDate, setItemDate] = useState(null);

    let cardLeftCss = 'item-card-left';
    // CHANGE THE WIDTH OF THE LEFT SIDE OF THE CARD IF ASKED TO
    if (item && item.obtained === false) {
        // THIS SHOULD ONLY HAPPEN WHEN THIS COMPONENT IS BEING USED ON THE 'items' PAGE
        cardLeftCss += ' min-w-item-card-min-wide-left-not-obtained';
    }

    useEffect(() => {
        async function doUseEffectStuff() {
            if (displayConsole) {
                console.log(`${COMPONENT_NAME} > Here is the list':`, list);
                console.log(`${COMPONENT_NAME} > Here is the item':`, item);
            }

            //SET THE listName & listUrl FROM THE PASSED IN list OBJECT
            if (list) {
                setListName(list && list.listDetails && list.listDetails.name ? list.listDetails.name : 'FOO');
                setListUrl(list && list.id ? list.id : null);
            }

            if (item && item.updatedAt) {
                const formattedItemDate = await fnConvertUtcToLocalDate(item.updatedAt);
                setItemDate(formattedItemDate);
            }
        }
        doUseEffectStuff();
    }, [list]);

    /**
     * OPENS A NEW WINDOW IF THE url IS VALID
     * @param {string} url 
     * @returns 
     */
    const onClickUrl = (url) => {
        if (url && url !== null && url !== '') {
            const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        }
    }

    return (
        <Fragment>
            <div className='flex'>
                {/* SMALL SCREENS */}
                <div className='xs:flex md:hidden w-full'>
                    <div className="max-w-sm min-w-gyfto-card-min-in-list rounded-lg overflow-hidden shadow-lg">
                        <div className="bg-white h-48 flex-none text-center overflow-hidden" onClick={() => onClickUrl(item.url)} style={{ "backgroundImage": `url('${item.imageUrl ? item.imageUrl : `${process.env.REACT_APP_PUBLIC_URL}/gyfto-logo-110.png`}')`, "backgroundSize": "cover", "overflow": "hidden", "backgroundRepeat": "no-repeat" }}></div>
                        <div className="px-6 py-4">
                            <div className="font-bold text-xl mb-2" onClick={() => onClickUrl(item.url)}>
                                {item.name}
                            </div>
                            <div className='flex pb-4'>
                                <div className='pb-0 text-sm text-grey-dark'>From the list </div>
                                <div className='pl-1 pb-0 text-sm italic'>
                                    <a href={`/l/${listUrl}`} className="item-name underline text-gyfto-green" alt={listName}>
                                        {listName}
                                    </a>
                                </div>
                            </div>
                            <div className="text-grey-darker text-base">
                                <HideShowLongText displayText={item && item.description ? item.description : ''} lengthToHide={30} />
                            </div>
                        </div>
                        <div className="flex flex-wrap p-4 max-w-xs justify-between">
                            <div className="item-prop-pill">
                                <span className="item-prop">AMOUNT</span>
                                <span className="item-prop-value">{item.amount}</span>
                            </div>
                            <div className="item-prop-pill">
                                <span className="item-prop">CREATED</span>
                                <span className="item-prop-value">{itemDate}</span>
                            </div>
                        </div>
                    </div>
                </div>
                {/* LARGE SCREENS */}
                <div className="xs:hidden md:flex max-w-full w-full">
                    <div className="h-auto w-48 flex-none bg-cover rounded-t rounded-l border-l border-b border-t border-grey-light bg-white text-center overflow-hidden cursor-pointer" onClick={() => onClickUrl(item.url)} style={{ "backgroundImage": `url('${item.imageUrl ? item.imageUrl : `${process.env.REACT_APP_PUBLIC_URL}/gyfto-logo-110.png`}')` }} />
                    <div className="min-w-gyfto-card-item-content-min-w max-w-gyfto-card-item-content-max-w border-r border-b border-t border-grey-light bg-white rounded-b rounded-r p-4 flex flex-col justify-between leading-normal">
                        <div className="mb-2">
                            <div className="font-bold text-xl mb-2 cursor-pointer" onClick={() => onClickUrl(item.url)}>{item.name}</div>
                            <div className='flex pb-4'>
                                <div className='pb-0 text-sm text-grey-dark'>From the list </div>
                                <div className='pl-1 pb-0 text-sm italic'>
                                    <a href={`/l/${listUrl}`} className="item-name underline text-gyfto-green" alt={listName}>
                                        {listName}
                                    </a>
                                </div>
                            </div>
                            <div className="text-grey-darker text-base">
                                <HideShowLongText displayText={item && item.description ? item.description : ''} lengthToHide={60} />
                            </div>
                            <div className="flex flex-wrap mt-6">
                                <div className="item-prop-pill">
                                    <span className="item-prop">AMOUNT</span>
                                    <span className="item-prop-value">{item.amount}</span>
                                </div>
                                <div className="item-prop-pill ml-2">
                                    <span className="item-prop">CREATED</span>
                                    <span className="item-prop-value">{itemDate}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment >
    );
}
