import React, { Fragment } from 'react';
import ItemName from '../item/itemName';
import history from '../history/index';
export default function ListCardMinScroll({ breadCrumb }) {
  // eslint-disable-next-line

  const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;
  const COMPONENT_NAME = "list.card.min.scroll";

  if(displayConsole){
    console.log(`${COMPONENT_NAME} > breadCrumb: `,breadCrumb);
  }

  async function processClick(listId) {
    if(listId !== null){
      history.push(`/l/${listId}`)
    }
  }
  
  return (
    <Fragment>
      
        {breadCrumb && breadCrumb.listId ? (
        <div className=" cursor-pointer flex-no-grow flex-no-shrink bg-white items-center rounded shadow-md w-gyfto-breadcrumbs-card" key={breadCrumb.listId} style={{ height: '40px', maxHeight: '40px' }} onClick={async () => { await processClick(breadCrumb.listId) }}>
          <div className="flex justify-start items-center px-2 py-1">
            <div >
              {breadCrumb.listHeaderImg ? (
                <div className="h-8 w-8 rounded-full mx-auto bg-grey-lighter" style={{ "backgroundImage": `url('${breadCrumb.listHeaderImg}')`, "backgroundSize": "contain", "overflow": "hidden", "backgroundRepeat": "no-repeat", "backgroundPosition": "center" }} />
              ) : (
                null
              )
              }
            </div>
            <div className="pl-3">
              <div className="font-bold text-xl mb-2 bg-white">
                <ItemName url={`l/${breadCrumb.listId}`} localRoute name={breadCrumb.listName} xsMinWidth={false} />
              </div>
            </div>
          </div>
        </div>
        ) : (
          <span>Expected list but didn't get one</span>
        )}
      
    </Fragment>
  );
}
