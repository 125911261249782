import React, { Fragment, useState, useEffect } from 'react';
import ListCard from '../list/list.card';
import ListCardNew from '../list/list.card.new';
import ListCardMore from '../list/list.card.more';
import ListCardCombo from '../list/list.card.combo';
import CarouselButton from '../common/buttons/carouselButton'
import ListEmpty from '../list/list.empty';
import ShareEmpty from '../shares/share.empty';
import Carousel from 'nuka-carousel';
import history from '../history/index';
const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;
/* eslint-disable */

export default function AllLists({ appState, userId, listUserType, showNewCard, showMoreCard, containerData, maxDisplayNumber }) {
  const [hidePrev, setHidePrev] = useState(true);
  const [hideNext, setHideNext] = useState(false);
  const [compLoaded, setCompLoaded] = useState(false);
  const [listType, setListType] = useState(''); // Owner, Editor or SharedWith
  const returnItems = [];
  const returnItemsCarousel = [];
  let moreItemsToShow = false;
  const funcName = 'allLists';

  // THIS WILL FIRE ONCE ON COMPONENT MOUNT AND ONLY CHANGE IF THE listUserType DOES
  useEffect(() => {
    async function doUseEffectStuff() {
      if (displayConsole) {
        console.log(`${funcName} > useEffect > Props: listUserType: `, listUserType);
      }

      // SORT OUT listUserType PROP
      if (listUserType && Array.isArray(listUserType) && listUserType.length > 0) {
        if (listUserType.length === 1 && listUserType[0] === 'SharedWith') {
          setListType('SharedWith');
        } else {
          setListType('Owner');
        }
      }

      // FINALLY, MARK THIS AS LOADED
      setCompLoaded(true);
    }
    doUseEffectStuff();
  }, []);


  // PUSH Lists INTO ANY ARRAY FOR EASIER USE IN THE RENDER BELOW - NOTE: WE CAN'T
  // SLICE ITEMS OUT HERE BECAUSE WE NEED TO KNOW IF WE SHOULD HAVE THE 'SHOW MORE' CARD OR NOT
  if (containerData && Array.isArray(containerData) && containerData.length > 0) {
    containerData.map((listUser, index) => {
      if (listUser && listUser.list && !listUser.list.deletedState ) {
        // returnItems IS THE ARRAY OF COMPONENTS FOR THE LARGE SCREENS
        returnItems.push(
          listUser.list
        );

        // IF WE EXCEED THE NUMBER OF ITEMS TO DISPLAY FOR THIS SCREEN - LET US KNOW
        moreItemsToShow = (index + 1 > maxDisplayNumber) ? true : false;

        // returnItemsCarousel IS THE ARRAY OF COMPONENTS FOR THE SMALL SCREENS
        returnItemsCarousel.push(<ListCard list={listUser.list} showActions={listUserType !== 'SharedWith'} key={listUser.list.id} />)
      }
    });

    // ADD THE 'CREATE NEW LIST' CARD FOR THE SMALLER/CAROUSEL SCREENS
    if (listType === 'Owner' && showNewCard === true) {
      returnItemsCarousel.push(
        <div key={Date.now.toString()} onClick={() => appState ? appState.showHide('list', 'create') : null}>
          <ListCardNew key={Date.now.toString()} />
        </div>
      );
    }

    // LASTLY - ADD THE 'CREATE NEW LIST' CARD FOR THE SMALLER/CAROUSEL SCREENS
    if (maxDisplayNumber && !Number.isNaN(maxDisplayNumber) && showMoreCard && returnItemsCarousel.length > maxDisplayNumber) {
      returnItemsCarousel.push(
        <div key={`morecard|${Date.now.toString()}`} onClick={() => history.push(`/${listType !== 'SharedWith' ? 'ls' : 'sh'}`)}>
          <ListCardMore key={`morecard|${Date.now.toString()}`} />
        </div>
      );
    }


    if (displayConsole) {
      console.log(`${funcName} > Here are the lists for user '${userId}' & listUserType '${listUserType}':`, returnItems)
    }
  } else {
    if (displayConsole) {
      console.log(`${funcName} > this data object looks weird: `, containerData);
    }
  }

  // ALL THE SLIDE STATE MANAGEMENT STUFF

  // let hidePrev, hideNext = false;
  const manageArrowState = slideIdx => {
    if (displayConsole) {
      console.log(`allLists > slide changed to ${slideIdx} out of ${returnItemsCarousel.length} slides`);
    }
    //HIDE / SHOW THE PREV BUTTON
    if (slideIdx > 0) {
      setHidePrev(false);
    } else {
      setHidePrev(true);
    }

    // HIDE / SHOW THE NEXT BUTTON
    if (slideIdx === (returnItemsCarousel.length - 1)) {
      setHideNext(true);
    } else {
      setHideNext(false);
    }
  };
  if (displayConsole) {
    console.log(`maxDisplayNumber: ${maxDisplayNumber} > showNewCard: ${showNewCard} > showMoreCard: ${showNewCard} > moreItemsToShow : ${moreItemsToShow}`);
  }
  return (
    <Fragment>
      <div>
        <div className="xs:hidden md:flex md:flex-wrap">
          {/* 
            FOR BIG SCREENS - FIRST SORT THE LIST BY ANY 'PINNED' ITEMS, THEN BY THE LAST TIME THE USER UPDATED THE LIST, THEN ITERATE ACROSS IT
            NOTE: THE SORTING IS DONE WITHIN THE GRAPHQL QUERY
          */}

          {/* FIGURE OUT IF WE SHOULD SHOW THE CTA HERE OR DISPLAY LISTS */}
          {compLoaded && returnItems && Array.isArray(returnItems) && returnItems.length < 1 ? (
            <div className='w-full flex justify-center'>
              {listType === 'SharedWith' ? (<ShareEmpty />) : (
                <ListEmpty appState={appState} />
              )}
            </div>

          ) : null}

          {/* OTHERWISE - MAP OUT THE ARRAY OF LISTS */}
          {returnItems.slice(0, maxDisplayNumber ? maxDisplayNumber : 1000).map((list, i) => {
            return (<div className="px-4 py-4" key={list.id}>
              <ListCard list={list} showActions={listType !== 'SharedWith'} />
            </div>)

          })}
          {/* THE NEW LIST CARD FOR LARGE SCREEN (NON-CAROUSEL) 
              NOTE: WE MAY DO A COMBO NEW/MORE CARD (BELOW) WHICH IS WHY WE CHECK FOR showMoreCard
          */}
          {listType === 'Owner' && showNewCard && !moreItemsToShow && returnItems && Array.isArray(returnItems) && returnItems.length > 0 ?
            <div className="px-4 py-4" onClick={() => appState ? appState.showHide('list', 'create') : null}>
              <ListCardNew key={`newcard|${Date.now.toString()}`} />
            </div>
            :
            <div />
          }
          {/* THE SHORE MORE LIST CARD FOR LARGE SCREEN (NON-CAROUSEL) IF APPROPRIATE */}
          {maxDisplayNumber && !Number.isNaN(maxDisplayNumber) && showMoreCard && returnItems.length > maxDisplayNumber ?
            <Fragment>
              {showNewCard ? (
                // COMBO NEW / MORE STACK GOES IN HERE
                <div className="px-4 py-4">
                  <ListCardCombo key={`combocard|${Date.now.toString()}`} />
                </div>
              ) :
                <div className="px-4 py-4" onClick={() => history.push(`/${listType === 'Owner' ? 'ls' : 'sh'}`)}>
                  <ListCardMore key={`morecard|${Date.now.toString()}`} />
                </div>
              }
            </Fragment>

            :
            <div />
          }

        </div>
        <div className="xs:flex items-center align-middle md:hidden outline-none">

          {/* THIS NEXT SECTION IS ONLY VISIBLE WHEN THE USER DOESN'T HAVE ANY LISTS */}
          <div className='w-full flex justify-center pb-6'>
            {compLoaded && returnItems && Array.isArray(returnItems) && returnItems.length < 1 ? (
              <Fragment>
                {listType === 'SharedWith' ? (<ShareEmpty />) : (
                  <ListEmpty appState={appState} />
                )}
              </Fragment>
            ) : (
              <div className="card-container pl-1 outline-none">
                <Carousel
                  autoplay={false}
                  afterSlide={slideIdx => manageArrowState(slideIdx)}
                  cellAlign="center"
                  cellSpacing={10}
                  framePadding="20px"
                  height={`360px`}
                  width={'370px'}
                  renderBottomCenterControls={null}
                  slideIndex={0}
                  slideListMargin={0}
                  slidesToScroll={1}
                  slidesToShow={1}
                  transitionMode="scroll"
                  withoutControls={returnItemsCarousel && returnItemsCarousel.length < 2}
                  wrapAround={false}
                  zoomScale={Number(0.5 || 0)}
                  renderCenterLeftControls={({ previousSlide }) => (
                    <div className="outline-none" hidden={hidePrev}><CarouselButton direction="L" handleClickEvent={previousSlide} /></div>
                  )}
                  renderCenterRightControls={({ nextSlide }) => (
                    <div className="pr-1 outline-none" hidden={hideNext}><CarouselButton direction="R" handleClickEvent={nextSlide} /></div>
                  )}
                >
                  {returnItemsCarousel}
                </Carousel>
              </div>
            )}
          </div>

          {/* FOR SMALL SCREENS - FIRST SORT THE LIST BY THE LAST TIME THE USER UPDATED THE LIST, THEN ITERATE ACROSS IT */}

        </div>
      </div>
    </Fragment>
  );
}
/* eslint-enable */
