import React from 'react';
import { Subscribe } from 'unstated';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Drawer } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import DrawerContainer from '../../containers/drawer';
import DrawerItem from './drawerItem';
const drawerWidth = 375;
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#F3F5F7",
    overscrollBehaviorY: "none"
  },
}));

export default function DrawerPanel() {
  const classes = useStyles();

  return (
    <Subscribe to={[DrawerContainer]}>
      {drawerContainer => (
        <div className="flex flex-col">
          <Drawer
            classes={{
              root: classes.root,
              paper: classes.drawerPaper
            }}
            open={drawerContainer && drawerContainer.state.show ? true : false}
            anchor={"right"}
            onClose={open => drawerContainer.drawerChange(open)}
          >
            <div className="flex flex-col flex-no-wrap justify-start items-stretch h-full" >
              {/* STICKY HEADER AT THE TOP OF THE DRAWER */}
              <div className="sticky" style={{ top: '0px', zIndex: 800 }}>
                <div className="flex justify-between items-center pl-3 align-middle bg-gyfto-green min-h-gyfto-drawer-header">
                  <div className="drawer-header-text ">
                    {drawerContainer.state.drawerTitle ? drawerContainer.state.drawerTitle : drawerContainer.state.drawerAction + ' ' + drawerContainer.state.drawerType}
                  </div>
                  <div className="p-3 flex items-center hover:bg-gyfto-green-darker rounded-full">
                    <FontAwesomeIcon
                      size="lg"
                      className="cursor-pointer text-white"
                      icon="times"
                      onClick={drawerContainer.closeDrawer}
                    />
                  </div>
                </div>
              </div>
              <div className="flex-grow bg-gyfto-card-bg" style={{overflowX: 'hidden'}}>
                <DrawerItem
                  action={drawerContainer.state.drawerAction}
                  type={drawerContainer.state.drawerType}
                  itemId={drawerContainer.state.itemId}
                />
              </div>
              {/* 06/12/21 - Taking this out to fix vertical scrolling issues in the drawer */}
              {/* <div className="flex justify-between align-middle bg-gyfto-green py-2" /> */}
            </div>
          </Drawer>
        </div>
      )}
    </Subscribe>
  );
}
// }
