/* eslint-disable */
// CONSTANTS
const logToConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;
let userToken = localStorage.getItem('id_token');
let userId = localStorage.getItem(`user_id`);

// LOCAL FUNCTIONS
/**
 * THIS WILL PAUSE EXECUTION FOR THE AMOUNT OF TIME PROVIDED
 * @param {int} milliseconds 
 */
function _sleep(milliseconds) {
  const date = Date.now();
  let currentDate = null;
  do {
    currentDate = Date.now();
  } while (currentDate - date < milliseconds);
}

/**
 * INTERNAL FUNCTION SUPPORTING THE fnGetColorValue FUNCTION
 * @param {string} value 
 */
const _stringAsciiCodeSum = (value) => {
  return [...value].map(letter => letter.charCodeAt(0)).reduce((current, previous) => previous + current);
}

// EXPORTABLE FUNCTIONS
/**
 * THIS WILL CONVERT FROM UTC TO DD/MM/YYYY
 * @param {ISO-8601 UTC date} inputUtcDate 
 */
export async function fnConvertUtcToLocalDate(inputUtcDate) {
  let rcDate = null;

  if (inputUtcDate) {
    var newDate = new Date(inputUtcDate);
    rcDate = newDate.toLocaleDateString();
  }

  return rcDate;
}

/**
 * THIS FUNCTION WILL RETRIEVE THE id_token THAT IS STORED AS A COOKIE
 */
export async function fnGetUserToken() {
  if (!userToken) {
    _sleep(500);
    userToken = localStorage.getItem('id_token');
  }

  return userToken;
}

/**
 * TAKES IN AN OBJECT AND CHECKS TO SEE IF IT IS AN ARRAY
 * @param {Object} inputObj 
 * @returns bool
 */
export async function fnDoesObjectHaveItems(inputObj) {
  let rc = false;

  if (inputObj && Array.isArray(inputObj) && inputObj.length > 0) {
    rc = true;
  }

  return rc;
}

/**
 * THIS FUNCTION WILL RETRIEVE THE user_id THAT IS STORED AS A COOKIE
 */
export async function fnGetUserIdFromStorage() {
  if (!userId) {
    _sleep(500);
    userId = localStorage.getItem('user_id');
  }

  return userId;

}

/**
 * GETS THE DETAILS OF A USER
 * @param {string} userId 
 */
export async function fnGetUserDetails(userId) {
  let responseObj = null;
  if (userId) {
    const apiUrl = process.env.REACT_APP_WEB_FUNCTION_BASE_URL;
    const apiCallUrl = `${apiUrl}/gyfto_user_get`;

    if (logToConsole) {
      console.log(`fnGetUserDetails > userId: ${userId}`);
    }

    const postBody = {
      "userId": `${userId}`
    };

    await fetch(apiCallUrl, {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
        , 'Authorization': `Bearer ${userToken}`
      },
      body: JSON.stringify(postBody),
    })
      .then(res => res.json())
      .then(
        result => {
          if (result) {
            responseObj = result;
            if (logToConsole) {
              console.log(`fnGetUserDetails > result:`);
              console.dir(result);
            }
          }
        },
        error => {
          if (logToConsole) {
            console.error(`Error attempting to scrape url: ${inputUrl}`);
            console.dir(error);
          }
        }
      );
  } else if (logToConsole) {
    console.log(`fnGetUserDetails > Cannot validate missing URL`);
  }
  return responseObj;
}

/**
 * CALLS OUT TO THE SERVICE TO GET THE LIST FOR UNAUTH USERS
 * @param {string} listId 
 */
export async function fnGetListDetailAndItems(listId) {
  let responseObj = null;
  if (listId) {
    const apiUrl = process.env.REACT_APP_WEB_FUNCTION_BASE_URL_NO_AUTH;
    const apiCallUrl = `${apiUrl}/gyfto_list_get?listId=${listId}`;

    if (logToConsole) {
      console.log(`fnGetListDetailAndItems > listId: ${listId}`);
      console.log(`fnGetListDetailAndItems > apiCallUrl: ${apiCallUrl}`);
    }

    await fetch(apiCallUrl, {
      mode: 'cors',
      method: 'GET',
    })
      .then(res => res.json())
      .then(result => {
        if (result) {
          responseObj = result;
          if (logToConsole) {
            console.log(`fnGetListDetailAndItems > result:`, result);
          }
        }
      })
      .catch((error) => {
        if (logToConsole) {
          console.error(`fnGetListDetailAndItems > Error attempting to get list: ${listId} with error: ${error.message}`);

          throw new Error(error.message);
        }
      });
  } else if (logToConsole) {
    console.log(`fnGetListDetailAndItems > Cannot find list: missing 'listId'`);
  }
  return responseObj;
}

/**
 * GETS A LIST HEADER IMAGE (FIRST ITEM'S IMAGE)
 * @param {object} list 
 */
export async function fnGetListHeaderImg(list) {
  if (logToConsole) {
    console.log(`fnGetListHeaderImg > list: `, list);
  }

  let listHeaderImg = `${process.env.REACT_APP_PUBLIC_URL}/gyfto-logo-110.png`;
  if (list) {
    // GET THE LIST ITEMS
    if (list && list.listItems && Array.isArray(list.listItems) && list.listItems.length > 0) {
      // THIS WILL GIVE US AN ARRAY OF THE FIRST (BY ordinal) ITEM WITH AN IMAGE (IF IT EXISTS)
      let firstImage = list.listItems.filter(li => li.item.obtained === false).sort((a, b) =>
        a.item.ordinal > b.item.ordinal ? 1 : b.item.ordinal > a.item.ordinal ? -1 : 0
      );
      // MAKE SURE THAT OUR list HAS listItems THAT AREN'T OBTAINED AND HAVE AN imageUrl
      if (firstImage && Array.isArray(firstImage) && firstImage.length > 0 && firstImage[0].item && firstImage[0].item.imageUrl) {
        listHeaderImg = firstImage[0].item.imageUrl;
      }
    }
  }

  // RETURN THE LIST HEADER IMAGE
  return listHeaderImg;
}

/**
 * CREATES AN ITEM AND ASSOCIATES IT TO A LIST
 * @param {string} listId 
 * @param {object} itemObj 
 */
export async function fnItemCreate(listId, itemObj) {
  if (logToConsole) {
    console.log(`in fnItemCreate with listId: ${listId} and itemObj:`);
    console.dir(itemObj);
  }
  let responseObj = null;
  if (listId && itemObj) {
    const apiUrl = process.env.REACT_APP_WEB_FUNCTION_BASE_URL;
    const apiCallUrl = `${apiUrl}gyfto-item-create?listId=${listId}`;

    await fetch(apiCallUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
        , 'Authorization': `Bearer ${userToken}`
      },
      body: JSON.stringify(itemObj),
    })
      .then(res => res.json())
      .then(
        result => {
          if (logToConsole) {
            console.log(`fnItemCreate > Got this from calling ${apiCallUrl}`);
            console.dir(result);
          }
          if (result) {
            responseObj = result;
          } else if (logToConsole) {
            console.log(`fnItemCreate > Issue with the response in the item create call`);
          }
        },
        error => {
          if (logToConsole) {
            console.log(`Error creating Item:`);
            console.dir(error);
          }
        }
      );
  }
  return responseObj;
}

/**
 * SCRAPES THE URL FOR ITEM PROPERTIES
 * @param {string} inputUrl 
 */
export async function fnGetItemDetailsFromUrl(inputUrl) {
  let responseObj = null;
  if (inputUrl) {
    const apiUrl = process.env.REACT_APP_WEB_FUNCTION_BASE_URL;
    const apiCallUrl = `${apiUrl}/gyfto_get_url_details`;

    if (logToConsole) {
      console.log(`fnGetItemDetailsFromUrl > inputUrl: ${inputUrl}`);
    }

    const postBody = {
      "url": `${inputUrl}`
    };

    const bearerToken = localStorage.getItem('id_token');
    await fetch(apiCallUrl, {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
        , 'Authorization': `Bearer ${bearerToken}`
      },
      body: JSON.stringify(postBody),
    })
      .then(res => res.json())
      .then(result => {
        if (result) {
          if (logToConsole) {
            console.log(`fnGetItemDetailsFromUrl > result:`);
            console.dir(result);
          }

          if(result.returnData){
            responseObj = result;
          } else {
            console.error(`fnGetItemDetailsFromUrl > Error attempting to scrape url: ${inputUrl}`);
            console.dir(result);
          }
          
        }
      })
      .catch((error) => {
        if (logToConsole) {
          console.error(`fnGetItemDetailsFromUrl > Error attempting to scrape url: ${inputUrl} with error: ${error.message}`);

          throw new Error(error.message);
        }
      });
  } else if (logToConsole) {
    console.log(`fnGetItemDetailsFromUrl > Cannot validate missing URL`);
  }
  return responseObj;
}

/**
 * REORDERS AN ITEM IN A PARTICULAR LIST BASED UPON INDEX OF THE ITEM
 * @param {string} listId 
 * @param {string} oldIndex 
 * @param {string} newIndex 
 */
export async function fnReorderListItem(listId, oldIndex, newIndex) {
  let responseObj = null;

  if (listId && Number.isSafeInteger(oldIndex) && Number.isSafeInteger(newIndex)) {
    const apiUrl = process.env.REACT_APP_WEB_FUNCTION_BASE_URL;
    const apiCallUrl = `${apiUrl}/gyfto_list_items_reorder`;

    if (logToConsole) {
      console.log(`fnReorderListItem > listId: "${listId}" | oldIndex: "${oldIndex}" | newIndex: "${newIndex}"`);
    }

    const postBody = {
      "listId": `${listId}`,
      "oldIndex": `${oldIndex}`,
      "newIndex": `${newIndex}`
    };

    if (logToConsole) {
      console.log(`fnReorderListItem > postBody: ${JSON.stringify(postBody)}`);
    }

    const bearerToken = localStorage.getItem('id_token');
    await fetch(apiCallUrl, {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
        , 'Authorization': `Bearer ${bearerToken}`
      },
      body: JSON.stringify(postBody),
    })
      .then(res => res.json())
      .then(
        result => {
          if (result) {
            responseObj = result;
            if (logToConsole) {
              console.log(`fnReorderListItem > result:`);
              console.dir(result);
            }
          }
        },
        error => {
          if (logToConsole) {
            console.error(`Error attempting to re-order item from the list: ${listId}`);
            console.dir(error);
          }
        }
      );
  } else if (logToConsole) {
    console.log(`fnReorderListItem > Missing one of these values - listId: "${listId}" | oldIndex: ${oldIndex} | newIndex: ${newIndex}`);
  }

  // UPDATE LAST TOUCH ON THE LIST
  try {
    const msgPosted = fnGyftoEventPipeline('Item', 'Reorder', listId);
  } catch (error) {
    if (logToConsole) {
      console.log(`fnReorderListItem > Error calling out to fnGyftoEventPipeline for listId: "${listId}" | MSG: ${error}`);
    }
  }
  return responseObj;
}

/**
 * UPDATES THE USER LAST TOUCH VALUE OF A LIST FOR ORDERING 
 * PURPOSES ON THE DASHBOARD
 * @param {string} listId 
 */
async function fnUpdateListLastTouch(listId) {
  let responseObj = null;

  if (listId) {
    const apiUrl = process.env.REACT_APP_WEB_FUNCTION_BASE_URL;
    const apiCallUrl = `${apiUrl}/gyfto_list_update_last_touch`;

    if (logToConsole) {
      console.log(`fnUpdateListLastTouch > listId: "${listId}"`);
    }

    const postBody = {
      "listId": `${listId}`,
      "updatedDateTime": `${new Date().toISOString()}`
    };

    if (logToConsole) {
      console.log(`fnUpdateListLastTouch > postBody: ${JSON.stringify(postBody)}`);
      // console.dir();
    }

    await fetch(apiCallUrl, {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userToken}`
      },
      body: JSON.stringify(postBody),
    })
      .then(res => res.json())
      .then(
        result => {
          if (result) {
            responseObj = result;
            if (logToConsole) {
              console.log(`fnUpdateListLastTouch > result:`);
              console.dir(result);
            }
          }
        },
        error => {
          if (logToConsole) {
            console.error(`fnUpdateListLastTouch > Error attempting update last touch for List: ${listId}`);
            console.dir(error);
          }
        }
      );
  } else if (logToConsole) {
    console.log(`fnUpdateListLastTouch > Missing listId: "${listId}"`);
  }
  return responseObj;
}

/**
 * GETS THE CURRENT NUMBER OF NON-DELETED
 * ITEMS IN A LIST
 * @param {string} listId 
 */
export async function fnGetItemsCountFromList(listId) {
  let responseObj = 99;

  if (listId) {
    const apiUrl = process.env.REACT_APP_WEB_FUNCTION_BASE_URL;
    const apiCallUrl = `${apiUrl}/gyfto_list_items_get_count`;

    if (logToConsole) {
      console.log(`fnGetItemsCountFromList > listId: "${listId}"`);
    }

    const postBody = {
      "listId": `${listId}`
    };

    const bearerToken = localStorage.getItem('id_token');
    await fetch(apiCallUrl, {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
        , 'Authorization': `Bearer ${bearerToken}`
      },
      body: JSON.stringify(postBody),
    })
      .then(res => res.json())
      .then(
        result => {
          if (result) {
            // responseObj = result;
            if (logToConsole) {
              console.log(`fnGetItemsCountFromList > result:`);
              console.dir(result);
            }

            if (result.List && result.List.listId === listId) {
              responseObj = result.List.listItemsCount;
            }
          } else {

          }
        },
        error => {
          if (logToConsole) {
            console.error(`fnGetItemsCountFromList > Error attempting to get number of Items from List: ${listId}`);
            console.dir(error);
          }
        }
      );
  } else if (logToConsole) {
    console.log(`fnGetItemsCountFromList > Missing listId: "${listId}"`);
  }
  return responseObj;
}

/**
 * THIS FUNCTION IS THE GATEWAY TO PUTTING MESSAGES ON THE GYFTO EVENT PIPELINE 
 * (USED FOR NON-BLOCKING/ASYNC EVENTS)
 * @param {string} itemType - can be 'List', 'Item' or 'Share'
 * @param {string} itemAction - can be 'Created', 'Deleted', 'Updated', 'Reorder', 'Copied'
 * @param {string} itemId - represents the ID of the itemType (ie List, Item or Share)
 */
export async function fnGyftoEventPipeline(itemType, itemAction, itemId, actionMessage) {
  let msgPosted = false;
  let msgActionType, msgAction, msgActionMessage = null;

  if (!itemType || !itemAction) {
    if (logToConsole) {
      console.log(`fnGyftoEventPipeline > Missing itemType: "${itemType}" OR itemAction: '${itemAction}' OR itemId: '${itemId}'`);
    }
    return msgPosted;
  }

  if (logToConsole) {
    console.log(`fnGyftoEventPipeline > Received message to post to pipeline: itemType: "${itemType}" - itemAction: '${itemAction}' - itemId: '${itemId}' - actionMessage: `, actionMessage);
  }

  if (itemType === 'Item') {
    switch (itemAction) {
      case 'Reorder':
        // MAKE SURE LIST LAST TOUCH IS UPDATED
        // msgPosted = await fnUpdateListLastTouch(itemId);
        // if (msgPosted) {
        //   msgPosted = true;
        // }
        break;

      case 'Created':
        // MAKE SURE LIST LAST TOUCH IS UPDATED
        // msgPosted = await fnUpdateListLastTouch(itemId);
        // if (msgPosted) {
        //   msgPosted = true;
        // }
        break;

      case 'Deleted':
        // MAKE SURE LIST LAST TOUCH IS UPDATED
        // msgPosted = await fnUpdateListLastTouch(itemId);
        // if (msgPosted) {
        //   msgPosted = true;
        // }
        break;

      case 'Copied':
        // MAKE SURE LIST LAST TOUCH IS UPDATED
        // msgPosted = await fnUpdateListLastTouch(itemId);
        // if (msgPosted) {
        //   msgPosted = true;
        // }
        break;
      case 'Updated':
        // MAKE SURE LIST LAST TOUCH IS UPDATED
        // msgPosted = await fnUpdateListLastTouch(itemId);
        // if (msgPosted) {
        //   msgPosted = true;
        // }
        break;
      default:
        if (logToConsole) {
          console.log(`fnGyftoEventPipeline > Received message to post to pipeline but couldn't find a matching itemAction for itemType: "${itemType}"`);
        }
        break;
    }

  } else if (itemType === 'List') {
    switch (itemAction) {
      case 'Updated':
        // MAKE SURE LIST LAST TOUCH IS UPDATED
        // msgPosted = await fnUpdateListLastTouch(itemId);
        // if (msgPosted) {
        //   msgPosted = true;
        // }
        break;
      default:
        if (logToConsole) {
          console.log(`fnGyftoEventPipeline > Received message to post to pipeline but couldn't find a matching itemAction for itemType: "${itemType}"`);
        }
        break;
    }

  } else if (itemType === 'Share') {
    switch (itemAction) {
      case 'Create':
        msgAction = 'create';
        msgActionType = 'share';
        msgActionMessage = actionMessage;
        break;

      default:
        break;
    }
  } else {
    if (logToConsole) {
      console.log(`fnGyftoEventPipeline > Received message to post to pipeline but couldn't find a matching itemType: "${itemType}"`);
    }
  }

  // NEXT, POST TO THE EVENTS INTAKE SERVICE
  const eventsIntakeBody = {
    "actionType": `${msgActionType}`,
    "action": `${msgAction}`,
    "userId": `${userId}`,
    "actionMessage": msgActionMessage
  }

  const apiUrl = process.env.REACT_APP_WEB_FUNCTION_BASE_URL;
  const apiCallUrl = `${apiUrl}/gyfto_events_intake`;
  const bearerToken = localStorage.getItem('id_token');

  if (logToConsole) {
    console.log(`fnGyftoEventPipeline > About to post to event queue (${apiCallUrl}) itemType: "${itemType}" || itemAction: "${itemAction}"`, eventsIntakeBody);
  }

  /**
     * RESPONSE WILL LOOK LIKE THIS:
     * {
        "eventReceived": true,
        "responseMessage": {
          "postedMsgIds": [
            {
              "postedMessageId": "1713085942060164"
              ,"postedMessageId": "1713085942060165"
            }
          ]
        }
      }
     */
  await fetch(apiCallUrl, {
    mode: 'cors',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
      , 'Authorization': `Bearer ${bearerToken}`
    },
    body: JSON.stringify(eventsIntakeBody),
  })
    .then(res => res.json())
    .then(result => {
      if (result && result.eventReceived) {
        msgPosted = result.eventReceived;
        if (logToConsole) {
          console.log(`fnGyftoEventPipeline > result:`);
          console.dir(result);
        }
      } else {
        if (logToConsole) {
          console.error(`fnGyftoEventPipeline > Attempting to post to message queue but got malformed response: `, result);
        }
      }
    },
      error => {
        if (logToConsole) {
          console.error(`fnGyftoEventPipeline > Error attempting to post message to the queue! `, error);
        }
      }
    ).catch((e) => {
      if (logToConsole) {
        console.error(`fnGyftoEventPipeline > Error attempting to post message to the queue! `, e);
      }
    });

  return msgPosted;
}

/**
 * THIS FUNCTION WILL TAKE AN UPLOADED PICTURE AND STORE IT IN OUR DAM SaaS OFFERING
 * TO BE THEN USED AND PUSHED INTO THE GYFTO DATA STORE
 * @param {file} file
 */
export async function fnUploadItemImage(file) {
  let responseObj = null;
  if (file) {
    const cloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
    const unsignedUploadPreset = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET;
    const apiCallUrl = `https://api.cloudinary.com/v1_1/${cloudName}/upload`;

    if (logToConsole) {
      console.log(`fnUploadItemImage > input file: ${file}`);
    }

    // FORM DATA FOR THE SUBMIT
    const fd = new FormData();
    fd.append("upload_preset", unsignedUploadPreset);
    fd.append("tags", "browser_upload"); // Optional - add tag for image admin in Cloudinary
    fd.append("file", file);

    await fetch(apiCallUrl, {
      mode: 'cors',
      method: 'POST',
      body: fd,
    })
      .then(res => res.json())
      .then(
        result => {
          if (result) {
            responseObj = result;
            if (logToConsole) {
              console.log(`fnUploadItemImage > result:`);
              console.dir(result);
            }
          }
        },
        error => {
          if (logToConsole) {
            console.error(`fnUploadItemImage> Error attempting to upload item image: ${file}`);
            console.dir(error);
          }
        }
      );
  } else if (logToConsole) {
    console.log(`fnUploadItemImage > Cannot validate missing file`);
  }
  return responseObj;
}

/**
 * THIS FUNCTION WILL TAKE THE value AND RETURN A COLOR CODE BASED
 * UPON THE value
 * 
 * BASED UPON: https://github.com/Sitebase/react-avatar/blob/master/src/utils.js
 * @param {string} value 
 */
export async function fnGetColorValue(value) {

  const defaultColors = ['#d73d32', '#7e3794', '#4285f4', '#67ae3f', '#d61a7f', '#ff4080'];

  // if no value is passed, always return transparent color otherwise
  // a rerender would show a new color which would will
  // give strange effects when an interface is loading
  // and gets rerendered a few consequent times
  if (!value) return 'transparent';

  // value based random color index
  // the reason we don't just use a random number is to make sure that
  // a certain value will always get the same color assigned given
  // a fixed set of colors
  const sum = _stringAsciiCodeSum(value);

  const colorIndex = sum % defaultColors.length;

  if (logToConsole) {
    console.log(`fnGetColorValue > value: ${value} || colorIndex: ${colorIndex}`);
  }

  return defaultColors[colorIndex];
}

/**
 * THIS CALLS OUT THE EVENT PIPELINE TO CREATE A NEW INVITAITON
 * @param {object} invitationObj 
 {
   "listId":"afd0f139-b383-47db-9f22-b224d9daae66",
   "senderUserId":"fd8fad3a-e3c9-4065-8f77-b6d41e6e4a6a",
   "selectedUsers":[
      {
         "id":"unkown-1605306435092",
         "firstName":"",
         "lastName":"",
         "avatarUrl":"",
         "email":"kellitrent@gmail.com",
         "userType":"new"
      }
   ],
   "selectedItems":[
      {
         "id":"09674fb2-fc7d-41a1-bafb-31d11cb377f5",
         "itemId":46,
         "name":"ShakeWeight"
      },
      {
         "id":"5a13466e-789a-48a6-8f87-f6834d67e064",
         "itemId":14,
         "name":"Rolex Pepsi GMT-Master 16750"
      }
   ],
   "listEditorRights":false,
   "invitationMessage":"Some message goes here"
}
 */
export async function fnCreateInvitation(invitationObj) {
  let invitationCreated = null;
  if (invitationObj) {
    if (logToConsole) {
      console.log(`fnCreateInvitation > About to post to Gyfto message queue with invitationObj: `, invitationObj);
    }

    // PUT THIS ON THE PIPELINE
    await fnGyftoEventPipeline('Share', 'Create', null, invitationObj).then((rslt) => {
      if (logToConsole) {
        console.log(`fnCreateInvitation > Created invitation: `, rslt);
      }
      invitationCreated = rslt;
    }).catch((e) => {
      if (logToConsole) {
        console.error(`fnCreateInvitation > Issue creating Invitation on message queue!`, e);
      }
    });

  } else {
    if (logToConsole) {
      console.error(`fnCreateInvitation > Missing invitation object`);
    }
  }
  return invitationCreated;
}

/**
 * THIS FUNCTION WILL RETURN THE SEARCH HISTORY AS STORED IN localStorage
 */
export async function fnGetSearchHistory() {
  const prevSearchHistory = [];
  const prevSearchHistoryArr = localStorage.getItem('searchHistory');
  if (prevSearchHistoryArr && prevSearchHistoryArr.length) {
    for (const searchItm of JSON.parse(prevSearchHistoryArr)) {
      prevSearchHistory.push(searchItm);
    }
  }

  if (logToConsole) {
    console.log(`fnGetSearchHistory > Returning search history: `, prevSearchHistory);
  }

  return prevSearchHistory;
}

export async function fnSaveSearchHistory(searchTerm, searchTermId, listOwner) {
  if (logToConsole) {
    console.log(`fnSaveSearchHistory> Saving '${searchTerm}' to search history `);
  }
  if (searchTerm !== null && searchTerm !== '' && searchTermId) {
    // FIRST, GET THE HISTORY SO WE CAN SAVE IT BACK
    const prevSearchHistory = await fnGetSearchHistory();

    // MAKE SURE WE AREN'T ADDING DUPLICATE SEARCH ENTRIES
    let canAdd = true;
    for (const itm of prevSearchHistory) {
      if (itm && itm.searchTermId && itm.searchTermId !== '') {
        if (itm.searchTermId === searchTermId) {
          canAdd = false;
        }
      }
    }

    // NEXT, PUSH THE NEW TERM INTO THE HISTORY
    if (canAdd) {
      prevSearchHistory.push({ searchTerm, searchTermId, timeStamp: Date.now().toString(), listOwner });
    }

    // FINALLY, SAVE SEARCH HISTORY
    try {
      localStorage.setItem('searchHistory', JSON.stringify(prevSearchHistory));
    } catch (error) {
      if (logToConsole) {
        console.log(`fnSaveSearchHistory> GYFTOERROR: Attempting to save '${searchTerm}' to search history `, error);
      }
    }

  }
}

/**
 * THIS METHOD WILL DETECT IF THE IMAGE LOADED FAILS (4XX) 
 * AND REPLACE IT WITH A DEFAULT IMAGE
 * @param {object} ev = the src of the image tab
 */
export async function fnSetDefaultImgSrcOnError(ev) {
  if (logToConsole) {
    console.log(`fnSetDefaultImgSrcOnError > ev input: `, ev);
  }
  if (ev) {
    ev.target.src = `${process.env.REACT_APP_PUBLIC_URL}/gyfto-logo-110.png`;
  }
}

/**
 * THIS METHOD WILL GET ALL THE BREADCRUBMS
 * @returns 
 */
export async function fnGetBreadCrumbs() {
  const prevBreadCrumbs = [];
  const prevBreadCrumbsArr = sessionStorage.getItem('breadCrumbs');
  if (prevBreadCrumbsArr && prevBreadCrumbsArr.length) {
    for (const searchItm of JSON.parse(prevBreadCrumbsArr)) {
      prevBreadCrumbs.push(searchItm);
    }
  }

  if (logToConsole) {
    console.log(`fnGetBreadCrumbs > Returning search history: `, prevBreadCrumbs);
  }

  return prevBreadCrumbs;
}

/**
 * THIS METHOD SAVE BREADCRUMBS ON EACH LIST VISIT
 * @param {list}
 */
export async function fnSaveBreadCrumb(list) {
  if (logToConsole) {
    console.log(`fnSaveBreadCrumb > list:`, list);
  }

  if (list && list.listId) {
    // FIRST, GET THE HISTORY SO WE CAN SAVE IT BACK
    const prevBreadCrumbs = await fnGetBreadCrumbs();

    // MAKE SURE WE AREN'T ADDING DUPLICATE BREAD CRUMBS && PUSH
    // INTO HISTORY IF POSSIBLE
    if (prevBreadCrumbs.filter(l => l.listId === list.id).length < 1) {
      try {
        const listId = list.id;
        let listName = list.listDetails && list.listDetails.name ? list.listDetails.name : null;
        let listHeaderImg = await fnGetListHeaderImg(list);
        prevBreadCrumbs.push({ listId, listName, listHeaderImg, timeStamp: Date.now().toString() });
      } catch (error) {
        if (logToConsole) {
          console.log(`fnSaveBreadCrumb > GYFTOERROR: can't save this list to bread crumbs: '${list.id}'`, error);
        }
      }

    }

    // FINALLY, SAVE BREAD CRUMBS

    try {
      sessionStorage.setItem('breadCrumbs', JSON.stringify(prevBreadCrumbs));
    } catch (error) {
      if (logToConsole) {
        console.log(`fnSaveBreadCrumb> GYFTOERROR: Attempting to save '${list.listId}' to bread crumbs `, error);
      }
    }

  }
}

/**
 * THIS METHOD WILL GET ROLE LIKE 'OWNER' OR 'EDITOR' FROM THE GIVEN LIST OBJECT
 * @param {list}
 * @param {currentUserId}
 * @returns 
 */
export async function fnGetUserRoleInList(list, currentUserId) {
  let currentUserRole = null;

  if (logToConsole) {
    console.log(`fnGetUserRoleInList > Searching for user ${currentUserId} in list `, list);
  }

  if (list && currentUserId) {
    if (list.listUsers && Array.isArray(list.listUsers) && list.listUsers.length > 0) {
      for (const listUser of list.listUsers) {
        if (listUser && listUser.user && listUser.user.id && listUser.user.id === currentUserId) {
          currentUserRole = listUser && listUser.listUserType ? listUser.listUserType : null;

          if (logToConsole) {
            console.log(`fnGetUserRoleInList > Found user ${currentUserId} and role '${currentUserRole}' `);
          }
        }
      }
    }
  } else {
    if (logToConsole) {
      console.log(`fnGetUserRoleInList > Missing user ${currentUserId} or list `, list);
    }
  }

  return currentUserRole;
}

/**
 * THIS FUNCTION WILL RETURN AN ARRAY OF item WITH itemWatches IN A LIST OF item FOR THE currentUserId (IF NOT NULL)
 * @param {array of items} itemList 
 * @param {string} currentUserId (nullable)
 * @param {boolean} filterOutObtained 
 * @returns array of Items
 */
export async function fnGetItemWatchFromItemList(itemList, currentUserId, filterOutObtained) {
  let currentUserWatchArr = [];
  let processItem = true;

  if (logToConsole) {
    console.log(`fnGetItemWatchFromItemList > Searching for user ${currentUserId} in item `, itemList);
  }

  if (itemList && Array.isArray(itemList) && itemList.length > 0) {
    for (const itm of itemList) {
      if (itm && itm.item) {
        // ONLY GET AN ITEM WATCH IF THE ITEM ISN'T APART OF DELETED LIST
        if (itm.item.sharedLists && Array.isArray(itm.item.sharedLists)) {
          for (const sharedList of itm.item.sharedLists) {
            if (sharedList && sharedList.list && sharedList.list.deletedState && sharedList.list.deletedState === true) {
              processItem = false;

              if (logToConsole) {
                console.log(`fnGetItemWatchFromItemList > Omitting this item b/c it has a shared list that is deleted `, sharedList);
              }
            }
          }
        }
        // IF THE user IS PASSED IN (currentUserId CAN BE null) - SEARCH FOR THIS USER
        if (processItem) {
          if (currentUserId) {
            if (await fnGetItemWatchFromItem(itm.item, currentUserId, filterOutObtained)) {
              currentUserWatchArr.push(itm);
            }
          } else {
            // WE JUST WANT TO RETURN ALL WATCHES SO GET ANY FOR THIS item
            if (itm.item.itemWatches && Array.isArray(itm.item.itemWatches) && itm.item.itemWatches.length > 0) {
              currentUserWatchArr.push(itm);
              if (logToConsole) {
                console.log(`fnGetItemWatchFromItemList > Getting any watches for this item `, itm);
              }
            }
          }
        }
      }
    }
  }

  if (logToConsole) {
    console.log(`fnGetItemWatchFromItemList > Found ${currentUserWatchArr.length} watches`, currentUserWatchArr);
  }

  return currentUserWatchArr;
}

/**
 * THIS FUNCTION WILL RETURN bool IF THE currentUserId HAS A itemWatch FOR THE GIVEN item
 * @param {Item} item 
 * @param {string} currentUserId 
 * @param {boolen} filterOutObtained 
 * @returns boolean
 */
export async function fnGetItemWatchFromItem(item, currentUserId, filterOutObtained) {
  let currentItemHasWatchForUser = false;

  if (logToConsole) {
    console.log(`fnGetItemWatchFromItem > Searching for user ${currentUserId} in item `, item);
  }

  if (item.itemWatches && Array.isArray(item.itemWatches) && item.itemWatches.length > 0) {
    // THERE ARE ITEM WATCHES - MAKE SURE WE ARE FILTERING ON THE ONES FOR THE CURRENT USER
    for (const itmWatch of item.itemWatches) {
      if (itmWatch && itmWatch.watchUsers && itmWatch.watchUsers.id && itmWatch.watchUsers.id === currentUserId) {
        //console.log(`WATCHED ITEM:`, itm)
        if (filterOutObtained) {
          if (!item.obtained) {
            currentItemHasWatchForUser = true;
          }
        } else {
          currentItemHasWatchForUser = true;
        }

      }
    }
  }

  if (logToConsole) {
    console.log(`fnGetItemWatchFromItem > Found watch for current user ${currentUserId} on item`, item);
  }

  return currentItemHasWatchForUser;
}

/**
 * THIS FUNCTION WILL RETURN AN ARRAY OF ALL user OBJECTS THAT ARE WATCHING AN item
 * @param {object} item 
 * @param {bool} filterOutObtained 
 * @returns array of user
 */
export async function fnGetItemUsersWatchFromItem(item, filterOutObtained) {
  let itemWatchUsers = [];
  const funcName = 'fnGetItemUsersWatchFromItem';

  if (logToConsole) {
    console.log(`${funcName} > Searching for user watch users in item `, item);
  }

  if (item.itemWatches && Array.isArray(item.itemWatches) && item.itemWatches.length > 0) {
    // THERE ARE ITEM WATCHES - MAKE SURE WE ARE FILTERING ON THE ONES FOR THE CURRENT USER
    for (const itmWatch of item.itemWatches) {
      if (logToConsole) {
        console.log(`${funcName} > item.watchUsers `, itmWatch.watchUsers);
      }
      if (filterOutObtained) {
        if (!item.obtained) {
          itemWatchUsers.push(itmWatch.watchUsers)
        }
      } else {
        itemWatchUsers.push(itmWatch.watchUsers)
      }
    }
  }

  if (logToConsole) {
    console.log(`${funcName} > returning itemWatchUsers `, itemWatchUsers);
  }
  return itemWatchUsers;
}

/**
 * THIS  FUNCTION WILL TAKE AN item AND FIND THE list FROM 'SharedLists' THAT IS ASSOCIATED TO AN 'OWNER'
 * @param {object} item 
 * @returns list object
 */
export async function fnGetOriginalListFromItem(item) {
  let rcList = null;
  const funcName = 'fnGetOriginalListFromItem';

  if (item) {
    // SEE IF THIS item HAS sharedLists
    const hasSharedLists = await fnDoesObjectHaveItems(item.sharedLists);

    if (hasSharedLists) {
      //TODO: ITERATE THROUGH ALL THE SHAREDLISTS TO FIND A LIST WITH AN OWNER
      for (const list of item.sharedLists) {
        if (list && list.List && list.List.listUsers) {
          // ITERATE THROUGH ALL THE listUser OBJECTS
          for (const listUser of list.List.listUsers) {
            if (listUser && listUser.listUserType && listUser.listUserType === 'Owner') {
              rcList = list.List;
              if (logToConsole) {
                console.log(`${funcName} > found the Owner list `, rcList);
              }
            }
          }
        }
      }
    } else {
      if (logToConsole) {
        console.log(`${funcName} > Couldn't find any 'sharedLists' in item `, item);
      }
    }
  }

  return rcList;
}

/* eslint-enable */
