import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import SVGBookmark from '../common/icons/bookmark-svg';
import JumpingDots from '../common/jumpingDots';

import { mutationTogglePin } from '../../graphql/graphql';

/* eslint-disable  */
export default function ListBookmark({ listPinState, currentUserId, listId }) {
  const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false; //listId === "658937f4-941c-4262-b3b9-49a1336e856d";
  const [toggleBookmark, { dataToggleBookmark }] = useMutation(mutationTogglePin);
  const [showDots, setShowDots] = useState(false);

  if (displayConsole) {
    console.log(`listBookmark > itemWatchId : ${listPinState}`);
    console.log(`listBookmark > currentUser : ${currentUserId}`);
    console.log(`listBookmark > listItem: `, listId);
  }

  const toggleTheBookmark = (e, toggleState) => {
    // PREVENT onClick FROM OTHER ITEMS IN THE DOM
    if (e) {
      e.preventDefault();
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
    }

    // REGISTER THAT SOMETHING HAPPENED
    setShowDots(true);

    // CALL THE MUTATION
    toggleBookmark({ variables: { listId: listId, pinned: toggleState } })
      .then(({ data }) => {
        if (data && data.update_List) {
          // UPDATE THE STATE OF THE ITEM WATCH
          if (displayConsole) {
            console.log(`listBookmark > toggleBookmark > data from mutation: `, data);
          }

        } else {
          console.error('listBookmark > toggleBookmark > There was an error with the result from the mutation: ', data);
        }

        // REGISTER THAT SOMETHING FINISHED
        setShowDots(false);
      })
      .catch(error => {
        console.error('listBookmark > toggleBookmark > There was an error sending the mutation: ', error);
      });
  };

  return (
    <div className="cursor-pointer h-10 w-10 rounded-full bg-white hover:bg-grey-lighter flx mx-auto" >
      <div className="p-2" onClick={(e) => toggleTheBookmark(e, !listPinState)}>
        {showDots ? (
          <JumpingDots />
        ) : (
            <SVGBookmark secondaryColor="#5FBA7D" primaryColor="#979dac" height="20px" fillState={listPinState} />
          )}
      </div>
    </div>
  );
}
/* eslint-enable */
ListBookmark.propTypes = {
  listPinState: PropTypes.bool,
  currentUserId: PropTypes.string,
  listId: PropTypes.string,
};

ListBookmark.defaultProps = {
  listPinState: false,
  currentUserId: null,
  listId: null
};