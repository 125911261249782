import React, { Fragment } from 'react';
import Lottie from 'lottie-react-web';
import animationData from './lotties/jumping_dots.json';

export default function JumpingDots(props) {
  return (
    <Fragment>
      <div className="flex flex-col justify-center mx-auto my-auto pt-2">
          <Lottie
            options={{
              animationData,
              loop: true,
            }}
            height={20}
            width={30}
          />
      </div>

    </Fragment>
  );
}
