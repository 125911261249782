import React from 'react';
import PropTypes from 'prop-types';
import { Subscribe } from 'unstated';
import DrawerContainer from '../../containers/drawer';
import SVGNew from '../common/icons/new-svg';

export default function NavBottom({ navType, list, auth }) {
    return (
        <Subscribe to={[DrawerContainer]}>
            {appState => (
                <div className="flex md:hidden z-50 absolute sticky pb-8" style={{ bottom: '0px' }}>
                    <div className="flex-none w-6"></div>
                    <div className="flex-1">
                        <div className="w-full rounded-full bg-gyfto-green flex shadow-md" style={{minHeight: '60px'}} onClick={() => appState.showHide('list', 'create')}>
                            <div className="w-full flex justify-center items-center p-3">
                                <div className=" flex items-center" >
                                    <div><SVGNew primaryColor="#ffffff" secondaryColor="#979dac" width="22px" height="22px" /></div>
                                    <div className="pl-4"><span className="text-white text-bold uppercase text-l">New list</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex-none w-6"></div>
                </div>
            )}
        </Subscribe>
    );
}

NavBottom.propTypes = {
    navType: PropTypes.string,
    list: PropTypes.object,
};

NavBottom.defaultProps = {
    navType: 'H',
    list: null,
};
