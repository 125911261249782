import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

export default class CaratHeader extends Component {
  render() {
    const { hide } = this.props;
    // console.log(`in Carat: hide = ${hide}`);
    return (
      <div className="flex">
        <div hidden={!hide}>
          <FontAwesomeIcon icon="angle-up" />
        </div>
        <div hidden={hide}>
          <FontAwesomeIcon icon="angle-down" />
        </div>
      </div>
    );
  }
}

CaratHeader.propTypes = {
  hide: PropTypes.bool,
};

CaratHeader.defaultProps = {
  hide: true,
};
