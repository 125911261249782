import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import ReactSlider from 'react-slider'
import Button from '../common/button';
import Or from '../common/or';

export default function Slider(props) {
    const { maxValue, minValue, handleChangeEvent, showValues, enableSpecific } = props;
    const [currentSliderValue, setCurrentSliderValue] = useState(maxValue && maxValue > 0 ? maxValue / 2 : 0);
    const [specificSliderValue, setSpecificSliderValue] = useState(maxValue && maxValue > 0 ? maxValue / 2 : 0);

    const sliderChanged = (sliderValue) => {
        if (handleChangeEvent && sliderValue) {
            setCurrentSliderValue(sliderValue);
            handleChangeEvent(sliderValue);
        }
    }
    return (
        <Fragment>
            <div className="flex-row w-full">
                <ReactSlider
                    className="horizontal-slider"
                    thumbClassName="example-thumb"
                    trackClassName="example-track"
                    withTracks={true}
                    defaultValue={currentSliderValue}
                    max={maxValue}
                    min={minValue}
                    onAfterChange={(e) => sliderChanged(e)} // only set state when handle is released
                />
                {showValues ? (
                    <Fragment>
                        <div className="flex justify-between ">
                            <div className="text-xs font-bold text-gyfto-font-grey">${minValue}</div>
                            <div className="pl-3 text-xs font-bold text-gyfto-font-grey"> {currentSliderValue}</div>
                            <div className="text-xs font-bold text-gyfto-font-grey">${maxValue}</div>
                        </div>
                        <div>
                            {enableSpecific ? (
                                <div className='pt-4 w-full'>
                                    <Or />
                                    <div className='flex align-middle pt-4 justify-center'>
                                        <div className='xs:text-xs md:text-sm form-label pr-2 pt-4'>Specific filter amount:</div>
                                        <div>
                                            <input
                                                autoFocus
                                                id="sliderAmount"
                                                type="text"
                                                className="form-input focus:outline-none focus:shadow-outline"
                                                value={specificSliderValue}
                                                maxLength="8"
                                                onChange={e => setSpecificSliderValue(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className='pl-2'>
                                            <Button
                                                buttonType="pill-standard"
                                                weight="thin"
                                                xtra="hover:shadow"
                                                onClick={() => {
                                                    sliderChanged(specificSliderValue);
                                                }}
                                            >
                                                <span className="text-center">filter</span>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                null
                            )}
                        </div>
                    </Fragment>
                ) : null}
            </div>
        </Fragment>
    );
}

Slider.propTypes = {
    maxValue: PropTypes.number,
    minValue: PropTypes.number,
    step: PropTypes.number,
    handleChangeEvent: PropTypes.func,
    showValues: PropTypes.bool,
    enableSpecific: PropTypes.bool,
};

Slider.defaultProps = {
    maxValue: 0,
    minValue: 0,
    step: 0,
    handleChangeEvent: null,
    showValues: true,
    enableSpecific: false,
};

/**
 * 08/10/22 - REPLACED THE FOLLOWING WITH A DIFFERENT SLIDER TO SUPPORT TOUCH DEVICES
 * 
 * <div>
        <input
            type="range"
            min={minValue}
            max={maxValue}
            step={step}
            defaultValue={currentSliderValue} // but instead pass state value as default value
            onChange={(e) => sliderChanged(e.target.value)} // only set state when handle is released
            onMouseUp={(e) => handleChangeEvent(e.target.value)} // only set state when handle is released
        />
    </div>
    {showValues ? (
        <div className="flex justify-between ">
            <div className="text-xs font-bold text-gyfto-font-grey">${minValue}</div>
            <div className="pl-3 text-xs font-bold text-gyfto-font-grey">{currentSliderValue}</div>
            <div className="text-xs font-bold text-gyfto-font-grey">${maxValue}</div>
        </div>
    ) : null}
 */
