import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import PageSpace from '../common/pageSpace';
import NavBar from '../navbar/index';
import { useQuery } from '@apollo/react-hooks';
import { SkeletonListCard } from '../common/skeletons';
import { qryGetListsByUserTypeShallow_Full } from '../../graphql/graphql';
import ErrorUI from '../common/error';
import AllLists from '../home/allLists'
import ListPrivateReveal from '../list/list.private.reveal';
import ListEmpty from '../list/list.empty';
import { Subscribe } from 'unstated';
import DrawerContainer from '../../containers/drawer';

export default function Lists(props) {
    const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;
    const listUserType = ["Owner", "Editor"];
    const { auth } = props;
    const isAuthenticated = localStorage.getItem('isLoggedIn');
    const userId = localStorage.getItem(`user_id`);
    const { loading, error, data } = useQuery(qryGetListsByUserTypeShallow_Full, {
        variables: { userId, listUserType },
        suspend: false,
    });
    const allLists = [];
    const pinnedLists = [];
    const privateLists = [];
    
    const COMPONENT_NAME = 'lists';


    if (error) {
        if (displayConsole) {
            console.log(`${COMPONENT_NAME} > Error on query for userId: ${userId}`, error.message);
        }
        return (
            <Fragment>
                <div className="flex ">
                    <div className="flex-1"></div>
                    <div className="flex-none md:max-w-gyfto-max">
                        <div className="py-8">
                            <ErrorUI displayMessage="Click 'Sign In' above to see this list." errorMsg={error && error.message ? error.message : null} />
                        </div>
                    </div>
                    <div className="flex-1"></div>

                </div>
            </Fragment>
        );
    }

    if (displayConsole) {
        console.log(`${COMPONENT_NAME} > Here is the data back for '${userId}' & listUserType '${listUserType}':`, data);
    }
    if (data && data.User && Array.isArray(data.User) && data.User.length > 0) {

        // GRAB THE FIRST ITEM IN THE User ARR
        for (const dataItem of data.User[0].listUsers) {
            if (dataItem && dataItem.list) {
                // DON'T INCLUDE 'prviate' LISTS IN THESE
                if(dataItem.list.listDetails && dataItem.list.listDetails.visibilityType === 'visible'){
                    if (dataItem.list.pinned) {
                        pinnedLists.push(dataItem);
                    } else {
                        allLists.push(dataItem);
                    }
                } else {
                    privateLists.push(dataItem);
                }
            }
        }
    }
    return (
        <Subscribe to={[DrawerContainer]}>
            {appState => (
                <div>
                    <div className="md:hidden sticky bg-white" style={{ top: '0px', zIndex: 900 }} visible={isAuthenticated}>
                        <NavBar navType="H" auth={auth} />
                    </div>
                    <div className="flex md:justify-center">
                        {/* THIS IS THE WHOLE CONTENT AREA CENTERED - MAX OF 800PX */}
                        <div className="w-full md:max-w-gyfto-max items-center md:pl-4 md:pr-4">
                            <Helmet>
                                <title>Gyfto - Your Lists</title>
                                <meta property="og:title" content={`Gyfto - Your Lists`} />
                                <meta name="description" content={`Gyfto - Your Lists`} />
                            </Helmet>
                            <PageSpace>
                                <div className="w-full" visible={isAuthenticated}>
                                    <div className="xs:pt-6">
                                        {loading ? (
                                            <Fragment>
                                                <div className="xs:hidden md:flex flex-wrap">
                                                    <div className="px-4 py-4">
                                                        <SkeletonListCard />
                                                    </div>
                                                    <div className="px-4 py-4">
                                                        <SkeletonListCard />
                                                    </div>
                                                    <div className="px-4 py-4">
                                                        <SkeletonListCard />
                                                    </div>
                                                    <div className="px-4 py-4">
                                                        <SkeletonListCard />
                                                    </div>
                                                </div>
                                                <div className="xs:flex md:hidden flex-wrap">
                                                    <div className="px-4 py-4">
                                                        <SkeletonListCard />
                                                    </div>
                                                </div>
                                            </Fragment>
                                        ) : null
                                        }
                                        {pinnedLists.length > 0 ? (<div>
                                            <h1 className="page-section-header pb-4 xs:pl-4">your saved lists</h1>
                                            <AllLists userId={userId} listUserType={listUserType} containerData={pinnedLists} showNewCard={false} />
                                            <br />
                                        </div>) : null}
                                        {allLists.length > 0 ? (<div>
                                            <h1 className="page-section-header pb-4 xs:pl-4">your lists</h1>
                                            <AllLists userId={userId} listUserType={listUserType} containerData={allLists} showNewCard={false} />
                                            <br />
                                        </div>) : (
                                            <div className='w-full flex justify-center pb-6'>
                                                <ListEmpty appState={appState} />
                                            </div>
                                        )}
                                        {privateLists.length > 0 ? (<div>
                                            <h1 className="page-section-header pb-4 xs:pl-4">your private lists</h1>
                                            <ListPrivateReveal userId data={privateLists} />
                                            
                                        </div>) : null}

                                    </div>
                                </div>
                            </PageSpace>
                        </div>
                    </div>
                </div>
            )
            }
        </Subscribe >
    );
}
