import gql from 'graphql-tag';

const fragmentUser = gql`
  fragment FragmentUser on User {
    id
    userId
    deletedState
    avatarUrl
    firstName
    lastName
    createdAt
    email
  }
`;

export default fragmentUser;
