import React, { Fragment, useState, useEffect } from 'react';
import ListCardMinAlt from '../list/list.card.min.alt';
import { Subscribe } from 'unstated';
import DrawerContainer from '../../containers/drawer';

export default function ListCardSearchResults(props) {
  const { searchTerm, data } = props
  const [dataLists, setDataLists] = useState([]);
  const [dataShares, setDataShares] = useState([]);

  const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;
  const COMPONENT_NAME = "list.card.search.results";

  useEffect(() => {
    if (data && Array.isArray(data) && data.length > 1 && searchTerm) {
      if (displayConsole) {
        console.log(`${COMPONENT_NAME} > incoming data for searchTerm: ${searchTerm}`, data);
      }

      const tmpDataLists = [];
      const tmpDataShares = [];
      data.filter((l) => { return l.listDetails.name.toUpperCase().indexOf(searchTerm.toUpperCase()) >= 0 || l.listDetails.description.toUpperCase().indexOf(searchTerm.toUpperCase()) >= 0 }).map(list => {
        if (list && list.id && list.listUserType) {
          if (list.listUserType === 'SharedWith') {
            tmpDataShares.push(list);
            if (displayConsole) {
              console.log(`pushing this list to tmpDataShares: `, list);
            }
          } else {
            tmpDataLists.push(list);
            if (displayConsole) {
              console.log(`pushing this list to tmpDataLists: `, list);
            }
          }
        }
      });

      //CACHE
      setDataLists(tmpDataLists);
      setDataShares(tmpDataShares);
    }

  }, [searchTerm, data]);

  return (
    <Subscribe to={[DrawerContainer]}>
      {drawerContainer => (
        <Fragment>
          <div className="w-full" hidden={!searchTerm}>
            <div><i>Showing results for "{searchTerm}"</i></div>
            {dataLists && Array.isArray(dataLists) && dataLists.length > 0 ? (
              <Fragment>
                <div className="drawer-header-description-text pb-2 pt-6">Your Lists</div>
                <div>
                  {dataLists
                    .map(list => (
                      <div className="py-3" key={list.id}>
                        <ListCardMinAlt list={list} saveClickToSearch={true} appState={drawerContainer} />
                      </div>
                    ))}
                </div>
              </Fragment>
            ) : null}

            {dataShares && Array.isArray(dataShares) && dataShares.length > 0 ? (
              <Fragment>
                <div className="drawer-header-description-text pb-2 pt-6">Your Shares</div>
                <div>
                  {dataShares
                    .map(list => (
                      <div className="py-3" key={list.id}>
                        <ListCardMinAlt list={list} saveClickToSearch={true} />
                      </div>
                    ))}
                </div>
              </Fragment>
            ) : null}

          </div>
        </Fragment>
      )}
    </Subscribe>
  );
}