import React, { useState } from 'react';
import PropTypes from 'prop-types';

export default function SVGEdit(props) {
    const { primaryColor, secondaryColor, width, height, altText, hoverColor } = props;
    const [isShown, setIsShown] = useState(false);
    return (
        <div style={{ height: height, width: width }} onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 27">
                {altText ? (<title>{altText}</title>) : null}
                <defs />
                <g fill="none" fillRule="evenodd" transform="translate(1.298 .75)" >
                    <rect width="23.186" height="23.174" x=".147" y="1.824" stroke={isShown ? `${secondaryColor}` : `${primaryColor}`} strokeWidth="2" rx="4" />
                    <path fill={hoverColor} d="M14.094.007h10.37v10.37h-10.37z" />
                    <path fill={isShown ? `${secondaryColor}` : `${primaryColor}`} d="M6.549 19.825l.806-4.434 3.597 3.473z" />
                    <path stroke={isShown ? `${secondaryColor}` : `${primaryColor}`} strokeLinecap="square" strokeWidth="5" d="M17.665 8.39l-6.794 6.984M21.665 4.39l-.07.072" />
                </g>
            </svg>
        </div>
    );
}

SVGEdit.propTypes = {
    primaryColor: PropTypes.string,
    secondaryColor: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    altText: PropTypes.string,
    hoverColor: PropTypes.string,
};

SVGEdit.defaultProps = {
    primaryColor: '#5FBA7D',
    secondaryColor: '#000000',
    width: "24px",
    height: "24px",
    altText: null,
    hoverColor: "#fff"
};
