import gql from 'graphql-tag';
import FragmentListUser from './fragmentListUser';
import FragmentUser from './fragmentUser';
import FragmentItemWatch from './fragmentItemWatch';
import FragmentListDetails from './fragmentListDetails';

const fragmentItem = gql`
  fragment FragmentItem on Item {
    id
    itemId
    name
    description
    imageUrl
    amount
    code
    color
    obtained
    ordinal
    processedImage
    qty
    size
    deletedState
    store
    url
    updatedAt
    succsessfulScrape
    user: User{
      id
    }
    obtainedby: ObtainedBy{
      ...FragmentUser
    }
    itemWatches: ItemWatches(where:{Item:{deletedState:{_eq:false}}}){
      ...FragmentItemWatch
    }
    sharedLists: ListItems{
      id
      List{
        id
        listId
        deletedState
        listDetails: ListDetails {
        ...FragmentListDetails
        }
        listUsers: ListUsers{
          ...FragmentListUser
        }
      }
      
    }
    # sharedLists: lists {
    #   id
    #   listUsers {
    #     ...FragmentListUser
    #   }
    # }
  }
  ${FragmentItemWatch}
  ${FragmentListUser}
  ${FragmentListDetails}
  ${FragmentUser}
`;

export default fragmentItem;
