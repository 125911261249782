import React, { Fragment } from 'react';
import { Subscribe } from 'unstated';
import DrawerContainer from '../../containers/drawer';
import DrawerItemItemDelete from './draweritem.item.delete';

export default function DeleteContainer() {
  return (
    <Subscribe to={[DrawerContainer]}>
      {appState => (
        <Fragment>
          {/* {console.log(`draweritem.item.delete.container > appState.state: `, appState.state)} */}
          <DrawerItemItemDelete item={appState.state.selectedItemObj} listId={appState.state.selectedListId} />
        </Fragment>
      )}
    </Subscribe>
  );
}
