import React from 'react';
import PropTypes from 'prop-types';

export default function SVGScreenshot(props) {
    const { width, height } = props;
    return (
        <div>
            <img src="./landing_screenshot.png" width={width} height={height} border="0" loading='lazy' alt='Gyfto | Your Social Giftlist' />
        </div>
    );
}

SVGScreenshot.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    altText: PropTypes.string,
};

SVGScreenshot.defaultProps = {
    width: "1278",
    height: "822",
    altText: null,
};
