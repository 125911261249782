import React from 'react';
import PropTypes from 'prop-types';

export default function Filter(props) { 
    const { width, height, altText } = props;
    return (
        <div style={{ height: height, width: width }}>
            <svg viewBox="0 0 24 24" id="svg__filter" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">{altText ? (<title>{altText}</title>) : null}<path fill="currentColor" fillRule="evenodd" d="M6 9.17V5a1 1 0 1 0-2 0v4.17a3.001 3.001 0 0 0 0 5.66V19a1 1 0 0 0 2 0v-4.17a3.001 3.001 0 0 0 0-5.66zm7 5V5a1 1 0 0 0-2 0v9.17a3.001 3.001 0 1 0 2 0zm7-4.34V19a1 1 0 0 1-2 0V9.83a3.001 3.001 0 1 1 2 0zM19 8a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-7 10a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm-7-5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"></path></svg>
        </div>

    );
}

Filter.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    altText: PropTypes.string
};

Filter.defaultProps = {
    width: "20px",
    height: "20px",
    altText: null
};
