
import React, { Component, Fragment } from 'react';
// import PropTypes from 'prop-types';
import { Subscribe } from 'unstated';
import CardSpace from '../home/card.css';
// THIS IS FOR THE unstated CONTAINER
import DrawerContainer from '../../containers/drawer';

export default function ListCardMore(props) {

  return (
    <React.Fragment>
      <Subscribe to={[DrawerContainer]}>
        {drawerContainer => (
          <CardSpace type="more">
            <div className="flex flex-col text-white min-h-gyfto-card items-stretch align-text-bottom cursor-pointer rounded-lg">
              <div className="text-center flex-1"></div>
              <div className="text-center text-2xl flex-1 mt-12">See More</div>
            </div>
          </CardSpace>
        )}
      </Subscribe>
    </React.Fragment>
  );
}
