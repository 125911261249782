import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import history from '../history/index';
import { Subscribe } from 'unstated';
import { useQuery, useMutation } from '@apollo/react-hooks';
import DrawerContainer from '../../containers/drawer';
import DrawerSpace from '../common/drawerSpace';
import Loading from '../common/loading';
import Button from '../common/button';
import DrawerMessageWarning from '../common/drawerMessageWarning';
import UserAvatar from '../avatar/userAvatar';
import { qryGetSharedList, mutationDeleteShareForUser } from '../../graphql/graphql';

/* eslint-disable  */


export default function DrawerItemShareListDelete(props) {

  const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;
  const COMPONENT_NAME = 'draweritem.share.list.delete';
  const { userId, listId } = props;
  let listUserId = null; // THIS IS THE listUser RECORD THAT WE'LL WANT TO DELETE
  let sharedListId = null; // THIS IS THE list ON THE listUser RECORD FOR THE USER THAT WE ARE DELETING
  let linkListId = null; // THIS IS THE list.id THAT WE SHOULD SHARE
  let user = null; // THIS IS THE user RECORD THAT WAS CLICKED ON 
  let userDisplayName = null; // USE THIS TO REFERENCE THE USER BY NAME
  let sourceList = null; // THIS IS THE SOURCE LIST THAT THIS listUser IS TIED TO
  let listItemsArr = []; // THESE ARE THE ITEMS THAT ARE SHARED WITH THE USER
  const userArr = [];
  let thereAreNoListItems = true;
  const [deleteListShare] = useMutation(mutationDeleteShareForUser);


  if (displayConsole) {
    console.log(`${COMPONENT_NAME} > props': `, props);
  }

  // GET DATA
  const { loading, error, data } = useQuery(qryGetSharedList, {
    variables: { listId, userId },
    suspend: false
  });

  if (error) {
    console.error(`Error calling query:`, error);
  }

  if (data) {
    if (displayConsole) {
      console.log(`${COMPONENT_NAME} > data from 'qryGetSharedList': `, data);
    }

    if ((data.ListUser && Array.isArray(data.ListUser) && data.ListUser.length > 0)) {
      let listUser = data.ListUser[0];
      if (listUser && listUser.id) {
        listUserId = listUser.id;
      }

      if (listUser && listUser.user) {
        user = listUser.user;
        userArr.push(listUser.user);
        userDisplayName = listUser.user.firstName ? listUser.user.firstName : listUser.user.email;
      }

      if (listUser && listUser.list && listUser.list.listId) {
        sharedListId = listUser.list.listId;
      }

      if (listUser && listUser.list && listUser.list.listId) {
        linkListId = listUser.list.id;
      }

      if (listUser && listUser.list && listUser.list.sourceList && listUser.list.sourceList.id) {
        sourceList = listUser.list.sourceList;
      }

      if (listUser && listUser.list && listUser.list.listItems) {
        thereAreNoListItems = false;
        for (const listItm of listUser.list.listItems) {
          if (listItm) {
            listItemsArr.push(listItm);
          }
        }
      }
    }
  }

  return (
    <Subscribe to={[DrawerContainer]}>
      {appState => (
        <Fragment>
          {loading ? (
            <DrawerSpace>
              <div className="flex content-center">
                <Loading loadingText="Wait for it..." />
              </div>
            </DrawerSpace>
          ) : null}
          <DrawerSpace>
            <div className="drawer-header-description-text pb-4 pt-3">
              This list is currently shared with <i>{userDisplayName}</i>
            </div>
            <div className="w-full flex">
              <div>
                <UserAvatar users={userArr} direction="vertical" subtext={userArr && Array.isArray(userArr) && userArr.length === 1 ? userArr[0].email : null} />
              </div>
            </div>
            <div className="py-6">
            <div className="leading-normal text-grey-dark text-sm pb-2">Click here to see what {userDisplayName ? userDisplayName : 'this user'} sees on this list:</div>
              <Button
                onClick={e => history.push(`/sl/${linkListId}/og/${appState.state.selectedListId}`)}
                buttonType="pill-standard"
                weight="thin"
                xtra="hover:shadow xs:py-4 xs:px-6"
              >
                <div className="">see your list as this user</div>
              </Button>
            </div>
            <div className="drawer-header-description-text pb-4 pt-3">
              If you want to delete this share for this user, hit the <i>delete share</i> button below
            </div>
            <div className="py-4">
              <DrawerMessageWarning>
                <span>You can't take this back!</span>
              </DrawerMessageWarning>
            </div>

            {/* <div>
              <UserAvatar users={userArr} direction="vertical" />
            </div> */}
            <div hidden={listItemsArr.length < 1}>
              <div className="drawer-header-description-text pt-6">
                Shared Items
              </div>
              <div className="leading-normal text-grey-dark pt-1 text-sm">These items will no longer be shared with {userDisplayName ? userDisplayName : 'this user'} :</div>
              <div className="flex flex-col pt-3 pb-6" style={{ width: '310px', minWidth: '310px', maxWidth: '310px' }}>
                {listItemsArr.map(listItem => (
                  <div className=" flex-no-grow flex-no-shrink m-3 p-3 bg-white items-center" key={listItem.id} style={{ height: '100px', maxHeight: '100px' }}>
                    <div className="flex justify-start pt-2">
                      <div >
                        {listItem.item && listItem.item.imageUrl ? (
                          <div className="h-12 w-12 rounded-full mx-auto bg-grey-lighter" style={{ "backgroundImage": `url('${listItem.item.imageUrl}')`, "backgroundSize": "contain", "overflow": "hidden", "backgroundRepeat": "no-repeat", "backgroundPosition": "center" }} />
                        ) : null}
                      </div>
                      <div className="pl-3 text-grey-dark leading-normal">{listItem.item && listItem.item.name ? listItem.item.name : ''}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="fixed pin-b pb-12" hidden={thereAreNoListItems}>
              <div className="mx-auto " style={{ minWidth: '310px' }}>
                <Button
                  buttonType="pill-delete"
                  xtra="hover:shadow"
                  showSpinner={true}
                  onClick={async () => {
                    await deleteListShare({
                      variables: { listUserId, listId: sharedListId },
                    })
                      .then(() => {
                        appState.closeDrawer();
                        appState.showMessageCard('msg', `Took that share back!`);
                      })
                      .catch(e => {
                        if (dispalyConsole) {
                          console.error(`${COMPONENT_NAME} > calling deleteListShare > Error attempting to call 'mutationDeleteShareForUser': `, e);
                        }
                      });
                  }}
                >
                  <span className="text-center">Delete share</span>
                </Button>
              </div>
            </div>
          </DrawerSpace>
        </Fragment>
      )}
    </Subscribe>
  );
}

/* eslint-enable  */
DrawerItemShareListDelete.propTypes = {
  userId: PropTypes.string,
  listId: PropTypes.string,
};

DrawerItemShareListDelete.defaultProps = {
  userId: null,
  listId: null,
};