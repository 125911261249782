import React, { useState } from 'react';
import PropTypes from 'prop-types';

export default function SVGBookmark(props) {
    const { primaryColor, secondaryColor, width, height, fillState } = props;
    const [isShown, setIsShown] = useState(false);
    return (
        <div style={{ height: height, width: width }} onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill={fillState ? `${secondaryColor}` : `none` } stroke={fillState ? `${secondaryColor}` : isShown? `${secondaryColor}` : `${primaryColor}`} strokeWidth="1">
                <path d="M5 4a2 2 0 012-2h6a2 2 0 012 2v14l-5-2.5L5 18V4z" />
            </svg>
        </div>

    );
}

SVGBookmark.propTypes = {
    primaryColor: PropTypes.string,
    secondaryColor: PropTypes.string,
    fillState: PropTypes.bool,
    width: PropTypes.string,
    height: PropTypes.string,
    altText: PropTypes.string
};

SVGBookmark.defaultProps = {
    primaryColor: '#5FBA7D',
    secondaryColor: '#000000',
    fillState: false,
    width: "24px",
    height: "24px",
    altText: null
};
