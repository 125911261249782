import React, { useState } from 'react';
import { Subscribe } from 'unstated';
import { useMutation } from '@apollo/react-hooks';
import history from '../history/index';
import DrawerContainer from '../../containers/drawer';
import DrawerSpace from "../common/drawerSpace";
import Button from '../common/button';
import Loading from '../common/loading';
import ErrorUI from '../common/error';
import { mutationCreateList } from '../../graphql/graphql';

export default function DrawerListCreate() {
  const [listName, setName] = useState('');
  const [listDescription, setDescription] = useState('');
  const [showErrMsg, toggleErrMsg] = useState(false);
  const [buyType, setBuyType] = useState('SharedBuy');
  const [visibilityType, setVisibilityType] = useState('visible');
  const [showLoading, setShowLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const currentUserId = localStorage.getItem('user_id');
  const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;
  const funcName = 'draweritem.list.create';

  // MUTATION TO CREATE THE LIST
  const [createList] = useMutation(mutationCreateList);

  // SHOW THE ERROR MESSAGE IF NEEDED
  const captureError = (msg) => {
    if (displayConsole) {
      console.log(`draweritem.list.creat > captureError - error message captured: ${msg}`);
    }

    setShowErrorMsg(true);
    setErrorMsg(msg);
  };

  // SETS THE buyType BASED UPON THE TOGGLE
  const handleListTypeToggleSwitchChange = (checkedState, toggleType) => {
    if(displayConsole){
      console.log(`${funcName} > handleListTypeToggleSwitchChange > changing switch '${toggleType}' to '${checkedState}`);
    }

    if(toggleType && toggleType !== ''){
      if(toggleType === 'buyType'){
        setBuyType(checkedState && checkedState === true ? 'OwnerBuy' : 'SharedBuy');
      }
      
      if(toggleType === 'visibilityType'){
        setVisibilityType(checkedState && checkedState === true ? 'private' : 'visible');
      }
    } else {
      if(displayConsole){
        console.error(`${funcName} > Error setting state for the switch '${toggleType}' to '${checkedState}'`);
      }
    }
  };

  if (showErrorMsg) {
    return (
      <DrawerSpace>
        <ErrorUI errorMsg={errorMsg} />
      </DrawerSpace>
    );
  }

  return (
    <Subscribe to={[DrawerContainer]}>
      {appState => (
        <DrawerSpace>
          {showLoading ? (
            <div className="flex content-center">
              <Loading loadingText="Creating your new list..." />
            </div>
          ) : (
            <div>
              <form>
                <div className="drawer-header-description-text pb-3">Enter details for your new List below.</div>
                <div className="flex justify-between">
                  <div className="form-label">list name</div>
                  <div className="form-plain text-grey">{listName.length} / 30</div>
                </div>
                <input
                  autoFocus
                  id="listName"
                  type="text"
                  className="w-full form-input focus:outline-none focus:shadow-outline"
                  value={listName}
                  maxLength="30"
                  onChange={e => setName(e.target.value)}
                  required
                />
                {showErrMsg ?
                  (
                    <div className="form-input-error">
                      <div className="p-2">List name is required to save!</div>
                    </div>
                  )
                  : <span />}
                <br />
                <br />
                <span className="form-label">list description</span>
                <textarea
                  id="listDescription"
                  rows="6"
                  className="w-full form-input focus:outline-none focus:shadow-outline"
                  value={listDescription}
                  onChange={e => setDescription(e.target.value)}
                />
                <br />
                <span className="form-label pt-6">Who is buying off this list?</span>
                <input type="checkbox" id="listTypeToggleSwitch" className="hidden" onChange={(e) => { handleListTypeToggleSwitchChange(e.target.checked) }} />
                <div className="switchToggleContent">
                  <label htmlFor="listTypeToggleSwitch" className="switchToggleLabel">
                    <div className="switchToggleToggle" />
                    <div className="switchToggleItems">
                      <div className="switchToggleLeft pt-3" style={{ marginLeft: '-10px' }}>Others are</div>
                      <div className="switchToggleRight pt-3" style={{ marginLeft: '-80px' }} >I am</div>
                    </div>
                  </label>
                </div>
                <div className="pt-1 leading-normal text-grey-dark">
                  {buyType === 'SharedBuy' ? (
                    <span>I will share this list with others to chose items from</span>
                  ) : (
                    <span>I will purchase items off this list and may share it with others</span>
                  )}
                </div>
                <br />
                <span className="form-label pt-6">Is this list public?</span>
                <input checked={visibilityType === 'visible' ? false : true} type="checkbox" id="listVisibilityTypeToggleSwitch" className="hidden" onChange={(v) => { handleListTypeToggleSwitchChange(v.target.checked, 'visibilityType') }} />
                <div className="switchToggleContent">
                  <label htmlFor="listVisibilityTypeToggleSwitch" className="switchToggleLabel">
                    <div className="switchToggleToggle" />
                    <div className="switchToggleItems">
                      <div className="switchToggleLeft pt-3" style={{ marginLeft: '-10px' }}>Visible</div>
                      <div className="switchToggleRight pt-3" style={{ marginLeft: '-80px' }} >Private</div>
                    </div>
                  </label>
                </div>
                <div className="pt-1 leading-normal text-grey-dark">
                  {visibilityType === 'visible' ? (
                    <span>This list and items in it will be visible everywhere</span>
                  ) : (
                    <span>This list and items in it won't show up in search or other areas such as Items</span>
                  )}
                </div>
                <div className="flex justify-between px-2 pt-4">
                  <Button
                    buttonType="pill-cancel"
                    weight="thin"
                    xtra="hover:shadow"
                    onClick={() => {
                      appState.closeDrawer();
                    }}
                  >
                    <span className="text-center">Cancel</span>
                  </Button>
                  <Button
                    buttonType="pill-add"
                    weight="thin"
                    xtra="hover:shadow"
                    onClick={async e => {
                      if (listName === '') {
                        toggleErrMsg(true);
                      } else {
                        toggleErrMsg(false);
                        setShowLoading(true);
                        if(displayConsole){
                          console.log(`${funcName} > creating a ${visibilityType} list '${listName}' with description '${listDescription}' for user '${currentUserId}' of buyType '${buyType}'`)
                        }
                        await createList({
                          variables: {
                            input: {
                              listName,
                              listDescription,
                              userId: currentUserId,
                              buyType,
                              visibilityType
                            }
                          },
                        })
                          .then(({ data }) => {
                            if (displayConsole) {
                              console.log(`draweritem.list.create > await createList > This came back from the create list mutation: `, data);
                            }
                            appState.closeDrawer();
                            setShowLoading(false);
                            history.push(`/l/${data.List.id}`);
                          }).catch(e => {
                            if (displayConsole) {
                              console.error(`draweritem.list.create > await createList > Error attempting to call 'createList' mutation: `, e);
                            }
                            captureError(e.toString());
                          });
                      }
                    }}
                  >
                    <span className="text-center">save</span>
                  </Button>
                </div>
              </form>
            </div>
          )}
        </DrawerSpace>
      )}
    </Subscribe>
  );
}
