import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import PageSpace from '../common/pageSpace';
import Button from '../common/button';
import LogoLg from '../common/logo_lg';
import Logo from '../common/logo';
import Screenshot from '../common/icons/screenshot-svg'

export default class NotAuthenticated extends Component {

  handleClickSignInSignUp(e) {
    const { auth } = this.props;
    auth.login();
  }

  render() {
    let mailToAdress = "howdy@gyfo.dev";

    return (
      <Fragment>
        <div className='w-full flex bg-gyfto-grey-background landing-page-background-image'>
          <div className='xs:w-4 md:w-8' />
          <div className='pb-8'>
            <div className='md:hidden w-full min-h-screen-3/4'>
              <div className='px-2'>
                <div className='pt-6 flex justify-between align-middle'>
                  <div className='pt-2 bg-white'>
                    <Logo showText={true} />
                  </div>
                  <div className=''>
                    <Button
                      onClick={e => this.handleClickSignInSignUp(e)}
                      buttonType="pill-landing-cta"
                      weight="thin"
                      xtra="shadow"
                    >
                      <div className="">sign in</div>
                    </Button>
                  </div>
                </div>
                <div className='pt-6'>
                  <Screenshot width='466.42' height='300' />
                </div>
                <div className='pl-6 pb-10'>
                  <div className="text-4xl text-gyfto-font-grey-darker font-extrabold pt-5 flex-no-wrap">Your social giftlist</div>
                  <div className="flex flex-col pl-2 max-w-gyfto-drawer-content-xs">
                    <div className="flex-shrink-0 pt-8 pb-8">
                      <div className="text-bold text-lg ">Create a list</div>
                      <div className="font-light text-base leading-normal">Birthdays, holidays, anniversaries - any event is worth a list</div>
                    </div>
                    <div className="flex-shrink-0 pb-8">
                      <div className="text-bold text-lg ">Fill with items</div>
                      <div className="font-light text-base leading-normal">Add items from anywhere - online or in real life</div>
                    </div>
                    <div className="flex-shrink-0 pb-8">
                      <div className="text-bold text-lg ">Share with friends</div>
                      <div className="font-light text-base leading-normal">Selectively share the whole list or only certain items with others</div>
                    </div>
                  </div>
                  <div className="max-w-gyfto-landing-value-prop-text-box">
                    <Button
                      onClick={e => this.handleClickSignInSignUp(e)}
                      buttonType="pill-standard"
                      weight="thin"
                      xtra="hover:shadow xs:py-4 xs:px-6"
                    >
                      <div className="">get started</div>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className='xs:hidden md:flex w-full min-h-screen-3/4 pb-6 justify-center' >
              <div className="px-2 pt-6">
                <div className='flex w-full justify-between'>
                  {/* THE LOGO AND TAGLINE */}
                  <div className='bg-white'><LogoLg /></div>
                  {/* THE CTA BUTTON */}
                  <div className='flex'>
                    <div className=''>
                      <Button
                        onClick={e => this.handleClickSignInSignUp(e)}
                        buttonType="pill-landing-cta"
                        weight="thin"
                        xtra="shadow"
                      >
                        <div className="">sign in</div>
                      </Button>
                    </div>
                    <div className='w-8' />
                  </div>
                </div>
                <div className='w-full flex justify-between'>
                  <div className="pt-12 bg-white">
                    <div className="text-4xl text-gyfto-font-grey-darker font-extrabold pt-5 flex-no-wrap">Your social giftlist</div>
                    <div className="flex flex-col pl-2">
                      <div className="flex-shrink-0 pt-8 pb-8">
                        <div className="text-bold text-lg ">Create a list</div>
                        <div className="font-light text-base leading-normal ">Birthdays, holidays, anniversaries - any event is worth a list</div>
                      </div>
                      <div className="flex-shrink-0 pb-8">
                        <div className="text-bold text-lg ">Fill with items</div>
                        <div className="font-light text-base leading-normal">Add items from anywhere - online or in real life</div>
                      </div>
                      <div className="flex-shrink-0 pb-8">
                        <div className="text-bold text-lg ">Share with friends</div>
                        <div className="font-light text-base leading-normal">Selectively share the whole list or only certain items with others</div>
                      </div>
                    </div>
                    <div className="max-w-gyfto-landing-value-prop-text-box">
                      <Button
                        onClick={e => this.handleClickSignInSignUp(e)}
                        buttonType="pill-standard"
                        weight="thin"
                        xtra="hover:shadow xs:py-4 xs:px-6"
                      >
                        <div className="">get started</div>
                      </Button>
                    </div>
                  </div>
                  <div className='w-24' />
                  <Screenshot width='715' height='460' />
                </div>
              </div>
            </div>
            <div className='w-full bg-grey-lightest min-h-screen-1/4 p-8' style={{ borderRadius: '60px' }}>
              <div className="">
                <div className="text-2xl text-bold pt-5"><a href="#about" aria-label={"about"} name="about" />About Gyfto</div>
                <div className="font-light text-base pt-4 leading-normal">
                  Gyfto was started to enable us all to create and share gift lists, regardless of where those gifts exist - online or in the real world.<br />
                  <br />
                  Gyfto is always free to use - we provide different ways to create an account and rest assured that we only store a limited amount of information about you based upon the
                  login type you chose (Google, Facebook, Twitter).  If you ever want to know more about the information we collect and how we use it or if you wish to remove the information
                  {/* stored within Gyfto - please <a href="mailto:gyftoapp@gmail.com">reach out</a>.<br/> */}
                  stored within Gyfto - please <a href={'mailto:' + mailToAdress}>reach out</a>.<br />
                  <br />
                  For some gifts added we may add referral codes - this allows us to collect a very small portion of the proceeds when someone buys the gift from an affiliated partner.  The price
                  of the gift does not increase; however, it's the main form of income used to keep the site running.

                </div>
              </div>
            </div>
          </div>
          <div className='xs:w-4 md:w-8' />
        </div>


      </Fragment>
    );
  }
}

NotAuthenticated.propTypes = {
  auth: PropTypes.object,
};

NotAuthenticated.defaultProps = {
  auth: null,
};
