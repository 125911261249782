import React, { useState, useEffect, Fragment } from 'react';
import { Subscribe } from 'unstated';
import { useMutation } from '@apollo/react-hooks';
import DrawerContainer from '../../containers/drawer';
import Button from '../common/button';
import Or from '../common/or';
import DrawerSpace from "../common/drawerSpace";
import { mutationUpdateList } from '../../graphql/graphql';
import { fnGyftoEventPipeline, fnGetUserRoleInList } from '../../functions/index';
import DeleteButton from '../common/buttons/deleteButton';

export default function DrawerListEdit(props) {
  const [listId, setListId] = useState(props.listId);
  const [listName, setName] = useState(props.listName);
  const [list, setList] = useState(props.list);
  const [listDescription, setDescription] = useState(props.listDescription);
  const [showErrMsg, toggleErrMsg] = useState(false);
  const [buyType, setBuyType] = useState(props.buyType);
  const [visibilityType, setVisibilityType] = useState(props.visibilityType);
  const [listDetailsId, setListDetailsId] = useState(props.listDetailsId);
  const [canSeeDelete, setCanSeeDelete] = useState(false);
  const [updateList, { dataList }] = useMutation(mutationUpdateList);
  const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;
  const funcName = 'draweritem.list.edit';

  // SETS THE buyType BASED UPON THE TOGGLE
  const handleListTypeToggleSwitchChange = (checkedState, toggleType) => {
    if (displayConsole) {
      console.log(`${funcName} > handleListTypeToggleSwitchChange > changing switch '${toggleType}' to '${checkedState}`);
    }

    if (toggleType && toggleType !== '') {
      if (toggleType === 'buyType') {
        setBuyType(checkedState && checkedState === true ? 'OwnerBuy' : 'SharedBuy');
      }

      if (toggleType === 'visibilityType') {
        setVisibilityType(checkedState && checkedState === true ? 'private' : 'visible');
      }
    } else {
      if (displayConsole) {
        console.error(`${funcName} > Error setting state for the switch '${toggleType}' to '${checkedState}'`);
      }
    }

  };

  // CHECKS TO SEE IF THE CURRENT USER CAN DELETE THE LIST (ONLY OWNER, NOT EDITOR)
  useEffect(() => {
    async function figureOutUserRoleInList() {
      if (props && props.list) {
        let lclLst = props && props.list ? props.list : null;
        if (lclLst) {
          const currentUserId = localStorage.getItem('user_id');
          const userRoleInList = await fnGetUserRoleInList(lclLst, currentUserId);
          if (userRoleInList && userRoleInList.toUpperCase() === 'OWNER') {
            setCanSeeDelete(true);
          }
        }
      }

      //COMPONENT LOGGING
      if (displayConsole) {
        console.log(`${funcName} > List props: `, props);
      }
    }

    figureOutUserRoleInList();

  }, [])


  return (
    <Subscribe to={[DrawerContainer]}>
      {appState => (
        <DrawerSpace>
          <div className="w-full">
            {canSeeDelete ? (
              <div className="flex flex-col md:hidden pb-8 w-full ">
                <div className="drawer-header-description-text pb-6">Delete this list</div>
                <div className="flex flex-col w-full justify-center items-center">
                  <DeleteButton length="medium" list={list} />
                  <div className='pt-12 pb-4 w-full'>
                    <Or />
                  </div>
                </div>
              </div>
            ) : null}
            <form>
              <div className="drawer-header-description-text pb-3">Edit details of this list</div>
              <br />
              <div className="flex justify-between">
                <div className="form-label">list name</div>
                <div className="form-plain text-grey">{listName.length} / 30</div>
              </div>
              <input
                id="listName"
                type="text"
                maxLength="30"
                className="w-full form-input focus:outline-none focus:shadow-outline"
                value={listName}
                onChange={e => setName(e.target.value)}
                required
              />

              {showErrMsg ? (
                <div className="form-input-error">
                  <div className="p-2">List name is required to save!</div>
                </div>
              ) : <span />}
              <br />
              <br />
              <span className="form-label">list description</span>
              <textarea
                id="listDescription"
                rows="6"
                className="w-full form-input focus:outline-none focus:shadow-outline"
                value={listDescription}
                onChange={e => setDescription(e.target.value)}
              />
              <br />
              <span className="form-label pt-6">Who is buying off this list?</span>
              <input checked={buyType === 'OwnerBuy' ? true : false} type="checkbox" id="listTypeToggleSwitch" className="hidden" onChange={(e) => { handleListTypeToggleSwitchChange(e.target.checked, 'buyType') }} />
              <div className="switchToggleContent">
                <label htmlFor="listTypeToggleSwitch" className="switchToggleLabel">
                  <div className="switchToggleToggle" />
                  <div className="switchToggleItems">
                    <div className="switchToggleLeft pt-3" style={{ marginLeft: '-10px' }}>Others are</div>
                    <div className="switchToggleRight pt-3" style={{ marginLeft: '-80px' }} >I am</div>
                  </div>
                </label>
              </div>
              <div className="pt-1 leading-normal text-grey-dark">
                {buyType === 'SharedBuy' ? (
                  <span>I will share this list with others to chose items from</span>
                ) : (
                  <span>I will purchase items off this list and may share it with others</span>
                )}
              </div>
              <br />
              <span className="form-label pt-6">Is this list public?</span>
              <input checked={visibilityType === 'visible' ? false : true} type="checkbox" id="listVisibilityTypeToggleSwitch" className="hidden" onChange={(v) => { handleListTypeToggleSwitchChange(v.target.checked, 'visibilityType') }} />
              <div className="switchToggleContent">
                <label htmlFor="listVisibilityTypeToggleSwitch" className="switchToggleLabel">
                  <div className="switchToggleToggle" />
                  <div className="switchToggleItems">
                    <div className="switchToggleLeft pt-3" style={{ marginLeft: '-10px' }}>Visible</div>
                    <div className="switchToggleRight pt-3" style={{ marginLeft: '-80px' }} >Private</div>
                  </div>
                </label>
              </div>
              <div className="pt-1 leading-normal text-grey-dark">
                {visibilityType === 'visible' ? (
                  <span>This list and items in it will be visible everywhere</span>
                ) : (
                  <span>This list and items in it won't show up in search or other areas such as Items</span>
                )}
              </div>
              <div className="flex  justify-between pt-4">
                <Button
                  buttonType="pill-cancel"
                  weight="thin"
                  xtra="hover:shadow pr-4"
                  onClick={() => {
                    appState.closeDrawer();
                  }}
                >
                  <span className="text-center">Cancel</span>
                </Button>
                <Button
                  buttonType="pill-add"
                  weight="thin"
                  xtra="hover:shadow"
                  onClick={async e => {
                    if (listName === '') {
                      toggleErrMsg(true);
                    } else {
                      toggleErrMsg(false);
                      await updateList({
                        variables: {
                          listId,
                          listName,
                          listDescription,
                          listDetailsId,
                          buyType,
                          visibilityType,
                          updateDateTime: new Date().toISOString()
                        },
                      }).then(({ data }) => {
                        fnGyftoEventPipeline('List', 'Updated', listId);
                        appState.closeDrawer();
                      });
                    }
                  }}
                >
                  <span className="text-center">save</span>
                </Button>
              </div>
            </form>
          </div>
        </DrawerSpace>
      )}
    </Subscribe>
  );
}
