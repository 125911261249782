import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

export default class Carat extends Component {
  render() {
    const { direction, size } = this.props;
    let faIcon = 'angle-up';
    if (direction === 'up') {
      faIcon = 'angle-up';
    } else if (direction === 'down') {
      faIcon = 'angle-down';
    }
    return (
      <div className="flex">
        <FontAwesomeIcon icon={faIcon} size={size} />
      </div>
    );
  }
}

Carat.propTypes = {
  direction: PropTypes.string,
  size: PropTypes.string,
};

Carat.defaultProps = {
  direction: 'up',
  size: '1x',
};
