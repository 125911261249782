import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Subscribe } from 'unstated';
import { useMutation, useQuery } from '@apollo/react-hooks';
import _forEach from 'lodash.foreach';
import ItemCardMin from '../item/itemCard.min';
import Button from '../common/button';
import DrawerSpace from '../common/drawerSpace';
import DrawerContainer from '../../containers/drawer';
import Checkbox from '../common/checkbox';
import ListCardMin from '../list/list.card.min';
import { qryGetListsAssocToItem, mutationDeleteListItems, mutationDeleteItemAndListItem } from '../../graphql/graphql';
import { fnGyftoEventPipeline } from '../../functions/index';
import ErrorUI from '../common/error';
import DrawerMessageError from '../common/drawerMessageError';
import Loading from '../common/loading';

/* eslint-disable  */

export default function DrawerItemItemDelete(props) {
  const { item, listId } = props;
  const dispalyConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;
  const [deleteItemAndListItem] = useMutation(mutationDeleteItemAndListItem);
  const [deleteListItem] = useMutation(mutationDeleteListItems);
  const [assocLists, setAssocLists] = useState([]);
  const [errorMsg, setErrorMsg] = useState(null);
  const [currentListItemId, setCurrentListItemId] = useState(null);
  const [componentLoaded, setComponentLoaded] = useState(false);

  const { loading, error, data } = useQuery(qryGetListsAssocToItem, {
    variables: { itemId: item.id },
    suspend: false,
  });

  if (error) {
    console.error(`Error querying information about item '${itemId}' in draweritem.item.delete`)
  }

  let tmpAssocLists = [];
  // THIS WILL GET THE MOST RECENT COPY OF LISTS ASSOCIATED TO THIS ITEM (AS OPPOSED TO TAKING IN FROM THE item IN PROPS)
  if (data) {
    if (dispalyConsole) {
      console.log(`draweritem.item.delete.assocLists > data > lists associated to item '${item.id}': `, data);
    }
    if (Array.isArray(data.Item) && data.Item[0] && data.Item[0].listItems && Array.isArray(data.Item[0].listItems)) {
      // GET ALL THE LISTS FROM QUERY AND BIND TO A LOCAL ARRAY
      const sourceListItems = data.Item[0].listItems;
      let ordinalVal = 1;

      _forEach(sourceListItems, listItem => {
        if (listItem && listItem.list && listItem.list.id && listItem.list.id !== listId) {
          // BASICALLY - WE'RE PUSHING LISTS INTO THIS ARRAY WHERE WE PRE-CHECK A LIST IF 
          // IT IS THE LIST WE'RE IN BUT WE DON'T DISPLAY IT TO THE SCREEN

          // BUT - DON'T SHOW LISTS THAT ARE SHARES OF THE PARENT LIST ASSOCIATED TO THIS ITEM
          // console.log(` ### SEE THIS listItem:`, listItem);
          if (listItem.list.sourceList && listItem.list.sourceList.id) {
            // LET PURPOSELY BLANK - IF THIS lisItem HAS A sourceList WITH AND id THEN IT'S A SHARED LIST
            // AND WE DON'T WANT TO ADD IT
            // console.log(`listItem sourceList id:`, listItem.list.sourceList.id);
          } else {
            tmpAssocLists.push({ "id": `${listItem.list.id}`, "listItemId": `${listItem.id}`, "checked": listItem.list.id === listId ? true : false, "list": listItem.list, ordinal: ordinalVal, "displayToScreen": listItem.list.id !== listId ? true : false, "listName": `${listItem.list.listDetails.name}` });
          }
          ordinalVal += 1;
        } else {
          if (dispalyConsole) {
            console.log(`draweritem.item.delete.assocLists > missing listId: `, listItem);
          }
        }
      });

      if (tmpAssocLists.length > 0) {
        if (dispalyConsole) {
          console.log(`draweritem.item.delete.assocLists > tmpAssocLists: `, tmpAssocLists);
        }
      }
    } else {
      if (dispalyConsole) {
        console.log(`draweritem.item.delete.assocLists > issue(s) with lists associated to item': `, data);
      }
    }
  }

  // THIS WILL ACCOMPLISH TWO THINGS - ONLY RAN ON INITIAL MOUNT:
  // 1) GET THE currentListItemId 
  // 2) BIND THE DATA FROM THE QUERY TO LOCAL CACHE
  useEffect(() => {

    // GET THE ASSOCIATED ListItem ID FROM THE item PROP
    if (item && item.id && item.sharedLists && Array.isArray(item.sharedLists)) {
      if (dispalyConsole) {
        console.log(`draweritem.item.delete.assocLists > useEffect > lists associated to item '${item.id}': `, item);
      }

      _forEach(item.sharedLists, listItem => {
        if (listItem && listItem.id && listItem.list && listItem.list.id && listItem.list.id === listId) {
          // THIS IS THE CURRENT ListItem THAT WE PULLED OUT OF THE item PASSED IN (FROM sharedLists)
          setCurrentListItemId(listItem.id);
        }
      });
    }
    // BIND THE RESULTS OF THE QUERY TO PERMANENT CACHE
    setAssocLists(tmpAssocLists);
    setComponentLoaded(true);
  }, [data]);

  // FIRES WHEN A CHECKBOX IS CLICKED - HANDLES STATE MGMT FOR THE LISTS CHECKED, ETC.
  const handleChange = e => {
    if (dispalyConsole && false) {
      console.log(`${e.target.name} is ${e.target.checked}`, e);
    }

    // A BIT OF A HACK BUT THIS UPDATES THE useState ARRAY WITH A NEW ARRAY WITH AN UPDATED
    // LIST OF CHECKED ITEMS
    let tmpArr = assocLists.filter(l => l.id !== e.target.name);
    let tmpI = assocLists.filter(l => l.id === e.target.name);
    if (tmpI && Array.isArray(tmpI) && tmpI.length > 0) {
      tmpI.map(i => {
        tmpArr.push({ "listItemId": `${i.listItemId}`, "id": `${i.id}`, "checked": e.target.checked, "list": i.list, ordinal: i.ordinal, "displayToScreen": i.displayToScreen, "listName": i.listName });
      })

    }
    setAssocLists(tmpArr);
  };

  // RETURNS COUNT OF NUMBER OF CHECKED LISTS
  const checkForCheck = (arrLists) => {
    let foundCheck = 0;

    if (arrLists && Array.isArray(arrLists)) {
      _forEach(arrLists, list => {
        if (list && list.checked && list.checked === true) {
          foundCheck += 1;
        }
      })
    }

    return foundCheck;
  };

  // HANDLE THE DELETE OF THE ITEM -- BASED UPON WHAT IS PASSED IN
  // WE WILL EITHER DELETE A listItem OR A listItem AND AN item
  const doTheDelete = async (appState) => {
    let allUpdated = false;
    if (appState && item.id && currentListItemId) {
      if (dispalyConsole) {
        console.log(`draweritem.item.delete > doTheDelete > assocLists: `, assocLists);
        console.log(`draweritem.item.delete > doTheDelete > currentListItemId: `, currentListItemId);
        console.log(`draweritem.item.delete > doTheDelete > item.id: `, item.id);
      }
      const listItemIds = []
      
      // FIRST FIGURE OUT IF THE ITEM EXISTS ON OTHER LISTS AND IF WE CHECKED THOSE LISTS BY
      // CHECKING THE assocLists ARRAY

      if (assocLists && assocLists.length > 0) {
        listItemIds.push(`${currentListItemId}`);
        // THIS MEANS THAT THERE ARE OTHER LISTS WHERE THIS ITEM EXISTS
        console.log(`deleting itemId: ${item.id} exists in multiple diff lists: `, assocLists);
        // START BY ITERATING THROUGH THE assocLIsts ARR AND FIND EVERYTHING THAT THE
        // USER CHECKED
        assocLists.filter(l => l.checked === true).map((chkd) => {
          console.log(`In the map func of assocLists - pushing this into listItemIds: `, chkd);
          listItemIds.push(`${chkd.listItemId}`);
        });

        // NOW, IF item.sharedLists SIZE === THE SIZE OF liteItemIds (THE LIST ITEMS THAT WERE CHECKED + THE ONE FOR THE SOURCE LIST) 
        // WE CAN DO THE FULL DELETE OF THE ListItems AND THE Item
        if (item.sharedLists.length === listItemIds.length) {
          await deleteItemAndListItem({
            variables: { listItemIds, itemId: item.id },
          })
            .then(() => {
              allUpdated = true;
            })
            .catch(e => {
              if (dispalyConsole) {
                console.error(`draweritem.item.delete > doTheDelete > Error attempting to call 'deleteItemAndListItem': `, e);
              }
            });

        } else {
          // HOWEVER, IF THOSE NUMBERS AREN'T THE SAME THAT MEANS THAT THE USER WANTS TO LEAVE AN Item / ListItem 
          // SO JUST DELETE THE PICKED ListItem(s)
          await deleteListItem({
            variables: { listItemIds },
          })
            .then(() => {
              allUpdated = true;
            })
            .catch(e => {
              if (dispalyConsole) {
                console.error(`draweritem.item.delete > doTheDelete > Error attempting to call 'deleteListItem': `, e);
              }
            });

        }
      } else {
        // THIS ITEM ONLY EXISTS IN THE CURRENT LIST - BECAUSE OF THE WAY THAT THE CHECKBOX UI WORKS
        // TO FIND THE SAME ITEM ACROSS MULTIPLE DIFFERENT LISTS, WE WILL POPULATE THE listItemIds ARR HERE MANUALLY
        if (item && item.sharedLists && Array.isArray(item.sharedLists)) {
          if (dispalyConsole) {
            console.log(`draweritem.item.delete.assocLists > doTheDelete > lists associated to item '${item.id}': `, item);
          }
    
          _forEach(item.sharedLists, listItem => {
            if (listItem && listItem.id) {
              // THIS IS THE CURRENT ListItem THAT WE PULLED OUT OF THE item PASSED IN (FROM sharedLists)
              listItemIds.push(`${listItem.id}`);
            }
          });
        }
        const graphqlVars = { listItemIds, itemId: item.id };
        if (dispalyConsole) {
          console.log(`draweritem.item.delete > doTheDelete > This item only exists in the current list. About to call 'deleteItemAndListItem' mutation with these vars: `, graphqlVars);
        }
        await deleteItemAndListItem({
          variables: graphqlVars,
        })
          .then(() => {
            allUpdated = true;
          })
          .catch(e => {
            if (dispalyConsole) {
              console.error(`draweritem.item.delete > doTheDelete > Error attempting to call 'deleteItemAndListItem': `, e);
            }
          });
      }

    } else {
      if (dispalyConsole) {
        console.log(`draweritem.item.delete > doTheDelete > Error attempting the delete - missing one of the core properties: itemId: ${item.id} | currentListItemId: ${currentListItemId} | assocLists: `, assocLists);
      }
    }

    // LASTLY, SHOW MESSAGE TO THE USER
    if (allUpdated) {
      fnGyftoEventPipeline("Item", "Deleted", item.id);
      appState.closeDrawer();
      appState.showMessageCard('msg', `Item removed from the list`);
    } else {
      if (dispalyConsole) {
        console.error(`draweritem.item.delete > doTheDelete > Item wasn't removed from all lists: `);
      }
      appState.closeDrawer();
      appState.showMessageCard('error', "Issue attempting to delete the item from all the lists");
    }
  }

  // IF EVERYTHING GOES WRONG - SHOW A FRIENDLY ERROR UI
  if (errorMsg) {
    return (
      <DrawerContainer>
        <ErrorUI errorMsg={errorMsg ? errorMsg : ""} />
      </DrawerContainer>
    );
  }

  return (
    <Subscribe to={[DrawerContainer]}>
      {appState => (
        <Fragment>
          {loading ? (
            <DrawerSpace>
              <div className="flex content-center">
                <Loading loadingText="Wait for it..." />
              </div>
            </DrawerSpace>
          ) : null}
          <div>
            <DrawerSpace>
              {appState && appState.state && appState.state.selectedItemObj ? (
                <Fragment>
                  <div className="py-4">
                    <DrawerMessageError>
                      <span>You can't take this back!</span>
                    </DrawerMessageError>
                  </div>
                  <div className="pt-4">
                    <ItemCardMin item={appState.state.selectedItemObj} />
                  </div>
                </Fragment>
              ) : (
                  <div />
                )}
              <div className="pt-6" hidden={assocLists && Array.isArray(assocLists) && assocLists.filter(l => l.displayToScreen === true).length < 1}>
                <span className="drawer-header-description-text pb-2">This item is on several <i>other</i> lists as well - select the additional lists to delete this item from</span>
                {/* THIS WILL FIRST SORT THE LISTS BY ORDINAL AND THEN ITERATE OVER THEM TO DISPALY */}
                {assocLists.filter(l => l.displayToScreen === true).sort((a, b) => (a.ordinal > b.ordinal) ? 1 : ((b.ordinal > a.ordinal) ? -1 : 0)).map(lst => (
                  <div key={lst.list.id} className="py-4 flex items-start">
                    <div className="pt-1">
                      <Checkbox name={lst.list.id} checked={lst.checked} onChange={handleChange} />
                    </div>
                    <div className="ml-4">
                      <ListCardMin key={lst.list.id} list={lst.list} showActions={false} />
                    </div>
                  </div>
                ))}
              </div>
              <div className=" pt-8 w-full flex justify-center content-center">
                <Button
                  showSpinner={true}
                  buttonType="pill-drawer-delete"
                  xtra="hover:shadow w-gyfto-drawer-button"
                  onClick={async () => {
                    // console.log(`delete this thing: `, appState);
                    await doTheDelete(appState).then(() => {
                    });
                  }}
                >
                  {assocLists && Array.isArray(assocLists) && assocLists.length > 0 && assocLists.filter(l => l.displayToScreen === true).length < 2 ? (
                    <span className="text-center">delete from  {(checkForCheck(assocLists)) > 1 ? 'these ' + (checkForCheck(assocLists)) + ' lists' : 'this list'}</span>
                  ) : (
                      <span className="text-center">delete this item</span>
                    )}
                </Button>
              </div>
            </DrawerSpace>
          </div>
        </Fragment>
      )}
    </Subscribe>
  );
}
/* eslint-enable  */

DrawerItemItemDelete.propTypes = { 
  item: PropTypes.object,
  listId: PropTypes.string
};


DrawerItemItemDelete.defaultProps = {
  item: null,
  listId: null,
};
