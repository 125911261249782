import React from 'react';

export default function SVGPrivateList(props) {
    const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;

    if (displayConsole) {
        console.log(`private-list-svg > props: `, props);
    }
    return (
        <div >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={208.076}
                height={195.65}
                {...props}
            >
                <defs>
                    <clipPath id="a">
                        <path
                            data-name="Path 15"
                            d="M289 281.518q2.655 41.847-39.192 32.744T180.4 299.976q-27.561-5.183-57.144-32.744t2.781-52.34a609.414 609.414 0 0 0 57.144-49.18q24.779-24.4 56.259-6.7t39.192 49.18q7.711 31.479 10.368 73.326Z"
                            fill="rgba(167,182,191,0.1)"
                        />
                    </clipPath>
                    <clipPath id="b">
                        <path
                            data-name="Rectangle 2"
                            transform="translate(781.19 -13805.715)"
                            fill="#6dc088"
                            d="M0 0h129v43H0z"
                        />
                    </clipPath>
                </defs>
                <g data-name="Private List" transform="translate(-848.257 13768.825)">
                    <g
                        data-name="Blob shape"
                        transform="rotate(-169 -93.718 -6754.895)"
                        clipPath="url(#a)"
                    >
                        <path
                            data-name="Path 14"
                            d="M289 281.518q2.655 41.847-39.192 32.744T180.4 299.976q-27.561-5.183-57.144-32.744t2.781-52.34a609.414 609.414 0 0 0 57.144-49.18q24.779-24.4 56.259-6.7t39.192 49.18q7.711 31.479 10.368 73.326Z"
                            fill="rgba(167,182,191,0.1)"
                        />
                    </g>
                    <path
                        data-name="Path 7"
                        d="M1021.078-13594.286V-13732a4 4 0 0 0-4-4h-98.2a4 4 0 0 0-4 4v137.714a4 4 0 0 0 4 4h98.2a4 4 0 0 0 4-4Z"
                        fill="#fff"
                    />
                    <path
                        data-name="Path 8"
                        d="M1014.2-13698.484v-26.872a4 4 0 0 0-4-4h-54.891m58.891 82.066v7.307m0 16.114v36.228a4 4 0 0 1-4 4h-15.89m-45-145.715H912a4 4 0 0 0-4 4v137.715a4 4 0 0 0 4 4h67.31"
                        fill="none"
                        stroke="#6dc088"
                        strokeLinecap="round"
                        strokeWidth={2}
                    />
                    <path
                        data-name="Path 9"
                        d="M1014.2-13698.484v-26.872a4 4 0 0 0-4-4h-54.891m58.891 82.066v7.307m0 16.114v36.228a4 4 0 0 1-4 4h-15.89m-45-145.715H912a4 4 0 0 0-4 4v137.715a4 4 0 0 0 4 4h50.81"
                        fill="none"
                        stroke="#6dc088"
                        strokeLinecap="round"
                        strokeWidth={2}
                    />
                    <path
                        data-name="Path 10"
                        d="M1014.2-13713.3v-12.056a4 4 0 0 0-4-4H912a4 4 0 0 0-4 4v137.715a4 4 0 0 0 4 4h52.81m49.39-108.669v32.523m0 9.262v13.542m0 10.5v38.846a4 4 0 0 1-4 4h-9.7m-64.858-86.349h10.021m25.147 0h-14m-21.168 26.892h42.128m-42.128 26.894h46.882"
                        fill="none"
                        stroke="#adbcc3"
                        strokeLinecap="round"
                        strokeWidth={2}
                    />
                    <ellipse
                        data-name="Ellipse 3"
                        cx={3.738}
                        cy={3.705}
                        rx={3.738}
                        ry={3.705}
                        transform="translate(921.441 -13673.787)"
                        fill="none"
                        stroke="#6abd85"
                        strokeWidth={2}
                    />
                    <rect
                        data-name="Rectangle 1"
                        width={7}
                        height={7}
                        rx={1}
                        transform="translate(921.81 -13647)"
                        fill="none"
                        stroke="#6dc088"
                        strokeWidth={2}
                    />
                    <ellipse
                        data-name="Ellipse 4"
                        cx={3.738}
                        cy={3.705}
                        rx={3.738}
                        ry={3.705}
                        transform="translate(921.441 -13620.002)"
                        fill="none"
                        stroke="#6abd85"
                        strokeWidth={2}
                    />
                    <path
                        data-name="Path 11"
                        d="M935.642-13696.2h66.882"
                        fill="none"
                        stroke="#adbcc3"
                        strokeLinecap="round"
                        strokeWidth={2}
                    />
                    <ellipse
                        data-name="Ellipse 5"
                        cx={3.738}
                        cy={3.705}
                        rx={3.738}
                        ry={3.705}
                        transform="translate(921.441 -13700)"
                        fill="none"
                        stroke="#66ba83"
                        strokeWidth={2}
                    />
                    <path
                        data-name="Path 4826"
                        d="M1021.078-13591.286V-13729a4 4 0 0 0-4-4h-98.2a4 4 0 0 0-4 4v137.714a4 4 0 0 0 4 4h98.2a4 4 0 0 0 4-4Z"
                        fill="#fff"
                        opacity={0.77}
                    />
                    <g transform="translate(121.81 123.715)" clipPath="url(#b)">
                        <path
                            d="M831.586-13798.214a22.92 22.92 0 0 1 14.209-5.1 23.632 23.632 0 0 1 16.166 6.765 32.516 32.516 0 0 1 7.8 11 2.669 2.669 0 0 1 0 2.065 33.278 33.278 0 0 1-6.706 9.937l8.83 6.916a2.015 2.015 0 1 1-2.484 3.173l-49.691-38.942a2.014 2.014 0 1 1 2.485-3.171Zm3.282 2.579 3.861 3.022a10.75 10.75 0 0 1 16.618 13.026l4.515 3.542a32.632 32.632 0 0 0 5.959-8.469 30.28 30.28 0 0 0-6.605-9.09c-3.45-3.2-7.948-5.758-13.421-5.758a18.513 18.513 0 0 0-10.927 3.728Zm17.214 13.488a6.839 6.839 0 0 0 .428-2.442 6.761 6.761 0 0 0-6.714-6.711c-.059 0-.109.076-.243.076a3.228 3.228 0 0 1 .243 1.267 5.434 5.434 0 0 1-.558 2.447Zm.789 10.936 3.517 2.77a22.063 22.063 0 0 1-10.592 2.728 23.617 23.617 0 0 1-16.165-6.765 34.179 34.179 0 0 1-7.8-11 2.684 2.684 0 0 1 0-2.065 31.913 31.913 0 0 1 4.082-6.9l3.165 2.493a26.939 26.939 0 0 0-3.3 5.363 29.144 29.144 0 0 0 6.6 9.165 19.646 19.646 0 0 0 13.421 5.682 17.855 17.855 0 0 0 7.075-1.469Zm-17.818-13.378c0-.168.008-.4.025-.638l4.709 3.71a6.418 6.418 0 0 0 4.5 3.475l4.717 3.785a13.8 13.8 0 0 1-3.282.487 10.807 10.807 0 0 1-10.745-10.819Z"
                            fill="#6dc088"
                        />
                    </g>
                </g>
            </svg>
        </div>
    );
}
