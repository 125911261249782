
import React, { Component, Fragment } from 'react';
// import PropTypes from 'prop-types';
import { Subscribe } from 'unstated';
import CardSpace from '../home/card.css';
// THIS IS FOR THE unstated CONTAINER
import DrawerContainer from '../../containers/drawer';

export default function ListCardNew(props) {

  return (
    <React.Fragment>
      <Subscribe to={[DrawerContainer]}>
        {drawerContainer => (
          <CardSpace type="new"> 
            <div className="flex flex-col text-white min-h-gyfto-card items-stretch align-text-bottom cursor-pointer rounded-lg">
              <div className="text-center flex-1"></div>
              <div className="text-center flex-1 align-middle pt-8">
                <span className="text-center text-white text-7xl">+</span>
              </div>
              <div className="text-center text-2xl flex-1 mt-12">New List</div>
            </div>
          </CardSpace>
        )}
      </Subscribe>
    </React.Fragment>
  );
}

// ListCard.propTypes = {
//   list: PropTypes.object,
//   showActions: PropTypes.bool,
// };

// ListCard.defaultProps = {
//   list: null,
//   showActions: false,
// };
