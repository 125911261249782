import React from 'react';
import PropTypes from 'prop-types';
import { fnSetDefaultImgSrcOnError } from '../../functions/index';

class ItemImage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {}; 
  }

  cropImage = (imageUrl, size) => {
    let updatedImageUrl = null;

    let cropElement = null;

    if (imageUrl) {
      cropElement = size === 'medium' ? 'h_110,w_110' : 'h_375,w_375';
      updatedImageUrl = `${imageUrl.substring(0, 45)}c_imagga_scale,${cropElement}${imageUrl.substring(44)}`;

      if (true) {
        console.log(`in cropImage: ${updatedImageUrl} & size: ${size} & cropElement: ${cropElement}`);
      }
    }

    return updatedImageUrl;
  };


  render() {
    const { url, imageUrl, processedImage, size } = this.props;

    return (
      <React.Fragment>
        {imageUrl ? (
          <div className='flex justify-center max-w-80p'>
            {url ? (
              <a href={url} target="_blank" rel="noopener noreferrer">
                {processedImage ? (
                  // APPLY THE IMAGE CROP
                  <img src={this.cropImage(imageUrl, size)} alt="" loading="lazy" onError={fnSetDefaultImgSrcOnError} />
                ) : (
                  <img src={imageUrl} alt="" loading="lazy" onError={fnSetDefaultImgSrcOnError} />
                )}
              </a>
            ) : (
              <img src={imageUrl} 
              onError={fnSetDefaultImgSrcOnError}
              alt="" loading="lazy" />
            )}
          </div>
        ) : (
          <div />
        )}
      </React.Fragment>
    );
  }
}

export default ItemImage;

ItemImage.propTypes = {
  imageUrl: PropTypes.string,
  url: PropTypes.string,
  processedImage: PropTypes.bool,
  size: PropTypes.string,
};

ItemImage.defaultProps = {
  imageUrl: null,
  url: null,
  processedImage: false,
  size: 'medium',
};
