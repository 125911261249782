import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function HoverMenu({ children }) {
    const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;
    return (
        <div className="z-40">
            <div className="absolute items-center">
                <div className=" text-grey-light ml-8 border-b-0">
                    <FontAwesomeIcon icon="caret-up" size="2x" />
                </div>
                <div className=" text-white ml-8 border-b-0 absolute -mt-7">
                    <FontAwesomeIcon icon="caret-up" size="2x" />
                </div>
                <div className="-ml-12 -mt-3 shadow border border-grey-light rounded  bg-white items-center" >
                        {children}
                </div>
            </div>
        </div>
    );
}

HoverMenu.propTypes = {
    children: PropTypes.object,
};

HoverMenu.defaultProps = {
    children: null,
};