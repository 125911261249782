import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSubscription } from '@apollo/react-hooks';
import { SkeletonListCard } from '../common/skeletons';
import { subGetListsByUserTypeShallow } from '../../graphql/graphql';
import { Subscribe } from 'unstated';
import DrawerContainer from '../../containers/drawer'
import ErrorUI from '../common/error';
import AllLists from './allLists';
const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;
/* eslint-disable */

export default function AllListsContainer({ userId, listUserType, showNewCard, showMoreCard, maxDisplayNumber }) {

  const { loading, error, data } = useSubscription(subGetListsByUserTypeShallow, {
    variables: { userId, listUserType },
    suspend: false,
  });

  if (loading) {
    return (
      <Fragment>
        <div className="xs:hidden md:flex flex-wrap">
          <div className="px-4 py-4">
            <SkeletonListCard />
          </div>
          <div className="px-4 py-4">
            <SkeletonListCard />
          </div>
          <div className="px-4 py-4">
            <SkeletonListCard />
          </div>
          <div className="px-4 py-4">
            <SkeletonListCard />
          </div>
        </div>
        <div className="xs:flex md:hidden flex-wrap">
          <div className="px-4 py-4">
            <SkeletonListCard />
          </div>
        </div>
      </Fragment>
    );
  }

  if (error) {
    return (
      <Fragment>
        <ErrorUI errorMsg={error.message} />
      </Fragment>
    );
  }

  if (data) {
    if (displayConsole) {
      console.log(`allLists.container > Here is the data back for '${userId}' & listUserType '${listUserType}':`, data.User[0].listUsers);
    }
    return (
      <Subscribe to={[DrawerContainer]}>
        {appState => (
          <Fragment>
            <AllLists userId={userId} listUserType={listUserType} containerData={data && Array.isArray(data.User) && data.User.length === 1 && data.User[0].listUsers && Array.isArray(data.User[0].listUsers) ? data.User[0].listUsers : null} showNewCard={showNewCard} showMoreCard={showMoreCard} maxDisplayNumber={maxDisplayNumber} appState={appState} />
          </Fragment>
        )}
      </Subscribe>
    )
  } 
}
/* eslint-enable */

AllListsContainer.propTypes = {
  userId: PropTypes.string,
  listUserType: PropTypes.array,
  showNewCard: PropTypes.bool,
  showMoreCard: PropTypes.bool,
  maxDisplayNumber: PropTypes.number,
};

AllListsContainer.defaultProps = {
  userId: null,
  listUserType: null,
  showNewCard: false,
  showMoreCard: false,
  maxDisplayNumber: 0,
};