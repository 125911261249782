import React, { Fragment } from 'react';
import EmptyShare from '../common/emptyShare';

/* eslint-disable  */
export default function ShareEmpty() {
  const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;
  const componentName = 'shareEmpty';

  if (displayConsole) {
    console.log(`${componentName} > appState : ${appState}`);
  }

  return (
    <Fragment>
      <div className="xs:hidden md:flex w-full justify-center" >
        <div className='flex pt-3'>
          <div className=''>
            <EmptyShare />
          </div>
          <div className='pl-3 pt-2'>
            <div className='font-bold text-2xl'>You don't have any shares</div>
            <div className='text-sm text-grey pt-2 max-w-xs'>Invite your friends to join Gyfto and share there lists with you</div>
          </div>
        </div>
      </div>
      <div className="xs:flex md:hidden w-full justify-center" >
        <div className='w-full flex flex-col pt-3 justify-center'>
          <div className='flex justify-center'>
            <EmptyShare />
          </div>
          <div className='pl-3 pt-2'>
            <div className='font-bold text-2xl'>You don't have any shares</div>
            <div className='text-sm text-grey pt-2 max-w-xs'>Invite your friends to join Gyfto and share there lists with you</div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}