import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Button(props) {
  const { buttonType, weight, xtra, type, children, onClick, disabled, responsive, showSpinner, htmlFor } = props;
  const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;
  let renderButtonType = 'btn ';
  let [localButtonText, setLocalButtonText] = useState(null);

  switch (buttonType) {
    case 'outline':
      renderButtonType += ' btn-outline hover:bg-gyfto-green hover:text-white hover:border-transparent';
      break;
    case 'plain':
      renderButtonType += ' w-auto font-bold btn-plain hover:underline ';
      break;
    case 'pill':
      renderButtonType += ' btn-pill hover:border-transparent hover:bg-gyfto-green hover:text-white';
      break;
    case 'pill-white':
      renderButtonType += ' btn-pill-color bg-white border-white text-gyfto-green hover:bg-gyfto-green hover:text-white';
      break;
    case 'pill-landing-cta':
      renderButtonType += ' btn-pill-color bg-white border-white text-gyfto-green hover:shadow-md';
      break;
    case 'pill-delete':
      renderButtonType += ' btn-pill-color border-red bg-red text-white hover:bg-white hover:text-red';
      break;
    case 'pill-share':
      renderButtonType += ' btn-pill-color border-purple bg-purple text-white hover:bg-white hover:text-purple';
      break;
    case 'pill-share-outline-white':
      renderButtonType += ' btn-pill-color border-white bg-purple text-white hover:bg-white hover:text-purple p-0';
      break;
    case 'pill-share-outline-none':
      renderButtonType += ' btn-pill-color border-purple bg-purple-dark text-white p-0';
      break;
    case 'pill-add':
      renderButtonType += ' btn-pill-color border-blue bg-blue text-white hover:bg-white hover:text-blue';
      break;
    case 'pill-edit':
      renderButtonType += ' btn-pill-color border-gyfto-pink bg-gyfto-pink text-white hover:bg-white hover:text-gyfto-pink';
      break;
    case 'pill-cancel':
      renderButtonType += ' btn-pill border-transparent hover:border-gyfto-green hover:text-gyfto-green';
      break;
    case 'pill-drawer-delete':
      renderButtonType =
        'btn-pill-color-drawer border-red bg-red text-white hover:bg-white hover:text-red hover:shadow';
      break;
    case 'pill-drawer-leave':
      renderButtonType =
        'btn-pill-color-drawer border-purple bg-purple text-white hover:bg-white hover:text-purple hover:shadow';
      break;
    case 'pill-void':
      renderButtonType += ' btn-pill-color border-grey bg-grey text-white hover:bg-white hover:text-grey';
      break;
    case 'pill-void-standard':
      renderButtonType +=
        ' btn-pill-color border-grey-lighter bg-grey-lighter text-grey-darker hover:bg-gyfto-green hover:border-gyfto-green hover:text-white';
      break;
    case 'pill-void-tiny':
        renderButtonType +=
          ' btn-pill-color text-xxs p-0';
        break;
    case 'pill-void-standard-light':
      renderButtonType +=
        ' btn-pill-color bg-grey-lighter text-grey-darker hover:bg-grey hover:text-white border-grey-light font-bold p-1';
      break;
      case 'pill-void-stardard-blue':
        renderButtonType += ' btn-pill-color border-blue bg-blue text-white hover:bg-white hover:text-blue font-bold p-1';
        break;
    case 'pill-void-standard-light-no-border-no-hover':
      renderButtonType +=
        ' btn-pill-color bg-grey-lighter text-grey-darker font-bold border-0 cursor-default';
      break;
    case 'pill-void-share':
      renderButtonType += ' btn-pill-color border-grey bg-grey text-white hover:bg-purple hover:text-white';
      break;
    case 'pill-standard':
      renderButtonType +=
        ' btn-pill-color border-gyfto-green bg-gyfto-green text-white hover:bg-white hover:text-gyfto-green';
      break; 
    case 'pill-no-style':
      renderButtonType +=
        ' text-gyfto-green hover:bg-white bg-white border-transparent';
      break;
    case 'pill-no-style-hover':
      renderButtonType +=
        ' btn-pill-color text-gyfto-green hover:bg-grey-lighter bg-white border-transparent';
      break;
    default:
      renderButtonType = 'btn';
  }

  // ADD ANY ADDITIONAL CLASS STYLING IF PASSED
  if (weight && weight !== undefined && weight !== '') {
    switch (weight) {
      case 'thin':
        renderButtonType += ' btn-thin';
        break;
      default:
        break;
    }
  }

// IF WE'RE ON A SMALL DEVICE - ADD SOME PADDING TO THE BUTTON
if (responsive) {
  renderButtonType += ' xs:py-4 xs:px-6 md:py-3 md:px-6 xs:text-base md:text-sm';
}

  if (xtra && xtra !== undefined && xtra !== '') {
    renderButtonType += ` ${xtra}`;
  }

  

  if (displayConsole) {
    // THIS CAUSES LOTS OF NOISE
    console.log(`button > ${children} type: ${type} - renderButtonType: ${renderButtonType} - xtra: ${xtra} || props: `, props);
  }

  // CAN INTERCEPT THE NATIVE BUTTON CLICK AND FUNCTION PASSED DOWN FROM
  // THE PARENT AND ADD TO THAT EVENT
  function handleTheClick() {
    if (onClick) {
      if (displayConsole) {
        console.log(`button > handleTheClick > onClickEvent clicked!`, onClick);
      }
      // REPLACE THE BUTTON TEXT WITH A SPINNER
      setLocalButtonText(<FontAwesomeIcon icon="circle-notch" size="1x" spin />)

      // CALL THE onClick EVENT (FROM PARENT)
      onClick();

    }
  }

  return (
    <div>
      {/* eslint-disable-next-line  */}
      <button htmlFor={htmlFor} type={type} disabled={disabled} className={renderButtonType} onClick={(e) => { e =>
        // THIS IS ALL REQUIRED IF WE WANT TO MAKE SURE THAT ANY PARENT
        // COMPONENTS DON'T SWALLOW UP THIS CLICK
        e.preventDefault();
        e.stopPropagation();
        handleTheClick();
      }} style={{ outline: 'none', maxHeight: '52px' }}>
        {showSpinner ? (localButtonText ? localButtonText : children) : children}
      </button>
    </div>
  );
}

Button.propTypes = {
  children: PropTypes.node,
  buttonType: PropTypes.string,
  showSpinner: PropTypes.bool,
  weight: PropTypes.string,
  xtra: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  responsive: PropTypes.bool,
  htmlFor: PropTypes.string
};

Button.defaultProps = {
  children: null,
  buttonType: `primary`,
  showSpinner: false,
  weight: null,
  xtra: null,
  type: 'button',
  onClick: null,
  disabled: false,
  responsive: true,
  htmlFor: ''
};
