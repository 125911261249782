import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Chip(props) {
  // eslint-disable-line
  const { label, deleteChip, chipType } = props;

  let chipCSS = '';
  let chipCloseCSS = '';
  switch (chipType) {
    case 'share':
      chipCSS = 'bg-purple text-white';
      chipCloseCSS = 'text-white';
      break;
    default:
      chipCSS = 'bg-grey-light text-black';
      chipCloseCSS = 'text-black';
  }
  return (
    <div
      className={`flex flex-wrap items-center rounded-full py-2 px-4 uppercase text-sm ${chipCSS}`}
      style={{ width: 'fit-content' }}
    >
      <div>{label}</div>
      <div className="pl-4">
        <FontAwesomeIcon size="1x" className={`cursor-pointer ${chipCloseCSS}`} icon="times" onClick={deleteChip} />
      </div>
    </div>
  );
}
