import React, { Component } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAngleDown,
  faAngleUp,
  faCaretUp,
  faTimes,
  faTrash,
  faEdit,
  faClone,
  faCircleNotch,
  faCamera,
  faArrowUp,
  faArrowDown,
  faThumbsUp
} from '@fortawesome/free-solid-svg-icons';
import { Provider } from 'unstated';

// ROUTE COMPONENTS
import { Redirect, Route } from 'react-router-dom';

import Header from './components/header';
import Footer from './components/footer';
import DrawerPanel from './components/drawer';
import NotificationCard from './components/notificationCard';
import Home from './components/home';
import ListContainerAuth from './components/list/list.container.auth';
import ListConatinerNoAuth from './components/list/list.container.noauth';
import NotAuthenticated from './components/notAuthenticated';
import Shares from './components/shares';
import Items from './components/items'
import Lists from './components/lists';
import Callback from './components/callback/index';

// APP CONTEXT
import AppContext from './context/context.app';

// BOOTSTRAPING FONTAWESOME
library.add(faAngleDown, faAngleUp, faCaretUp, faTimes, faTrash, faEdit, faClone, faCircleNotch, faCamera, faArrowUp, faArrowDown,  faThumbsUp);

// VARIABLES
// const isAuthenticated = false;

class App extends Component {
  // constructor(props) {
  //   super(props);
  // }

  handleAuthentication = ({ location }) => {
    const { auth } = this.props;
    if (/access_token|id_token|error/.test(location.hash)) {
      auth.handleAuthentication();
    }
  };

  render() {
    // THIS IS USED TO DETERMINE THE PATH SO WE CAN
    // SELECTIVELY SHOW THE NAV LINKS (BELOW)
    // eslint-disable-next-line
    const { auth } = this.props;
    const isAuthenticated = auth.isAuthenticated();
    // console.log(`app > isAuthenticated: ${isAuthenticated}`);
    return (
      <div className="sans text-smooth h-auto">
        <Provider>
          <AppContext.Provider value={{ state: this.state, login: () => this.login() }}>

            {/* 07/23/22 - DON'T SHOW THE <Header /> COMPONENT ON THE LANDING ROUTE */}
            <Route
              render={({ location }) =>
                location.pathname !== "/" ? (
                  <div className="hidden md:block sticky" style={{ top: '0px', width: '100vw', zIndex: 300 }}>
                    {/* <div className="h-1 bg-gyfto-green" /> */}
                    <Header auth={auth} />
                  </div>) : null
              }
            />
            <DrawerPanel />
            <div className="bg-gyfto-main">
              <Route
                exact
                path="/h"
                render={props => (isAuthenticated ? <Home {...props} auth={auth} /> : <NotAuthenticated auth={auth} />)}
              />
              {/* 06/04/21 - CHANGED THIS ROUTE TO FORWARD TO THE 'ListContainer' BASED UPON AUTHENTICATION */}
              <Route
                exact
                path="/l/:listId"
                render={props =>
                  isAuthenticated ? <ListContainerAuth {...props} auth={auth} /> : <ListConatinerNoAuth {...props} auth={auth} fromShareLink={true} />
                }
              />
              <Route path="/na" component={NotAuthenticated} />
              <Route
                exact
                path="/sh"
                render={props =>
                  // isAuthenticated ? <Shares {...props} auth={auth} /> : <NotAuthenticated auth={auth} />
                  isAuthenticated ? <Shares {...props} auth={auth} /> : <Redirect to="/" />
                }
              />
              <Route
                exact
                path="/ls"
                render={props =>
                  // isAuthenticated ? <Lists {...props} auth={auth} /> : <NotAuthenticated auth={auth} />
                  isAuthenticated ? <Lists {...props} auth={auth} /> : <Redirect to="/" />
                }
              />
              <Route
                exact
                path="/itms"
                render={props =>
                  // isAuthenticated ? <Items {...props} auth={auth} /> : <NotAuthenticated auth={auth} />
                  isAuthenticated ? <Items {...props} auth={auth} /> : <Redirect to="/" />
                }
              />
              <Route
                exact
                path="/sl/:listId/og/:ogListId"
                render={props =>
                  isAuthenticated ? <ListContainerAuth {...props} auth={auth} fromShareLink={true} /> : <ListConatinerNoAuth {...props} auth={auth} fromShareLink={true} />
                }
              />
            </div><div>
              {/* THIS HANDLES THE LANDING PAGE */}
              <Route path="/"
                exact
                render={props =>
                  isAuthenticated ? <Redirect to="/h" /> : <NotAuthenticated auth={auth} />
                } />
              <Route
                path="/callback"
                render={props => {
                  this.handleAuthentication(props);
                  return <Callback {...props} />;
                }}
              />
            </div>
            <Footer />
            <NotificationCard />
          </AppContext.Provider>
        </Provider>
      </div>
    );
  }
}

export default App;
