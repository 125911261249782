import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class ItemName extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { url, localRoute, name, textClass, xsMinWidth } = this.props;
    let cssClass = `item-name ${textClass ? textClass : 'text-xl'}`;
    let xsMinWidthVal = xsMinWidth ? 'xs:w-52' : '';
    return (
      <div
        className={`item-section md:w-full ${xsMinWidthVal} max-w-gyfto-max-card-item-name-md`}
      >
        {/* eslint-disable no-nested-ternary */}
        {url ? (
          !localRoute ? (
            <a href={url} target="_blank" rel="noopener noreferrer" className={`${cssClass}`} alt={name}>
              {name}
            </a>
          ) : (
            <Link to={`/l/${url}`} className={`${cssClass}`} alt={name}>
              <span style={{textOverflow:'elipsis', width:'180px',  overflow:'hidden',whiteSpace:'nowrap'}}>{name}</span>
            </Link>
          )
        ) : (
          <div className={`${cssClass}`} style={{textOverflow:'elipsis', width:'180px',  overflow:'hidden',whiteSpace:'nowrap'}}>{name} </div>
        )}
        {/* eslint-enable no-nested-ternary */}
      </div>
    );
  }
}

export default ItemName;

ItemName.propTypes = {
  url: PropTypes.string,
  name: PropTypes.string,
  localRoute: PropTypes.bool,
  textCase: PropTypes.string,
  xsMinWidth: PropTypes.bool
};

ItemName.defaultProps = {
  url: null,
  name: null,
  localRoute: false,
  textCase: 'text-xl',
  xsMinWidth: true
};
