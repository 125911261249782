import React, { Component } from 'react';

export default class PageSpace extends Component {
  render() {
    /* eslint-disable-next-line  */
    const { children } = this.props;
    return (
      <div>
        {/* THIS IS THE TOP WHITE BORDER THAT SPANS THE WHOLE PAGE */}
        {/* TRYING TO SORT OUT WEIRD PADDING AT THE TOP OF THE MAIN SECTION */}
        <div className="sm:pt-8 justify-center flex">
          {/* THIS IS THE INNER CONTAINER THAT CENTERS THE CONTENT AND FORCES A MAX WIDTH */}
          <div className="container"> 
            <div className="pb-6">{children}</div>
          </div>
        </div>
      </div>
    );
  }
}
