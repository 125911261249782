import React, { Fragment, useState, useEffect } from 'react';
import _forEach from 'lodash.foreach';
import _size from 'lodash.size';
import PropTypes from 'prop-types';
import { Subscribe } from 'unstated';
import Carat from '../common/carat';
import UserAvatar from '../avatar/userAvatar';
import ItemCardUserAvatar from '../avatar/itemCardUserAvatar';
import ItemProps from './itemProps';
import ItemName from './itemName';
import ItemWatch from './itemWatch';
import ItemObtained from './itemObtained';
import ItemImage from './itemImage';
import ItemDelete from './itemDelete';
import ItemEdit from './itemEdit';
import ItemMove from './itemMove';
import DrawerContainer from '../../containers/drawer';
import SVGDelete from '../common/icons/delete-svg';
import SVGEdit from '../common/icons/edit-svg';
import SVGMove from '../common/icons/move-svg';
import Checkbox from '../common/checkbox';
import HideShowLongText from '../common/hideShowLongText';
import JumpingDots from '../common/jumpingDots';
import Button from '../common/button';
import _ from 'lodash';

export default function ItemCard({ listItem, listItemId, isShare, currentUserCanSee, currentUserCanTakeActionOnItemCard, currentUserIsOwnerOrEditor, ordinal, isAuthenticated, loopCount, loopTotalCount, updateItemOrdinal, sharedListIds, listBuyType, listId, listUserType, listIsReordering, listEditorsLength, filterVisible, ogListMode, showSelectItem, selectedListItemsArr, onListItemClick }) {

  // FIRST THINGS FIRST - FIGURE OUT WHO THE CURRENT USER IS
  const COMPONENT_NAME = 'itemCard';
  const currentUserId = localStorage.getItem('user_id');
  const appUserId = localStorage.getItem('user_id_app');
  const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;// listItem.id === "22a9222b-e935-4c69-bf44-aaf7203ceeb8";
  // STATE TO TRACK THE SELECTED ITEM
  const [thisItemIsSelected, setThisItemIsSelected] = useState(false);
  let currentItemWatches = [];
  let currentItemWatchesUserIds = [];
  let itemWatchesType = 0; // 0 = CANNOT CREATE, 1 = CAN CREATE, 2 = CAN DELETE/CREATE
  let itemWatchId = null;
  let sourceListId = null;

  // THIS WILL FIRE ONCE WE GET THE item OBJECT FROM PROPS
  useEffect(() => {
    async function doUseEffectStuff() {
      if (displayConsole) {
        console.log(`${COMPONENT_NAME} > useEffect - isShare: ${isShare}`);
        console.log(`${COMPONENT_NAME} > useEffect - listItem: `, listItem);
        // console.log(`${COMPONENT_NAME} > useEffect - selectedListItemsArr: `, selectedListItemsArr);
      }

      // IF WE FIND THE CURRENT ITEM IN THE selectedListItems ARR
      if (selectedListItemsArr && Array.isArray(selectedListItemsArr)) {
        // TRY TO FIND THE listItem.id IN THE props (selectedListItemsArr)
        if (_.find(selectedListItemsArr, searchItemId => { return searchItemId === listItem.id; }) === listItem.id) {
          //  UPDATE CHECKBOX STATE
          setThisItemIsSelected(true);
          if (displayConsole) {
            console.log(`${COMPONENT_NAME} > useEffect - setting this item as 'checked' because we found it in selectedListItemsArr: `, selectedListItemsArr);
          }
        } else {
          setThisItemIsSelected(false);
        }
      }
    }
    doUseEffectStuff();
  }, [selectedListItemsArr]);

  // EXIT IF listItem IS EMPTY
  if (!listItem) {
    return <React.Fragment />;
  }

  // HACK - NEW ARRAY OF USERS THIS ITEM IS SHARED WITH
  const sharedWith = [];
  const listUsersSharedWith = [];
  let showSharedWith = false;
  // ONLY DO THIS CALC IF THE USER IS AUTHENTICATED
  if (isAuthenticated && isAuthenticated === true) {

    // FIGURE OUT WHO ELSE THIS ITEM IS SHARED WITH
    const tmpListSharedWithArr = [];
    _forEach(listItem.sharedLists, listSharedWith => {
      if (displayConsole) {
        console.log(`### ${COMPONENT_NAME} > listItem: `, listItem);
      }
      // ONLY SHOW THE 'Also Shared With' SECTION IF THIS ITEM IS SHARED WITH OTHER PEOPLE 
      // THAN THE CURRENT USER
      if (listSharedWith && listSharedWith.list && listSharedWith.list.listUsers && Array.isArray(listSharedWith.list.listUsers) && listSharedWith.list.listUsers.length > 0 && listSharedWith.list.listUsers[0].listUserType) {
        // THIS IS TO FIND USERS WHO ARE SHARED WITH ON THIS LIST
        if (listSharedWith.list.listUsers[0].listUserType === 'SharedWith' && listSharedWith.list.deletedState === false) {
          if (listSharedWith.list.listUsers[0].user.id !== currentUserId) {
            // THIS ONE IS FOR A NON OWNER LIST CARD (NO CLICKING THE USER AVATAR)
            sharedWith.push(listSharedWith.list.listUsers[0].user);

            // THIS ONE IS FOR AN OWNER/EDITOR LIST CARD (CAN CLICK THE USER AVATAR TO DELETE A SHARE)
            tmpListSharedWithArr.push(listSharedWith.list.listUsers[0].user);
            showSharedWith = true;
          }
        } else if ((listSharedWith.list.listUsers[0].listUserType === 'Owner') || (listSharedWith.list.listUsers[0].listUserType === 'Editor')) {
          sourceListId = listSharedWith.list.id;
        }
      }
    });

    // NEXT, IF WE SET sourceListId IN THE PREVIOUS STEP THEN DO SOMETHING
    if (sourceListId && Array.isArray(tmpListSharedWithArr) && tmpListSharedWithArr.length > 0) {
      _forEach(tmpListSharedWithArr, usr => {
        listUsersSharedWith.push({ sourceListId: sourceListId, sourceItemId: listItem.id, user: usr });
      })
    }
  } else {
    if (displayConsole) {
      console.log(`${COMPONENT_NAME} > render > isAuthenticated: ${isAuthenticated}`);
    }
  }

  if (displayConsole) {
    console.log(`${COMPONENT_NAME} > render >  listUsersSharedWith: `, listUsersSharedWith);
  }

  /** FIGURING OUT WHAT ICON TO SHOW FOR ITEM WATCH IS A BIT TRICKY - HERE'S
   * THE ROUGH ORDER:
   * 
   * GET CURRENT ITEM WATCHES (X)
   * IF X > 0
   *  ITERATE THROUGH X - FOR EACH (Y)
   *    IF Y == CURRENT USER - TYPE: 2
   *    IF Y !== CURRENT USER (SOMEONE ELSE IS WATCHING)
   *      IF ITEM QTY < COUNT OF ITEM WATCHES - TYPE: 1
   * 
  */

  // FIGURE OUT IF THERE ARE ITEM WATCHES HERE
  // ONLY DO THIS CALC IF THE USER IS AUTHENTICATED
  if (isAuthenticated) {

    // 0 = CANNOT CREATE
    // 1 = CAN CREATE
    // 2 = CAN DELETE/CREATE
    if (_size(listItem.itemWatches) < 1) {
      // NO ITEM WATCHES TAKEN YET
      itemWatchesType = 1;
    } else {
      // AN ITEM WATCH HAS BEEN ENTERED - NEED TO FIGURE OUT WHO DID IT
      currentItemWatches = listItem.itemWatches.filter(itmWtch => itmWtch.watchUsers.id !== currentUserId);
      const currentUserItemWatchArr = listItem.itemWatches.filter(itmWtch => itmWtch.watchUsers.id === currentUserId);

      if (displayConsole) {
        console.log(`${COMPONENT_NAME} > listItem: `, listItem);
        console.log(`${COMPONENT_NAME} > currentItemWatches: `, currentItemWatches);
        console.log(`${COMPONENT_NAME} > currentUserItemWatchArr: `, currentUserItemWatchArr);

      }

      // PASS THE IDS OF USERS THAT DO HAVE WATCHES DOWN TO GET HIGHLIGHTED BY UserAvatar
      listItem.itemWatches.filter(itmWtch => itmWtch.watchUsers.id !== currentUserId).map((itm) => {
        if (itm) {
          currentItemWatchesUserIds.push({ "watchUserId": `${itm.watchUsers.id}` });
        }
      });

      if (currentUserItemWatchArr && Array.isArray(currentUserItemWatchArr) && currentUserItemWatchArr.length > 0) {
        // CURRENT USER IS THE ONLY WATCHER - CAN CREATE/DELETE
        itemWatchesType = 2;
        itemWatchId = currentUserItemWatchArr[0].id;
      } else {
        if (currentItemWatches.length > 0) {
          // THIS MEANS THAT SOMEONE(S) ELSE IS ALSO WATCHING
          if (listItem.qty > currentItemWatches.length) {
            // THERE IS STILL AN OPEN ITEM TO WATCH HERE - CURRENT USER CAN CREATE/DELETE
            itemWatchesType = 1; //3;
            itemWatchId = currentUserItemWatchArr.length > 0 ? currentUserItemWatchArr[0].id : null;
          } else {
            // ALL AVAILABLE SPOTS FOR AN ITEM WATCH ARE TAKEN
            itemWatchesType = 0;
          }
        }
      }
    }
  }

  // FIGURE OUT IF THIS IS A LIST OR A SHARE
  const shareText = isShare ? 'ALSO SHARED WITH' : 'SHARED WITH';

  // FIGURE OUT WHAT TYPE OF MOVEMENT ARROWS TO RENDER FOR THIS ITEM (ONLY IN SM SCREENS)
  let arrowType = 'both';
  if (loopCount === 0) {
    arrowType = 'start';
  } else if (loopCount === loopTotalCount - 1) {
    arrowType = 'end';
  }


  // FOR THE CHECKBOX
  const handleCheckboxChange = (e, inputLisItemtId) => {
    if (displayConsole) {
      console.log(`${COMPONENT_NAME} > handleCheckboxChange > listItem.id: ${inputLisItemtId} || e: `, e.target);
    }

    onListItemClick(e.target.name);
  };

  return (
    <Fragment>
      <Subscribe to={[DrawerContainer]}>
        {appState => (
          <div className="flex bg-white md:max-w-gyfto-max-card-item shadow mb-4" key={listItem.id}>
            {/* THIS IS ONLY SHOWN ON SMALL SCREENS */}
            <div className="md:hidden min-w-gyfto-card-min-in-list xs:relative">
              <div className="pt-2 pl-2 max-w-xs flex">
                {/* THIS IS THE FIRST/LEFT COLUMN WHERE THE SELECT, UP/DOWN AND JUMPING DOTS WILL SHOW */}
                <div style={{ minWidth: '50px' }}>
                  {/* HIDE UP / DOWN IF A SHARE */}
                  {isAuthenticated && !isShare && currentUserCanSee && currentUserIsOwnerOrEditor && !listItem.obtained ? (
                    <div hidden={filterVisible !== ''}>
                      {listIsReordering ? (
                        <div>
                          <JumpingDots />
                        </div>
                      ) : (
                        <div className=" -ml-4 flex flex-col items-center justify-between">
                          {/* eslint-disable  */}
                          {arrowType === 'start' ? (
                            <div className="text-grey cursor-pointer" onClick={() => updateItemOrdinal('down', listItem.id, ordinal)}>
                              <Carat direction="down" size="3x" />
                            </div>
                          ) : (arrowType === 'end') ? (
                            <div className="flex flex-col">
                              <div className="text-grey cursor-pointer" onClick={() => updateItemOrdinal('up', listItem.id, ordinal)}>
                                <Carat direction="up" size="3x" />
                              </div>
                              <div className="text-grey text-2xl -ml-1 pb-3" >
                                <Button
                                  onClick={() => updateItemOrdinal('up', listItem.id, ordinal, true)}
                                  buttonType="pill-void-standard"
                                  xtra="text-xs py-1 px-2"
                                  weight="thin"
                                  responsive={false}
                                >
                                  <span className="text-center">top</span>
                                </Button>
                              </div>
                            </div>
                          ) : (arrowType === 'both') ? (
                            <div className="flex flex-col">
                              <div className="text-grey cursor-pointer" onClick={() => updateItemOrdinal('up', listItem.id, ordinal)}>
                                <Carat direction="up" size="3x" />
                              </div>
                              <div className="text-grey text-2xl -ml-1" >
                                <Button
                                  onClick={() => updateItemOrdinal('up', listItem.id, ordinal, true)}
                                  buttonType="pill-void-standard"
                                  xtra="text-xs py-1 px-2"
                                  weight="thin"
                                  responsive={false}
                                >
                                  <span className="text-center">top</span>
                                </Button>
                              </div>
                              <div className="text-grey cursor-pointer" onClick={() => updateItemOrdinal('down', listItem.id, ordinal)}>
                                <Carat direction="down" size="3x" />
                              </div>
                            </div>
                          ) : <div />}
                          {/* eslint-enable  */}
                        </div>
                      )}
                    </div>
                  ) : null}
                  {/* SHOW SELECT FOR THE CARD */}
                  {showSelectItem ? (
                    <div className='flex pl-1'>
                      <div>
                        <Checkbox name={listItem.id} id={listItem.itemId} checked={thisItemIsSelected} onChange={handleCheckboxChange} />
                      </div>
                    </div>
                  ) : null}
                </div>
                {/* HIDE ORDINAL IF OBTAINED */}
                <div hidden={listItem.obtained} className="item-ordinal pr-2 align-text-top">
                  {ordinal}
                </div>
                <div>
                  <ItemName url={listItem.url} name={listItem.name} localRoute={false} xsMidWidth={'w-full'} />
                  {listItem.store ? (
                    <div className="pt-2 pb-0 text-sm text-grey">{listItem.store}</div>
                  ) : null}
                  <div className="flex items-start align-top">
                    {listUserType && (listUserType.toUpperCase() === 'OWNER' || listUserType.toUpperCase() === 'EDITOR') && listItem && listItem.user && listItem.user.id && listItem.user.id !== currentUserId ? (
                      <div className="flex-no-shrink" style={{ minWidth: '40px' }}><UserAvatar users={[listItem.user]} /></div>
                    ) : null}
                    <div className="item-description"><HideShowLongText displayText={listItem.description} lengthToHide={30} /></div>
                  </div>
                </div>
              </div>

              <div className="break" />

              {/* DON'T SHOW THIS IF THERE ISN'T AN ITEM IMAGE */}
              {listItem.imageUrl ? (
                <Fragment>
                  <div className="flex w-full pt-4 justify-center">
                    <ItemImage
                      imageUrl={listItem.imageUrl}
                      url={listItem.url}
                      processedImage={listItem.processedImage}
                      size="small"
                    />
                  </div>
                  <div className="break md:hidden" /></Fragment>
              ) : <Fragment />}

              {/* THE COST/QTY/COLOR/PRICE SECTION */}
              <ItemProps listItem={listItem} isShare={isShare} />

              {/* THIS IS THE SHARED WITH SECTION */}
              {showSharedWith ? (
                <div className="px-4">
                  <div className="section-header">{shareText}</div>
                  <div className="flex flex-wrap items-center mt-1">
                    {isShare ? (
                      <UserAvatar users={sharedWith} highlightUsers={currentItemWatchesUserIds} />
                    ) : (
                      <ItemCardUserAvatar sharedItemUsers={listUsersSharedWith} />
                    )}
                  </div>
                </div>
              ) : (
                <div />
              )}

              {/* THIS IS THE STORE BUTTON SECTION */}
              {listItem.url ? (
                <Fragment>
                  <div className="break md:hidden" />
                  <div className="pt-6 pb-3 flex flex-col justify-center items-center w-full">
                    {listItem.code ? (
                      <div className="text-xs pb-3 text-grey">Use code <b>'{listItem.code}'</b> at checkout</div>
                    ) : null}

                    <div className="w-full px-4 text-center">
                      <a href={listItem.url} className="btn" target="_blank" rel="noopener noreferrer">
                        SEE AT {listItem && listItem.store ? listItem.store : 'THIS RETAILER'}
                      </a>
                    </div>
                  </div>
                </Fragment>
              ) : (
                <div className="pb-4" />
              )}
              {/* THIS IS THE WATCH / OBATINED SECTION */}
              <div className='w-full px-4'>
                {isShare ? (
                  <div className="w-full"  hidden={!isAuthenticated  || ogListMode}>
                    <ItemWatch
                      listItem={listItem}
                      currentUserId={appUserId}
                      itemWatches={currentItemWatches}
                      itemWatchesType={itemWatchesType}
                      itemWatchId={itemWatchId}
                    />
                  </div>
                ) : (
                  <Fragment>
                    {isAuthenticated && currentUserCanSee ? (
                      <div className="flex justify-center items-center">
                        {listBuyType === 'OwnerBuy' && (listEditorsLength > 0 || (sharedListIds && Array.isArray(sharedListIds) && sharedListIds.length > 0)) ? (
                          <Fragment>
                            <div className='min-w-item-watch-obtained-button'>
                              <ItemObtained item={listItem} screenSize="md" />
                            </div>
                            <div className='pl-2 min-w-item-watch-obtained-button'>
                              <ItemWatch
                                listItem={listItem}
                                currentUserId={appUserId}
                                itemWatches={currentItemWatches}
                                itemWatchesType={itemWatchesType}
                                itemWatchId={itemWatchId}
                              />
                            </div>
                          </Fragment>
                        ) :
                          <div className='w-full'>
                            <ItemObtained item={listItem} screenSize="md" />
                          </div>
                        }
                      </div>
                    ) : null}
                  </Fragment>
                )}
              </div>

              {/* THIS IS THE ACTION BUTTONS AT THE BOTTOM OF THE CARD */}
              {!isShare && isAuthenticated && currentUserCanSee ? (
                <Fragment>

                  <div>
                    <div className="break md:hidden" />
                    <div className="py-3 flex justify-around align-middle w-full">
                      {listItem && listItem.user && listItem.user.id && listItem.user.id === currentUserId ? (
                        <Fragment>
                          <div className="icon-svg-col" onClick={() => {
                            appState.transferItemToDrawer(listItem);
                            appState.selectItem(listItem.id);
                            appState.showHide('item', 'delete', listItem.id);
                          }}>
                            <div className="pb-2"><SVGDelete primaryColor="#5FBA7D" secondaryColor="#979dac" width="20px" height="22px" /></div>
                            <div><span className="section-header">Delete</span></div>
                          </div>

                        </Fragment>
                      ) : null}
                      <div className="icon-svg-col" onClick={() => {
                        appState.transferItemToDrawer(listItem);
                        appState.selectItem(listItem.id);
                        appState.showHide('item', 'edit', listItem.id);
                      }}>
                        <div className="pb-2"><SVGEdit primaryColor="#5FBA7D" secondaryColor="#979dac" width="24px" height="24px" /></div>
                        <div><span className="section-header">Edit</span></div>
                      </div>
                      {/* <div className="icon-svg-col" onClick={() => {
                        appState.transferItemToDrawer(listItem);
                        appState.selectItem(listItem.id);
                        appState.showHide('item', 'copy', listItem.id);
                      }}>
                        <div className="pb-2"><SVGCopy primaryColor="#5FBA7D" secondaryColor="#979dac" width="24px" height="24px" /></div>
                        <div><span className="section-header">Copy</span></div>
                      </div> */}
                      {listItem && listItem.user && listItem.user.id && listItem.user.id === currentUserId ? (
                        <Fragment>
                          <div className="icon-svg-col" onClick={() => {
                            appState.transferItemToDrawer(listItem);
                            appState.selectItem(listItem.id);
                            appState.showHide('item', 'move', listItem.id, 'Move this item to a different list');
                          }}>
                            <div className="pb-2"><SVGMove primaryColor="#5FBA7D" secondaryColor="#979dac" width="24px" height="24px" /></div>
                            <div><span className="section-header">Move</span></div>
                          </div>
                        </Fragment>
                      ) : null}

                      {/* <div className="icon-svg-col" onClick={() => {
                        appState.transferItemToDrawer(listItem);
                        appState.selectItem(listItem.id);
                        appState.showHide('item', 'copy', listItem.id);
                      }}>
                        <div className="pb-2">
                          <SVGCart primaryColor="#5FBA7D" secondaryColor="#979dac" width="24px" height="24px" altText="Got it!" />
                        </div>
                        <div><span className="section-header">Got it</span></div>
                      </div> */}
                    </div>
                  </div>
                </Fragment>
              ) : (
                <div className="pb-4" />
              )}
            </div>

            {/* THIS IS ONLY SHOWN ON MEDIUM/LARGER SCREENS */}
            <div className="xs:hidden md:flex min-h-gyfto-card-item">
              {/* THIS IS THE LEFT HAND GREY VERTICAL COLUMN */}
              {/* HIDE THE ORDINAL IF IT'S ALREADY OBTAINED */}
              <div className="item-card-left">
                <div className="pt-4 text-center flex flex-col h-full">
                  {!listItem.obtained ? (
                    <div>
                      <div className="item-ordinal">{ordinal}</div>
                      {/* SHOW SELECT FOR THE CARD */}
                      {showSelectItem ? (
                        <div className='pt-4 flex justify-center'>
                          <div>
                            <Checkbox name={listItem.id} id={listItem.itemId} checked={thisItemIsSelected} onChange={handleCheckboxChange} />
                          </div>
                        </div>
                      ) : null}

                      {isAuthenticated && currentUserCanSee && currentUserIsOwnerOrEditor && !isShare ? (
                        // THIS IS WHERE ALL THE ACTIONS ON AN ITEM CARD WILL BE
                        <div className="flex flex-col justify-between">
                          {listIsReordering ? (
                            <div className="item-card-left-text my-2">
                              <JumpingDots />
                            </div>
                          ) : (
                            <div hidden={filterVisible !== ''} className="item-card-left-text my-2 cursor" style={{ cursor: 'move' }}>
                              : : :
                            </div>
                          )}
                          {/* eslint-disable */}

                          {listUserType && (listUserType.toUpperCase() === 'OWNER') || (listItem && listItem.user && listItem.user.id && listItem.user.id === currentUserId) ? (
                            <Fragment>
                              {/* THIS DIV IS FOR DELETE */}
                              <div className="pt-2"
                                onClick={() => {
                                  appState.transferItemToDrawer(listItem);
                                  appState.selectItem(listItem.id);
                                  appState.showHide('item', 'delete', listItem.id);
                                  //appState.selectList(list.id, list.listId);
                                }}
                                alt="Delete">
                                {/* eslint-enable */}
                                <ItemDelete />
                              </div>


                            </Fragment>
                          ) : null}

                          {/* THIS DIV IS FOR EDIT */}
                          {/* eslint-disable */}
                          <div
                            onClick={() => {
                              appState.transferItemToDrawer(listItem);
                              appState.selectItem(listItem.id);
                              appState.showHide('item', 'edit', listItem.id);
                            }}
                          >
                            {/* eslint-enable */}
                            <ItemEdit />
                          </div>

                          {/* THIS DIV IS FOR COPY */}
                          {/* eslint-disable */}
                          {/* <div
                            onClick={() => {
                              appState.transferItemToDrawer(listItem);
                              appState.selectItem(listItem.id);
                              appState.showHide('item', 'copy', listItem.id);
                            }}
                          >
                            <ItemCopy />
                          </div> */}

                          {/* THIS DIV IS FOR MOVE */}
                          {listUserType && (listUserType.toUpperCase() === 'OWNER') || (listItem && listItem.user && listItem.user.id && listItem.user.id === currentUserId) ? (
                            <Fragment>
                              {/* THIS DIV IS FOR DELETE */}
                              <div className="pb-2"
                                onClick={() => {
                                  appState.transferItemToDrawer(listItem);
                                  appState.selectItem(listItem.id);
                                  appState.showHide('item', 'move', listItem.id, 'Move this item to a different list');
                                }}
                                alt="Move">
                                {/* eslint-enable */}
                                <ItemMove />
                              </div>
                            </Fragment>
                          ) : null}
                        </div>
                      ) : (
                        <div />
                      )}
                    </div>
                  ) :
                    null
                  }
                </div>
              </div>

              {/* THIS IS THE MIDDLE/MAIN CONTENT AREA */}
              <div className="py-4 flex-no-shrink">
                <div className="w-72 pl-4" >
                  <div className="max-w-xs">
                    <ItemName url={listItem.url} name={listItem.name} />
                  </div>
                  {listItem.store ? (
                    <div className="pt-2 pb-0 text-sm text-grey">{listItem.store}</div>
                  ) : null}
                  {/* item-description */}
                  <div className="flex align-top items-start max-w-gyfto-max-card-item-description">
                    {listUserType && (listUserType.toUpperCase() === 'OWNER' || listUserType.toUpperCase() === 'EDITOR') && listItem && listItem.user && listItem.user.id && listItem.user.id !== currentUserId ? (
                      <div className="flex-no-shrink" style={{ minWidth: '40px' }}><UserAvatar users={[listItem.user]} /></div>
                    ) : null}
                    <div className="text-grey-darker text-base pt-2 pr-2 leading-normal" ><HideShowLongText displayText={listItem.description} lengthToHide={80} /></div>
                  </div>
                </div>

                {/* THE COST/QTY/COLOR/PRICE SECTION */}
                <ItemProps listItem={listItem} isShare={isShare} />

                {/* THIS IS THE SHARED WITH AREA */}
                {showSharedWith ? (
                  <div className="px-4">
                    <div className="section-header">{shareText}</div>
                    <div className="flex flex-wrap items-center mt-1">
                      {isShare ? (
                        <UserAvatar users={sharedWith} highlightUsers={currentItemWatchesUserIds} />
                      ) : (
                        <ItemCardUserAvatar sharedItemUsers={listUsersSharedWith} />
                      )}
                    </div>
                  </div>
                ) : (
                  <div />
                )}

                {/* THIS IS THE STORE BUTTON SECTION */}
                {listItem.url ? (
                  <div className="pb-2 flex flex-col items-center justify-center w-full pt-6">
                    {listItem.code ? (
                      <div className="text-xs pb-3 text-grey">Use code <b>'{listItem.code}'</b> at checkout</div>
                    ) : null}
                    <div className="w-full px-4 text-center">
                      <a
                        href={listItem.url}
                        className="btn_gradient hover:shadow"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        SEE AT {listItem && listItem.store ? listItem.store : 'THIS RETAILER'}
                      </a>
                    </div>
                  </div>
                ) : (
                  <div className="py-4" />
                )}

                {/* THIS IS THE WATCH / OBATINED SECTION */}
                <div className='w-full px-4 py-2'>
                  {isShare ? (
                    <div className=" w-full" hidden={!isAuthenticated  || ogListMode}>
                      <ItemWatch
                        listItem={listItem}
                        currentUserId={appUserId}
                        itemWatches={currentItemWatches}
                        itemWatchesType={itemWatchesType}
                        itemWatchId={itemWatchId}
                      />
                    </div>
                  ) : (
                    <Fragment>
                      {isAuthenticated && currentUserCanSee ? (
                        <div className="flex justify-center items-center">
                          {listBuyType === 'OwnerBuy' && (listEditorsLength > 0 || (sharedListIds && Array.isArray(sharedListIds) && sharedListIds.length > 0)) ? (
                            <Fragment>
                              <div className='min-w-item-watch-obtained-button'>
                                <ItemObtained item={listItem} screenSize="md" />
                              </div>
                              <div className='pl-2 min-w-item-watch-obtained-button'>
                                <ItemWatch
                                  listItem={listItem}
                                  currentUserId={appUserId}
                                  itemWatches={currentItemWatches}
                                  itemWatchesType={itemWatchesType}
                                  itemWatchId={itemWatchId}
                                />
                              </div>
                            </Fragment>
                          ) :
                            <div className='w-full'>
                              <ItemObtained item={listItem} screenSize="md" />
                            </div>
                          }
                        </div>
                      ) : null}
                    </Fragment>
                  )}
                </div>
              </div>

              {/* THIS IS THE RIGHT/IMAGE AREA */}
              <div className="flex border-l align-middle items-center justify-center">
                <ItemImage
                  imageUrl={listItem.imageUrl}
                  url={listItem.url}
                  processedImage={listItem.processedImage}
                  size="medium"
                />
              </div>
            </div>
          </div>
        )
        }
      </Subscribe>
    </Fragment>
  );
}

ItemCard.propTypes = {
  listItem: PropTypes.object,
  isShare: PropTypes.bool,
  currentUserCanSee: PropTypes.bool,
  currentUserCanTakeActionOnItemCard: PropTypes.bool,
  ordinal: PropTypes.number,
  isAuthenticated: PropTypes.bool,
  loopCount: PropTypes.number,
  loopTotalCount: PropTypes.number,
  sharedListIds: PropTypes.array,
  listId: PropTypes.string,
  listUserType: PropTypes.string,
  listBuyType: PropTypes.string,
  listIsReordering: PropTypes.bool,
  listEditorsLength: PropTypes.number,
  filterVisible: PropTypes.string,
  ogListMode: PropTypes.bool,
  showSelectItem: PropTypes.bool,
  hearSignalFromChild: PropTypes.func
};

ItemCard.defaultProps = {
  listItem: null,
  isShare: null,
  currentUserCanSee: false,
  currentUserCanTakeActionOnItemCard: false,
  ordinal: 0,
  isAuthenticated: false,
  loopCount: 0,
  loopTotalCount: 0,
  sharedListIds: null,
  listUserType: null,
  listBuyType: null,
  listIsReordering: false,
  listEditorsLength: 0,
  filterVisible: '',
  ogListMode: false,
  showSelectItem: false,
  hearSignalFromChild: null
};