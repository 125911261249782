import React, { Component, Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _size from 'lodash.size';
import _forEach from 'lodash.foreach';
import { Subscribe } from 'unstated';
import CardSpace from '../home/card.css';
import ItemName from '../item/itemName';
import UserAvatar from '../avatar/userAvatar';
import Button from '../common/button';
import ShareButton from '../common/buttons/shareButton';
import DeleteButton from '../common/buttons/deleteButton';
import history from '../history/index';
import ListBookmark from './listBookmark';
import { fnGetColorValue, fnGetListHeaderImg } from '../../functions/index';
import { format } from 'date-fns';
// THIS IS FOR THE unstated CONTAINER
import DrawerContainer from '../../containers/drawer';

export default function ListCard(props) {
  const { list, showActions, actionFunction } = props;

  const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;// list.id === "b06dea09-a8c5-4dbb-bb33-45ed4e1fb2e1";
  const userId = localStorage.getItem(`user_id`);

  let wantType = 'want';
  let haveType = 'have';
  let sharedWithType = 'shared with';
  let listUserType = null;
  let listVisibility = null;
  const sourceListOwner = [];
  let sourceListOwnerName = '';
  let sourceListCreatedAt = null;
  let currentUserIsOwnerEditor = false;
  let listDescription = '';
  let listName = '';
  let listMissingPropertiesMessage = '';
  let listMissingPropertiesShowBoth = false;
  let listItemsTotalCount = 0;
  let listItemsObtainedCount = 0;
  let listHeaderImage = '';

  if (!list) {
    console.error(`list.card > Missing List.`);
    return <Fragment />;
  }

  if (displayConsole) {
    console.log(`list.card > List: `, list);
  }

  // FIRST, WHAT KIND OF LIST IS THIS - Owner, Editor OR SharedWith
  // FROM THE listUser ELEMENT
  if (list.listUsers && _size(list.listUsers) > 0) {
    _forEach(list.listUsers, listUser => {
      if (listUser && listUser.user && listUser.user.id && listUser.user.id === userId) {
        // THIS IS OUR listUser TYPE FOR THE CURRENT USER
        // eslint-disable-next-line
        listUserType = listUser.listUserType;
        if (listUserType.toUpperCase() !== 'OWNER' || listUserType.toUpperCase() !== 'EDITOR') {
          wantType = 'wants';
          haveType = 'has';
          sharedWithType = 'also shared with';
        } else {
          // WE USE THIS INTENT for 'OwnerBuy' LISTS OTHERWISE STICK WITH THE DEFAULT (ABOVE)
          wantType = list.listDetails.buyType === 'OwnerBuy' ? 'planned' : 'want';
          haveType = list.listDetails.buyType === 'OwnerBuy' ? 'bought' : 'have';
          currentUserIsOwnerEditor = true;
        }
        if (displayConsole) {
          console.log(`list.card > render() - listUserType is ${listUserType} for list '${list.listDetails.name}' with showActions of ${showActions}': `, list);
        }
      } else {
        // THE CURRENT USER ISN'T THE ULITMATE OWNER - LET'S IDENTIFY WHO THAT IS
        if (listUser && listUser.user && listUser.listUserType && listUser.listUserType.toUpperCase() === 'OWNER') {
          sourceListOwner.push(listUser.user);
          sourceListCreatedAt = list.createdAt ? list.createdAt : null;
          sourceListOwnerName = `${listUser.user.firstName ? listUser.user.firstName : ''} ${listUser.user.lastName ? listUser.user.lastName : ''}`
        }
      }
    });
  } else {
    if (displayConsole) {
      console.log(`Something is wrong with this list object: `, list);
    }
  }

  // NEXT, DETERMINE THE SHARED USERS
  const sharedListUsers = [];
  if (listUserType !== 'SharedWith') {
    // Owner OR Editor
    if (list.sharedLists && _size(list.sharedLists) > 0) {
      _forEach(list.sharedLists, sharedList => {
        // ONLY SHOW SHARES THAT ARE NOT DELETED AND HAVE listUsers ARRAY
        if (sharedList && sharedList.deletedState === false && _size(sharedList.listUsers) > 0) {
          _forEach(sharedList.listUsers, sharedListUser => {
            if (sharedListUser && sharedListUser.user) {
              sharedListUsers.push(sharedListUser.user);
            }
          });
        }
      });
    }
  } else {
    // PART 1 - DETERMINED THE SharedWith
    /* eslint-disable no-lonely-if */
    if (list.sourceList && list.sourceList.sharedLists && _size(list.sourceList.sharedLists) > 0) {
      _forEach(list.sourceList.sharedLists, sharedList => {
        if (sharedList && sharedList.deletedState === false && sharedList.listUsers && _size(sharedList.listUsers) > 0) {
          _forEach(sharedList.listUsers, sharedListUser => {
            // THIS IS ONLY GIVING US THE SharedWith USERS THAT
            // AREN'T THE CURRENT USER

            if (sharedListUser && sharedListUser && sharedListUser.user && sharedListUser.user.id !== userId) {
              sharedListUsers.push(sharedListUser.user);
            }
          });
        }
      });
    }

    // PART 2 - FIGURE OUT THE SOURCE LIST OWNER
    if (list.sourceList && list.sourceList.listUsers && _size(list.sourceList.listUsers) > 0) {
      _forEach(list.sourceList.listUsers, listUser => {
        if (listUser && listUser.listUserType && listUser.listUserType === 'Owner' && listUser.user) {
          sourceListOwner.push(listUser.user);
          sourceListCreatedAt = list.sourceList.updatedAt ? list.sourceList.updatedAt : null;
          sourceListOwnerName = `${listUser.user.firstName ? listUser.user.firstName : ''} ${listUser.user.lastName ? listUser.user.lastName : ''}`
          if (listUser.user.id === userId) {
            currentUserIsOwnerEditor = true;
          }
        }
      });
    }
    /* eslint-enable no-lonely-if */
  }

  // LAST, MAP SOME COMMON list PROPERTIES TO VARS

  // listName
  if (list && list.listDetails && list.listDetails.name) {
    listName = list.listDetails.name;
  }

  // listDetails
  if (list && list.listDetails && list.listDetails.description) {
    // TRIM THE listDetails FOR THE SharedWith LIST IF IT'S TOO LONG
    if (list.listDetails.description.length > 62) {
      listDescription = listDescription.concat(list.listDetails.description.substring(0, 62), ' ...');
    } else {
      listDescription = list.listDetails.description;
    }
  }

  // listVisibility
  listVisibility = list && list.listDetails && list.listDetails.visibilityType;

  // listItemsTotalCount
  if (list && list.itemsTotal && list.itemsTotal.total.count && list.itemsTotal.total.count) {
    listItemsTotalCount = list.itemsTotal.total.count;
  }

  // listItemsObtainedCount
  if (list && list.itemsObtained && list.itemsObtained.total.count && list.itemsObtained.total.count) {
    listItemsObtainedCount = list.itemsObtained.total.count;
  }

  // listMissingPropertiesMessage - THIS IS SHOWN WHEN EITHER THE listItemsTotalCount OR sharedListUsers < 1
  if (listItemsTotalCount < 1 && _size(sharedListUsers) < 1) {
    listMissingPropertiesMessage = "You haven't created any items or any shares for this list yet.";
    // ONLY SHOW ONE OF THESE MESSAGES IN THE list CARD
    listMissingPropertiesShowBoth = true;
  } else if (listItemsTotalCount < 1) {
    listMissingPropertiesMessage = "You haven't created any items for this list yet.";
  } else if (_size(sharedListUsers) < 1) {
    listMissingPropertiesMessage = "You haven't created any shares for this list yet.";
  }

  if (displayConsole) {
    console.log(`list.card > sharedListUsers :`, sharedListUsers)
  }

  // THIS HANDLES THE CLICK FOR THE WHOLE LIST CARD (DIV) BUT ENABLE OTHER
  // ITEMS THAT HAVE onClick HANDLERS TO FIRE AS WELL
  const handleClick = (e, type, id) => {
    // THIS HANDLES THE GENERIC CLICK BUT WILL
    // LET BUTTON CHILD COMPONENTS STILL CLICK THROUGH
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    // THIS SHOULD ONLY BE FOR THE DIV CLICK
    if (type) {
      if (type === 'toList') {
        if (id) {
          history.push(`/l/${id}`);
        }
        // console.log(`list.card > toList clicked! `);
      }
    }
  }

  

  const [vertBarBgColor, setVertBarBgColor] = useState('');
  const [cardHeaderImg, setCardHeaderImg] = useState(`${process.env.REACT_APP_PUBLIC_URL}/gyfto-logo-110.png`);
  
  useEffect(() => {
    // THIS WILL ASYNC GET THE BACKGROUND COLORS FOR ANY OF THE AVATARS THAT ARE JUST TEXT
    async function getBgColor() {
      setVertBarBgColor(await fnGetColorValue(`${sourceListOwnerName}`));
    }
    
    // THIS WILL THE HEADER IMAGE FOR THE CARD (IF APPLICABLE)
    async function getListHeaderImg (list) {
      setCardHeaderImg(await fnGetListHeaderImg(list));
    }

    getBgColor();
    getListHeaderImg(list);
  }, [])
  return (
    <div className="cursor-pointer" onClick={(e) => handleClick(e, 'toList', list.id)}>
      <Subscribe to={[DrawerContainer]}>
        {appState => (
          <CardSpace >
            {/* IF THE LIST IS A SharedWith THEN WE WANT TO SHOW
              THE UserAvatar OF THE LIST OWNER */}
            <div className="px-4 pt-8 rounded-full" style={{ "backgroundImage": `linear-gradient(rgba(255, 255, 255, 0), white),url('${cardHeaderImg}')`, "backgroundSize": "contain", "overflow": "hidden", "backgroundRepeat": "no-repeat", "backgroundPosition": "center" }}>
              <div className="absolute pin-t pin-r z-40 pt-1 pr-1 flex align-middle" style={{height:'40px'}} >
                <div className="pt-2" hidden={listUserType.toUpperCase() !== 'EDITOR'}>
                  <Button
                    buttonType="pill-void-tiny"
                    xtra={`py-1 bg-gyfto-gold border-gyfto-gold text-black px-2`}
                    weight="thin"
                    responsive={false}
                  >
                    <span className="text-center">{listUserType}</span>
                  </Button>
                </div>
                {listVisibility == 'visible' ? (
                  <div hidden={!showActions && listUserType !== 'SharedWith'}><ListBookmark listId={list.id} listPinState={list.pinned} currentUserId={userId} /></div>
                ) : null}
                
              </div>
              <div className="font-bold text-xl mb-2 bg-white">
                <ItemName url={`l/${list.id}`} localRoute name={listName} />
              </div>
              <div className="text-grey-darker text-base min-h-gyfto-card-item-description bg-white">{listDescription}</div>
            </div>
            {/* IF THIS IS A SHARED LIST - SHOW THE ULTIMATE LIST OWNER */}
            {displayConsole ? (console.log(`### listUserType is ${listUserType} && sourceListOwner is`, sourceListOwner)) : null}
            {/* {listUserType === 'SharedWith' || listUserType === 'Editor' ? ( */}
            {listUserType.toUpperCase() !== 'OWNER' ? (
              <div className="flex justify-items-start">
                <div className="w-1 mr-3" style={{ backgroundColor: `${vertBarBgColor}` }}>&nbsp;</div>
                <UserAvatar
                  users={sourceListOwner}
                  direction="vertical"
                  subtext={format(sourceListCreatedAt, 'MM/DD/YYYY')}
                />

              </div>
            ) : (
                <div />
              )}

            <div className="px-4 pt-4">
              {listItemsTotalCount > 0 ? (
                <div className="flex-wrap justify-between pb-1">
                  <div className="item-prop-display">
                    <div className="item-prop-pill">
                      <span className="item-prop uppercase">{wantType}</span>
                      <span className="item-prop-value">{listItemsTotalCount}</span>
                    </div>
                  </div>
                  <div className="item-prop-display">
                    <div className="item-prop-pill">
                      <span className="item-prop uppercase">{haveType}</span>
                      <span className="item-prop-value">{listItemsObtainedCount}</span>
                    </div>
                  </div>
                  {/* TODO: ADD A SECTION HERE FOR 'Watched' ITEMS FOR LISTS THAT ARE SHARED */}
                </div>
              ) : (
                  // IF THERE AREN'T ANY ITEMS AND THE USER IS Owner/Editor THEN SHOW THE BUTTON
                  <div>
                    {showActions === true && listUserType !== 'SharedWith' ? (
                      <div>
                        <ShareButton length="long" list={list} />
                      </div>
                    ) : (
                        <div hidden={!showActions || !currentUserIsOwnerEditor}>
                          <span className="italic">
                            {listMissingPropertiesShowBoth ? '' : listMissingPropertiesMessage}
                          </span>
                        </div>
                      )}
                  </div>
                )}
              <div>
                {/* {_size(sharedListUsers) > 0 && listUserType !== 'SharedWith' ? ( */}
                {_size(sharedListUsers) > 0 && listUserType === 'Owner' ? (
                  <div>
                    <div className="section-header">{sharedWithType}</div>
                    <div className="flex flex-wrap items-center">
                      <UserAvatar users={sharedListUsers} maxDisplay={4} forAssetId={list.id} />
                    </div>
                  </div>
                ) : (
                    // IF THERE AREN'T ANY SHARED USERS AND THE USER IS Owner/Editor THEN SHOW THE BUTTON
                    <div>
                      {showActions === true && listUserType !== 'SharedWith' ? (
                        null
                      ) : (
                          <div>
                            {/* <span className="italic">{listMissingPropertiesMessage}</span> */}
                          </div>
                        )}
                    </div>
                  )}
              </div>
            </div>
            {/* THIS IS THE FOOTER OF THE CARD - IN THE FOOTER SHOW:
              - Delete button when the current user is an Owner
              - SharedWith when the current user is not the owner && there are users the list is 
            */}
            <div className="absolute pin-b pb-4 w-full h-18 bg-grey-lightest rounded-b-lg">
              {showActions === true && listUserType.toUpperCase() === 'OWNER' ? (
                // SHOW ACTION BUTTONS
                <div className="flex justify-items-auto justify-around align-middle xs:pt-4 md:pt-6" hidden={showActions}>
                  <div>
                    <DeleteButton length="short" list={list} />
                  </div>
                </div>
              ) : (
                  <Fragment>
                    {sharedListUsers.length > 0 ? (
                      <div className="pl-4 -mt-3">
                        <div className="section-header">{sharedWithType}</div>
                        <div className="flex flex-wrap items-center mt-1 ">
                          <UserAvatar users={sharedListUsers} maxDisplay={4} forAssetId={list.id} />
                        </div>
                      </div>
                    ) : null}
                  </Fragment>
                )}
            </div>
          </CardSpace>
        )}
      </Subscribe>
    </div>
  );
}

ListCard.propTypes = {
  list: PropTypes.object,
  showActions: PropTypes.bool,
};

ListCard.defaultProps = {
  list: null,
  showActions: false,
};
