import React from 'react';
import PropTypes from 'prop-types';

export default function CarouselButton(props) {
  const { direction, handleClickEvent } = props;
  const directionSymbol = direction === 'R' ? '>' : '<';

  /**
   * THIS WILL MAKE IT SO THAT WE DON'T SUBMIT A FORM WHEN USED IN A PARENT COMPONENT WITH A FORM
   * AND ALSO CALL THE FUNCTION PASSED VIA handleClickEvent
   */
  const proxyClickEvent = (e) =>{
    if(e){
      e.preventDefault();

      handleClickEvent();
    }
  }
  return (
    <button className="btn border-transparent bg-grey-lightest w-14 h-14 rounded-full text-center text-gyfto-green font-bold shadow-lg" onClick={(e) => proxyClickEvent(e)}>
      {directionSymbol}
    </button>
  );
}


CarouselButton.propTypes = {
  direction: PropTypes.string,
  handleClickEvent: PropTypes.func
};

CarouselButton.defaultProps = {
  direction: 'L',
  handleClickEvent: null
};
