import React, { useState } from 'react';
import Button from '../common/button';
import { useMutation } from '@apollo/react-hooks';
import { mutationUpdateListDetails } from '../../graphql/graphql';
import { Subscribe } from 'unstated';
import DrawerContainer from '../../containers/drawer';
import { fnGyftoEventPipeline } from '../../functions/index';

export default function ListNameEdit(props) {
    const { listDetails } = props;
    const [newListName, setNewListName] = useState(props.listDetails && props.listDetails.name ? props.listDetails.name : '');
    const [newListDescription, setNewListDescription] = useState(props.listDetails && props.listDetails.description ? props.listDetails.description : '');
    const [showErrMsg, setShowErrMsg] = useState(false);

    // MUTATION TO UPDATE LIST DETAILS
    const [updateListDetails] = useMutation(mutationUpdateListDetails);

    return (
        <Subscribe to={[DrawerContainer]}>
            {appState => (
                <div className="w-full" >
                    <form>
                        <div className="flex justify-between">
                            <div className="form-label">list name</div>
                            <div className="form-plain text-grey">{newListName.length} / 30</div>
                        </div>
                        <input
                            autoFocus
                            id="listName"
                            type="text"
                            className="w-full form-input focus:outline-none focus:shadow-outline"
                            value={newListName}
                            maxLength="30"
                            onChange={e => setNewListName(e.target.value)}
                            required
                        />
                        {showErrMsg ?
                            (
                                <div className="form-input-error">
                                    <div className="p-2">List name is required to save!</div>
                                </div>
                            )
                            : <span />}
                        <br />
                        <br />
                        <span className="form-label">list description</span>
                        <textarea
                            id="listDescription"
                            rows="6"
                            className="w-full form-input focus:outline-none focus:shadow-outline"
                            value={newListDescription}
                            onChange={e => setNewListDescription(e.target.value)}
                        />
                        <div className="flex justify-between px-2 pt-4">
                            <Button
                                buttonType="pill-cancel"
                                weight="thin"
                                xtra="hover:shadow"
                                onClick={() => {
                                    // window.alert(`Cancel!`);
                                    props.showHideFunc(false);
                                }}
                            >
                                <span className="text-center">Cancel</span>
                            </Button>
                            <Button
                                buttonType="pill-add"
                                weight="thin"
                                xtra="hover:shadow"
                                onClick={async e => {
                                    if (newListName !== '') {
                                        await updateListDetails({
                                            variables: {
                                                listDetailsId: listDetails.id,
                                                listName: newListName,
                                                listDescription: newListDescription
                                            },
                                        }).then((data) => {

                                            props.showHideFunc(false);
                                            fnGyftoEventPipeline('ListDetails', 'Updated', listDetails.id);
                                            appState.showMessageCard('msg', `List Details have been updated for ${newListName}`);
                                        }).catch((error) => {
                                            appState.showMessageCard('error', `Error updating List Details for ${newListName}`);
                                            setShowErrMsg(true);
                                        })
                                    } else {
                                        appState.showMessageCard('error', `A name is required for every List`);
                                        setShowErrMsg(true);
                                    }
                                }}
                            >
                                <span className="text-center">save</span>
                            </Button>
                        </div>
                    </form>
                </div>
            )}
        </Subscribe>
    );
};
