import React, { Fragment } from 'react';
import { Subscribe } from 'unstated';
import DrawerContainer from '../../containers/drawer';

import DrawerItemShareCreate from './draweritem.share.create';

export default function ShareContainer() {
  const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;
  return (
    <Subscribe to={[DrawerContainer]}>
      {appState => (
        <Fragment>
          {
            displayConsole ? (console.log(`draweritem.share.create.container > selectedListObj: `,appState.state.selectedListObj)) : null
          }
          <DrawerItemShareCreate listItems={appState.state.selectedListObj.listItems.filter(l => l.item.obtained !== true && l.item.deletedState === false)} listBuyType={appState.state.selectedListObj.listDetails.buyType} />
        </Fragment>
      )}
    </Subscribe>
  );
}
