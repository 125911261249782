import React, { Fragment } from 'react';
import { Subscribe } from 'unstated';
import DrawerContainer from '../../containers/drawer';
import DrawerItemShareListDelete from './draweritem.share.list.delete';
import DrawerItemShareListDeleteEditor from './draweritem.share.list.delete.editor';
import DrawerItemShareItemDelete from './draweritem.share.item.delete';

export default function ShareDeleteContainer(props) {
  const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;
  const COMPONENT_NAME = 'draweritem.share.delete.container';

  const getTheRightComponent = (shareType, user, objectId) => {
    let returnElement = null;
    switch (shareType) {
      case 'listUserShare':
        returnElement = (
          <DrawerItemShareListDelete userId={user.id ? user.id : ''} listId={objectId ? objectId : ''}/>
        );
        break;
      case 'listUserShare_Editor':
        returnElement = (
          <DrawerItemShareListDeleteEditor userId={user.id ? user.id : ''} listId={objectId ? objectId : ''}/>
        );
        break;
      case 'itemUserShare':
        returnElement = (
          <DrawerItemShareItemDelete user={user ? user : null} objectId={objectId ? objectId : ''} />
        );
        break;
      default:
        break;
    }

    return returnElement;
  }
  return (
    <Subscribe to={[DrawerContainer]}>
      {appState => (
        <Fragment>
          {
            displayConsole ? (console.log(`${COMPONENT_NAME} > userAvatarClickObj: `,appState.state.userAvatarClickObj)) : null
          }
          {appState && appState.state.userAvatarClickObj && appState.state.userAvatarClickObj.shareType &&  appState.state.userAvatarClickObj.shareType ? (
            getTheRightComponent(appState.state.userAvatarClickObj.shareType,appState.state.userAvatarClickObj.user, appState.state.userAvatarClickObj.objectId)
          ) : null}
        </Fragment>
      )}
    </Subscribe>
  );
}
