import React, { Fragment } from 'react';
import history from '../history/index';
import DrawerContainer from '../../containers/drawer';
import { Subscribe } from 'unstated';
import PropTypes from 'prop-types';
import SVGDelete from '../common/icons/delete-svg';
import SVGList from '../common/icons/list-svg';
import SVGNew from '../common/icons/new-svg';
import SVGEdit from '../common/icons/edit-svg';
import SVGShare from '../common/icons/share-svg';
import Header from '../../components/header';

// NavType - CAN BE L (List), H (Home)
export default function NavBar({ navType, list, auth }) {
    // console.log(`NavBar > auth: `, auth);
    return (
        <Subscribe to={[DrawerContainer]}>
            {appState => (
                <div>
                    <Header auth={auth} />
                    {/* <div id="gyfto-header-nav" className="w-full xs:gyfto-header-nav-sm min-h-gyfto-header-nav-mobile bg-white flex justify-around align-middle pt-2 border-b-2"> */}
                        {navType == 'LS' ? (

                            <div id="gyfto-header-nav" className="w-full xs:gyfto-header-nav-sm min-h-gyfto-header-nav-mobile bg-white flex justify-around align-middle pt-2 border-b-2">
                                <div className="icon-svg-col" onClick={() => {
                                    appState.selectList(list.id);
                                    appState.transferListToDrawer(list);
                                    appState.showHide('item', 'create', list.id);
                                }}>
                                    <div><SVGNew primaryColor="#5FBA7D" secondaryColor="#979dac" width="24px" height="24px" /></div>
                                    <div><span className="section-header">Add</span></div>
                                </div>
                                <div className="icon-svg-col" onClick={() => {
                                    appState.selectList(list.id);
                                    appState.transferListToDrawer(list);
                                    appState.showHide('share', 'create', list.id);
                                }}>
                                    <div><SVGShare primaryColor="#5FBA7D" secondaryColor="#979dac" width="24px" height="24px" /></div>
                                    <div><span className="section-header">Share</span></div>
                                </div>
                                <div className="icon-svg-col" onClick={() => {
                                    appState.selectList(list.id);
                                    appState.transferListToDrawer(list);
                                    appState.showHide('list', 'edit', list.id);
                                }}>
                                    <div ><SVGEdit primaryColor="#5FBA7D" secondaryColor="#979dac" width="24px" height="24px" /></div>
                                    <div><span className="section-header">Edit</span></div>
                                </div>
                                <div className="icon-svg-col" onClick={() => {
                                    appState.selectList(list.id);
                                    appState.transferListToDrawer(list);
                                    appState.showHide('list', 'delete', list.id);
                                }}>
                                    <div><SVGDelete primaryColor="#5FBA7D" secondaryColor="#979dac" width="22px" height="24px" /></div>
                                    <div><span className="section-header">Delete</span></div>
                                </div>
                            </div>
                        ) : (
                            null)}
                    </div>
                // </div>
            )}
        </Subscribe>
    );
}

NavBar.propTypes = {
    navType: PropTypes.string,
    list: PropTypes.object,
};

NavBar.defaultProps = {
    navType: 'H',
    list: null,
};