import React, { Fragment } from 'react';
import EmptyList from '../common/emptyList';
import Button from '../common/button';

/* eslint-disable  */
export default function ListEmpty({ appState }) {
  const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;
  const componentName = 'listEmpty';

  if (displayConsole) {
    console.log(`${componentName} > appState : ${appState}`);
  }

  return (
    <Fragment>
      <div className="xs:hidden md:flex w-full justify-center" >
        <div className='flex pt-3'>
          <div className=''>
            <EmptyList />
          </div>
          <div className='pl-3 pt-2'>
            <div className='font-bold text-2xl'>You don't have any lists yet!</div>
            <div className='text-sm text-grey pt-2'>Click here to get started</div>
            <div className='flex justify-center'>
              <div className='pt-6 w-32'>
                <Button
                  onClick={() => appState.showHide('list', 'create')}
                  buttonType="pill-standard"
                  weight="thin"
                  xtra="hover:shadow"
                >
                  <div className="flex justify-center">
                    <span>new list</span>
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="xs:flex md:hidden w-full justify-center" >
        <div className='w-full flex flex-col pt-3 justify-center'>
          <div className='flex justify-center'>
            <EmptyList />
          </div>
          <div className='pl-3 pt-2'>
            <div className='font-bold text-2xl'>You don't have any lists yet!</div>
            <div className='text-sm text-grey pt-2'>Click here to get started</div>
            <div className='flex justify-center'>
              <div className='pt-6 w-32'>
                <Button
                  onClick={() => appState.showHide('list', 'create')}
                  buttonType="pill-standard"
                  weight="thin"
                  xtra="hover:shadow"
                >
                  <div className="flex justify-center">
                    <span>new list</span>
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}