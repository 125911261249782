import React, { Fragment } from 'react';
import Logo from '../common/logo';
export default function ItemCardMin({ item }) {
  // eslint-disable-next-line
  
  return (
    <Fragment>
      
        {item ? (
        <div className=" flex-no-grow flex-no-shrink p-3 bg-white items-center rounded shadow-md" key={item.id} style={{ height: '100px', minWidth:'290px', maxHeight: '100px' }}>
          <div className="flex justify-start pt-2">
            <div >
              {item.imageUrl ? (
                <div className="h-12 w-12 rounded-full mx-auto bg-grey-lighter" style={{ "backgroundImage": `url('${item.imageUrl}')`, "backgroundSize": "contain", "overflow": "hidden", "backgroundRepeat": "no-repeat", "backgroundPosition": "center" }} />
              ) : (
                <div className="h-12 w-12 rounded-full mx-auto bg-grey-lighter pt-2 pl-2"><Logo showText={false} /></div>
              )
              }
            </div>
            <div className="pl-3">
              <div className="text-grey-dark leading-normal">
                {item.name ? item.name : ''}
              </div>
              <div className="text-grey-dark text-xs">{item.amount ? item.amount : ''}</div>
            </div>
          </div>
        </div>
        ) : (
          <span>Expected item but didn't get one</span>
        )}
      
    </Fragment>
  );
}
