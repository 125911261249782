import React from 'react';
import ContentLoader from 'react-content-loader';

/*
VIA: http://danilowoz.com/create-content-loader/
*/

/* eslint-disable */
export const SkeletonItemCard = props => (
	<div>
		{/* THIS IS FOR SMALL SCREENS */}
		<div className="md:hidden bg-white">
			<ContentLoader height={160} width={300} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb" {...props}>
				<rect x="70" y="15" rx="4" ry="4" width="117" height="6.4" />
				<rect x="70" y="57" rx="3" ry="3" width="250" height="6.4" />
				<rect x="70" y="80" rx="3" ry="3" width="280" height="6.4" />
				<rect x="70" y="101" rx="3" ry="3" width="210" height="6.4" />
				<circle cx="24" cy="31" r="18" />
			</ContentLoader>
		</div>
		{/* THIS IS FOR MD AND ABOVE SCREENS */}
		<div className="xs:hidden md:inline-block bg-white w-gyfto-item-card-skeleton">
			<ContentLoader height={160} width={400} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb" {...props}>
				<rect x="70" y="15" rx="4" ry="4" width="117" height="6.4" />
				<rect x="70" y="57" rx="3" ry="3" width="250" height="6.4" />
				<rect x="70" y="80" rx="3" ry="3" width="280" height="6.4" />
				<rect x="70" y="101" rx="3" ry="3" width="210" height="6.4" />
				<circle cx="24" cy="31" r="18" />
			</ContentLoader>
		</div>
	</div>
);

export const SkeletonListCard = props => (
	<div className="bg-white w-gyfto-list-card">
		<ContentLoader
			height={330}
			width={280}
			speed={2}
			primaryColor="#f3f3f3"
			secondaryColor="#ecebeb"
			{...props}
		>
			<circle cx="40.5" cy="157.5" r="16.5" />
			<rect x="22" y="16" rx="4" ry="4" width="200" height="8.97" />
			<rect x="27" y="39" rx="4" ry="4" width="120" height="8" />
			<rect x="0" y="252.61" rx="5" ry="5" width="400" height="216" />
			<circle cx="82.5" cy="156.5" r="16.5" />
			<circle cx="127.5" cy="156.5" r="16.5" />
			<rect x="31" y="113" rx="4" ry="4" width="50" height="8" />
		</ContentLoader>
	</div>
);

/* eslint-enable */
