import React from 'react';

export default function ItemSpace({ children, type }) {
  let cssClass = 'item-card-min';
  if (type === 'min-wide') {
    cssClass = 'item-card-min-wide'; 
  } else if(type === 'min-med') {
    cssClass = 'item-card-min-medium'; 
  }

  return <div className={cssClass}>{children}</div>;
}
