import gql from 'graphql-tag';

const fragmentListUser = gql`
  fragment FragmentListUser on ListUser {
    id
    listUserType
    user: User {
      ...FragmentUser
    }
  }
`;

export default fragmentListUser;
