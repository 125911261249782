import React, { Fragment, useEffect, useState } from 'react';
import ItemName from '../item/itemName';
import history from '../history/index';
import { fnGetBreadCrumbs } from '../../functions/index';
import ListCardMinScroll from '../list/list.card.min.scroll';
import Carousel from 'nuka-carousel';
import CarouselButton from '../common/buttons/carouselButton'

export default function ListCardBreadCrumbs({ location }) {
    // eslint-disable-next-line

    const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;
    const COMPONENT_NAME = "list.card.breadcrumbs";
    const [breadCrumbs, setBreadCrumbs] = useState([]);
    const [initialized, setInitialized] = useState(false);
    const [listId, setListId] = useState(null);
    const [breadCrumbsCarousel, setBreadCrumbsCarousel] = useState([]);

    useEffect(() => {
        if (displayConsole) {
            console.log(`${COMPONENT_NAME} > useEffect`, location);
        }
        if (!initialized) {
            let localListId = null;
            setInitialized(true);

            // FIGURE OUT THE CURRENT URL SO WE CAN FILTER OUT THE CURRENT LIST
            if (location && location.pathname) {
                // LOOKS LIKE "/l/658937f4-941c-4262-b3b9-49a1336e856d"

                const urlParams = location.pathname.split('/');
                if (urlParams && urlParams[1] && (urlParams[1] === 'l' || urlParams[1] === 'h')) {
                    localListId = urlParams[2] ? urlParams[2] : null;
                    setListId(localListId);

                }
            }

            //GET THE BREADCRUMB STUFF
            async function getBreadCrumbs() {
                let bCrumbs = await fnGetBreadCrumbs();
                const tempCrumbs = [];
                if (bCrumbs && Array.isArray(bCrumbs) && bCrumbs.length > 0) {

                    bCrumbs
                    .filter(l => l.listId !== localListId)
                    .sort((a, b) => (a.timestamp > b.timestamp) ? 1 : ((b.timestamp > a.timestamp) ? -1 : 0))
                    .map(b => {
                        if (b) {
                            tempCrumbs.push(<ListCardMinScroll breadCrumb={b} key={b.listId} />)
                        }
                    })
                }

                if (tempCrumbs && Array.isArray(tempCrumbs) && tempCrumbs.length > 0) {
                    // THIS IS A COMPLETE HACK BUT IT GIVES ME A BLANK 
                    // AT THE END OF THE Carousel
                    tempCrumbs.push(<div className="bg-grey-lightest h-full" key={19790201}>&nbsp;&nbsp;&nbsp;&nbsp;</div>)
                    setBreadCrumbsCarousel(tempCrumbs);
                }
            }

            getBreadCrumbs();
        }
    }, [])

    return (
        <Fragment>
            {breadCrumbsCarousel && Array.isArray(breadCrumbsCarousel) && breadCrumbsCarousel.length > 1 ? (
                <div className="flex w-full justify-start items-center h-16">
                    <div><span className="text-gyfto-green text-sm font-bold whitespace-no-wrap">Previous lists</span></div>
                    <div className="outline-none w-full ml-2">
                        <Carousel
                            autoplay={false}
                            cellAlign="left"
                            cellSpacing={12}
                            framePadding="1px"
                            width="99%"
                            renderBottomCenterControls={() => { }}
                            renderCenterLeftControls={({ previousSlide }) => (
                                <div className="outline-none" hidden={false}><CarouselButton direction="L" handleClickEvent={previousSlide} /></div>
                            )}
                            renderCenterRightControls={({ nextSlide }) => (
                                <div className="outline-none" hidden={false}><CarouselButton direction="R" handleClickEvent={nextSlide} /></div>
                            )}
                            slideIndex={0}
                            slideWidth={"210px"}
                            slidesToShow={2}
                            transitionMode="scroll"
                            withoutControls={true}
                            wrapAround={false}
                            zoomScale={Number(0.5 || 0)}
                        >
                            {breadCrumbsCarousel}
                        </Carousel>
                    </div>
                </div>
            ) : (null)}
        </Fragment>
    );
}
