import React, { Fragment, useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { qryGetItems } from '../../graphql/graphql';
import Loading from '../common/loading';

export default function DrawerItemsDisplayItems({ itemIds }) {

  const COMPONENT_NAME = 'draweritem.items.display.items';
  const [items, setItems] = useState([]);

  // GET THE SELECTED LIST ITEMS
  const { loading, error, data } = useQuery(qryGetItems, {
    variables: { itemIds: itemIds },
    suspend: false,
  });

  if (error) {
    console.error(`Error querying information about items in '${COMPONENT_NAME}'`);
  }

  useEffect(() => {
    async function doUseEffectStuff() {

      // console.log(`${COMPONENT_NAME} > data: `, data);
      if (data && data.Item && Array.isArray(data.Item) && data.Item.length > 0) {
        let tmpArr = [];
        for (const itm of data.Item) {
          if (itm) {
            console.log(`${COMPONENT_NAME} > itm: `, itm);
            tmpArr.push(itm);
          }
        }

        // COMMIT TO STATE
        setItems(tmpArr);
      }
    }
    doUseEffectStuff();
  }, [data]);

  return (

    <Fragment>
      {loading ? (
        <div className="flex content-center">
          <Loading loadingText="Wait for it..." />
        </div>
      ) : (
        <div className='w-full'>

          <div className='text-sm text-grey-dark pt-4'>{`You've selected the following ${items && Array.isArray(items) && items.length > 1 ? `${items.length} items` : 'item'}:`}</div>
          {/* SHOW THE item IMAGE  */}
          <div className='w-full flex justify-start pt-6 items-center'>
            {items && Array.isArray(items) && items.length > 0 ? (
              items
                .sort((a, b) =>
                  a.updatedAt < b.updatedAt ? 1 : b.updatedAt < a.updatedAt ? -1 : 0
                )
                .map(item => (
                  // eslint-disable-next-line
                  <div key={item.id} className="h-16 w-16 rounded-full mx-auto bg-white shadow flex items-center" >
                    <div className="h-12 w-12 rounded-full mx-auto bg-white" style={{ "backgroundImage": `url('${item.imageUrl ? item.imageUrl : `${process.env.REACT_APP_PUBLIC_URL}/gyfto-logo-110.png`}')`, "backgroundSize": "contain", "overflow": "hidden", "backgroundRepeat": "no-repeat", "backgroundPosition": "center" }} />
                  </div>
                ))
            ) : null}
          </div>
        </div>
      )}
    </Fragment>

  );
}
