import React, { Fragment } from 'react';
import { Subscribe } from 'unstated';
import DrawerContainer from '../../containers/drawer';
import DrawerItemsAction from '../drawer/draweritem.items.action';

export default function DrawerItemsContainer({action}) {
  return (
    <Subscribe to={[DrawerContainer]}>
      {appState => (
        <Fragment>
          <DrawerItemsAction action={action} itemIds={appState.state.itemIds} />
        </Fragment>
      )}
    </Subscribe>
  );
}
