import React, { Fragment, Component } from 'react';
import Flickity from "react-flickity-component";
import ItemCreateEnterItemUrl from './itemCreate.enterItemUrl';
import ItemCreateItemDetails from './itemCreate.enterItemDetails';
import ItemCreateShareItemContainer from './itemCreate.enterItemShare.container';
// import ItemCreateSelectList from './itemCreate.selectList';
// import ItemCreateFin from './itemCreate.fin';
import "../common/flickity.css"
const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;

class DrawerItemItemCreate extends Component {
  // constructor(props) {
  //   super(props);
  // }

  componentDidMount = () => {
    // You can register events in componentDidMount hook
    this.flkty.on("settle", () => {
      if(displayConsole){
        console.log(`draweritem.item.create > componentDidMount > current index is ${this.flkty.selectedIndex}`);
      }
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.calculate !== this.props.calculate) {
      //console.log(prevProps.calculate);
      if(displayConsole){
        console.log(`draweritem.item.create > componentDidUpdate > component updated`);
      }
    }
  }

  flickityNext = () => {
    // You can use Flickity API
    if(displayConsole){
      console.log(`draweritem.item.create > flickityNext > calling flkty.next()`);
    }
    this.flkty.next();
  };

  flickityPrev = () => {
    // You can use Flickity API
    if(displayConsole){
      console.log(`draweritem.item.create > flickityPrev > calling flkty.previous()`);
    }
    this.flkty.previous();
  };

  render() {
    const flickityOptions = {
      initialIndex: 0,
      draggable: false,
      wrapAround: false,
      pageDots: false,
      prevNextButtons: false,
      fade: true
    };

    return (
      <Fragment>
        <Flickity options={flickityOptions} elementType="div" flickityRef={c => (this.flkty = c)}>
          <ItemCreateEnterItemUrl forward={this.flickityNext} />
          <ItemCreateItemDetails  forward={this.flickityNext} previous={this.flickityPrev} />
          <ItemCreateShareItemContainer />
        </Flickity>
      </Fragment>
    );
  }
}


export default DrawerItemItemCreate;