import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Subscribe } from 'unstated';
import { Formik, Field } from 'formik';
import { useMutation } from '@apollo/react-hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../common/button';
import { mutationCreateItem } from '../../graphql/graphql';
import DrawerContainer from '../../containers/drawer';
import DrawerSpace from '../common/drawerSpace';
import { fnGyftoEventPipeline, fnUploadItemImage, fnGetItemsCountFromList } from '../../functions/index';
import ItemImageCarousel from '../item/itemImageCarousel';
import Loading from '../common/loading';
import ErrorUI from '../common/error';


export default function ItemCreateItemDetails({ previous, forward }) {
  const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;
  const isRequired = message => value => (value ? undefined : message);
  const [itmUrl, setItmUrl] = useState(null);
  const [uploadedThumbnail, setUploadedThumbnail] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const appUserId = localStorage.getItem('user_id_app');

  const saveItemImgUrl = inputUrl => {
    if (displayConsole) {
      console.log(`itemCreate.enterItemDetails > inputUrl: ${inputUrl}`);
    }
    if (inputUrl) {
      setItmUrl(inputUrl);
    }
  };

  const fileAttached = async event => {
    if (displayConsole) {
      console.log(`itemCreate.enterItemDetails > fileAttached > file attached!: `, event.target.files[0]);
    }
    if (event && event.target && event.target.files && event.target.files[0]) {
      const upFile = event.target.files[0];
      if (upFile.size < 15000000) {
        // SAVE THE FILE
        try {
          setShowLoading(true);
          await fnUploadItemImage(upFile).then(res => {
            if (displayConsole) {
              console.log(`itemCreate.enterItemDetails > fileAttached > This came back from the upload | res: `, res);
            }

            if (res && res.public_id && res.secure_url) {
              // UPLOAD WAS SUCCESSFUL - NOW SAVE THE IMAGE URL
              setUploadedThumbnail([{ imageUrl: res.secure_url }]);
              saveItemImgUrl(res.secure_url);
              setShowLoading(false);
            }
          });
        } catch (error) {
          if (displayConsole) {
            console.log(`itemCreate.enterItemDetails > fileAttached > Error uploading item picture | Error: `, error);
          }
        }
      } else if (displayConsole) {
        console.log(
          `itemCreate.enterItemDetails > fileAttached > File is too big to upload (${upFile.size
          }) - please select another image. `,
          event.target.files[0]
        );
      }
    }
  };

  // SHOW THE ERROR MESSAGE IF NEEDED
  const captureError = (msg) => {
    if (displayConsole) {
      console.log(`draweritem.list.create > captureError - error message captured: ${msg}`);
    }

    setErrorMsg(msg);
  };

  //, { update: updateCache }
  const [createNewItem] = useMutation(mutationCreateItem);
  return (
    <Subscribe to={[DrawerContainer]}>
      {appState => (
        <DrawerSpace>
          {errorMsg ? (
            <ErrorUI errorMsg={errorMsg} />
          ) : (
              <div className="pt-2">
                <Fragment>
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      qty:
                        appState.state.selectedItemObj && appState.state.selectedItemObj.qty
                          ? appState.state.selectedItemObj.qty
                          : 1,
                      color:
                        appState.state.selectedItemObj && appState.state.selectedItemObj.color
                          ? appState.state.selectedItemObj.color
                          : '',
                      size:
                        appState.state.selectedItemObj && appState.state.selectedItemObj.size
                          ? appState.state.selectedItemObj.size
                          : '',
                      code:
                        appState.state.selectedItemObj && appState.state.selectedItemObj.code
                          ? appState.state.selectedItemObj.code
                          : '',
                      name:
                        appState.state.selectedItemObj && appState.state.selectedItemObj.name
                          ? appState.state.selectedItemObj.name.length > 30 ? appState.state.selectedItemObj.name.substring(0,29) : appState.state.selectedItemObj.name
                          : '',
                      description:
                        appState.state.selectedItemObj && appState.state.selectedItemObj.description
                          ? appState.state.selectedItemObj.description
                          : '',
                      amount:
                        appState.state.selectedItemObj && appState.state.selectedItemObj.amount
                          ? appState.state.selectedItemObj.amount
                          : 0.0,
                      store:
                        appState.state.selectedItemObj && appState.state.selectedItemObj.store
                          ? appState.state.selectedItemObj.store
                          : '',
                      currency:
                        appState.state.selectedItemObj && appState.state.selectedItemObj.currency
                          ? appState.state.selectedItemObj.currency
                          : '',
                      imageUrl: itmUrl && itmUrl ? itmUrl : '',
                      url:
                        appState.state.selectedItemObj && appState.state.selectedItemObj.url
                          ? appState.state.selectedItemObj.url
                          : '',
                      succsessfulScrape:
                      appState.state.selectedItemObj && appState.state.selectedItemObj.succsessfulScrape
                        ? appState.state.selectedItemObj.succsessfulScrape
                        : false,
                    }}
                    validate={values => {
                      const errors = {};
                      if (!values.name) {
                        errors.name = 'Required';
                      }
                      return errors;
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                      const haveListId = !!appState.state.selectedListId;

                      // // 1) PERSIST THE FORM DETAILS TO STATE
                      if (displayConsole) {
                        console.log(`itemCreate.enterItemDetails > Here are the values of the new item:`);
                        console.dir(values);
                      }

                      // 2) FIGURE OUT WHAT TO DO NEXT
                      if (haveListId) {
                        // IF WE HAVE A listId THEN WE HAVE ENOUGH TO SAVE

                        // GET THE NEXT ORDINAL IN THE LIST - COULD RE-EVAL A MORE EFFICIENT WAY TO DO THIS LATER
                        const selectedListCnt = await fnGetItemsCountFromList(appState.state.selectedListId);
                        if (displayConsole) {
                          console.log(
                            `itemCreate.enterItemDetails > selectedListCnt for listId '${appState.state.selectedListId
                            }' is '${selectedListCnt}'`
                          );
                        }

                        await createNewItem({
                          variables: {
                            name: values.name,
                            description: values.description,
                            amount: values.amount,
                            ordinal: selectedListCnt + 1,
                            qty: values.qty,
                            color: values.color,
                            size: values.size,
                            store: values.store,
                            url: values.url,
                            imageUrl: Array.isArray(uploadedThumbnail) && uploadedThumbnail.length > 0 ? uploadedThumbnail[0].imageUrl : itmUrl,
                            processedImage: false,
                            code: values.code,
                            listId: appState.state.selectedListListId,
                            userId: appUserId,
                            succsessfulScrape: values.succsessfulScrape
                          },
                        }).then(d => {
                          // PUT ON THE PIPELINE
                          fnGyftoEventPipeline('Item', 'Created', appState.state.selectedListId);

                          // SAVE THE NEWLY CREATED ITEM
                          if (d && d.data && d.data.insert_Item
                            && d.data.insert_Item.returning
                            && Array.isArray(d.data.insert_Item.returning)
                            && d.data.insert_Item.returning[0]
                            && d.data.insert_Item.returning[0].id
                          ) {
                            const newlyCreatedItem = d.data.insert_Item.returning[0];
                            appState.transferItemToDrawer(newlyCreatedItem);
                            // console.log(`itemCreate.enterItemDetails > here is that new item: `, newlyCreatedItem);
                          } else {
                            console.warn(`itemCreate.enterItemDetails > item created but couldn't map to it: `, d.data);
                          }

                          // DETERMINE IF WE NEED TO GO TO THE NEXT PANE
                          const currentList = appState.state.selectedListObj;
                          if (currentList && currentList.sharedLists
                            && Array.isArray(currentList.sharedLists)
                            && currentList.sharedLists.length > 0
                          ) {
                            forward();
                            //console.log(`itemCreate.enterItemDetails > here is that list: `, appState.state.selectedListObj);
                          } else {
                            // CAN'T SHARE THIS ITEM BECAUSE THERE AREN'T ANY SHARES
                            appState.closeDrawer();
                            appState.showMessageCard('msg', `Item '${values.name} created!'`);
                          }
                          
                          setSubmitting(false);
                        }).catch((e) => {
                          if (displayConsole) {
                            console.error(`itemCreate.enterItemDetails > await createList > Error attempting to call 'createNewItem' mutation: `, e);
                          }
                          captureError(e.toString());
                        });
                      } else {
                        // OTHERWISE; WE NEED TO GO TO THE NEXT SCREEN TO PICK A LIST
                        forward();
                      }
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                      /* and other goodies */
                    }) => (
                        <Fragment>
                          {isSubmitting || showLoading ? (
                            <div className="flex content-center">
                              <Loading loadingText="Adding this to your list!" />
                            </div>
                          ) : (
                              <form onSubmit={handleSubmit}>
                                <div className="drawer-header-description-text pb-3">Enter all the specifics for your item</div>
                                <div>
                                  <ItemImageCarousel
                                    imageThumbnails={
                                      appState.state.selectedItemObj && appState.state.selectedItemObj.imageThumbnails
                                        ? appState.state.selectedItemObj.imageThumbnails
                                        : []
                                    }
                                    itemTitle={
                                      appState.state.selectedItemObj && appState.state.selectedItemObj.name
                                        ? appState.state.selectedItemObj.name
                                        : ''
                                    }
                                    fnSaveItemUrl={saveItemImgUrl}
                                    userOverriddenImage={uploadedThumbnail}
                                  />
                                  <br />
                                </div>
                                <span className="form-label">
                                  {!itmUrl ? 'Upload your own item picture' : 'Select item picture'}
                                </span>
                                <div className="-mt-4">
                                  <input type="file" id="fileElem" accept="image/*" onChange={fileAttached} style={{ opacity: 0 }} />
                                  <label htmlFor="fileElem" className="btn btn-pill-color border-blue bg-blue text-white hover:bg-white hover:text-blue btn-thin hover:shadow xs:py-4 md:py-3 xs:px-6 md:px-4 xs:text-base md:text-sm" style={{ outline: 'none' }}><FontAwesomeIcon icon="camera" size="1x" htmlFor="fileElem" /> &nbsp; &nbsp;Upload</label>
                                </div>
                                <br />
                                <br />
                                <span className="form-label pt-4">item name</span>
                                <Field
                                  type="text"
                                  className="w-full form-input focus:outline-none focus:shadow-outline"
                                  name="name"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.name}
                                  maxLength={30}
                                  validate={isRequired('Item name is required')}
                                />
                                {errors.name && touched.name ? (
                                  <div className="form-input-error">
                                    <div className="p-2">{errors.name}</div>
                                  </div>
                                ) : null}
                                <br />
                                <br />
                                <span className="form-label">item description</span>
                                <Field
                                  type="text"
                                  rows="6"
                                  component="textarea"
                                  className="w-full form-input focus:outline-none focus:shadow-outline"
                                  name="description"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.description}
                                />
                                <br />
                                <div className="flex justify-end content-end mt-2">
                                  <Button
                                    buttonType="pill-void-standard"
                                    xtra="text-xs py-1 px-2"
                                    weight="thin"
                                    responsive={false}
                                    onClick={async () => {
                                      // RESET THE VALUE OF THE values.description FIELD
                                      setFieldValue('description', '', false);
                                    }}
                                  >
                                    <span className="text-center">clear description</span>
                                  </Button>
                                </div>
                                <br />
                                <div className="flex justify-between">
                                  <div>
                                    <span className="form-label">price</span>
                                    <Field
                                      type="number"
                                      className=" w-24 form-input focus:outline-none focus:shadow-outline"
                                      name="amount"
                                      min={0}
                                      step={0.01}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.amount}
                                    />
                                  </div>
                                  <div>
                                    <span className="form-label">qty</span>
                                    <Field
                                      type="number"
                                      className=" w-24 form-input focus:outline-none focus:shadow-outline"
                                      name="qty"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.qty}
                                    />
                                  </div>
                                </div>
                                <br />
                                <br />
                                <div className="flex justify-between">
                                  <div>
                                    <span className="form-label">color</span>
                                    <Field
                                      type="text"
                                      className=" w-24 form-input focus:outline-none focus:shadow-outline"
                                      name="color"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.color}
                                    />
                                  </div>
                                  <div>
                                    <span className="form-label">size</span>
                                    <Field
                                      type="text"
                                      className=" w-24 form-input focus:outline-none focus:shadow-outline"
                                      name="size"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.size}
                                    />
                                  </div>
                                </div>
                                <br />
                                <br />
                                <span className="form-label">discount code</span>
                                <Field
                                  type="text"
                                  className="w-full form-input focus:outline-none focus:shadow-outline"
                                  name="code"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.code}
                                />
                                <br />
                                <br />
                                <span className="form-label">store / site</span>
                                <Field
                                  type="text"
                                  className="w-full form-input focus:outline-none focus:shadow-outline"
                                  name="store"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.store}
                                />
                                <br />
                                <br />
                                {/* FOOTER */}
                                <div className="py-4 ">
                                  <div className="flex justify-between">
                                    <Button
                                      buttonType="pill-cancel"
                                      weight="thin"
                                      xtra="hover:shadow"
                                      onClick={() => {
                                        appState.closeDrawer();
                                      }}
                                    >
                                      <span className="text-center">Cancel</span>
                                    </Button>
                                    {/* HACK: THERE IS SOME ISSUE WITH FLICKITY AND CALLING
                                OUT TO MY SCRAPE FUNCTION - ONLY SHOW THE 'Back' BUTTON 
                                IF WE DIDN'T SCRAPE A PAGE */}

                                    {!appState.state.selectedItemObj ? (
                                      <Button
                                        buttonType="pill-standard"
                                        weight="thin"
                                        xtra="hover:shadow"
                                        onClick={() => previous()}
                                      >
                                        <span className="text-center">  Back  </span>
                                      </Button>
                                    ) : null}

                                    {/* IF WE KNOW THE listId THEN WE CAN SUBMIT - OTHERWISE
                        WE NEED TO GO TO THE LAST TRANSITION TO PICK A LIST */}
                                    <Button
                                      disabled={isSubmitting}
                                      buttonType="pill-add"
                                      weight="thin"
                                      xtra="hover:shadow"
                                      type="submit"
                                    >
                                      <span className="text-center">{appState.state.selectedListId ? '  save  ' : '  next  '}</span>
                                    </Button>
                                  </div>
                                </div>
                              </form>
                            )}
                        </Fragment>
                      )}
                  </Formik>
                </Fragment>
              </div>
            )}
        </DrawerSpace>
      )}
    </Subscribe>
  );
}

ItemCreateItemDetails.propTypes = {
  forward: PropTypes.func,
  previous: PropTypes.func,
};

ItemCreateItemDetails.defaultProps = {
  forward: null,
  previous: null,
};
