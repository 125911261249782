import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Subscribe } from 'unstated';
import { useQuery } from '@apollo/react-hooks';
import Downshift from 'downshift';
import _forEach from 'lodash.foreach';
import ItemCardMin from '../item/itemCard.min';
import DrawerContainer from '../../containers/drawer';
import Button from '../common/button';
import { getAllInvitedUsersBySender } from '../../graphql/graphql';
import Chip from '../common/chip';
import DropdownUser from '../common/dropdownUser';
import Checkbox from '../common/checkbox';
import { fnCreateInvitation } from '../../functions/index';
import Loading from '../common/loading';
import DrawerMessageInformation from '../common/drawerMessageInformation';
import Slider from '../common/slider';
import Filter from '../common/icons/filter-svg';
/* eslint-disable  */


export default function DrawerItemShareCreate(props) {
  const { listItems, listBuyType } = props;
  const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;
  const appUserId = localStorage.getItem('user_id_app');
  const currentUserId = localStorage.getItem('user_id');
  const [selectedUsers, setSelectedUser] = useState([]);
  const [selectedItems, setSelectedItem] = useState([]);
  const [filterApplied, setFilterApplied] = useState(false);
  const [ogSelectedItems, setOgSelectedItem] = useState([]);
  const [currentInput, setInputValue] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [initialized, setInitialized] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [editStatus, setEditStatus] = useState(false);
  const [invitationMessage, setInvitationMessage] = useState('');
  const [showLoading, setShowLoading] = useState(false);
  const [valueMax, setValueMax] = useState(0.0);

  // SAVE THE prop listItems TO LOCAL STATE
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      const useEffectArr = [];
      let convertedItemAmount, highestValue = 0.00;
      _forEach(listItems, itm => {
        if (itm && itm.item) {
          useEffectArr.push({
            item: itm.item,
            id: itm.item.id,
            name: itm.item.name,
            checked: false,
            ordinal: itm.item.ordinal
          });
        }

        // BUILD THE MAX AMOUNT OF THE LIST
        try {
          convertedItemAmount = parseFloat(itm.item.amount.substring(1).replace(',', ''));

        } catch (error) {
          console.error(`draweritem.share.create > useEffect > error converting !`, error);
        }
        if (convertedItemAmount > highestValue) {
          highestValue = convertedItemAmount;
        }
      });

      // SET THE ITEMS IN BOTH THE RENDERED ARR (selectedItems) AS WELL AS THE 'READ-ONLY' COPY (ogSelectedItems)
      if (useEffectArr && Array.isArray(useEffectArr) && useEffectArr.length > 0) {
        setSelectedItem(useEffectArr);
        setOgSelectedItem(useEffectArr);
      }

      // SET THE valueMax TO USE IN THE SLIDER BELOW
      setValueMax(highestValue);
    }
  }, [listItems]
  );

  // UPDATE THE selectAll STATE AFTER THE selectedItems ARRAY
  // IS UPDATED
  useEffect(() => {
    // UPDATE selectAll TOGGLE BY COUNTING ALL 'checked' ITEMS IN THE
    // selectedItems STATE - IF THE NUMBER EQUALS THE NUMBER OF ITEMS IN THE ARRAY
    // THEN EVERYTHING IS CHECKED
    setSelectAll(selectedItems.filter(l => l.checked === true).length === selectedItems.length ? true : false);
  }, [selectedItems])


  // THIS IS THE LOADING WHEN THE BUTTON IS CLICKED
  if (showLoading) {
    <div className="flex content-center">
      <Loading loadingText="Sharing this list!" />
    </div>
  }

  // CALLING OUT TO GRAPHQL
  const { loading, error, data } = useQuery(getAllInvitedUsersBySender, {
    variables: { senderId: appUserId },
    suspend: false,
  });


  // THIS IS THE LOADING WHILE THE COMPONENT MOUNTS
  if (loading) {
    return (
      <div className="flex content-center">
        <Loading loadingText="Loading..." />
      </div>
    )
  };


  if (error) return `Error! ${error.message}`;
  let suggestions = [];
  if (data && data.Invitation && Array.isArray(data.Invitation)) {
    // UNIQUE SET OF PREVIOUS INVITATION RECEIVERS
    suggestions = getUnique(data.Invitation, 'id');
    if (displayConsole) {
      console.log(`draweritem.share.create > suggestions: `, suggestions);
    }
  }


  const createMockUser = (id, firstName, lastName, avatarUrl, email, userType) => {
    const mockUser = {
      id: id || `unkown-${Date.now().toString()}`,
      firstName,
      lastName,
      avatarUrl,
      email,
      userType, // EXISTING or NEW
    };

    return mockUser;
  };

  // CLEARS THE VALUE OF THE input
  const clearInput = eventSource => {
    setInputValue('');
  };

  /**
   * 
   * ADDS A USER TO THE LOCAL SELECTED USERS STATE
   * @param {object} user 
   */
  const addUser = async user => {
    let newUser = user;
    if (newUser) {
      if (displayConsole) {
        console.log(`drawerItem.share.create > addUser: `, newUser);
      }
      // ONLY ADD THE USER IF THEY AREN'T ALREADY IN THERE
      let add = true;

      // IF THE user OBJ HAS A userReceiver NODE - THIS CAME FROM PREVIOUS USER INVITES -
      // LET'S FLATTEN THIS OUT
      if (newUser.receiver) {
        newUser = createMockUser(
          user.receiver.id,
          user.receiver.firstName || '',
          user.receiver.lastName || '',
          user.receiver.avatarUrl || '',
          user.receiver.email || '',
          ''
        );
      }

      _forEach(selectedUsers, selectedUser => {
        if (selectedUser && selectedUser.email) {
          // IF WE FIND A USER WITH THE SAME EMAIL ADDRESS
          // DON'T ADD THEM
          if (selectedUser.email.toUpperCase() === newUser.email.toUpperCase()) {
            add = false;
          }
        }
      });

      if (add) {
        // TRANSLATE THIS FROM A user.userReceiver OBJECT TO userReceiver SO
        // THAT WE CAN ACCESS THE PROPERTIES DIRECTLY (BELOW)
        setSelectedUser([...selectedUsers, newUser]);
      }
      clearInput(``);
    }
  };

  // FIRES WHEN A CHECKBOX IS CLICKED - HANDLES STATE MGMT FOR THE LISTS CHECKED, ETC.
  const handleCheckboxCheck = e => {
    if (displayConsole) {
      console.log(`${e.target.name} is ${e.target.checked}`);
    }

    // A BIT OF A HACK BUT THIS UPDATES THE useState ARRAY WITH A NEW ARRAY WITH AN UPDATED
    // LIST OF CHECKED ITEMS
    let tmpArr = selectedItems.filter(l => l.id !== e.target.name);
    let tmpI = selectedItems.filter(l => l.id === e.target.name);
    if (tmpI && Array.isArray(tmpI) && tmpI.length > 0) {
      tmpI.map(i => {
        tmpArr.push({ "item": i.item, "id": `${i.id}`, "checked": e.target.checked, "name": `${i.name}`, "ordinal": i.ordinal });
      })

    }

    // UDPATE selectedItems
    setSelectedItem(tmpArr);
  };

  // HANDLES THE 'SELECT ALL' CHECKBOX
  const handleToggleSelectAll = e => {

    if (displayConsole) {
      console.log(`drawerItem.share.create > handleToggleSelectAll - e`, e);
    }
    // FIRST, ITERATE THROUGH ALL OF THE selectedItems AND THEN DO THE TOGGLE
    const handleToggleArr = [];
    selectedItems.map(itm => {
      handleToggleArr.push({ "item": itm.item, "id": `${itm.id}`, "checked": e.target.checked, "name": `${itm.name}`, "ordinal": itm.ordinal })
    })

    // CHECK ALL THE BOXES
    setSelectedItem(handleToggleArr);
  }

  /**
   * STORES THE input TEXT INTO LOCAL STATE
   */
  const handleInputChange = event => {
    if (event && event.inputValue) {
      setInputValue(event.inputValue);
    }
  };

  // HANDLES THE <Toggle /> ACTION FOR  'OwnerBuy' LISTS
  const handleEditorToggle = (toggleState) => {
    if (displayConsole) {
      console.log(`drawerItem.share.create > handleEditorToggle - e`, toggleState);
    }

    setEditStatus(toggleState);
  };

  // VALIDATES AN EMAIL ADDRESS AGAINST A REGEX PATTERN
  const isEmail = email => {
    console.log(`isEmail > email: ${email}`);
    let emailTest = true;
    if (email && email !== '') {
      // eslint-disable-next-line
      emailTest = /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
      if (!emailTest) {
        setErrorMsg(`This is not a valid email address...`);
      } else {
        setErrorMsg(``);
      }
    }

    return emailTest;
  };

  // HANDLES EITHER ENTERING A USER FROM THE input
  // PICKING THEM FROM THE LIST OF PREVIOUSLY SHARED WITH USERS IS HANDLED
  // VIA GOING DIRECTLY TO addUser()
  const handleInputKeyDown = async ({ event, inputValue }) => {
    if (['Enter', 'Tab', ',', ';'].includes(event.key)) {
      event.preventDefault();

      if (inputValue && inputValue !== '' && isEmail(inputValue)) {
        // THIS IS A USER WE DON'T ALREADY KNOW ABOUT, CREATE A MOCK userReceiver
        if (displayConsole) {
          console.log(`drawerItem.share.create > handleInputKeyDown - inputValue: `, inputValue);
          console.dir();
        }
        const unknownUserPlaceholderId = `unkown-${Date.now().toString()}`;
        await addUser(createMockUser(unknownUserPlaceholderId, '', '', '', inputValue, 'new'));
        clearInput(`addUser - brand new`);
      }
    } else if (['Backspace'].includes(event.key)) {
      // REMOVE THE LAST INPUT
      setInputValue(currentInput.length ? currentInput.slice(0, currentInput.length - 1) : '');
    }
  };

  // REMOVES THE USER FROM THE LOCAL CACHE
  const deleteUser = id => {
    // FILTER OUT THE USER FROM THE SELECTEDUSERS ARRAY
    setSelectedUser(selectedUsers.filter(user => user.id !== id));
  };

  // THIS IS PRETTY GNARLY BUT BASICALLY FINDING UNIQUE OBJECTS IN ARRAYS
  // ARE HARD - THIS ARTICLE HELPS: https://dev.to/saigowthamr/how-to-remove-duplicate-objects-from-an-array-javascript-48ok
  function getUnique(arr, comp) {
    let unique = [];
    if (Array.isArray(arr) && arr.length > 0 && arr && comp) {
      unique = arr
        .map(e => (e && e.receiver ? e.receiver[comp] : null))

        // store the keys of the unique objects
        .map((e, i, final) => final.indexOf(e) === i && i)

        // eliminate the dead keys & store unique objects
        .filter(e => arr[e])
        .map(e => arr[e]);
    }

    return unique;
  }

  // CALL THE FUNCTION TO CREATE THE INVITATION
  const createInvitation = async (listId, userId, appState) => {
    // COMMENTING OUT UNTIL WE'RE READY TO CALL OUT TO THE SERVICE
    if (listId && userId && selectedUsers.length > 0) {
      const checkedItems = selectedItems.filter(si => si.checked);
      const sendTheseItems = [];

      if (checkedItems && Array.isArray(checkedItems) && checkedItems.length > 0) {
        checkedItems.filter(si => si.checked).forEach(selectedItem => {
          if (selectedItem && selectedItem.item && selectedItem.item.id) {
            sendTheseItems.push({ "id": selectedItem.item.id, "itemId": selectedItem.item.itemId, "name": selectedItem.item.name })
          }
        });
      }
      const invitationObj = {
        listId,
        senderUserId: userId,
        selectedUsers,
        // ONLY GET THE ITEMS THAT THE USER SELECTED IN THE UI
        selectedItems: sendTheseItems,
        listEditorRights: editStatus,
        invitationMessage
      };

      // SEND THIS OFF TO THE EVENTS PIPELINE
      if (displayConsole) {
        console.log(`draweritem.share.create > createInvitation > sending this Invitation off to be created: `, invitationObj);
      }
      await fnCreateInvitation(invitationObj).then((invitationCreated) => {
        if (displayConsole) {
          console.log(`draweritem.share.create > createInvitation > This came back from invitation creation: `, invitationCreated);
        }
        if (invitationCreated) {
          appState.closeDrawer();
          appState.showMessageCard('msg', `${selectedUsers.length > 1 ? 'Invitation' : 'Invitations'} created!`);
        } else {
          appState.closeDrawer();
          appState.showMessageCard('error', `Issue creating ${selectedUsers.length > 1 ? 'Invitation' : 'Invitations'}. Please try again.'`);
        }
      })
    } else {
      // DIDN'T ENTER AT LEAST ONE EMAIL ADDRESS
      if (selectedUsers.length < 1) {
        setErrorMsg('Please enter at least one address to invite')
      } else {
        if (displayConsole) {
          console.log(`drawerItem.share.create > inviteNewUsers - missing either userId ${userId} or listId ${listId}`);
        }
      }

    }
  };

  /**
   * FIRED WHEN THE USER MOVES THE SLIDER
   * @param {int} filterAmount 
   */
  const sliderChange = (filterAmount) => {
    if (filterAmount) {
      // console.log(`drawerItem.share.create > sliderChange - filterAmount ${filterAmount}`);
      setFilterApplied(true);
      if (ogSelectedItems && ogSelectedItems.length > 1) {
        const tmpFilteredArr = [];
        _forEach(ogSelectedItems, itm => {
          if (itm && itm.item && itm.item.amount && parseFloat(itm.item.amount.substring(1).replace(',', '')) <= filterAmount) {
            tmpFilteredArr.push({
              item: itm.item,
              id: itm.item.id,
              name: itm.item.name,
              checked: false,
              ordinal: itm.item.ordinal
            });
          }
          // else {
          //   console.log(`drawerItem.share.create > sliderChange ${parseFloat(itm.item.amount.substring(1).replace(',', ''))} is > than ${filterAmount}`);
          // }
        });

        // SAVE THE FILTERED LIST OF ITEMS
        setSelectedItem(tmpFilteredArr);
      }
    }
  }

  return (
    <Subscribe to={[DrawerContainer]}>
      {appState => (
        <Fragment>
          <div className="pl-4 pr-12">
            <form>
              <span className="drawer-header-description-text">Invite other users to share this list with</span>
              <br />
              <Fragment>
                {!editStatus && selectedItems && Array.isArray(selectedItems) && selectedItems.length < 1
                  ? (
                    <div className="pt-3">
                      <DrawerMessageInformation>
                        <span>There aren't any items to share with other users.</span>
                      </DrawerMessageInformation>
                    </div>
                  )
                  : null}
                {/* THIS IS THE SECTION OF USERS THAT ARE ADDED VIA THIS COMPONENT */}
                <div hidden={selectedUsers && selectedUsers.length < 0} className="pt-4">
                  {selectedUsers.map(user => (
                    <div key={user.id} className="pb-2">
                      {/* eslint-disable */}
                      <Chip
                        chipType={user.userType && user.userType === 'existing' ? 'share' : null}
                        label={`${user.firstName
                          ? user.lastName
                            ? `${user.firstName} ${user.lastName}`
                            : user.firstName
                          : user.email
                          }`}
                        deleteChip={() => deleteUser(user.id)}
                      />
                      {/* eslint-enable */}
                    </div>
                  ))}
                </div>
                <Downshift
                  onChange={selection => {
                    addUser(selection);
                  }}
                  onStateChange={handleInputChange}
                  // itemToString gets the 'value' out of the object which - in this case - is
                  // the email addy
                  itemToString={item =>
                    item && item.receiver && item.receiver.receiverEmail ? item.receiver.receiverEmail : ''
                  }
                  inputValue={currentInput}
                >
                  {({
                    getInputProps, // Props to pass to our input
                    getItemProps, // Props to pass into each of the suggested items
                    getLabelProps,
                    getMenuProps,
                    isOpen, // Whether the "suggestions box" is visible or not
                    inputValue, // Value that the user typed in the search box
                    highlightedIndex, // Index of the item currently selected in the list
                    selectHighlightedItem,
                    setHighlightedIndex,
                    reset,
                    clearItems,
                    setItemCount,
                    selectedItem, // Item that is currently selected in the list (when hovering)
                  }) => (
                      <div className="pt-4" hidden={selectedItems && Array.isArray(selectedItems) && selectedItems.length < 1}>
                        <input
                          autoFocus
                          {...getInputProps({
                            onKeyDown: event =>
                              handleInputKeyDown({
                                event,
                                inputValue,
                              }),
                            onBlur: e => {
                              e.preventDefault();
                            },
                            placeholder: 'Enter an email address',
                          })}
                          className="w-full form-input focus:outline-none focus:shadow-outline"
                        />
                        <div {...getMenuProps()}>
                          {isOpen && inputValue !== ''
                            ? suggestions
                              // FROM THE DOWNSHIFT SOURCE EXAMPLES
                              .filter(function (item) {
                                // FILTERS SUGGESTIONS BY FIRST NAME, LAST NAME OR EMAIL
                                let foundIt = false;
                                if(item){
                                  let foundItEmail, foundItFirstName, foundItLastName = false;
                                  // FIRST CHECK FOR EMAIL
                                  if(item.receiverEmail){
                                    foundItEmail = item.receiverEmail.toUpperCase().includes(inputValue.toUpperCase());
                                  }

                                  // NEXT CHECK FIRST NAME
                                  if(item.receiver && item.receiver.firstName){
                                    foundItFirstName = item.receiver.firstName.toUpperCase().includes(inputValue.toUpperCase());
                                  }
                                  
                                  // NEXT CHECK LAST NAME
                                  if(item.receiver && item.receiver.lastName){
                                    foundItLastName = item.receiver.lastName.toUpperCase().includes(inputValue.toUpperCase());
                                  }

                                  // FINAL CHECK TO SEE IF WE FOUND ANYTHING
                                  if(foundItEmail || foundItFirstName || foundItLastName){
                                    foundIt = true;
                                  }
                                }
                                return foundIt;
                              })
                              .map((item, index) => (
                                <div
                                  {...getItemProps({
                                    key: item.receiver.id,
                                    index,
                                    item,
                                    style: {
                                      backgroundColor: highlightedIndex === index ? 'whitesmoke' : 'white',
                                      fontWeight: selectedItem === item ? 'bold' : 'normal',
                                      display: 'fixed',
                                      zIndex: 100
                                    },
                                  })}
                                >
                                  <DropdownUser user={item.receiver} />
                                </div>
                              ))
                            : null}
                          {/* {isOpen && inputValue !== '' ? console.log(`draweritem.share.create > suggestions: `, suggestions) : console.log("Can't show anything")} */}
                        </div>
                      </div>
                    )}
                </Downshift>
                {errorMsg ? (
                  <div className="form-input-error">
                    <div className="p-2">{errorMsg}</div>
                  </div>
                ) : <div />}
              </Fragment>
              {/* EDIT ROLE FOR USERS? */}
              {listBuyType === 'OwnerBuy' && selectedItems.length > 0 ? (
                <Fragment>
                  <div className="py-3">
                    <div className="drawer-header-description-text pt-3 pb-1">Allow users to add items?</div>
                    <input checked={editStatus} type="checkbox" id="listEditToggleSwitch" className="hidden" onChange={(e) => { handleEditorToggle(e.target.checked) }} />
                    <div className="switchToggleContent">
                      <label htmlFor="listEditToggleSwitch" className="switchToggleLabel">
                        <div className="switchToggleToggle" />
                        <div className="switchToggleItems">
                          <div className="switchToggleLeft pt-3" style={{ marginLeft: '-10px' }}>No way</div>
                          <div className="switchToggleRight pt-3" style={{ marginLeft: '-80px' }} >Sure</div>
                        </div>
                      </label>
                    </div>
                    <div className="pt-1 leading-normal text-grey-dark">
                      {editStatus ? (
                        <span>The people you invite can add, delete and reorder items on this list.<br /> <br />This is great if you want
                        others to add items to this list and buy together.
                        </span>
                      ) : (
                          <span>Only you can add, delete and reorder items on this list.</span>
                        )}
                    </div>
                  </div>
                </Fragment>
              ) : null}
              {/* USER CAN SEND A MESSAGE */}
              {(editStatus) || (selectedItems && Array.isArray(selectedItems) && selectedItems.length > 0)
                ? (
                  <div className="pt-6">
                    <p className="drawer-header-description-text">Include a message</p>
                    <p className="pt-1 pb-1 leading-normal text-grey-dark">This will be included in your invitation:</p>
                    <div>
                      <textarea
                        id="invitationMessage"
                        maxLength={200}
                        rows="5"
                        className="w-full form-input focus:outline-none focus:shadow-outline"
                        value={invitationMessage}
                        onChange={e => setInvitationMessage(e.target.value)}
                      />
                    </div>
                    <div className="flex justify-end">
                      <div className="form-plain text-grey">{invitationMessage.length} / 200</div>
                    </div>
                  </div>
                )
                : null}

              {/* SHOW THE ITEMS TO SELECT HERE */}
              {filterApplied || (!editStatus && selectedItems && Array.isArray(selectedItems) && selectedItems.length > 0) ? (
                <div className="pt-6">
                  <p className="drawer-header-description-text">Select items to share</p>
                  <p className="pt-1 pb-4 leading-normal text-grey-dark">Only the items you select below will be shared with the people you select above.</p>
                  <div className="py-4 flex items-start align-middle">
                    <Checkbox name="chkBoxSelectAll" checked={selectAll} onChange={handleToggleSelectAll} />
                    <span className="pl-4 drawer-header-description-text">Select all items</span>
                  </div>
                  {/* DON'T SHOW THE SLIDER IF THERE ISN'T A RANGE OF VALUES */}
                  {valueMax > 0.0 ? (
                    <div className="my-1 mb-3 bg-white w-full max-w-gyfto-max-card-item rounded-sm p-3 flex justify-start align-middle">
                      <div className=" w-24 pr-3 flex justify-start h-full my-auto">
                        <div><Filter secondaryColor="#7e7e7e" primaryColor="#7e7e7e" /></div>
                        <div className="text-sm px-2 pt-1">Price</div>
                      </div>
                      <Slider maxValue={valueMax} minValue={0} step={1} handleChangeEvent={sliderChange} enableSpecific={false} />
                    </div>
                  ) : (null)}
                </div>
              ) : null}

              {!editStatus && selectedItems && Array.isArray(selectedItems) && selectedItems.length > 0
                ? selectedItems
                  // eslint-disable-line no-nested-ternary
                  .sort((a, b) => (a.ordinal > b.ordinal ? 1 : b.ordinal > a.ordinal ? -1 : 0))
                  .map(itm => (
                    <div key={itm.item.id} className="py-4 flex items-start">
                      <div>
                        <Checkbox name={itm.id} checked={itm.checked} onChange={handleCheckboxCheck} />
                      </div>
                      <div className="ml-4">
                        <ItemCardMin item={itm.item} />
                      </div>
                    </div>
                  ))
                : null}

              {/* SHOW THE BUTTON IF THE INVITAITON IS FOR AN EDITOR (editStats === true) OR ITEMS HAVE BEEN CHECKED
              AND THERE ARE RECEIVERS DEFINED */}
              <div className="flex content-center">
                <div className={(selectedItems.filter(si => si.checked === true).length > 0 || editStatus === true) && selectedUsers.length > 0 ? ['btn-drawer ', 'show'].join(' ') : 'btn-drawer'}>
                  <Button
                    disabled={selectedUsers.length < 1}
                    buttonType="pill-add"
                    weight="thin"
                    xtra="hover:shadow"
                    showSpinner={true}
                    onClick={async e => {
                      setShowLoading(true);
                      if (
                        appState &&
                        appState.state &&
                        appState.state.selectedListObj &&
                        appState.state.selectedListObj.id &&
                        currentUserId
                      ) {
                        await createInvitation(appState.state.selectedListObj.id, currentUserId, appState).catch(error => {
                          if (displayConsole) {
                            console.log(`drawerItem.share.create > Add button - error calling inviteNewUsers: `, error);
                          }
                        });
                      } else {
                        appState.closeDrawer();
                        appState.showMessageCard('error', "Hmm...ran into an issue!");
                      }
                    }}
                  >
                    <span className="text-center">invite</span>
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </Fragment>
      )}
    </Subscribe>
  );
}

DrawerItemShareCreate.propTypes = {
  listItems: PropTypes.array.isRequired,
  listBuyType: PropTypes.string
};
/* eslint-enable  */
