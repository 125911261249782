import React, { useState, useEffect, Fragment } from 'react';
import { Subscribe } from 'unstated';
import _forEach from 'lodash.foreach';
import DrawerContainer from '../../containers/drawer';
import Button from '../common/button';

/* eslint-disable  */


export default function DrawerUserSettings(props) {
  const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;

  return (
    <Subscribe to={[DrawerContainer]}>
      {appState => (
        <Fragment>
          <div className="p-4">
            <div className="drawer-header-description-text pb-2">Click below to logout.</div>
            <br />
            <div className="">
              <Button
                onClick={() =>{
                  const auth = appState.state.auth;
                  if(auth){
                    auth.logout();
                  }
                }}
                buttonType="pill-standard"
                weight="thin"
                xtra="hover:shadow"
              >
                LOG OUT
            </Button>
            </div>
          </div>
        </Fragment>
      )}
    </Subscribe>
  );
}

/* eslint-enable  */
