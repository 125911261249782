import React, { Fragment } from 'react';
import { Subscribe } from 'unstated';
import DrawerContainer from '../../containers/drawer';
import DrawerItemItemCopy from './draweritem.item.copy';
export default function DrawerItemCopyContainer() {

  return (
    <Subscribe to={[DrawerContainer]}>
      {appState => (
        <Fragment>
          <DrawerItemItemCopy itemId={appState.state.selectedItemObj.id} listId={appState.state.selectedListId} />
        </Fragment>
      )}
    </Subscribe>
  );
}
