import React, { useState } from 'react';
import PropTypes from 'prop-types';

export default function SVGNew(props) {
    const { primaryColor, secondaryColor, width, height } = props;
    const [isShown, setIsShown] = useState(false);
    return (
        <div style={{ height: height, width: width }} onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 26">
                <defs />
                <g fill="none" fillRule="evenodd" stroke={isShown ? `${secondaryColor}` : `${primaryColor}`} strokeWidth="2" transform="translate(1.362 .75)">
                    <rect width="23.186" height="23.174" x=".516" y=".824" rx="4" />
                    <g strokeLinecap="square">
                        <path d="M5.783 11.812h12M12.005 18.034v-12" />
                    </g>
                </g>
            </svg>
        </div>
    );
}

SVGNew.propTypes = {
    primaryColor: PropTypes.string,
    secondaryColor: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string
};

SVGNew.defaultProps = {
    primaryColor: '#5FBA7D',
    secondaryColor: '#000000',
    width: "24px",
    height: "24px"
};
