import React from 'react';
import Avatar from '../avatar/index';

export default function DropdownUser(props) {
  // eslint-disable-line
  const { user } = props;
  const displayConsole = false;
  if(displayConsole){
    console.log(`dropdownuser > user: `, user);
  }
  return (
    <div className="flex w-full pl-2 py-2">
      <Avatar
        key={user.id}
        size="sm"
        userId={user.id}
        // IF THE CURRENT USER AND THE ACCOUNT THAT CREATED THIS LIST IS THE SAME
        // THEN REPLACE THE firstName lastName TEXT
        firstName={user.firstName}
        lastName={user.lastName}
        emailAddress={user.email}
        avatarUrl={user.avatarUrl}
        // SHOW NAMES IF THE DISPLAY IS VERITCAL
        showName
        subtext={user.email}
        alttext={`${user.firstName} ${user.lastName}`}
        direction="vertical"
        // avatarShadow={avatarShadow}
        // avatarShadowType={avatarShadowType}
      />
    </div>
  );
}
