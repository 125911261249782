import React from 'react';

export default function SVGEmptyList(props) {
    const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;

    if (displayConsole) {
        console.log(`empty-list-svg > props: `, props);
    }
    return (
        <div >
            <svg xmlns="http://www.w3.org/2000/svg" width="180.03" height="177.159" viewBox="0 0 180.03 177.159">
                <defs>
                    <clipPath id="clipPath">
                        <path id="Path_15" d="M289,281.518q2.655,41.847-39.192,32.744T180.4,299.976q-27.561-5.183-57.144-32.744t2.781-52.34a609.414,609.414,0,0,0,57.144-49.18q24.779-24.4,56.259-6.7t39.192,49.18Q286.343,239.671,289,281.518Z" fill="rgba(167,182,191,0.21)" />
                    </clipPath>
                </defs>
                <g id="Empty_List" transform="translate(-862.28 13753.159)" clipPath="url(#clip-path)">
                    <g id="Blob_shape" transform="translate(753.154 -13904.729)" >
                        <path id="Path_14" d="M289,281.518q2.655,41.847-39.192,32.744T180.4,299.976q-27.561-5.183-57.144-32.744t2.781-52.34a609.414,609.414,0,0,0,57.144-49.18q24.779-24.4,56.259-6.7t39.192,49.18Q286.343,239.671,289,281.518Z" fill="rgba(167,182,191,0.1)" />
                    </g>
                    <path id="Path_7" d="M114.078,141.714V4a4,4,0,0,0-4-4h-98.2a4,4,0,0,0-4,4V141.714a4,4,0,0,0,4,4h98.2A4,4,0,0,0,114.078,141.714Z" transform="translate(907 -13736)" fill="#fff" />
                    <path id="Path_8" d="M107.2,37.516V10.644a4,4,0,0,0-4-4H48.309M107.2,88.71v7.307m0,16.114v36.228a4,4,0,0,1-4,4H87.31M42.31,6.644H5a4,4,0,0,0-4,4V148.359a4,4,0,0,0,4,4H72.31" transform="translate(907 -13736)" fill="none" stroke="#6dc088" strokeLinecap="round" strokeWidth="2" />
                    <path id="Path_9" d="M107.2,37.516V10.644a4,4,0,0,0-4-4H48.309M107.2,88.71v7.307m0,16.114v36.228a4,4,0,0,1-4,4H87.31M42.31,6.644H5a4,4,0,0,0-4,4V148.359a4,4,0,0,0,4,4H55.81" transform="translate(907 -13736)" fill="none" stroke="#6dc088" strokeLinecap="round" strokeWidth="2" />
                    <path id="Path_10" d="M107.2,22.7V10.644a4,4,0,0,0-4-4H5a4,4,0,0,0-4,4V148.359a4,4,0,0,0,4,4H57.81M107.2,43.69V76.213m0,9.262V99.017m0,10.5v38.846a4,4,0,0,1-4,4H93.5M28.642,66.014H38.663m25.147,0h-14M28.642,92.906H70.77M28.642,119.8H75.524" transform="translate(907 -13736)" fill="none" stroke="#adbcc3" strokeLinecap="round" strokeWidth="2" />
                    <ellipse id="Ellipse_3" cx="3.738" cy="3.705" rx="3.738" ry="3.705" transform="translate(921.441 -13673.787)" fill="none" stroke="#6abd85" strokeWidth="2" />
                    <rect id="Rectangle_1" width="7" height="7" rx="1" transform="translate(921.81 -13647)" fill="none" stroke="#6dc088" strokeWidth="2" />
                    <ellipse id="Ellipse_4" cx="3.738" cy="3.705" rx="3.738" ry="3.705" transform="translate(921.441 -13620.002)" fill="none" stroke="#6abd85" strokeWidth="2" />
                    <path id="Path_11" d="M28.642,39.8H95.524" transform="translate(907 -13736)" fill="none" stroke="#adbcc3" strokeLinecap="round" strokeWidth="2" />
                    <ellipse id="Ellipse_5" cx="3.738" cy="3.705" rx="3.738" ry="3.705" transform="translate(921.441 -13700)" fill="none" stroke="#66ba83" strokeWidth="2" />
                    <circle id="Ellipse_6" cx="20" cy="20" r="20" transform="translate(988.31 -13616)" fill="#5fba7d" opacity="0.909" />
                    <path id="Path_12" d="M28.642,39.8h20" transform="translate(969.668 -13635.8)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="4" />
                    <path id="Path_13" d="M28.642,39.8h20" transform="translate(1048.11 -13634.642) rotate(90)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="4" />
                </g>
            </svg>

        </div>
    );
}
