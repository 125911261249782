import React, { Fragment, useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Subscribe } from 'unstated';
import { mutationSetItemObtained, mutationSetItemObtainedBy } from '../../graphql/graphql';
import Button from '../common/button';
import DrawerContainer from '../../containers/drawer';
import UserAvatar from '../avatar/userAvatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// eslint-disable-next-line react/prop-types
export default function ItemObtained({ item, screenSize, isShare, }) {
  const [obtainItem] = useMutation(mutationSetItemObtained);
  const [obtainItemBy] = useMutation(mutationSetItemObtainedBy);
  const displayConsole = item && item.id === "49ccc10f-b658-4264-ab1e-f1beb9561e1d" ? true : false;
  const appUserId = localStorage.getItem('user_id_app');
  const currentUserId = localStorage.getItem('user_id');
  const funcName = "itemObtained";
  const [arrObtainedby, setArrObtainedBy] = useState([]);

  // THIS WILL FIRE ONCE WE GET THE item OBJECT FROM PROPS
  useEffect(() => {
    async function doUseEffectStuff() {
      if (displayConsole) {
        console.log(`${funcName} > useEffect > Props: ${item.name} '${item.id}' isObtained '${item.obtainedBy}' obtainedBy: `);
        console.dir(item);
      }

      // IF THERE ARE obtainedBy ON THE item - LET'S GO
      if (item && item.obtainedBy) {
        const tmpObtainedbyArr = [];
        tmpObtainedbyArr.push(item.obtainedBy);

        setArrObtainedBy([item.obtainedBy]);

      }
    }
    doUseEffectStuff();
  }, [item]);

  /**
   * THIS WILL EITHER SET THE obtained FOR AN ITEM OR WILL SET obtainedBy DEPENDING
   * ON THE STATUS OF THE item THAT IS PASSED IN VIA PROPS
   */
  const markItem = async () => {
    if (!isShare) {
      //THIS IS CALLED WHEN THE LIST IS NOT SHARED (I.E. OWNER/EDITOR) & OBTAINED
      // NOTE: WE NEED TO LOOK AT THE PREVIOUS VALUE OF item.obtained TO FIGURE OUT WHAT
      // VALUE TO USE FOR obtainedBy
      await obtainItem({
        variables: {
          itemId: item.id,
          obtained: !item.obtained,
          obtainedBy: item.obtained === true ? null : appUserId
        },
      }).then(({ data }) => {
        if (data) {
          // UPDATE THE STATE OF THE ITEM WATCH
          if (displayConsole) {
            console.log(`itemObtained > markItem > data from obtainItem mutation: `, data);
          }
        }
      })
        .catch(error => {
          console.error('itemObtained > markItem > There was an error sending the mutation: ', error);
        });
    } else {
      //THIS IS CALLED WHEN THE LIST IS SHARED (I.E. OWNER/EDITOR) & OBTAINED
      await obtainItemBy({
        variables: {
          itemId: item.id,
          obtainedBy: item && item.obtainedBy && item.obtainedBy.id ? null : appUserId
        },
      }).then(({ data }) => {
        if (data) {
          // UPDATE THE STATE OF THE ITEM WATCH
          if (displayConsole) {
            console.log(`itemObtained > markItem > data from obtainedBy mutation: `, data);
          }
        }
      })
        .catch(error => {
          console.error('itemObtained > markItem > There was an error sending the mutation: ', error);
        });
    }
  };

  return (
    <Subscribe to={[DrawerContainer]}>
      {appState => (
        <Fragment>
          {item && !item.obtained ? (
            // <div>
            //   <Button
            //     buttonType="pill-void-standard-light"
            //     xtra=" text-xs "
            //     weight="thin"
            //     responsive={false}
            //     onClick={async () => {
            //       await markItem()
            //         .catch(error => {
            //           if (displayConsole) {
            //             console.log(`itemObtained > error calling obtainItem mutation - error: `, error);
            //           }
            //         })
            //         .then(() => {
            //           appState.showMessageCard('msg', 'Congrats!');
            //         });
            //     }}
            //   >
            //     <span className="text-center px-3">GOT IT</span>
            //   </Button>
            // </div>
            <div className='w-full'>
              <Button
                // buttonType="pill-void-standard-light"
                buttonType="pill-void-stardard-blue"
                xtra="text-xs flex items-center justify-center p-2"
                weight="thin"
                responsive={false}
                onClick={async () => {
                  await markItem()
                    .catch(error => {
                      if (displayConsole) {
                        console.log(`itemObtained > error calling obtainItem mutation - error: `, error);
                      }
                    })
                    .then(() => {
                      appState.showMessageCard('msg', 'Congrats!');
                    });
                }}
              >
                <FontAwesomeIcon icon="thumbs-up" size="2x" />
                <span className=" pl-2">I got this</span>
              </Button>
            </div>
          ) : (
            <Fragment>
              {/* IF THIS WAS OBTAINED BY THE CURRENT USER (OR IT'S OBTAINED BUT WE DON'T KNOW BY WHOM) - SHOW THE CHECKBOX */}
              {item.obtainedBy && item.obtainedBy.id && item.obtainedBy.id !== currentUserId ? (
                <div className="flex justify-center ml-1">
                  <UserAvatar users={arrObtainedby} size="xsm" />
                </div>
              ) : (
                <div className=" circular-container pr-2">
                  <label className="checkbox-label">
                    <input
                      alt="Click me!"
                      type="checkbox"
                      title="Click me!"
                      defaultChecked={item.obtained}
                      onClick={async () => {
                        await markItem()
                          .catch(error => {
                            if (displayConsole) {
                              console.log(`itemObtained > error calling obtainItem mutation - error: `, error);
                            }
                          })
                          .then(() => {
                            appState.showMessageCard('msg', 'Moved this item back to your list!');
                          });
                      }}
                    />
                    <span className="checkbox-custom circular" />
                  </label>
                </div>
              )}
            </Fragment>
          )}
        </Fragment>
      )}
    </Subscribe>
  );
}