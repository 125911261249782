import React, { Fragment } from 'react';
import { Subscribe } from 'unstated';
import DrawerContainer from '../../containers/drawer';
import DrawerItemItemMove from './draweritem.item.move';
export default function DrawerItemMoveContainer() {

  return (
    <Subscribe to={[DrawerContainer]}>
      {appState => (
        <Fragment>
          <DrawerItemItemMove item={appState.state.selectedItemObj} itemId={appState.state.selectedItemObj.id} listId={appState.state.selectedListId} />
        </Fragment>
      )}
    </Subscribe>
  );
}
