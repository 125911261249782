import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types'
import Lottie from 'lottie-react-web';
import animationData from './lotties/spilled_drink.json';
import Button from './button';
import Carat from './caratHeader';

export default function ErrorUI(props) {
  const { errorMsg, displayMessage } = props;
  const [hideErrorMsg, setHideErrorMsg] = useState(true);
  return (
    <Fragment>
      <div className="flex flex-col justify-center mx-auto my-auto pt-2">
        <div><span className="drawer-header-description-text">Whoops! Looks like we ran into an issue...</span></div>
        <div className="pt-4">
          <Lottie
            options={{
              animationData,
              loop: true,
            }}
            height={200}
            width={200}
          />
        </div>
        <div>
          {displayMessage? (
            <div className="pb-8">{displayMessage}</div>
          ) : null}
        </div>
        <div>
          <div className="flex align-middle">
            <div className="form-label pr-2 pt-2">Error details</div>
            <div>
              <Button
                onClick={() => setHideErrorMsg(!hideErrorMsg)}
                buttonType="pill-no-style"
                weight="thin"
                xtra="hover:shadow">
                <Carat hide={hideErrorMsg} />
              </Button>
            </div>
          </div>
          <div>
            {!hideErrorMsg ? (
              <span className="bg-grey-lightest">{errorMsg}</span>
            ) : <Fragment/>}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

ErrorUI.propTypes = {
  errorMsg: PropTypes.string,
  displayMessage: PropTypes.string
};

ErrorUI.defaultProps = {
  errorMsg: null,
  displayMessage: null
};
