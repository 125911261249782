import React, { useState, Fragment, useEffect } from 'react';
import { Subscribe } from 'unstated';
import DrawerContainer from '../../containers/drawer';
import { useQuery } from '@apollo/react-hooks';
import { qrySearchListsByUserTypeShallow_Full } from '../../graphql/graphql';
import Loading from '../common/loading';
import ListCardSearchResults from '../list/list.card.search.results';
import { fnGetSearchHistory } from '../../functions/index';
import ItemName from '../item/itemName';
import Button from '../common/button';
import Search from '../common/search';

/* eslint-disable  */


export default function DrawerSearch(props) {
  const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;
  const COMPONENT_NAME = "draweritem.search";
  const [searchVal, setSearchVal] = useState('');
  const [dataResult, setDataResult] = useState(null);
  const [searchHistory, setSearchHistory] = useState(null);

  const { loading, error, data } = useQuery(qrySearchListsByUserTypeShallow_Full, {
    variables: { userId: localStorage.getItem(`user_id`), listUserType: ['Owner', 'SharedWith', 'Editor'] },
    suspend: false,
  });

  useEffect(() => {
    // SEARCH HISTORY STUFF
    async function getSearchHistory() {
      const prvSearchHistory = await fnGetSearchHistory();
      setSearchHistory(prvSearchHistory);
    }

    getSearchHistory();

    if (loading === false && data) {
      if (displayConsole) {
        console.log(`${COMPONENT_NAME} > data: `, data);
      }

      if (data.User && Array.isArray(data.User)) {
        const user = data.User[0];
        if (user && user.listUsers && Array.isArray(user.listUsers) && user.listUsers.length > 0) {
          const tmpDataLists = [];

          // ITERATE THROUGH ALL THE LISTS
          for (const lst of user.listUsers) {
            if (lst && lst.listUserType && lst.list) {

              // FIGURE OUT IF AN ITEM ON THE LIST HAS AN IMAGE THAT CAN REPRESENT THE LIST
              let tmpImageUrl = null;
              if (lst.list.listItems && Array.isArray(lst.list.listItems) && lst.list.listItems.length > 0) {
                const itm = lst.list.listItems[0];
                tmpImageUrl = itm && itm.item && itm.item.imageUrl ? itm.item.imageUrl : null;
              }
              tmpDataLists.push({
                id: lst.list.id
                , listDetails: lst.list.listDetails ? lst.list.listDetails : null
                , listUserType: lst.listUserType ? lst.listUserType : null
                , listItems: lst.list.listItems ? lst.list.listItems : null
                , imageUrl: tmpImageUrl
                , listId: lst.list.listId
                , listUsers: lst.list.listUsers
                , sourceList: lst.list.sourceList
              });
            }
          }

          // PUSH TO CACHE
          setDataResult(tmpDataLists);
        }
      }
    }
  }, [loading, data])


  if (error) {
    if (displayConsole) {
      console.log(`${COMPONENT_NAME} > error: `, error);
    }
  }

  // THIS IS THE LOADING WHILE THE COMPONENT MOUNTS
  if (loading) {
    return (
      <div className="flex content-center">
        <Loading loadingText="Loading..." />
      </div>
    )
  };

  /**
   * THIS WILL SET THE SEARCH VALUE TO BE FILTERED OUT IN THE
   * QUERY RESULT SET
   * @param {*} event 
   */
  const handleChange = value => {
    if (value) {
      setSearchVal(value);
    } else {
      if (displayConsole) {
        console.warn(`Error: missing 'value' from the child Search component!`);
      }
    }
  };

  // This function handle the scroll performance issue
  const debounce = (func, delay) => {
    let inDebounce
    return function () {
      const context = this
      const args = arguments
      clearTimeout(inDebounce)
      inDebounce = setTimeout(() => func.apply(context, args), delay)
    }
  }

  return (
    <Subscribe to={[DrawerContainer]}>
      {appState => (
        <Fragment>
          <div className="p-4">
            <div className="drawer-header-description-text pb-2">Search for lists</div>
            <br />
            <Search parentCompChangeEventReceiver={handleChange} inputBoxPlaceholderText="Search" showClearSearch={true} />

            {/* SHOW THIS AT THE TOP OF THE PANEL ON SM SCREENS */}
            <div className="pt-6 md:hidden">
              {dataResult ? (
                <Fragment>
                  <ListCardSearchResults data={dataResult} searchTerm={searchVal} />
                </Fragment>
              ) : null}
            </div>

            {searchHistory && Array.isArray(searchHistory) && searchHistory.length > 0 ? (
              <Fragment>
                <div className="drawer-header-description-text pt-4">Previous Searches</div>
                {searchHistory.sort((a, b) => (a.timeStamp < b.timeStamp ? 1 : b.timeStamp < a.timeStamp ? -1 : 0)).slice(0, 5).map(historyItem => (
                  <div key={historyItem.searchTermId} className='pb-4'>
                    <div className="py-1" >
                      <ItemName url={`${historyItem.searchTermId}`} localRoute={true} name={historyItem.searchTerm} textClass={'text-base'} />
                    </div>
                    {historyItem.listOwner && historyItem.listOwner.id ? (
                      <div>
                        <Button
                          buttonType="pill-void-tiny"
                          xtra={`py-1 cursor-default bg-gyfto-blue-dark border-gyfto-blue-dark w-auto`}
                          weight="thin"
                          responsive={false}
                        >
                          <span className="text-center px-4">{historyItem.listOwner.firstName}</span>
                        </Button>
                      </div>
                    ) : null}

                  </div>
                ))}
              </Fragment>
            ) : null}

            {/* SHOW THIS AT THE BOTTOM OF THE PANEL ON LARGE SCREENS */}
            <div className="pt-6 xs:hidden md:flex">
              {dataResult ? (
                <Fragment>
                  <ListCardSearchResults data={dataResult} searchTerm={searchVal} />
                </Fragment>
              ) : null}
            </div>
          </div>
        </Fragment>
      )}
    </Subscribe>
  );
}

/* eslint-enable  */
