import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import DrawerItemListDelete from './draweritem.list.delete';
import DrawerItemListLeave from './draweritem.list.leave';
import DrawerItemItemCreate from './draweritem.item.create';
import DrawerItemContainer from './draweritem.item.delete.container';
import DrawerItemsContainer from './draweritem.items.container';
import DrawerItemEdit from './draweritem.item.edit';
import DrawerItemCopyContainer from './draweritem.item.copy.container';
import DrawerItemMoveContainer from './draweritem.item.move.container';
import DrawerListCreate from './draweritem.list.create';
import DrawerListEditContainer from './draweritem.list.edit.container';
import DrawerShareCreateContainer from './draweritem.share.create.container';
import DrawerShareDeleteContainer from './draweritem.share.delete.container';
import DrawerUserSettings from './draweritem.user.settings';
import DrawerSearch from './draweritem.search';

export default class DrawerItem extends Component {
  /* eslint-disable class-methods-use-this */
  renderDrawerType(type, action, itemId) {
    const userId = localStorage.getItem('user_id');
    if (type === 'list') {
      switch (action) {
        case 'create':
          return <DrawerListCreate />;
        case 'edit':
          return <DrawerListEditContainer />;
        case 'delete':
          return <DrawerItemListDelete itemId={itemId} userId={userId} />;
          case 'leave':
            return <DrawerItemListLeave itemId={itemId} userId={userId} />;
          default:
          return <div />;
      }
    } else if (type === 'item') {
      switch (action) {
        case 'create':
          return <DrawerItemItemCreate />;
        case 'delete':
          return <DrawerItemContainer />;
        case 'edit':
          return <DrawerItemEdit />;
          case 'copy':
            return <DrawerItemCopyContainer />;
          case 'move':
            return <DrawerItemMoveContainer />;
          default:
          return <div />;
      }
    } else if (type === 'items') {
      return <DrawerItemsContainer action={action}/>

    } else if (type === 'share') {
      switch (action) {
        case 'create':
          return <DrawerShareCreateContainer />;
        case 'delete':
          return <DrawerShareDeleteContainer />;
        default:
          return <div />;
      }
    } else if (type === 'user') {
      switch (action) {
        case 'settings':
          return <DrawerUserSettings />;

        default:
          return <div />;
      }
     } else if (type === 'search') {
      return <DrawerSearch />;

    } else {
      return <div />;
    }
  }
  /* eslint-enable class-methods-use-this */

  render() {
    const { type, action, itemId } = this.props;
    //  06/12/21 - Taking this out 'overflow-x-hidden' to fix vertical scrolling issues in the drawer
    return <div className="pt-2 ">{this.renderDrawerType(type, action, itemId)}</div>;
  }
}

DrawerItem.propTypes = {
  type: PropTypes.string,
  action: PropTypes.string,
  itemId: PropTypes.string,
};

DrawerItem.defaultProps = {
  type: null,
  action: null,
  itemId: null,
};
