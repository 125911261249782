import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import PageSpace from '../common/pageSpace';
import NavBar from '../navbar/index';
import _size from 'lodash.size';
import { useQuery } from '@apollo/react-hooks';
import { SkeletonItemCard } from '../common/skeletons';
import { qryGetallItemsForUser } from '../../graphql/graphql';
import ErrorUI from '../common/error';
import { fnDoesObjectHaveItems, fnGetOriginalListFromItem } from '../../functions/index'
import ItemCard from './itemCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../common/button';
import Slider from '../common/slider';
import Filter from '../common/icons/filter-svg';
import Loading from '../common/loading';
import Search from '../common/search';

export default function Items(props) {
    const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;
    const envQueryLimit = parseInt(process.env.REACT_APP_ITMS_QUERY_LIMIT);
    const { auth } = props;
    const isAuthenticated = localStorage.getItem('isLoggedIn');
    const userId = localStorage.getItem(`user_id`);
    const COMPONENT_NAME = 'items';
    const [showLoading, setShowLoading] = useState(false);
    const initialQryLimit = envQueryLimit ? envQueryLimit : 10;
    // const initialQryLimit = 190;
    const [qryLimit, setQryLimit] = useState(initialQryLimit);
    const [offsetStart, setOffsetStart] = useState(0);
    const [itemsTotalCnt, setItemsTotalCnt] = useState(0);
    const [itemsTotalAmnt, setItemsTotalAmnt] = useState(0);
    const [userItems, setUserItems] = useState([]);
    const [userHasItems, setUserHasItems] = useState(false);
    const [userItemsLists, setUserItemsLists] = useState(false);
    const [orderBy, setOrderBy] = useState({ "amount": "desc_nulls_last" })
    const [whereBy, setWhereBy] = useState({
        "deletedState": {
            "_eq": false
        }, "obtained": {
            "_eq": false
        }
    })
    const [orderByName, setOrderByName] = useState('amount')
    const [orderByDirection_Amount, setOrderByDirection_Amount] = useState('desc')
    const [orderByDirection_Created, setOrderByDirection_Created] = useState('desc')
    const [filterVisible, setFilterVisible] = useState('');
    const [listFilterObject, setListFilterObject] = useState(null);
    const [listFilterObjectStoreValue, setListFilterObjectStoreValue] = useState(null);
    const [listFilterObjectWatchesValue, setListFilterObjectWatchesValue] = useState(false);
    const [showItemsFromPrivateLists, setShowItemsFromPrivateLists] = useState(false);

    // QUERY
    const { loading, error, data } = useQuery(qryGetallItemsForUser, {
        variables: { userId, offsetStart, qryLimit, orderBy, whereBy },
        suspend: false,
    });

    useEffect(() => {
        async function doUseEffectStuff() {
            if (data && data.allUsers && Array.isArray(data.allUsers) && data.allUsers.length > 0 && data.allUsers.length === 1) {

                if (displayConsole) {
                    console.log(`${COMPONENT_NAME} > Here is the data back for '${userId}':`, data);
                    console.log(`${COMPONENT_NAME} > Here is qryLimit':`, qryLimit);
                    console.log(`${COMPONENT_NAME} > Here is loading state':`, loading);
                }

                // GRAB THE FIRST ITEM IN THE User ARR
                const dataUser = data.allUsers[0];
                const dataUserHasItems = await fnDoesObjectHaveItems(dataUser.Items);
                if (dataUser && dataUserHasItems) {
                    // SHOW THE UI LATER
                    setUserHasItems(dataUserHasItems);

                    // GET ALL THE AGGREGATES FOR THE ITEMS THIS USER HAS
                    if (dataUser.totalDataset) {
                        setItemsTotalCnt(dataUser.totalDataset.aggregate.count ? dataUser.totalDataset.aggregate.count : 0)
                        setItemsTotalAmnt(dataUser.totalDataset.aggregate.max ? parseFloat(dataUser.totalDataset.aggregate.max.amount.substring(1).replace(',', '')) : 0)
                    }

                    // GET THE ACTUAL ITEMS
                    const fooDiddy = [];
                    const ownerLists = [];

                    for (let index = 0; index < dataUser.Items.length; index++) {
                        const item = dataUser.Items[index]

                        if (item) {
                            //GET THE list DETAILS FOR THIS ITEM
                            let ownerList = await fnGetOriginalListFromItem(item);
                            if (displayConsole) {
                                console.log(`${COMPONENT_NAME} > restult from fnGetOriginalListFromItem: `, ownerList);
                            }
                            if (ownerList && ownerList.deletedState === false) {
                                // IF THE USER REQUESTS - DON'T FILTER OUT private LISTS
                                if (showItemsFromPrivateLists) {
                                    ownerLists.push(ownerList);
                                    fooDiddy.push(item);
                                    // OTHERWISE, JUST SHOW visible LISTS
                                } else {
                                    if (ownerList.listDetails && ownerList.listDetails.visibilityType && ownerList.listDetails.visibilityType === 'visible') {
                                        ownerLists.push(ownerList);
                                        fooDiddy.push(item);
                                    }
                                }
                            }
                        }
                    }

                    // ADD THE ARRAYS
                    if (displayConsole) {
                        console.log(`${COMPONENT_NAME} > Here are the items:`, fooDiddy);
                        console.log(`${COMPONENT_NAME} > Here are the lists:`, ownerLists);
                    }
                    setUserItems(fooDiddy);
                    setUserItemsLists(ownerLists);
                }
            }
        }
        doUseEffectStuff();
    }, [data]);

    /**
     * THIS WILL SEND NEW orderBy VARIABLES TO THE QUERY
     */
    const handleChangeOrderby = async (inputOrderByName) => {
        if (inputOrderByName) {
            setOrderByName(inputOrderByName);
            setShowLoading(true); // THIS WILL GIVE THE BOUNCING PRESENT INSTEAD OF THE SKELETON CARDS
            setQryLimit(initialQryLimit); // THIS WILL RESET ANY 'Load more' REQUESTS FROM THE USER
            let newOrderByDirection = 'desc'
            if (inputOrderByName === 'amount') {
                newOrderByDirection = orderByDirection_Amount === 'desc' ? 'asc' : 'desc';
                setOrderByDirection_Amount(newOrderByDirection);
            } else {
                newOrderByDirection = orderByDirection_Created === 'desc' ? 'asc' : 'desc';
                setOrderByDirection_Created(newOrderByDirection);
            }

            setOrderBy({ [inputOrderByName]: `${newOrderByDirection}_nulls_last` });
        }
    }

    /**
   * FIRED WHEN THE USER MOVES THE SLIDER
   * @param {int} filterAmount 
   */
    const sliderChange = (filterAmount) => {
        if (filterAmount) {
            handleFilterSelection('price', filterAmount);
        }
    }

    /**
     * FILTERS THE ITEMS IN A LIST BASED UPON filterType AND filterValue
     * @param {string} filterType 
     * @param {any} filterValue 
     */
    const handleFilterSelection = (filterType, filterValue) => {
        if (filterType) {

            // // FILTERS BY STORES
            // setListFilterObject({ filterType, filterValue });
            // if (filterType === 'store') {
            //     setListFilterObjectStoreValue(filterValue);
            // }

            if (filterType === 'price') {
                // BUILD OUT THE whereBy PART OF THE QUERY
                setWhereBy({
                    "deletedState": {
                        "_eq": false
                    },
                    "obtained": {
                        "_eq": false
                    },
                    "amount": {
                        "_lte": `${filterValue}`
                    }
                });
            }

            if(filterType === 'search'){
                if(filterValue){
                    // BUILD OUT THE whereBy PART OF THE QUERY
                    setWhereBy({
                        "deletedState": {
                            "_eq": false
                        },
                        "obtained": {
                            "_eq": false
                        },
                        "name": {
                            "_ilike": `%${filterValue}%`
                        }
                    });
                } else {
                    setWhereBy({
                        "deletedState": {
                            "_eq": false
                        },
                        "obtained": {
                            "_eq": false
                        }
                    });
                }
            }

            // LAST - CHANGE THE TYPE OF COMPONENT WE SHOW DURING LOADING
            setShowLoading(true);

        }
    }

    /**
     * THIS WILL CLEAR ANY FILTERS APPLIED TO THE LIST
     */
    const clearFilterSelection = () => {
        setListFilterObject(null);
        setListFilterObjectStoreValue(null);
        setListFilterObjectWatchesValue(false);
    }

    /**
     * FIRES WHEN A FILTER IS CLICKED
     * @param {string} filterName 
     */
    const handleFilterClick = (filterName) => {
        if (filterName) {
            setFilterVisible(filterName);
        }
    }

    /** 
   * HANDLES THE <Toggle /> ACTION FOR FILTERING 'My Watches'
   * */
    const handleFilterSearch = (searchValue) => {
        handleFilterSelection('search', searchValue);
    };

    if (error) {
        if (displayConsole) {
            console.log(`${COMPONENT_NAME} > Error on query for userId: ${userId}`, error.message);
        }
        return (
            <Fragment>
                <div className="flex ">
                    <div className="flex-1"></div>
                    <div className="flex-none md:max-w-gyfto-max">
                        <div className="py-8">
                            <ErrorUI displayMessage="Click 'Sign In' above to see this list." errorMsg={error && error.message ? error.message : null} />
                        </div>
                    </div>
                    <div className="flex-1"></div>

                </div>
            </Fragment>
        );
    }

    return (
        <div>
            <div className="md:hidden sticky bg-white" style={{ top: '0px', zIndex: 900 }} visible={isAuthenticated}>
                <NavBar navType="H" auth={auth} />
            </div>
            <div className="flex md:justify-center">
                {/* THIS IS THE WHOLE CONTENT AREA CENTERED - MAX OF 800PX */}
                <div className="w-full md:max-w-gyfto-max items-center md:pl-4 md:pr-4">
                    <Helmet>
                        <title>Gyfto - Your Items</title>
                        <meta property="og:title" content={`Gyfto - Your Items`} />
                        <meta name="description" content={`Gyfto - Your Items`} />
                    </Helmet>
                    <PageSpace>
                        <div className="w-full" visible={isAuthenticated}>
                            <h1 className="page-section-header xs:pt-4 xs:pl-1 mb-2">your items</h1>
                            <div className="xs:pt-6">
                                {loading && showLoading === false ? (
                                    <Fragment>
                                        <div className="flex-wrap">
                                            <div className="px-4 py-4">
                                                <SkeletonItemCard />
                                            </div>
                                            <div className="px-4 py-4">
                                                <SkeletonItemCard />
                                            </div>
                                            <div className="px-4 py-4">
                                                <SkeletonItemCard />
                                            </div>
                                            <div className="px-4 py-4">
                                                <SkeletonItemCard />
                                            </div>
                                        </div>
                                        <div className="xs:flex md:hidden flex-wrap">
                                            <div className="px-4 py-4">
                                                <SkeletonItemCard />
                                            </div>
                                        </div>
                                    </Fragment>

                                ) : null
                                }
                                {userHasItems ? <Fragment>
                                    {/* THE ORDER BY SECTION */}
                                    <div className='flex mb-4 justify-end align-middle max-w-gyfto-drawer-header-max-w'>
                                        <div className='text-grey-dark pt-3'>Order by:</div>
                                        <div className='flex justify-between ml-2'>
                                            <div className={`cursor-pointer flex align-middle rounded-full ${orderByName && orderByName === 'amount' ? 'bg-grey-lightest' : ''}`} onClick={async () => { await handleChangeOrderby('amount') }}>
                                                <div className='flex align-middle p-3'>
                                                    <div className='px-1'><p className={orderByName && orderByName === 'amount' ? 'text-gyfto-green' : 'text-grey-darkest'}>Amount</p></div>
                                                    <div className='px-1'><FontAwesomeIcon icon={orderByDirection_Amount && orderByDirection_Amount === 'desc' ? 'arrow-down' : 'arrow-up'} size='1x' /></div>
                                                </div>
                                            </div>
                                            <div className={`cursor-pointer flex align-middle rounded-full ${orderByName && orderByName === 'updatedAt' ? 'bg-grey-lightest' : ''}`} onClick={async () => { await handleChangeOrderby('updatedAt') }}>
                                                <div className='flex align-middle p-3'>
                                                    <div className='px-1'><p className={orderByName && orderByName === 'updatedAt' ? 'text-gyfto-green' : 'text-grey-darkest'}>Created</p></div>
                                                    <div className='px-1'><FontAwesomeIcon icon={orderByDirection_Created && orderByDirection_Created === 'desc' ? 'arrow-down' : 'arrow-up'} size='1x' /></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* THE FILTERING SECTION */}
                                    <div className='flex mb-4 justify-end align-middle max-w-gyfto-drawer-header-max-w'>
                                        {_size(userItems) > 1 ? (
                                            <div className='w-full'>
                                                {/* THIS IS THE STICKY PANEL THAT HOUSES FILTERING, ACTION BUTTONS AND SELECTED ITEMS - BROKEN  UP INTO 3 PARTS */}
                                                <div className="flex justify-between items-center bg-white w-full min-h-gyfto-header-nav-mobile  rounded-default z-50 absolute sticky filter-bar border-grey-lighter border-b-2" >
                                                    {/* FIRST PART IS THE FILTERING */}
                                                    <div className='flex-none w-full'>
                                                        <div className="flex items-start min-h-gyfto-card-item-description w-full">
                                                            <div className="pt-3 px-2"><Filter secondaryColor="#7e7e7e" primaryColor="#7e7e7e" /></div>
                                                            <div onClick={() => handleFilterClick('price')} className="text-sm px-2 py-3 hover:bg-grey-lighter cursor-pointer">Price</div>
                                                            {/* <div onClick={() => handleFilterClick('store')} className="text-sm px-2 py-3 hover:bg-grey-lighter cursor-pointer">Stores</div> */}
                                                            <div className='flex w-full justify-end pr-2'>
                                                                <div className='w-64'>
                                                                    <Search parentCompChangeEventReceiver={handleFilterSearch} inputBoxPlaceholderText="Search for items..." showClearSearch={true} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                {/* THIRD PART IS THE SELECTED  ITEMS */}
                                                {filterVisible && filterVisible !== '' ? (
                                                    <div className="py-3 bg-grey-lightest" style={{ minHeight: '60px' }}>
                                                        {filterVisible === 'price' ? (
                                                            <div className="w-full px-4">
                                                                <Slider maxValue={itemsTotalAmnt} minValue={0} step={10} handleChangeEvent={sliderChange} enableSpecific={true} />
                                                            </div>
                                                        ) : null}
                                                        <div />
                                                        {filterVisible === 'store' ? (
                                                            // <div className="w-full flex flex-wrap pb-3" hidden={filterVisible !== 'store'}>
                                                            //     {/* THIS WILL FILTER OUT ANY DUPES AND THEN ORDER BY storeName */}
                                                            //     {itemStores.map((store, index, arr) => (
                                                            //         <div onClick={() => handleFilterSelection('store', store.storeName)} className={listFilterObjectStoreValue === store.storeName ? `filter-item-pill cursor-pointer m-1 hover:bg-green-lighter bg-green-lighter` : `filter-item-pill cursor-pointer m-1 hover:bg-green-lighter `} key={store.id}>{store.storeName}</div>
                                                            //     ))}
                                                            // </div>
                                                            null
                                                        ) : null}
                                                        {/* THIS IS THE BOTTOM OF THE PANEL THAT POPS OUT WHEN A FILTER IS APPLIED */}
                                                        <div className="flex-1">
                                                            <div className="flex justify-end my-auto pt-2 pr-2">
                                                                <div hidden={!filterVisible || filterVisible === ''}>
                                                                    <Button
                                                                        buttonType="pill-void-standard"
                                                                        xtra="text-xs py-1 px-2"
                                                                        weight="thin"
                                                                        responsive={false}
                                                                        onClick={async () => {
                                                                            // RESET THE VALUE OF THE FILTER(S)
                                                                            clearFilterSelection();
                                                                            setFilterVisible('');
                                                                        }}
                                                                    >
                                                                        <span className="text-center">clear filter</span>
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : null}

                                            </div>
                                        ) : (null)}
                                    </div>
                                    {/* THIS IS THE Loading COMPONENT THAT WILL SHOW ANY ANY RE-QUERY */}
                                    <div>
                                        {loading && showLoading ? (
                                            <div className="flex content-center">
                                                <Loading loadingText="Wait for it..." />
                                            </div>
                                        ) : (
                                            null
                                        )}
                                    </div>
                                    <Fragment>
                                        {loading === false && _size(userItems) > 0 ? (
                                            <Fragment>
                                                {userItems.map((item, index) => (
                                                    <div className="pb-6" key={item.id}>
                                                        <ItemCard item={item} type="min-wide" showListName={true} list={userItemsLists[index]} />
                                                    </div>
                                                ))}
                                                <div className='xs:w-full md:w-4/5 flex justify-center'>
                                                    {itemsTotalCnt > qryLimit ? (
                                                        <div className='pt-6 md:w-48 xs:w-52'>
                                                            <Button
                                                                onClick={() => {
                                                                    setShowLoading(true);
                                                                    setQryLimit(qryLimit + 20)
                                                                }}
                                                                buttonType="pill-standard"
                                                                weight="thin"
                                                                xtra="hover:shadow"
                                                            >
                                                                <div className="flex justify-center">
                                                                    <span>load more items</span>
                                                                </div>
                                                            </Button>
                                                        </div>
                                                    ) : null}

                                                </div>
                                                {/* <div>Qry Limit: {qryLimit}</div>
                                                <div>Items count: {itemsTotalCnt}</div> */}
                                            </Fragment>
                                        ) : null}
                                    </Fragment>

                                </Fragment> : null}
                            </div>
                        </div>
                    </PageSpace>
                </div>
            </div>
        </div>
    );
}
