import React, { useEffect, useState } from 'react';
import { Subscribe } from 'unstated';
import DrawerContainer from '../../containers/drawer';
import Button from '../common/button';
import Logo from '../common/logo';

export default function ModalUserExpired(props) {
  const { handleModalState, modalDataObject, auth } = props;
  const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;
  const [listId, setListId] = useState(null);

  // DO SOMETHING WITH THE modalDataObject - IF PROVIDED
  useEffect(() => {
    if (modalDataObject) {
      if (displayConsole) {
        console.log(`modal.user.expired > useEffect > modalDataObject: `, modalDataObject);
      }
      if(modalDataObject.listId && modalDataObject.listId !== ''){
        setListId(modalDataObject.listId);
      }
    }
  }, [modalDataObject]);

  const handleClickSignInSignUp = () => {
    // SAVE THIS SO THAT WE WILL BE TAKEN TO THE LIST AFTER LOGIN
    sessionStorage.setItem('deepLinkRoute',`${listId ? '/l/' + listId : '/sh'}`)
    auth.login();
  }

  return (
    <Subscribe to={[DrawerContainer]}>
      {appState => (
        // <div className="flex items-stretch h-full">
        <div className="flex ">

          <div className="relative w-auto my-6 mx-auto ">
            {/*content*/}
            <div className="max-w-gyfto-main-content-xs md:min-w-gyfto-modal-md xs:min-w-gyfto-modal-xs border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/*header*/}
              <div className="flex items-center justify-between px-5 pt-5 rounded-t">
                <Logo showText={true}/>
                <Button
                  onClick={e => handleModalState(false)}
                  buttonType="pill-standard"
                  weight="thin"
                  xtra="hover:shadow"
                >
                  <div className="flex">X</div>
                </Button>
              </div>
              {/*body*/}
              <div className="relative px-6 flex-auto">
                <p className="my-4 modal-header-font text-grey-dark font-semibold text-2xl">
                  Sign back in
                </p>
                <p className="leading-normal text-grey-dark -mt-2">Looks like you were looking at a Gyfto List but got signed out due to inactivity.  No worries! Click below to sign back in! </p>

              </div>
              {/*footer*/}
              <div className="flex items-center justify-center p-6  rounded-b">
                <div>
                  <Button
                    onClick={e => handleModalState(false)}
                    buttonType="pill-no-style"
                    weight="thin"
                    xtra=" cursor-pointer"
                  >
                    <div className="flex">CLOSE</div>
                  </Button>
                </div>
                <div>
                  <Button
                    onClick={e => handleClickSignInSignUp()}
                    buttonType="pill-standard"
                    weight="thin"
                    xtra="hover:shadow"
                  >
                    <div className="flex">SIGN BACK IN</div>
                  </Button>
                </div>

              </div>
            </div>
          </div>
        </div>
      )}
    </Subscribe>
  );
}
// }
