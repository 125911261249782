import React, { Fragment, useEffect, useState } from 'react';
import ItemSpace from './itemMin.css';
import ItemName from './itemName';
import ItemImage from './itemImage';
import ItemWatch from './itemWatch';
import HideShowLongText from '../common/hideShowLongText';
import UserAvatar from '../avatar/userAvatar';
import { fnGetItemWatchFromItem, fnGetItemUsersWatchFromItem } from '../../functions/index'

// eslint-disable-next-line
export default function ItemWatchedCardMinWide({ item, type, isShare }) {
  const displayConsole = false;
  const funcName = 'ItemWatchedCardMinWide';
  let currentUserId = localStorage.getItem(`user_id`);
  const [itemWatchedByCurrentUser, setItemWatchedByCurrentUser] = useState(false);
  const [itemWatchUsers, setItemWatchUsers] = useState([]);
  // const itemWatchUsers = [];

  // THIS WILL FIRE ONCE WE GET THE item OBJECT FROM PROPS
  useEffect(() => {
    async function doUseEffectStuff() {
      if (displayConsole) {
        console.log(`${funcName} > useEffect > item:`, item);
      }
      // IF THERE ARE itemWatches - LET'S GO
      if (item && item.itemWatches && Array.isArray(item.itemWatches) && item.itemWatches.length > 0) {

        // FIGURE OUT IF THE CURRENT USER HAS AN ITEM WATCH ON THIS item
        // itemWatchedByCurrentUser = await fnGetItemWatchFromItem(item, currentUserId, true);
        const localItemWatchedByCurrentUser = await fnGetItemWatchFromItem(item, currentUserId, true);
        setItemWatchedByCurrentUser(localItemWatchedByCurrentUser);
        if (displayConsole) {
          console.log(`${funcName} > useEffect > localItemWatchedByCurrentUser:`, localItemWatchedByCurrentUser);
        }

        // GET AN ARRAY OF ALL THE itemWatch USERS AND PUSH THE LOCAL itmWatchUsrs TO THE STATE
        const itmWatchUsrs = await fnGetItemUsersWatchFromItem(item, true);
        setItemWatchUsers(itmWatchUsrs);
        if (displayConsole) {
          console.log(`${funcName} > useEffect > itemWatchUsers:`, itemWatchUsers);
        }

      }
    }
    doUseEffectStuff();
  }, [item]);

  return ( 
    <Fragment>
      <ItemSpace type={type}>
        <div className="flex">
          <div className="item-card-left">
            <div className="pt-4">
              {itemWatchedByCurrentUser ? (
                <ItemWatch
                  listItem={item}
                  currentUserId={currentUserId}
                  itemWatches={item.itemWatches ? item.itemWatches : null}
                  itemWatchesType={2} // SORT THIS OUT FOR REAL
                  itemWatchId={item.itemWatches[0].id}
                  displayType={'compact'}
                />
              ) : (
                <div className="w-full flex justify-center ml-2">
                  <UserAvatar users={itemWatchUsers} size="xsm" />
                </div>
              )}

            </div>
          </div>
          <div className="flex-no-shrink w-2/3">
            <div className="p-4"><ItemName url={item.url} name={item.name} localRoute={false} /></div>
            <div className="px-4 py-4 item-description ">
              <HideShowLongText displayText={item && item.description ? item.description : ''} lengthToHide={30} />
            </div>
          </div>
          <div className="flex align-middle items-center">
            {item.imageUrl ? (
              <ItemImage
                imageUrl={item.imageUrl}
                processedImage={item.processedImage}
                size="medium"
              />
            ) : (
              <div />
            )}
          </div>
        </div>
      </ItemSpace>
    </Fragment>
  );
}
