import React, { useEffect, useState } from 'react';
import { Subscribe } from 'unstated';
import DrawerContainer from '../../containers/drawer';
import Button from '../common/button';
import Logo from '../common/logo';

export default function ModalListNoAuth(props) {
  const { handleModalState, modalDataObject, auth } = props;
  const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;
  const [listId, setListId] = useState(null);

  // DO SOMETHING WITH THE modalDataObject - IF PROVIDED
  useEffect(() => {
    if (modalDataObject) {
      if (displayConsole) {
        console.log(`modal.list.noauth > useEffect > modalDataObject: `, modalDataObject);
      }
      if(modalDataObject.listId && modalDataObject.listId !== ''){
        setListId(modalDataObject.listId);
      }
    }
  }, [modalDataObject]);

  const handleClickSignInSignUp = () => {
    // SAVE THIS SO THAT WE WILL BE TAKEN TO THE LIST AFTER LOGIN
    sessionStorage.setItem('deepLinkRoute',`${listId ? '/l/' + listId : '/sh'}`)
    auth.login();
  }

  return (
    <Subscribe to={[DrawerContainer]}>
      {appState => (
        <div className="flex ">
          <div className="flex w-auto my-6 mx-auto ">
            {/*content*/}
            <div className="xs:max-w-gyfto-drawer-content-xs md:max-w-gyfto-main-content-xs md:min-w-gyfto-modal-md xs:min-w-gyfto-modal-xs border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/*header*/}
              <div className="flex items-center justify-between px-5 pt-5 rounded-t">
                <Logo showText={true}/>
                <Button
                  onClick={e => handleModalState(false)}
                  buttonType="pill-standard"
                  weight="thin"
                  xtra="hover:shadow"
                >
                  <div className="flex">X</div>
                </Button>
              </div>
              {/*body*/}
              <div className="relative px-6 flex-auto">
                <p className="my-4 modal-header-font text-grey-dark font-semibold text-2xl">
                  Welcome to Gyfto
                </p>
                <p className="leading-normal text-grey-dark -mt-2">Gyfto is a <span className="text-grey-darker border-b border-gyfto-green">social gift list</span> that was started to enable us all to <span className="text-grey-darker border-b border-gyfto-green">create and share</span> gift lists, regardless of where those gifts exist - online or in the real world. </p>
                
                {/* WE MAY NOT ALWAYS HAVE THE LIST OWNER'S NAME - SO HANDLE THAT IN-LINE BELOW */}
                  <p className="pt-5 leading-normal text-grey-dark">
                    You've been invited by <span className={modalDataObject && modalDataObject.listOwnerFirstName && modalDataObject.listOwnerFirstName !== 'undefined' ? 'text-grey-darker border-b border-gyfto-green' : null}>{modalDataObject && modalDataObject.listOwnerFirstName && modalDataObject.listOwnerFirstName !== 'undefined' ? modalDataObject.listOwnerFirstName : 'a friend'}</span>
                  &nbsp;to see {modalDataObject && modalDataObject.listName ? 'their list ' : null} <span className={modalDataObject && modalDataObject.listName ? 'text-grey-darker border-b border-gyfto-green' : null}>{modalDataObject && modalDataObject.listName ? "'" + modalDataObject.listName + "'" : ' this list'}</span>!
                </p>
                
                <p className="pt-5 leading-normal text-grey-dark">When you sign up for <span className="text-grey-darker border-b border-gyfto-green">Gyfto</span> you can:</p>
                <div className="pt-6">
                  <div className="flex align-middle">
                    <div style={{ minWidth: "50px" }}>
                      <div className="bg-grey-lightest rounded-full w-11 h-11 flex flex-col items-center">
                        <div className="pt-1"><img alt="" src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGZSURBVEhL7ZaxSsNQFIY7iKg4KQ66iCBYbqwWg3pblTyBb+FD+BY6iJPQxVXEUcTeRKQgVFy6dehQQZBOorP+Jz0U2pyW9CaKQz74aUnO+U6bNLc3l5Hx5xhPzZqSOjYl5xmvX37JefO1c13V6x6X9MDxQ6PVLfLerVX1sBcOLomHrwsFDGoh33LUeUOpyZrW0/hgl3JNmBa5WDua4CC/CPGrIOkLvtEVht5I5/qj2uRk/XAgvJAF9iEn62W6ly68n6LAPuqT3DwmiimrotyYPPd7ziaPiVLdLbhSUxoJdH6Lx0QJL7V2PqTGJCGn8ZaneIwMnsVTqTlZ1Anrh1PTag7FnWizdTrkZP1ocGmOBIFVyMXaeKCpMiixSIV18am77gzW4BdBFiu0xpODdePxsF9cgKQ5KI2RJvWyxo5gZ2OF1ltBPiRYm9HD7cnAI7YK6Yh/q15aVMtt6fBYXlvCPW8Iw7rBOarh8nS5287P40fzNDiUjtE5Lvsdwp0Jdhu9obTzGHenYYvxvAksDGdh8J4PZ2T8B3K5H8jqs+A30u8UAAAAAElFTkSuQmCC' border="0" /></div>
                      </div>
                    </div>
                    <div className="modal-header-text text-lg font-bold text-gyfto-green pl-3">Choose items to buy and let others know</div>
                  </div>
                  <div className="flex align-middle pt-5">
                    <div style={{ minWidth: "50px" }}>
                      <div className="bg-grey-lightest rounded-full w-11 h-11 flex flex-col items-center">
                        <div className="text-lg text-gyfto-green pt-1">3</div>
                        <div className="text-xxs text-gyfto-green">more</div>
                      </div>
                    </div>
                    <div className="modal-header-text text-lg font-bold text-gyfto-green pl-3">See others that the list and items are shared with</div>
                  </div>
                  <div className="flex align-middle pt-5">
                    <div style={{ minWidth: "50px" }}>
                      <div className="bg-grey-lightest rounded-full w-11 h-11 flex flex-col items-center">
                        <div className="text-3xl text-gyfto-green">+</div>
                      </div>
                    </div>
                    <div className="modal-header-text text-lg font-bold text-gyfto-green pl-3">Create your own lists and share with others</div>
                  </div>
                </div>
              </div>
              {/*footer*/}
              <div className="flex items-center justify-center p-6  rounded-b">
                <div>
                  <Button
                    onClick={e => handleModalState(false)}
                    buttonType="pill-no-style"
                    weight="thin"
                    xtra=" cursor-pointer"
                  >
                    <div className="flex">CLOSE</div>
                  </Button>
                </div>
                <div>
                  <Button
                    onClick={e => handleClickSignInSignUp()}
                    buttonType="pill-standard"
                    weight="thin"
                    xtra="hover:shadow"
                  >
                    <div className="flex">SIGN UP</div>
                  </Button>
                </div>

              </div>
            </div>
          </div>
        </div>
      )}
    </Subscribe>
  );
}
// }
