import React, { useState } from 'react';
import PropTypes from 'prop-types';

export default function SVGMove(props) {
    const { primaryColor, secondaryColor, width, height, altText } = props;
    const [isShown, setIsShown] = useState(false);
    const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;

    if(displayConsole){
        console.log(`move-svg > props: `, props);
    }
    return (
        <div style={{ height: height, width: width }} onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                {altText ? (<title>{altText}</title>) : null}
                <defs />
                <g fill="none" fillRule="evenodd" transform="translate(1.73 -3.25)">
                    <rect width="23.186" height="23.174" x=".147" y="5.824" stroke={isShown ? `${secondaryColor}` : `${primaryColor}`} strokeWidth="3" rx="4" />
                    <path fill="#f4f4f4" d="M19.094 14.007h5.37v6.37h-5.37z" />
                    <path d="M32.82 17.16l-7.865 5.053.176-10.369z" fill={isShown ? `${secondaryColor}` : `${primaryColor}`} />
                    <path d="M24.922 17.167l-9.742-.03"  stroke={isShown ? `${secondaryColor}` : `${primaryColor}`} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="3" />
                </g>
            </svg>
        </div>
    );
}

SVGMove.propTypes = {
    primaryColor: PropTypes.string,
    secondaryColor: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    altText: PropTypes.string
};

SVGMove.defaultProps = {
    primaryColor: '#5FBA7D',
    secondaryColor: '#000000',
    width: "18px",
    height: "18px",
    altText: null
};
