import React, { Fragment, useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ItemCard from './index';
import { fnReorderListItem } from '../../functions/index';
/* eslint-disable  */

export default function DraggableItemList({ itemList, isShare, listId, isAuthenticated, currentUserCanSee, currentUserCanTakeActionOnItemCard, currentUserIsOwnerOrEditor, sharedListIds, listBuyType, itemFilterObject, filterVisible, listUserType, listEditorsLength, ogListMode, onListItemClick, selectedListItems }) {
  const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;
  const currentUserId = localStorage.getItem(`user_id`);
  const [showLoading, setShowLoading] = useState(false);
  const COMPONENT_NAME = `itemListDraggable`;
  if (displayConsole) {
    console.log(`${COMPONENT_NAME} > here is prop 'sharedListIds': `, sharedListIds);
    console.log(`${COMPONENT_NAME} > here is prop 'isAuthenticated': `, isAuthenticated);
    console.log(`${COMPONENT_NAME} > here is prop 'filterVisible': `, filterVisible);
  }

  const [orderedItemList, setOrderedItemList] = useState([]);
  // PUSH TO STATE - ONLY THE FIRST TIME WHEN THE data IS LOADED
  useEffect(() => {
    if (displayConsole) {
      console.log(`${COMPONENT_NAME} > useEffect > incoming itemList data: `, itemList);
    }

    // APPLY THE FILTER IF IT'S PASSED
    if (itemFilterObject && itemFilterObject.filterType && itemFilterObject.filterValue) {
      const tmpArr = [];
      itemList.map((itm) => {
        if (itm && itm.item) {
          if (itemFilterObject.filterType === 'price') {
            if (parseFloat(itm.item.amount.substring(1).replace(',', '')) <= itemFilterObject.filterValue) {
              tmpArr.push(itm);
            }
          }

          if (itemFilterObject.filterType === 'store') {
            if (itm.item.store === itemFilterObject.filterValue) {
              tmpArr.push(itm);
            }
            
          }

          if (itemFilterObject.filterType === 'search') {
            if (itm.item.name.toUpperCase().includes(itemFilterObject.filterValue.toUpperCase())) {
              tmpArr.push(itm);
            }
            if(displayConsole){
              console.log(`${COMPONENT_NAME} > useEffect > filterType === search for itm:`, itm);
            }
          }

          if (itemFilterObject.filterType === 'watches') {
            // IF TRUE - LOOK FOR ITEM WATCHES FOR THE CURRENT USER
            if (itemFilterObject.filterValue) {
              if (itm && itm.item) {
                if (itm.item.itemWatches && Array.isArray(itm.item.itemWatches) && itm.item.itemWatches.length > 0) {
                  // THERE ARE ITEM WATCHES - MAKE SURE WE ARE FILTERING ON THE ONES FOR THE CURRENT USER
                  for (const itmWatch of itm.item.itemWatches) {
                    if (itmWatch && itmWatch.watchUsers && itmWatch.watchUsers.id && itmWatch.watchUsers.id === currentUserId) {
                      tmpArr.push(itm);
                    }
                  }
                }
              }
            }
          }
        }
      })

      setOrderedItemList(tmpArr);
    } else {
      setOrderedItemList(itemList);
    }
  }, [itemList]);

  // THIS WILL REORDER THE UI
  const reorder = async (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  // THIS METHOD WILL BE CALLED BY EITHER A DRAG/DROP ON THE UI OR FORM THE ARROW BUTTONS
  const doTheReorder = async (sourceIdx, destinationIdx) => {

    setOrderedItemList(await reorder(orderedItemList, sourceIdx, destinationIdx));

    // CALLS OUT TO DO THE REORDER IN THE BACKEND
    try {
      setShowLoading(true);
      const reorderRespone = await fnReorderListItem(listId, sourceIdx, destinationIdx).then((res) => {
        if (res && res.reorderedListItem) {
          setShowLoading(false);
        }
      });
      if (displayConsole) {
        console.log(`${COMPONENT_NAME} > doTheReorder > call out to fnReorderListItem(${listId},${sourceIdx}, ${destinationIdx})`);
      }
    } catch (error) {
      if (displayConsole) {
        console.log(`${COMPONENT_NAME} > onDragEnd > error calling out to reorder the items in the list. Error: `, error);
      }
    }
  };

  // THIS IS CALLED FROM THE DRAG/DROP UI
  const onDragEnd = async result => {
    // const {source, destination} = result;
    // console.log(`${COMPONENT_NAME} > onDragEnd > source`, source);
    // console.log(`${COMPONENT_NAME} > onDragEnd > destination`, destination);
    if (!result.destination || !result.source) {
      if (displayConsole) {
        console.log(`${COMPONENT_NAME} > onDragEnd > missing source or destination`, result);
      }
      return;
    }

    if (Number.isNaN(result.destination.index) || Number.isNaN(result.source.index)) {
      if (displayConsole) {
        console.log(`${COMPONENT_NAME} > onDragEnd > missing source.index or destination.index`, result);
      }
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    if (displayConsole) {
      console.log(`${COMPONENT_NAME} > onDragEnd > result: `, result);
    }

    // CALL OUT TO THE SHARED REORDER METHOD
    await doTheReorder(result.source.index, result.destination.index);
    // await doTheReorder(1, 0);
  };

  // THIS METHOD WILL BE CALLED FROM THE SM SCREEN ADRROW BUTTONS
  const updateItemOrdinal = (direction, itemId, ordinal, fullStop) => {
    if (displayConsole) {
      console.dir(`${COMPONENT_NAME} > updateItemOrdinal > Moving the ordinal for '${itemId}' ${direction} to order number ${ordinal}`);
    }

    // 0-BASE THE ORDINAL
    ordinal = ordinal - 1;

    // CALL THE COMMON METHOD
    let destinationId = 0;
    if (direction === 'up') {
      if (fullStop) {
        destinationId = 0;
      } else {
        destinationId = ordinal - 1;
      }
    } else {
      destinationId = ordinal + 1;
    }

    if (displayConsole) {
      console.dir(`${COMPONENT_NAME} > updateItemOrdinal > About to call doTheReorder - ordinal: ${ordinal} destinationId: ${destinationId}`);
    }
    doTheReorder(ordinal, destinationId);
  };

  if (displayConsole) {
    console.log(`${COMPONENT_NAME} > orderedItemList: `, orderedItemList.filter(item => !item.deletedState));
  }
  return (
    <Fragment>
      {/* {showLoading ? (
        <div className="flex content-center">
          <Loading loadingText="Reorganizing your items!" />
        </div>
      ) : ( */}

      {/* IF THE USER IS AUTHENTICATED, SHOW THE DRAG/DROP OPTION; OTHERWISE, JUST RENDER THE LIST */}
      {isAuthenticated && currentUserIsOwnerOrEditor ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="list">
            {(provided, snapshot) => (
              <Fragment>
                {/* SHOW DRAGGABLE ON BIG SCREENS   */}
                <div className="xs:hidden md:inline-block"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {orderedItemList.filter(item => !item.deletedState).map((openItem, index) => (
                    // DON'T ENABLE Draggable IF THE LIST IS RE-ORDERING OR THERE IS A FILTER BEING APPLIED
                    <Draggable key={openItem.id} draggableId={openItem.id} index={index} isDragDisabled={showLoading || filterVisible !== ''}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{ cursor: 'default', ...provided.draggableProps.style, }}
                        >
                          <ItemCard
                            key={openItem.id}
                            listId={listId}
                            listItem={openItem.item}
                            listItemId={openItem.id}
                            ordinal={index + 1}
                            isShare={isShare}
                            currentUserCanSee={currentUserCanSee}
                            isAuthenticated={isAuthenticated}
                            sharedListIds={sharedListIds}
                            listBuyType={listBuyType}
                            listUserType={listUserType}
                            refs={provided.innerRef}
                            listIsReordering={showLoading}
                            filterVisible={filterVisible}
                            currentUserCanTakeActionOnItemCard={currentUserCanTakeActionOnItemCard}
                            currentUserIsOwnerOrEditor={currentUserIsOwnerOrEditor}
                            listEditorsLength={listEditorsLength}
                            ogListMode={ogListMode}
                            showSelectItem={orderedItemList && Array.isArray(orderedItemList) && orderedItemList.length > 1}
                            onListItemClick={onListItemClick}
                            selectedListItemsArr={selectedListItems}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>
                {/* DON'T SHOW DRAGGABLE ON SMALL SCREENS */}
                <div className="xs:inline-block md:hidden">
                  {orderedItemList.filter(item => !item.deletedState).map((openItem, index, arr) => (
                    <ItemCard
                      key={openItem.id}
                      listId={listId}
                      listItemId={openItem.id}
                      listItem={openItem.item}
                      ordinal={index + 1}
                      loopCount={index}
                      isShare={isShare}
                      currentUserCanSee={currentUserCanSee}
                      isAuthenticated={isAuthenticated}
                      loopTotalCount={arr.length}
                      updateItemOrdinal={updateItemOrdinal}
                      sharedListIds={sharedListIds}
                      listBuyType={listBuyType}
                      listUserType={listUserType}
                      listIsReordering={showLoading}
                      filterVisible={filterVisible}
                      currentUserCanTakeActionOnItemCard={currentUserCanTakeActionOnItemCard}
                      currentUserIsOwnerOrEditor={currentUserIsOwnerOrEditor}
                      listEditorsLength={listEditorsLength}
                      ogListMode={ogListMode}
                      showSelectItem={orderedItemList && Array.isArray(orderedItemList) && orderedItemList.length > 1}
                      onListItemClick={onListItemClick}
                      selectedListItemsArr={selectedListItems}
                    />
                  ))}
                </div>
              </Fragment>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <div >
          {orderedItemList.filter(item => !item.deletedState).map((openItem, index, arr) => (
            <ItemCard
              key={openItem.id ? openItem.id : index}
              listId={listId}
              listItemId={openItem.id}
              listItem={openItem.item}
              ordinal={index + 1}
              loopCount={index}
              isShare={isShare}
              currentUserCanSee={currentUserCanSee}
              isAuthenticated={isAuthenticated}
              loopTotalCount={arr.length}
              // updateItemOrdinal={updateItemOrdinal}
              sharedListIds={sharedListIds}
              listBuyType={listBuyType}
              listUserType={listUserType}
              listIsReordering={showLoading}
              filterVisible={filterVisible}
              currentUserCanTakeActionOnItemCard={currentUserCanTakeActionOnItemCard}
              currentUserIsOwnerOrEditor={currentUserIsOwnerOrEditor}
              listEditorsLength={listEditorsLength}
              ogListMode={ogListMode}
              showSelectItem={orderedItemList && Array.isArray(orderedItemList) && orderedItemList.length > 1}
              onListItemClick={onListItemClick}
              selectedListItemsArr={selectedListItems}
            />
          ))}
        </div>
      )}


    </Fragment>
  );
}
/* eslint-enable  */
