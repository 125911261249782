import React, { Fragment, useState, useEffect } from 'react';
import Logo from '../common/logo';
import ItemName from '../item/itemName';
import history from '../history/index';
import { fnSaveSearchHistory } from '../../functions/index';
export default function ListCardMinAlt({ list, saveClickToSearch, appState }) {
  // eslint-disable-next-line

  const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;
  const COMPONENT_NAME = "list.card.min.alt";
  const [currentListUser, setCurrentListUser] = useState(null);

  // RUNS ON EACH COMPONENT LOAD TO  FIGURE OUT THE LIST OWNER
  useEffect(() => {
    if (list) {
      if (displayConsole) {
        console.log(`${COMPONENT_NAME} > list: `, list);
      }

      // PRE-PROCESS THIS LIST TO FIGURE OUT THE LIST OWNER FOR SharedWith B/C THAT CAN GET MESSY TO DO IN-LINE
      if (list && list.listUserType === "SharedWith" && list.sourceList && list.sourceList.listUsers && Array.isArray(list.sourceList.listUsers) && list.sourceList.listUsers.length > 0) {
        // NEED TO ITERATE THROUGH THIS listUsers TO FIND 'Owner'
        for (const usr of list.sourceList.listUsers) {
          if (usr && usr.listUserType === 'Owner' && usr.user) {
            setCurrentListUser(usr.user);
          }
        }
      }
    }
  }, [list]);

  /**
   * SAVES THIS TO THE HISTORY TO SHOW IN THE RESULTS
   * @param {*} listName 
   * @param {*} listId 
   * @param {*} listOwner 
   */
  async function processClick(listName, listId, listOwner) {
    if (saveClickToSearch) {
      if (listName) {
        await fnSaveSearchHistory(listName, listId, listOwner);
      }
    }

    if (listId !== null) {
      history.push(`/l/${list.id}`)
    }

    // IF PROVIDED, WE'LL SAVE THIS CLICK TO THE APP STATE
    if (appState && list) {
      appState.selectList(list.id, list.listId);
      // console.log(`IN APPSTATE: list.id: ${list.id} list.listId: ${list.listId}`, list);
    } else {
      //console.log(`NO APPSTATE`)
    }

  }

  return (
    <Fragment>

      {list ? (
        <div className=" cursor-pointer flex-no-grow flex-no-shrink p-3 bg-white items-center rounded shadow-md" key={list.id} style={{ height: '140px', minWidth: '290px', maxHeight: '140px' }} onClick={async () => { await processClick(list.listDetails.name, list.id, currentListUser) }}>
          <div className="flex justify-start pt-2">
            <div >
              {list.imageUrl ? (
                <div className="h-12 w-12 rounded-full mx-auto bg-grey-lighter" style={{ "backgroundImage": `url('${list.imageUrl}')`, "backgroundSize": "contain", "overflow": "hidden", "backgroundRepeat": "no-repeat", "backgroundPosition": "center" }} />
              ) : (
                <div className="h-12 w-12 rounded-full mx-auto bg-grey-lighter pt-2 pl-2"><Logo showText={false} /></div>
              )
              }
            </div>
            <div className="pl-3">
              <div className="font-bold text-xl mb-2 bg-white">
                <ItemName url={`l/${list.id}`} localRoute name={list.listDetails.name} />
              </div>
              {list.listUserType === 'SharedWith' && currentListUser ? (
                <div className="text-grey-dark text-xs mb-2">Created by: {currentListUser.firstName} {currentListUser.lastName}</div>
              ) : null}
              {list && list.listDetails && list.listDetails.description ? (
                <div className="text-grey-darker text-base min-h-gyfto-card-item-description bg-white">{list.listDetails.description.length > 60 ? list.listDetails.description.substring(0, 57) + '...' : list.listDetails.description}</div>
              ) : null}
            </div>
          </div>
        </div>
      ) : (
        <span>Expected list but didn't get one</span>
      )}

    </Fragment>
  );
}
