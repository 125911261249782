import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Subscribe } from 'unstated';
import DrawerContainer from '../../containers/drawer';
import ListRender from './list.render';
import PageSpace from '../common/pageSpace';
import { SkeletonItemCard } from '../common/skeletons';
import Modal from '../../components/modal/index';

export default function ListConatinerNoAuth(props) {

  // eslint-disable-next-line
  const { match, auth, fromShareLink } = props;
  const { listId } = match.params;
  const currentUserId = localStorage.getItem(`user_id`);
  let error = null;
  const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;
  const [listData, setListData] = useState(null);
  const [showModal, setShowModal] = useState(true);

  if (displayConsole) {
    console.log(`list.container.noauth > Props: listId: ${listId}`);
  }

  // THIS WILL GET THE LIST
  useEffect(() => {
    async function getListData() {
      if (listId) {
        const apiUrl = process.env.REACT_APP_WEB_FUNCTION_BASE_URL_NO_AUTH;
        const apiCallUrl = `${apiUrl}/gyfto_list_get?listId=${listId}`;

        if (displayConsole) {
          console.log(`list.container.noauth > listId: ${listId}`);
          console.log(`list.container.noauth > apiCallUrl: ${apiCallUrl}`);
        }

        await fetch(apiCallUrl, {
          mode: 'cors',
          method: 'GET',
        })
          .then(res => res.json())
          .then(result => {
            if (result && result.List) {
              if (displayConsole) {
                console.log(`list.container.noauth > result:`, result);
              }

              let localList = result.List;
              const tempObj = {
                "id": `${localList.id}`
                , "listId": localList.listId
                , "createdAt": `${localList.createdAt}`
                , "deletedState": localList.deletedState
                , "listDetails": localList.ListDetails
                , "listUsers": localList.ListUsers
                , "listItems": localList.ListItems
                , "sharedLists": localList.SharedLists
                , "sourceList": localList.SourceList
                , "__tyepname": "List"
              }
              const List = [];
              List.push(tempObj);
              if (displayConsole) {
                console.log(`list.container.noauth > List:`, List);
              }
              setListData({ List });
            }
          })
          .catch((error) => {
            if (displayConsole) {
              console.error(`list.container.noauth > Error attempting to get list: ${listId} with error: ${error.message}`);

              throw new Error(error.message);
            }
          });
      } else if (displayConsole) {
        console.log(`list.container.noauth > Cannot find list: missing 'sharedListId'`);
      }
    }

    getListData();
  }, []);

  return (
    <Subscribe to={[DrawerContainer]}>
      {appState => (
        <Fragment>
          {!listData ? (
            <PageSpace>
              <div className="flex w-full justify-center content-center">
                <div>
                  <SkeletonItemCard />
                  <br />
                  <br />
                  <SkeletonItemCard />
                  <br />
                  <br />
                  <SkeletonItemCard />
                  <br />
                  <br />
                  <SkeletonItemCard />
                  <br />
                  <br />
                  <SkeletonItemCard />
                  <br />
                  <br />
                  <SkeletonItemCard />
                </div>
              </div>
            </PageSpace>
          ) : (
              <Fragment>
                {currentUserId !== null ? null : (
                  <Modal showModal={showModal} modalType="sharedListCTA" dataObject={listData} auth={auth} />
                )}
                <ListRender appState={appState} listId={listId} auth={auth} fromShareLink={fromShareLink} loading={false} error={error} data={listData} />
              </Fragment>
            )}
        </Fragment>
      )}
    </Subscribe>

  );
}

ListConatinerNoAuth.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      sharedListId: PropTypes.string,
    }).isRequired,
  }).isRequired,
  fromShareLink: PropTypes.bool
};
