import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Carousel from 'nuka-carousel';
import CarouselButton from '../common/buttons/carouselButton'

export default function ItemImageCarousel(props) {
  const { imageThumbnails, itemTitle, fnSaveItemUrl, userOverriddenImage } = props;
  const [hidePrev, setHidePrev] = useState(true);
  const [hideNext, setHideNext] = useState(false);
  let render = false;
  const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;

  // BECAUSE OF MULTIPLE MOUNTS OF THE PARENT COMPONENT WE
  // NEED TO MAKE SURE THAT THE imageThumbnails ARRAY THAT
  // IS GETTING PASSED ACTUALLY HAS PROPERTIES - IF IT DOES
  // THEN PUSH TO A COMPONENT-SCOPED ARRAY OF IMAGE URLS
  const imgThumbs = [];
  if (userOverriddenImage && Array.isArray(userOverriddenImage) && userOverriddenImage.length > 0) {
    userOverriddenImage.map((img, idx) => {
      render = true;
      imgThumbs.push(img.imageUrl);
    });
  } else if (imageThumbnails && Array.isArray(imageThumbnails) && imageThumbnails.length > 0) {
    imageThumbnails.map((img, idx) => {
      render = true;
      imgThumbs.push(img.imageUrl);
    });
  }

  const slides = imgThumbs.map((img, index) => <img src={img} alt={itemTitle} key={index} />);

  // CALLS OUT TO A FUNCTION THAT WILL SAVE THE URL TO USE AS THE ITEM IMAGE
  const setImageUrl = slideIdx => {
    if (imgThumbs && Array.isArray(imgThumbs) && imgThumbs.length > 0 && imgThumbs[slideIdx]) {
      fnSaveItemUrl(imgThumbs[slideIdx]);
    }
  };

  // MAKE SURE WE SAVE THE FIRST ONE FOR SCENARIOUS WHERE THIS ONLY ONE!
  useEffect(
    () => {
      if (imageThumbnails && Array.isArray(imageThumbnails) && imageThumbnails.length > 0) {
        // THIS IS THE FIRST ITEM IN BOTH THE .map ITERATION AND THE imageThumbnails ARRAY
        fnSaveItemUrl(imageThumbnails[0].imageUrl);
      }
    },
    [imageThumbnails]
  );


  // ALL THE SLIDE STATE MANAGEMENT STUFF
  const manageArrowState = slideIdx => {
    if (displayConsole) {
      console.log(`itemImageCarousel > manageArrowState > slide changed to ${slideIdx} out of ${imgThumbs.length} slides`);
    }
    //HIDE / SHOW THE PREV BUTTON
    if (slideIdx > 0) {
      setHidePrev(false);
    } else {
      setHidePrev(true);
    }

    // HIDE / SHOW THE NEXT BUTTON
    if (slideIdx === (imgThumbs.length - 1)) {
      setHideNext(true);
    } else {
      setHideNext(false);
    }

    // SAVE THIS IMAGE STATE BACK UP TO THE PARENT COMPONENT
    setImageUrl(slideIdx);
  };

  return (
    <Fragment>
      {render ? (
        <div className="card-container pl-1 outline-none">
          <Carousel
            afterSlide={slideIdx => manageArrowState(slideIdx)}
            autoplay={false}
            cellAlign="center"
            renderBottomCenterControls={null}
            renderCenterLeftControls={({ previousSlide }) => (
              <div className="outline-none" hidden={hidePrev}><CarouselButton direction="L" handleClickEvent={previousSlide} /></div>
            )}
            renderCenterRightControls={({ nextSlide }) => (
              <div className="outline-none" hidden={hideNext}><CarouselButton direction="R" handleClickEvent={nextSlide} /></div>
            )}
            slideIndex={0}
            slideListMargin={0}
            slidesToScroll={1}
            slidesToShow={1}
            transitionMode="scroll"
            withoutControls={imgThumbs && imgThumbs.length < 2}
            wrapAround={false}
            zoomScale={Number(0.5 || 0)}
            width={'300px'}
          >
            {slides}
          </Carousel>
        </div>
      ) : (
          <div />
        )}
    </Fragment>
  );
}

ItemImageCarousel.propTypes = {
  imageThumbnails: PropTypes.array,
  itemTitle: PropTypes.string,
  fnSaveItemUrl: PropTypes.func,
  userOverriddenImage: PropTypes.array,
};

ItemImageCarousel.defaultProps = {
  imageThumbnails: [],
  itemTitle: '',
  fnSaveItemUrl: null,
  userOverriddenImage: [],
};
