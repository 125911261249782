import React, { Fragment } from 'react';
import { Subscribe } from 'unstated';
import DrawerContainer from '../../containers/drawer';
import DrawerListEdit from './draweritem.list.edit';

export default function DrawerListCreate(props) {
  return (
    <Subscribe to={[DrawerContainer]}>
      {appState => (
        <Fragment>
          <DrawerListEdit
            list={appState.state.selectedListObj}
            listId={appState.state.selectedListObj.id}
            listName={
              appState.state.selectedListObj.listDetails.name ? appState.state.selectedListObj.listDetails.name : ''
            }
            listDescription={
              appState.state.selectedListObj.listDetails.description
                ? appState.state.selectedListObj.listDetails.description
                : ''
            }
            listDetailsId={appState.state.selectedListObj.listDetails.listDetailsId ? appState.state.selectedListObj.listDetails.listDetailsId : null}
            buyType={appState.state.selectedListObj.listDetails.buyType ? appState.state.selectedListObj.listDetails.buyType : ''}
            visibilityType={appState.state.selectedListObj.listDetails.visibilityType ? appState.state.selectedListObj.listDetails.visibilityType : ''}
          />
        </Fragment>
      )}
    </Subscribe>
  );
}
