import React, { Fragment, useState } from 'react';
import { Subscribe } from 'unstated';
import { useMutation } from '@apollo/react-hooks';
import { Formik, Form, Field } from 'formik';
import Button from '../common/button';
import DrawerContainer from '../../containers/drawer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { mutationUpdateItem } from '../../graphql/graphql';
import { fnUploadItemImage } from '../../functions/index';
import ErrorUI from '../common/error';
import DrawerSpace from '../common/drawerSpace';
import ItemImage from '../item/itemImage';

export default function DrawerItemItemDelete() {
  const COMPONENT_NAME = 'draweritem.item.edit';
  const isRequired = message => value => (value ? undefined : message);
  const [updateItem] = useMutation(mutationUpdateItem);
  const [errorMsg, setErrorMsg] = useState(null);
  const [imageUrlVisible, setImageUrlVisible] = useState(false);
  const displayConsole = process.env.REACT_APP_DISPLAY_CONSOLE === 'local' && false;
  const [itmUrl, setItmUrl] = useState(null);
  const [uploadedThumbnail, setUploadedThumbnail] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [itemImgUrlChanged, setItemImgUrlChanged] = useState(false);

  
  const saveItemImgUrl = inputUrl => {
    if (displayConsole) {
      console.log(`${COMPONENT_NAME} > inputUrl: ${inputUrl}`);
    }
    if (inputUrl) {
      setItmUrl(inputUrl);
      setItemImgUrlChanged(true);
    }
  };
  
  const fileAttached = async event => {
    if (displayConsole) {
      console.log(`${COMPONENT_NAME} > fileAttached > file attached!: `, event.target.files[0]);
    }
    if (event && event.target && event.target.files && event.target.files[0]) {
      const upFile = event.target.files[0];
      if (upFile.size < 15000000) {
        // SAVE THE FILE
        try {
          setShowLoading(true);
          await fnUploadItemImage(upFile).then(res => {
            if (displayConsole) {
              console.log(`${COMPONENT_NAME} > fileAttached > This came back from the upload | res: `, res);
            }

            if (res && res.public_id && res.secure_url) {
              // UPLOAD WAS SUCCESSFUL - NOW SAVE THE IMAGE URL
              setUploadedThumbnail([{ imageUrl: res.secure_url }]);
              saveItemImgUrl(res.secure_url);
              setShowLoading(false);
            }
          });
        } catch (error) {
          if (displayConsole) {
            console.log(`${COMPONENT_NAME} > fileAttached > Error uploading item picture | Error: `, error);
          }
        }
      } else if (displayConsole) {
        console.log(
          `${COMPONENT_NAME} > fileAttached > File is too big to upload (${upFile.size
          }) - please select another image. `,
          event.target.files[0]
        );
      }
    }
  };

  if (errorMsg) {
    return (
      <DrawerSpace>
        <ErrorUI errorMsg={errorMsg} />
      </DrawerSpace>
    );
  }

  return (
    <Subscribe to={[DrawerContainer]}>
      {appState => (
        <DrawerSpace>

          <div>
            <div className="drawer-header-description-text pb-3">Enter all the specifics for your item</div>

            <Formik
              initialValues={{
                qty:
                  appState.state.selectedItemObj && appState.state.selectedItemObj.qty
                    ? appState.state.selectedItemObj.qty
                    : 1,
                color:
                  appState.state.selectedItemObj && appState.state.selectedItemObj.color
                    ? appState.state.selectedItemObj.color
                    : '',
                size:
                  appState.state.selectedItemObj && appState.state.selectedItemObj.size
                    ? appState.state.selectedItemObj.size
                    : '',
                code:
                  appState.state.selectedItemObj && appState.state.selectedItemObj.code
                    ? appState.state.selectedItemObj.code
                    : '',
                name:
                  appState.state.selectedItemObj && appState.state.selectedItemObj.name
                    ? appState.state.selectedItemObj.name
                    : '',
                description:
                  appState.state.selectedItemObj && appState.state.selectedItemObj.description
                    ? appState.state.selectedItemObj.description
                    : '',
                amount:
                  appState.state.selectedItemObj && appState.state.selectedItemObj.amount
                    ? appState.state.selectedItemObj.amount.substring(1)
                    : '',
                store:
                  appState.state.selectedItemObj && appState.state.selectedItemObj.store
                    ? appState.state.selectedItemObj.store
                    : '',
                currency:
                  appState.state.selectedItemObj && appState.state.selectedItemObj.currency
                    ? appState.state.selectedItemObj.currency
                    : '',
                imageUrl:
                  appState.state.selectedItemObj && appState.state.selectedItemObj.imageUrl
                    ? appState.state.selectedItemObj.imageUrl
                    : '',
                url:
                  appState.state.selectedItemObj && appState.state.selectedItemObj.url
                    ? appState.state.selectedItemObj.url
                    : '',
                ordinal:
                  appState.state.selectedItemObj && appState.state.selectedItemObj.ordinal
                    ? appState.state.selectedItemObj.ordinal
                    : 0,
              }}
              initialTouched={{
                field: true,
              }}
              onSubmit={(values, actions) => {
                // const haveListId = !!appState.state.selectedListId;
                if (appState && appState.state && appState.state.selectedItemObj && appState.state.selectedItemObj.id) {
                  const updateItemId = appState.state.selectedItemObj.id;

                  // 1) PERSIST THE FORM DETAILS TO STATE
                  if (displayConsole) {
                    console.log(`itemCreate.enterItemDetails > Here are the values of the new item:`);
                    console.dir(values);
                  }
                  appState.transferItemToDrawer(values);

                  // 2) FIGURE OUT WHAT TO DO NEXT
                  if (updateItemId && values.name) {
                    // IF WE HAVE A listId THEN WE HAVE ENOUGH TO SAVE

                    // THIS IS CALLING GRAPHQL BACKEND DIRECTLY TO CREATE THE ITEM
                    if (displayConsole) {
                      console.log(
                        `itemEdit.enterItemDetails > about to call mutation - update item for listId: ${appState.state.selectedListId
                        } | item:`
                      );
                      console.dir(values);
                    }
                    updateItem({
                      variables: {
                        itemId: updateItemId,
                        name: values.name,
                        description: values.description,
                        amount: values.amount,
                        ordinal: values.ordinal,
                        qty: values.qty,
                        color: values.color,
                        size: values.size,
                        store: values.store,
                        url: values.url,
                        imageUrl: itemImgUrlChanged ? itmUrl : values.imageUrl,
                        processedImage: false,
                        code: values.code,
                      },
                    }).then(({ data }) => {
                      if (displayConsole) {
                        console.log(
                          `${COMPONENT_NAME} > updated item '${updateItemId}': `, data
                        );
                      }
                      appState.showMessageCard('msg', `${values.name} updated!`);
                      appState.closeDrawer();
                    }).catch(e => {
                      if (displayConsole) {
                        console.error(`${COMPONENT_NAME} > unable to update Item '${updateItemId}' with 'mutationUpdateItem' mutation: `, e);
                      }
                      setErrorMsg(e.message.toString());
                    });
                  } else {
                    if (displayConsole) {
                      console.log(`${COMPONENT_NAME} > unable to update item - missing ID`);
                    }

                    setErrorMsg('Item Name required');
                  }
                }

                actions.setSubmitting(false);
              }}
              render={({ errors, status, touched, isSubmitting, setFieldValue }) => (
                <Form>
                  <div className="pb-2 flex justify-center mx-auto">
                    <ItemImage
                      imageUrl={itemImgUrlChanged ? itmUrl : appState.state.selectedItemObj.imageUrl}
                      processedImage={appState.state.selectedItemObj.processedImage}
                      size="medium"
                    />
                  </div>
                  <div className="flex justify-end py-2">
                    <Button
                      buttonType="pill-void-standard-light"
                      xtra="text-xs py-1 px-2"
                      weight="thin"
                      responsive={false}
                      onClick={async () => {
                        await setImageUrlVisible(true);
                      }}
                    >
                      <span className="text-center">change image</span>
                    </Button>
                  </div>
                  {imageUrlVisible ? (
                    <Fragment>
                      <div className="">
                        <input type="file" id="fileElem" accept="image/*" onChange={fileAttached} style={{ opacity: 0 }} />
                        <label htmlFor="fileElem" className="btn btn-pill-color border-blue bg-blue text-white hover:bg-white hover:text-blue btn-thin hover:shadow xs:py-4 md:py-3 xs:px-6 md:px-4 xs:text-base md:text-sm" style={{ outline: 'none' }}><FontAwesomeIcon icon="camera" size="1x" htmlFor="fileElem" /> &nbsp; &nbsp;Upload</label>
                      </div>
                      <div className='w-full flex py-6 content-center'>
                        <div className='w-full '></div>
                        <div className='form-label'>OR</div>
                        <div className=' w-full'></div>

                      </div>
                      <span className="form-label">item image</span>
                      <Field
                        type="text"
                        className="w-full form-input focus:outline-none focus:shadow-outline"
                        name="imageUrl"
                      />
                      <br />
                      <div className="flex justify-end content-end mt-2">
                        <Button
                          buttonType="pill-void-standard"
                          xtra="text-xs py-1 px-2"
                          weight="thin"
                          responsive={false}
                          onClick={async () => {
                            // RESET THE VALUE OF THE values.imageUrl FIELD
                            setFieldValue('imageUrl', '', false);
                          }}
                        >
                          <span className="text-center">clear image location</span>
                        </Button>
                      </div>
                      <br />
                      <br />
                    </Fragment>
                  ) : (<Fragment />)}
                  <div className="flex justify-between">
                    <div className="form-label">item name</div>
                    {/* <div className="form-plain text-grey">{name.length} / 30</div> */}
                  </div>
                  <Field
                    type="text"
                    className="w-full form-input focus:outline-none focus:shadow-outline"
                    name="name"
                    maxLength={30}
                    validate={isRequired('Item name is required')}
                  />
                  {errors.name && touched.name ? (
                    <div className="form-input-error">
                      <div className="p-2">{errors.name}</div>
                    </div>
                  ) : null}
                  <br />
                  <br />
                  <span className="form-label">item description</span>
                  <Field
                    type="text"
                    rows="6"
                    component="textarea"
                    className="w-full form-input focus:outline-none focus:shadow-outline"
                    name="description"
                  />
                  <br />
                  <div className="flex justify-end content-end mt-2">
                    <Button
                      buttonType="pill-void-standard"
                      xtra="text-xs py-1 px-2"
                      weight="thin"
                      responsive={false}
                      onClick={async () => {
                        // RESET THE VALUE OF THE values.description FIELD
                        setFieldValue('description', '', false);
                      }}
                    >
                      <span className="text-center">clear description</span>
                    </Button>
                  </div>
                  <br />
                  <div className="flex justify-between">
                    <div>
                      <span className="form-label">price</span>
                      <Field
                        // type="number"
                        className=" w-24 form-input focus:outline-none focus:shadow-outline"
                        name="amount"
                      />
                    </div>
                    <div>
                      <span className="form-label">qty</span>
                      <Field
                        type="number"
                        className=" w-24 form-input focus:outline-none focus:shadow-outline"
                        name="qty"
                      />
                    </div>
                  </div>
                  <br />
                  <br />
                  <div className="flex justify-between">
                    <div>
                      <span className="form-label">color</span>
                      <Field
                        type="text"
                        className=" w-24 form-input focus:outline-none focus:shadow-outline"
                        name="color"
                      />
                    </div>
                    <div>
                      <span className="form-label">size</span>
                      <Field
                        type="text"
                        className=" w-24 form-input focus:outline-none focus:shadow-outline"
                        name="size"
                      />
                    </div>
                  </div>
                  <br />
                  <br />
                  <span className="form-label">discount code</span>
                  <Field
                    type="text"
                    className="w-full form-input focus:outline-none focus:shadow-outline"
                    name="code"
                  />
                  <br />
                  <br />
                  <span className="form-label">store / site</span>
                  <Field
                    type="text"
                    className="w-full form-input focus:outline-none focus:shadow-outline"
                    name="store"
                  />
                  <br />
                  <br />
                  {/* FOOTER */}
                  <div className="py-4 ">
                    <div className="flex justify-between px-2 ">
                      <Button
                        buttonType="pill-cancel"
                        weight="thin"
                        xtra="hover:shadow px-2"
                        onClick={() => {
                          appState.closeDrawer();
                        }}
                      >
                        <span className="text-center">Cancel</span>
                      </Button>

                      <Button buttonType="pill-add" weight="thin" xtra="hover:shadow px-2" type="submit" showSpinner={true}>
                        <span className="text-center">update</span>
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            />
          </div>
        </DrawerSpace>
      )
      }
    </Subscribe >
  );
}
